import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { Alert, Button, Form, Input, Spin } from 'antd'

import EmailCheckMessage from './components/email-check-message'

import SignInLink from '~/authentication/forms/components/sign-in-link'
import { useResetPassword } from '~/features/reset-password-form/mutations/use-reset-password'

interface FormRule {
  email: string
}

export default function NewPasswordForm() {
  useLingui()

  const [form] = Form.useForm()

  const { mutate: resetPassword, status: resetPasswordStatus } = useResetPassword()

  function handleFinish(values: FormRule) {
    resetPassword({ email: values.email })
  }

  if (resetPasswordStatus === 'success')
    return <EmailCheckMessage />

  return (
    <Spin spinning={resetPasswordStatus === 'loading'}>
      {resetPasswordStatus === 'error' && (
        <Alert
          message={t`Ошибка: Невозможно сбросить пароль. Аккаунт не был подтвержден.`}
          type="error"
          style={{ marginBottom: 20 }}
          showIcon
        />
      )}

      <Form layout="vertical" name="new-password" form={form} onFinish={handleFinish}>
        <Form.Item<FormRule>
          label={t`Email`}
          name="email"
          rules={[{ required: true }, { type: 'email' }]}
          hasFeedback
        >
          <Input type="email" autoComplete="email" />
        </Form.Item>

        <Form.Item>
          <p>{t`Требуется подтвержденный адрес электронной почты SupplyDirector.`}</p>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" block>
            {t`Сбросить пароль`}
          </Button>
        </Form.Item>

        <SignInLink />
      </Form>
    </Spin>
  )
}
