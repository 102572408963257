import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { useQueryClient } from '@tanstack/react-query'
import { App } from 'antd'
import { useState } from 'react'
import { ReceivedOrderStatusEnum } from '~/domain/order/enums'
import OrderReceivedResponseDialog from '~/features/order-received-response-dialog'

import { useRejectOrder } from '~/pages/orders/received/mutations/use-reject-order'
import TableActions from '~/shared/components/table-actions'
import { companyKeys } from '~/shared/tanstack/keys/company'

interface Props {
  status: ReceivedOrderStatusEnum
  productName: string
  orderId: number
}

export default function TableActionMenu({ status, orderId, productName }: Props) {
  useLingui()
  const { modal } = App.useApp()
  const queryClient = useQueryClient()

  const [showConfirmForm, setShowConfirmForm] = useState(false)

  const { mutate: reject } = useRejectOrder()

  const canBeConfirmed = status === ReceivedOrderStatusEnum.WAITING
  const canBeRejected = status === ReceivedOrderStatusEnum.WAITING

  function handleRejectClick() {
    modal.confirm({
      title: t`Вы уверены, что хотите отклонить запрос?`,
      centered: true,
      onOk: () => {
        reject(orderId, {
          onSuccess: () => queryClient.invalidateQueries({ queryKey: companyKeys.receivedOrders({}) }),
        })
      },
    })
  }

  function handleConfirm() {
    queryClient.invalidateQueries({ queryKey: companyKeys.receivedOrders({}) })
  }

  return (
    <>
      <TableActions items={[
        {
          key: 'confirm',
          handler: () => setShowConfirmForm(true),
          visible: canBeConfirmed,
        },
        {
          key: 'reject',
          handler: handleRejectClick,
          visible: canBeRejected,
        },
      ]}
      />

      {/* TODO: Заменить на PopConfirm */}
      <OrderReceivedResponseDialog
        orderId={orderId}
        productName={productName}
        open={showConfirmForm}
        onClose={() => setShowConfirmForm(false)}
        onOk={handleConfirm}
      />
    </>
  )
}
