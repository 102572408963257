import type { TourStepProps } from 'antd'

export function createTourStep({
  title,
  description,
  nextButtonProps,
  ...args
}: TourStepProps): TourStepProps {
  const buttonStyles = {
    height: 32,
  }

  return {
    title,
    description,
    prevButtonProps: { style: buttonStyles },
    nextButtonProps: { ...nextButtonProps, style: buttonStyles },
    ...args,
  }
}
