import { Trans, t } from '@lingui/macro'

import GetStartedButton from './LandingGetStartedButton'

export default function LandingInterfacePreview() {
  return (
    <section className="flex flex-col gap-5 py-4 lg:flex-row lg:items-center lg:justify-between lg:px-4 lg:py-8">
      <img
        className="max-w-[700px] w-full lg:order-1"
        src="/landing/easy-search.png"
        srcSet="/landing/easy-search@2x.png 2x"
        width={320}
        height={206}
        alt="Демонстрация интерфейса сервиса"
        loading="lazy"
      />
      <div className="grid gap-5 lg:max-w-[416px] lg:gap-6">
        <h2 className="text-xxl font-bold lg:text-3xl">
          {t`Максимально простой поиск поставщиков`}
        </h2>
        <p className="text-gray-text-3 lg:text-lg">
          <Trans>
            Данная платформа предлагает вам услуги автоматизированного поиска новых
            поставщиков оборудования из пищевой, сельскохозяйственной и других отраслей, а
            также размещения собственных товаров и услуг
          </Trans>
        </p>

        <GetStartedButton />
      </div>
    </section>
  )
}
