import type { UseQueryOptions } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'
import type { AxiosError } from 'axios'
import { getCreatedOrderDetails } from '~/domain/order/api'

import { companyKeys } from '~/shared/tanstack/keys/company'

interface Params {
  id: number
}

type Response = Awaited<ReturnType<typeof getCreatedOrderDetails>>['data']
type Options = UseQueryOptions<Response, AxiosError>

export function useCreatedOrderDetails({ id }: Params, options?: Options) {
  return useQuery({
    queryKey: companyKeys.createdOrder(id),
    queryFn: async () => {
      const { data } = await getCreatedOrderDetails(id)
      return data
    },
    ...options,
  })
}
