import { buildApiUrl } from './utils'
import type { CompanyDetails } from '~/domain/company/api'

import type { User } from '~/entities/user'
import axios from '~/lib/utils/axios-utils'
import type { COMPANY_TYPE } from '~/shared/constants'

type UpdateUserPayload = Partial<{
  email: string
  first_name: string
  last_name: string
  telephone: string
  password: string
  new_password: string
}>

interface UpdateUserResponse {
  city: Nullable<string>
  date_of_birth: Nullable<string>
  first_name: string
  last_name: string
  middle_name: string
  telephone: Nullable<string>
  country: Nullable<string>
  email: string
}

interface ChangePasswordPayload {
  password: string
  new_password: string
}

interface ResetPasswordPayload {
  email: string
}

interface VerifySignInCodePayload {
  email: string
  code: string
}

interface AddNewCompanyPayload {
  name: string
  inn?: string
  okved: number[]
  website?: string
  company_type: Values<typeof COMPANY_TYPE>
  legal_address: string
}

interface SignInPayload {
  username: string
  password: string
}

interface SignInResponse {
  Token: string
  status: 'token' | 'code'
}

interface SignUpPayload {
  username: string
  password: string
}

export interface CompanyByInnResponse {
  name: string
  inn: string
  type: string
  address: string
  kpp: string
  ogrn: string
  okpo: string
  okved: string
  postal_code: string
}

interface ConfirmNewPasswordPayload {
  token: string
  uid: string
  new_password: string
}

interface VerifyPhoneCodePayload {
  phone: string
  code: string
}

export const USER_TOKEN_STORAGE_KEY = 'token'

const USER_PATH = '/api/users/user/'
const ADD_NEW_COMPANY_PATH = '/api/users/company/create/'
const COMPANY_TOKEN_PATH = '/api/users/company/token/:companyId/'
const INVITED_COMPANY_INFO_PATH = '/api/users/invitation/company/info/'
const ACCEPT_COMPANY_INVITE_PATH = '/api/users/confirm/link/:token/'
const TELEGRAM_DATA_PATH = '/api/users/user/telegram/info/'
const CHANGE_PASSWORD_PATH = '/api/users/user/password/change/'
const RESET_PASSWORD_PATH = '/api/users/password/reset/'
const VERIFY_SIGN_IN_CODE_PATH = '/api/users/user/code/verify/'
const RESEND_SIGN_IN_CODE_PATH = '/api/users/user/code/send/'
const SIGN_IN_PATH = '/api/users/login/'
const SIGN_UP_PATH = '/api/users/auth/'
const SIGN_UP_WITH_PHONE_PATH = '/api/users/auth/phone/'
const SEND_PHONE_CODE_PATH = '/api/users/user/code/send/phone/'
const VERIFY_PHONE_CODE_PATH = '/api/users/user/code/verify/'
const CONFIRM_NEW_PASSWORD_PATH = '/api/users/password/confirm/'

export function verifyPhoneCode({ code, phone }: VerifyPhoneCodePayload) {
  const url = buildApiUrl(VERIFY_PHONE_CODE_PATH)
  return axios.post<{ Token: string }>(url, { username: phone, code })
}

export function sendPhoneCode(phone: string) {
  const url = buildApiUrl(SEND_PHONE_CODE_PATH)
  return axios.post(url, { phone })
}

export function signUpWithPhone(phone: string) {
  const url = buildApiUrl(SIGN_UP_WITH_PHONE_PATH)
  return axios.post(url, { username: phone })
}

export function confirmNewPassword(payload: ConfirmNewPasswordPayload) {
  const url = buildApiUrl(CONFIRM_NEW_PASSWORD_PATH)
  return axios.post(url, payload)
}

export function signUp(payload: SignUpPayload) {
  const url = buildApiUrl(SIGN_UP_PATH)
  return axios.post<{ text: string }>(url, payload)
}

export function signIn(payload: SignInPayload) {
  const url = buildApiUrl(SIGN_IN_PATH)
  return axios.post<SignInResponse>(url, payload)
}

export function getUser() {
  const url = buildApiUrl(USER_PATH)
  return axios.get<User>(url)
}

export function updateUser(payload: UpdateUserPayload) {
  const url = buildApiUrl(USER_PATH)
  return axios.post<UpdateUserResponse>(url, payload)
}

export function changePassword(payload: ChangePasswordPayload) {
  const url = buildApiUrl(CHANGE_PASSWORD_PATH)
  return axios.post<Response>(url, payload)
}

export function resetPassword(payload: ResetPasswordPayload) {
  const url = buildApiUrl(RESET_PASSWORD_PATH)
  return axios.post<{ email: string }>(url, payload)
}

export function getInvitedCompanyInfo(inviteToken: string) {
  const url = buildApiUrl(INVITED_COMPANY_INFO_PATH)
  return axios.get<CompanyDetails>(url, { params: { token: inviteToken } })
}

export function acceptCompanyInvite(inviteToken: string) {
  const url = buildApiUrl(ACCEPT_COMPANY_INVITE_PATH).replace(':token', encodeURIComponent(inviteToken))
  return axios.get(url)
}

export function addNewCompany(payload: AddNewCompanyPayload) {
  const url = buildApiUrl(ADD_NEW_COMPANY_PATH)
  return axios.post<{ id: number }>(url, payload)
}

export function getCompanyToken(companyId: number) {
  const url = buildApiUrl(COMPANY_TOKEN_PATH).replace(':companyId', encodeURIComponent(companyId))
  return axios.get<{ Token: string }>(url)
}

export function getTelegramData() {
  const url = buildApiUrl(TELEGRAM_DATA_PATH)
  return axios.get<{ telegram_confirmed: boolean }>(url)
}

export function verifySignInCode({ email, code }: VerifySignInCodePayload) {
  const url = buildApiUrl(VERIFY_SIGN_IN_CODE_PATH)
  return axios.post<{ Token: string }>(url, { email, code })
}

export function resendSignInCode(email: string) {
  const url = buildApiUrl(RESEND_SIGN_IN_CODE_PATH)
  return axios.post<{ email: string }>(url, { email }, { tokenType: null })
}
