import { i18n } from '@lingui/core'
import { t } from '@lingui/macro'

export function priceFormat(value: number, currencyCode = 'RUB') {
  return new Intl.NumberFormat(i18n.locale, {
    style: 'currency',
    currency: currencyCode,
    maximumFractionDigits: 0,
  }).format(value)
}

export function priceFormatWithFallback(price: Nullable<number>, fallback: string) {
  return price === null ? fallback : priceFormat(price)
}

export function priceFormatOrRequest(price: Nullable<number>) {
  return price === null ? t`Цена по запросу` : priceFormat(price)
}
