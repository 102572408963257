import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { QueryAsyncBoundary } from '@suspensive/react-query'
import { Alert } from 'antd'

import DocumentUpload from './document-upload'
import ImageUpload from './image-upload'

interface Props {
  orderId: number
}

export default function Files({ orderId }: Props) {
  useLingui()

  return (
    <QueryAsyncBoundary
      rejectedFallback={<Alert message={t`Загрузка файлов временно недоступна`} type="error" />}
    >
      <div className="grid gap-3 md:grid-cols-2">
        <DocumentUpload orderId={orderId} />
        <ImageUpload orderId={orderId} />
      </div>
    </QueryAsyncBoundary>
  )
}
