import { i18n } from '@lingui/core'
import { msg, t } from '@lingui/macro'
import clsx from 'clsx'
import { useId, useState } from 'react'

import { MenuOutlined } from '@ant-design/icons'

import LandingStartButton from './LandingStartButton'
import LocaleSelect from '~/components/locale-select'

const MENU_ITEMS = [
  { name: msg`Как работает`, path: '#how_works' },
  { name: msg`Преимущества`, path: '#features' },
  { name: msg`FAQ`, path: '#faq' },
  { name: msg`Товары`, path: '#products' },
]

export default function LandingHeader() {
  const [isMenuOpened, setIsMenuOpened] = useState<boolean>(false)

  const menuId = useId()

  return (
    <header>
      <nav className="flex flex-wrap items-center justify-between py-4">
        <img src="/logo-desktop.svg" width={127} height={28} alt="SupplyDirector логотип" />
        <button
          aria-label={t`Открыть/Закрыть меню`}
          className="inline-flex items-center rounded-lg p-2 lg:hidden hover:bg-white"
          type="button"
          aria-expanded={isMenuOpened}
          aria-controls={menuId}
          onClick={() => setIsMenuOpened(open => !open)}
        >
          <MenuOutlined />
        </button>
        <div
          id={menuId}
          className={clsx(
            'mt-2 w-full rounded-lg border border-gray-border-1 bg-white shadow-xxl',
            'lg:m-0 lg:flex lg:w-auto lg:border-transparent lg:bg-transparent lg:shadow-transparent',
            { hidden: !isMenuOpened },
          )}
        >
          <div className="flex flex-col p-1 lg:flex-row">
            {MENU_ITEMS.map(({ name, path }) => (
              <a
                className="rounded-lg p-2 text-base hover:bg-background lg:px-4 hover:text-blue"
                href={path}
                key={path}
              >
                {i18n._(name)}
              </a>
            ))}

            <div
              className={clsx(
                'flex flex-col items-center gap-4 border-t p-3',
                'sm:flex-row sm:justify-between',
                'lg:ml-6 lg:gap-2 lg:border-none lg:p-0',
              )}
            >
              <LocaleSelect />
              <LandingStartButton />
            </div>
          </div>
        </div>
      </nav>
    </header>
  )
}
