import {
  createContext as createReactContext,
  useContext as useReactContext,
} from 'react'

interface CreateContextOptions<T> {
  /** * Если true, генерирует ошибку, если значение контекста не определено. */
  strict?: boolean
  /**
   * Название хука, аналогичного useContext.
   * @default useContext
   */
  hookName?: string
  /**
   * Название компонента-провайдера контекста.
   * @default Provider
   */
  providerName?: string
  /** * Пользовательское сообщение об ошибке, которое будет сгенерировано, если включен строгий режим и значение контекста не определено. */
  errorMessage?: string
  /** * Отображаемое имя контекста. */
  name?: string
  defaultValue?: T
}

type CreateContextReturn<T> = [React.Provider<T>, () => T, React.Context<T>]

function getErrorMessage(hook: string, provider: string) {
  return `${hook} returned \`undefined\`. Seems you forgot to wrap component within ${provider}`
}

export function createSafeContext<T>(options: CreateContextOptions<T> = {}) {
  const {
    name,
    strict = true,
    hookName = 'useContext',
    providerName = 'Provider',
    errorMessage,
    defaultValue,
  } = options

  const Context = createReactContext<T | undefined>(defaultValue)

  Context.displayName = name

  function useContext() {
    const context = useReactContext(Context)

    if (!context && strict) {
      const error = new Error(errorMessage ?? getErrorMessage(hookName, providerName))

      error.name = 'ContextError'
      Error.captureStackTrace(error, useContext)

      throw error
    }

    return context
  }

  return [Context.Provider, useContext, Context] as CreateContextReturn<T>
}
