import { t } from '@lingui/macro'
import { Button } from 'antd'
import { useNavigate } from 'react-router-dom'
import { RoutePathEnum } from '~/enums/route-path-enum'
import { useIsLoggedIn } from '~/lib/auth'
import { useGlobalDialog } from '~/shared/context/global-dialog'

export default function LandingSupplierSearchRequestButton() {
  const isLoggedIn = useIsLoggedIn()
  const navigate = useNavigate()

  const { openUserSignUpWithSupplierSearchModal } = useGlobalDialog()

  return (
    <Button
      type="primary"
      size="large"
      onClick={isLoggedIn
        ? () => navigate(RoutePathEnum.DASHBOARD) // TODO: При переходе на дашборд нужно открыть окно создания запроса
        : openUserSignUpWithSupplierSearchModal}
    >
      {t`Запрос на оборудование`}
    </Button>
  )
}
