import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { isAxiosError } from 'axios'
import { deleteCompanyProduct } from '~/domain/company/api'

import { showMessage } from '~/shared/show-message'
import { companyKeys } from '~/shared/tanstack/keys/company'

export default function useDeleteCompanyProduct() {
  useLingui()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: deleteCompanyProduct,
    onSuccess: async () => {
      showMessage(t`Успешно удалено!`, 'success')
      queryClient.invalidateQueries({ queryKey: companyKeys.products({}) })
    },
    onError: (error) => {
      if (isAxiosError(error)) {
        showMessage(
          error.response?.data?.detail ?? t`Произошла ошибка при удалении товара. Попробуйте ещё раз`,
          'error',
        )
      }
    },
  })
}
