import { t } from '@lingui/macro'
import { CreatedOrderStatusEnum, ReceivedOrderStatusEnum } from '../enums'

export function getCreatedOrderStatusNames() {
  return {
    [CreatedOrderStatusEnum.HISTORY]: t`УСТАРЕЛ`,
    [CreatedOrderStatusEnum.CONSTRUCTION]: t`СОЗДАН`,
    [CreatedOrderStatusEnum.ACTIVE]: t`АКТИВНО`,
    [CreatedOrderStatusEnum.ARCHIVE]: t`В АРХИВЕ`,
    [CreatedOrderStatusEnum.REJECT]: t`ОТКЛОНËН`,
  }
}

export function getReceivedOrderStatusNames() {
  return {
    [ReceivedOrderStatusEnum.COMPLETED]: t`ЗАВЕРШЕНО`,
    [ReceivedOrderStatusEnum.WAITING]: t`ОЖИДАНИЕ`,
    [ReceivedOrderStatusEnum.REJECT]: t`ОТКЛОНЕНО`,
    [ReceivedOrderStatusEnum.CONFIRMED]: t`ПОДТВЕРЖДЕНО`,
  }
}
