import type { UseQueryOptions } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'

import { get } from '../fetchers'
import type { components } from '../api'
import { productKeys } from './keys'

export interface SearchProductCategoriesVariables {
  query: string
}

export interface SearchProductCategoriesResponse {
  results: components['schemas']['Category'][]
}

export async function searchProductCategories(
  { query }: SearchProductCategoriesVariables,
  signal?: AbortSignal,
) {
  const { data, error } = await get(`/products/categories/search/`, {
    // @ts-expect-error FIXME(swagger)
    params: { query: { q: query.trim() } },
    signal,
  })
  if (error)
    throw error

  return data as unknown as SearchProductCategoriesResponse
}

export type SearchProductCategoriesData = Awaited<ReturnType<typeof searchProductCategories>>
export type SearchProductCategoriesError = unknown

export function useSearchProductCategoriesQuery<TData = SearchProductCategoriesData>(
  { query }: SearchProductCategoriesVariables,
  { enabled = true, ...options }: UseQueryOptions<SearchProductCategoriesData, SearchProductCategoriesError, TData> = {},
) {
  return useQuery<SearchProductCategoriesData, SearchProductCategoriesError, TData>({
    retry: false,
    queryKey: productKeys.searchCategories(query),
    queryFn: ({ signal }) => searchProductCategories({ query }, signal),
    enabled: enabled && typeof query !== 'undefined' && query.trim().length > 0,
    ...options,
  })
}
