import { CloseOutlined, PlusOutlined } from '@ant-design/icons'
import { t } from '@lingui/macro'
import { App, Button, DatePicker, Form, Input, InputNumber, Select } from 'antd'
import dayjs from 'dayjs'
import type { Ref } from 'react'
import { forwardRef, useImperativeHandle, useMemo } from 'react'
import { DESCRIPTION_ROWS, MAX_DESCRIPTION_LENGTH, MAX_PRICE, MAX_REQUEST_INPUT_LENGTH, MIN_PRICE } from '~/constants'
import { useDebouncedState } from '~/core/hooks/use-debaunced-state'
import { usePersonalOrderActivateMutation } from '~/data/order/personal-order-activate-mutation'
import { usePersonalOrderUpdateMutation } from '~/data/order/personal-order-update-mutation'
import { useSearchProductCategoriesQuery } from '~/data/products/search-product-categories-query'
import { useConfirmations } from '~/hooks/use-confirmations'
import Files from '~/personal-order-drawer/components/files'
import { isOverLimit, prepareOrderData } from '~/personal-order-drawer/utils/order-util'
import type { CustomFieldEntry } from '~/shared/api'

interface Props {
  orderId: number
  onUpdate?: (orderId: number) => void
  onUpdateFailed?: () => void
  onActivate?: (orderId: number) => void
  onActivateFailed?: () => void
  initialValues?: Partial<FormState>
}

interface FormState {
  request: string
  description: string
  preferable_price: number
  amount: number
  preferable_time?: dayjs.Dayjs
  category?: any
  address: string
  extra_fields: CustomFieldEntry[]
}

interface Handlers {
  saveOrder: () => void
  saveAndActivateOrder: () => void
  close: () => void
}

const OrderUpdateForm = forwardRef((
  {
    onUpdate,
    onUpdateFailed,
    onActivate,
    onActivateFailed,
    orderId,
    initialValues,
  }: Props,
  ref: Ref<Handlers>,
) => {
  useImperativeHandle(ref, () => ({ saveOrder, saveAndActivateOrder, close: handleClose }))

  const { message } = App.useApp()
  const [form] = Form.useForm<FormState>()
  const confirmModal = useConfirmations()

  const [categoryQuery, , setCategoryQuery] = useDebouncedState('')

  const { data: categories, isFetching: isSearchingCategories } = useSearchProductCategoriesQuery({ query: categoryQuery })
  const { mutateAsync: activateOrder, isLoading: isActivating } = usePersonalOrderActivateMutation({
    onSuccess: () => {
      message.success(t`Персональный запрос на товар успешно отправлен! Ожидайте ответа от продавца.`)
      onActivate?.(orderId)
    },
    onError: () => {
      message.error(t`Извините, не удалось отправить ваш запрос. Пожалуйста, попробуйте ещё раз или проверьте подключение к интернету.`)
      onActivateFailed?.()
    },
  })
  const { mutateAsync: updateOrder, isLoading: isUpdating } = usePersonalOrderUpdateMutation({
    onSuccess: () => {
      message.success(t`Запрос на товар успешно создан и сохранён. Активируйте его по вашему желанию в удобное время.`)
      onUpdate?.(orderId)
    },
    onError: (error) => {
      // @ts-expect-error FIXME(swagger)
      message.error((error?.detail) ?? t`Неизвестная ошибка`)
      onUpdateFailed?.()
    },
  })

  const price = Form.useWatch<number>('preferable_price', form)
  const amount = Form.useWatch<number>('amount', form)

  const isOverLimitPrice = isOverLimit(price * amount)
  const isLoading = isActivating || isUpdating

  const categoriesOptions = useMemo(() => categories?.results.map(category => ({ ...category, label: category.name, value: category.id })) ?? [], [categories])

  function closeWithReset() {
    form.resetFields()
    onUpdate?.(orderId)
  }

  async function handleOrderSave(shouldActivate: boolean) {
    const values = await form.validateFields()

    const preparedOrder = prepareOrderData(orderId, values)
    if (isOverLimitPrice)
      return

    const updatedOrder = await updateOrder(preparedOrder)

    if (!shouldActivate)
      return

    if (!updatedOrder.id)
      return message.error(t`Ошибка: Невозможно активировать запрос. Попробуйте ещё раз`)

    await activateOrder({ orderId: updatedOrder.id })
    closeWithReset()
  }

  async function saveOrder() {
    await handleOrderSave(false)
  }

  async function saveAndActivateOrder() {
    await handleOrderSave(true)
  }

  function handleClose() {
    const hasFieldsWithValues = Object.values(form.getFieldsValue()).filter(Boolean).length > 0
    if (hasFieldsWithValues) {
      confirmModal.unsavedFields({ onOk: closeWithReset })
      return
    }

    closeWithReset()
  }

  return (
    <Form
      layout="vertical"
      form={form}
      disabled={isLoading}
      initialValues={{
        ...initialValues,
        preferable_time: initialValues?.preferable_time
          ? dayjs(initialValues.preferable_time)
          : undefined,
      }}
      style={{ marginBottom: 16 }}
    >
      <Form.Item<FormState>
        name="request"
        label={t`Краткое название запроса`}
        rules={[{ required: true }]}
        hasFeedback
      >
        <Input maxLength={MAX_REQUEST_INPUT_LENGTH} />
      </Form.Item>

      <div className="grid gap-x-3 md:grid-cols-2">
        <Form.Item<FormState> name="category" label={t`Категория`}>
          <Select
            placeholder={t`Поиск категорий`}
            filterOption={false}
            onSearch={setCategoryQuery}
            notFoundContent={null}
            loading={isSearchingCategories}
            options={categoriesOptions}
            showSearch
          />
        </Form.Item>

        <Form.Item<FormState>
          name="preferable_price"
          label={t`Цена за 1шт`}
          rules={[
            { required: true },
            { type: 'number', max: MAX_PRICE, message: t`Максимальная цена ${MAX_PRICE}` },
            { type: 'number', min: MIN_PRICE },
          ]}
          hasFeedback
        >
          <InputNumber
            formatter={value => `₽ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
            parser={value => value!.replace(/₽\s?|( *)/g, '')}
            style={{ width: '100%' }}
          />
        </Form.Item>

        <Form.Item<FormState>
          name="amount"
          label={t`Желаемое кол-во`}
          rules={[{ required: true }]}
          hasFeedback
        >
          <InputNumber style={{ width: '100%' }} min={MIN_PRICE} max={MAX_PRICE} />
        </Form.Item>

        <Form.Item<FormState>
          name="preferable_time"
          label={t`Дата получения`}
          rules={[{ required: true, message: t`Пожалуйста, выберите дату` }, { type: 'date' }]}
        >
          <DatePicker
            style={{ width: '100%' }}
            disabledDate={d => !d || (d.isBefore(Date.now()) && !d.isSame(Date.now(), 'day'))}
          />
        </Form.Item>

        <Form.Item<FormState>
          name="address"
          label={t`Адрес доставки`}
          rules={[{ required: true }]}
          hasFeedback
        >
          <Input />
        </Form.Item>
      </div>

      <Form.Item label={t`Характеристики`}>
        <Form.List name="extra_fields">
          {(fields, { add, remove }) => {
            return (
              <div className="flex flex-col">
                {fields.map((field) => {
                  return (
                    <div className="grid grid-cols-[1fr,1fr,auto] items-start gap-2" key={field.key}>
                      <Form.Item name={[field.name, 'name']} rules={[{ required: true }]}>
                        <Input placeholder={t`Название`} />
                      </Form.Item>
                      <Form.Item name={[field.name, 'value']} rules={[{ required: true }]}>
                        <Input placeholder={t`Значение`} />
                      </Form.Item>

                      <Button
                        type="link"
                        onClick={() => remove(field.name)}
                        icon={<CloseOutlined />}
                        danger
                      />
                    </div>
                  )
                })}

                <Button type="dashed" onClick={() => add()} icon={<PlusOutlined />}>
                  {t`Добавить поле характеристик`}
                </Button>
              </div>
            )
          }}
        </Form.List>
      </Form.Item>

      <Form.Item<FormState>
        name="description"
        label={t`Подробное описание товара`}
        rules={[{ required: true }]}
        hasFeedback
      >
        <Input.TextArea rows={DESCRIPTION_ROWS} maxLength={MAX_DESCRIPTION_LENGTH} />
      </Form.Item>

      <Files orderId={orderId} />
    </Form>
  )
})

OrderUpdateForm.displayName = 'OrderUpdateForm'

export default OrderUpdateForm
