import { Route, Routes } from 'react-router-dom'

import AboutPage from './about'
import IdentityVerificationPage from './authentication/identity-verification'
import SignInPage from './authentication/sign-in'
import SignUpPage from './authentication/sign-up'
import CatalogPage from './catalog'
import JoinCompanyPage from './company/join-company'
import StaffPage from './company-members'
import CompanyProductsEditPage from './company-product-edit'
import NewCompanyProductPage from './company/products/new'
import CompanyEditPage from './company-edit'
import CompanyProductPage from './company-product-details'
import CompanyProductsPage from './company-products'
import CreatedOrderDetailsPage from './created-order-details'
import CraetedOrdersPage from './created-orders'
import DashboardPage from './dashboard/index'
import FactoriesPage from './factories'
import HelpPage from './help'
import LandingPage from './landing'
import { LazyNewPassPage } from './new-pass'
import NotFoundPage from './not-found'
import NotificationsPage from './notifications'
import { NotificationDetailsPage } from './notifications-details'
import { PartnersPage } from './partners'
import ProductDetailsPage from './product-details'
import ProductSearchPage from './products-search'
import ReceivedOrderDetailsPage from './received-order-details'
import ReceivedOrdersPage from './received-orders'
import NewPasswordPage from './users/new-password'
import PhoneVerificationPage from './users/phone-verification'
import ProfilePage from './profile'

import { RoutePathEnum } from '~/enums/route-path-enum'
import AppLayout from '~/layouts/app'
import RequireAuth from '~/layouts/require-auth'
import RequireGuest from '~/layouts/require-guest'
import WelcomeTour from '~/welcome-tour'
import LayoutCompanyRequire from '~/components/layout/LayoutCompanyRequire'

export default function Routing() {
  return (
    <Routes>
      <Route index element={<LandingPage />} />
      <Route path={RoutePathEnum.RESET_PASSWORD} element={<LazyNewPassPage />} />

      <Route element={<RequireGuest />}>
        <Route path={RoutePathEnum.SIGN_IN} element={<SignInPage />} />
        <Route path={RoutePathEnum.SIGN_UP} element={<SignUpPage />} />
        <Route path={RoutePathEnum.PHONE_VERIFICATION} element={<PhoneVerificationPage />} />
        <Route path={RoutePathEnum.IDENTITY_VERIFICATION} element={<IdentityVerificationPage />} />
        <Route path={RoutePathEnum.NEW_PASSWORD} element={<NewPasswordPage />} />
      </Route>

      <Route
        element={(
          <WelcomeTour>
            <AppLayout />
          </WelcomeTour>
        )}
      >
        <Route element={<RequireAuth />}>
          <Route element={<LayoutCompanyRequire />}>
            <Route path={RoutePathEnum.COMPANY_EDIT} element={<CompanyEditPage />} />
            <Route path={RoutePathEnum.COMPANY_PRODUCTS} element={<CompanyProductsPage />} />
            <Route path={RoutePathEnum.COMPANY_PRODUCT_DETAILS} element={<CompanyProductPage />} />
            <Route path={RoutePathEnum.COMPANY_PRODUCT_EDIT} element={<CompanyProductsEditPage />} />
            <Route path={RoutePathEnum.COMPANY_PRODUCT_NEW} element={<NewCompanyProductPage />} />
            <Route path={RoutePathEnum.COMPANY_MEMBERS} element={<StaffPage />} />
            <Route path={RoutePathEnum.PARTNERS} element={<PartnersPage />} />
            <Route path={RoutePathEnum.FACTORIES} element={<FactoriesPage />} />
            <Route path={RoutePathEnum.CREATED_ORDERS} element={<CraetedOrdersPage />} />
            <Route path={RoutePathEnum.CREATED_ORDER} element={<CreatedOrderDetailsPage />} />
            {/* <Route path={RoutePathEnum.CREATED_ORDER_EDIT} element={<CreatedOrderEditPage />} /> */}
            {/* <Route path={RoutePathEnum.CREATED_ORDER_NEW} element={<NewOrderPage />} /> */}
            <Route path={RoutePathEnum.RECEIVED_ORDERS} element={<ReceivedOrdersPage />} />
            <Route path={RoutePathEnum.RECEIVED_ORDER} element={<ReceivedOrderDetailsPage />} />
          </Route>

          <Route path={RoutePathEnum.DASHBOARD} element={<DashboardPage />} />
          <Route path={RoutePathEnum.NOTIFICATIONS} element={<NotificationsPage />} />
          <Route path={RoutePathEnum.NOTIFICATION} element={<NotificationDetailsPage />} />
          <Route path={RoutePathEnum.PROFILE} element={<ProfilePage />} />
          <Route path={RoutePathEnum.ABOUT} element={<AboutPage />} />
          <Route path={RoutePathEnum.HELP} element={<HelpPage />} />
          <Route path={RoutePathEnum.COMPANY_JOIN_TOKEN} element={<JoinCompanyPage />} />
        </Route>

        <Route path={RoutePathEnum.CATALOG} element={<CatalogPage />} />
        <Route path={RoutePathEnum.PRODUCTS} element={<ProductSearchPage />} />
        <Route path={RoutePathEnum.PRODUCT} element={<ProductDetailsPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Route>
    </Routes>
  )
}
