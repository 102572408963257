import { Navigate, Outlet } from 'react-router-dom'

import LockScreen from '~/components/lock-screen'
import { RoutePathEnum } from '~/enums/route-path-enum'
import { useGetUser } from '~/shared/tanstack/queries/use-get-user'

export default function RequireGuest() {
  const { status, fetchStatus } = useGetUser()

  if (fetchStatus === 'fetching')
    return <LockScreen loading />

  if (status === 'success')
    return <Navigate to={RoutePathEnum.DASHBOARD} replace />

  return <Outlet />
}
