import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { RoutePathEnum } from '~/enums/route-path-enum'
import { MaintanceMessage } from '~/shared/components/maintance-message'
import PageHeader from '~/components/common/PageHeader'

export default function Page() {
  useLingui()
  return (
    <>
      <PageHeader title={t`Партнёры`} backRoutePath={RoutePathEnum.DASHBOARD} />
      <MaintanceMessage />
    </>
  )
}
