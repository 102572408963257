import { useMutation, useQueryClient } from '@tanstack/react-query'

import { USER_TOKEN_STORAGE_KEY, verifyPhoneCode } from '~/api/user-api'
import { userKeys } from '~/shared/tanstack/keys/user'

type Response = Awaited<ReturnType<typeof verifyPhoneCode>>['data']
type Variables = Parameters<typeof verifyPhoneCode>[0]

export function useVerifyPhoneCode() {
  const queryClient = useQueryClient()

  return useMutation<Response, Error, Variables>({
    mutationFn: async (variables) => {
      const { data } = await verifyPhoneCode(variables)
      localStorage.setItem(USER_TOKEN_STORAGE_KEY, data.Token)
      return data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: userKeys.me() })
    },
  })
}
