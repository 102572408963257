import { createSafeContext } from './safe-context'

interface LocaleContext {
  locale: string
  changeLocale: (locale: string) => Promise<void>
}

export const [LocaleProvider, useLocale] = createSafeContext<LocaleContext>({
  name: 'LocaleContext',
  providerName: 'LocaleProvider',
  hookName: 'useLocale',
  strict: true,
})
