import { Link } from 'react-router-dom'

export default function Logo() {
  return (
    <Link to="/dashboard" className="mb-8 -mx-4 xl:mx-0">
      <picture>
        <source
          media="(min-width: 1280px)"
          srcSet="/logo-desktop.svg"
          width="127"
          height="24"
        />
        <img src="/logo-mobile.svg" width="54" height="24" alt="SupplyDirector logo" />
      </picture>
    </Link>
  )
}
