import React from 'react'

import EmptyIcon from './empty-icon'
import type { StatusType } from './interface'
import { Icon } from '~/shared/components/icon'

interface Props {
  status?: StatusType
  /** @deprecated вместо этого используйте поле `footer` */
  extra?: React.ReactNode
  footer?: React.ReactNode
  icon?: React.ReactNode
  title?: string
  /** @deprecated вместо этого используйте поле `description` */
  subtitle?: string
  description?: string
  /** @deprecated вместо этого используйте поле `footer` */
  children?: React.ReactNode
  // size?: Size;
}

const statusIconMap: Indexed<StatusType, React.ReactNode> = {
  notFoundProduct: <Icon name="not-found" width={85} height={85} />,
  empty: <EmptyIcon width={100} height={100} />,
}

export default function Result({
  title,
  children,
  extra,
  icon,
  subtitle,
  status,
  description,
  footer,
}: Props) {
  let titleNode: React.ReactNode
  if (title)
    titleNode = <p className="text-center text-xxl text-black font-bold">{title}</p>

  let descriptionNode: React.ReactNode
  if (subtitle || description) {
    descriptionNode = (
      <p className="max-w-md text-center text-base text-gray-text-2">
        {subtitle || description}
      </p>
    )
  }

  let footerNode: React.ReactNode
  if (extra || footer)
    footerNode = <div className="text-center">{extra || footer}</div>

  let iconNode: React.ReactNode
  if (icon || status)
    iconNode = status ? statusIconMap[status] : icon

  return (
    <div className="flex flex-col items-center gap-8 px-5 py-8">
      {iconNode}

      <div className="grid gap-2.5">
        {titleNode}
        {descriptionNode}
      </div>

      {footerNode}

      {children}
    </div>
  )
}
