import { useLocalStorageState } from 'ahooks'
import { LOCAL_STORAGE_KEYS } from '../lib/constants'
import { useCompanyDetail } from '~/data/company/company-detail-query'
import { useIsLoggedIn } from '~/lib/auth'

export function useCompanyActiveId() {
  const [activeCompanyId, setActiveCompanyId] = useLocalStorageState<number | undefined>(
    LOCAL_STORAGE_KEYS.ACTIVE_COMPANY_ID,
  )

  return {
    activeCompanyId,
    setActiveCompanyId,
  }
}

export function useActiveCompanyDetail({ enabled = true } = {}) {
  const isLoggedIn = useIsLoggedIn()
  const [activeCompanyId] = useLocalStorageState<number | undefined>(LOCAL_STORAGE_KEYS.ACTIVE_COMPANY_ID)

  const query = useCompanyDetail(
    { activeCompanyId },
    { enabled: isLoggedIn && enabled },
  )

  return query
}
