import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { Alert } from 'antd'
import { useCompanyProductProcessingFiles } from '../hooks/use-company-product-processing-files'

export default function UploadTableProgress() {
  useLingui()

  const { hasFilesInProgress, statusesCount } = useCompanyProductProcessingFiles()

  if (!hasFilesInProgress)
    return null

  const { inProgress, total } = statusesCount

  return (
    <Alert
      message={t`Идёт обработка загруженной таблицы (${inProgress} из ${total}). Мы уведомим вас по окончании обработки`}
      type="warning"
      showIcon
    />
  )
}
