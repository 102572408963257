import type { UseMutationOptions } from '@tanstack/react-query'
import { useMutation } from '@tanstack/react-query'

import { App } from 'antd'
import { post } from '../fetchers'
import type { CustomFieldEntry } from '~/shared/api'
import { LOCAL_STORAGE_KEYS } from '~/lib/constants'

export type PersonalOrderUpdateVariables = Partial<{
  address: string
  amount: number
  categories: string[]
  description: string
  extra_fields: CustomFieldEntry[]
  id: number
  preferable_price: number
  preferable_time: string
  request: string
}>

export async function updatePersonalOrder(payalod: PersonalOrderUpdateVariables) {
  const token = localStorage.getItem(LOCAL_STORAGE_KEYS.ACTIVE_COMPANY_TOKEN)
  if (!token)
    throw new Error('token is required')

  const { data, error } = await post('/orders/request/personal/update/', {
    // @ts-expect-error FIXME(swagger)
    body: payalod,
    // FIXME(company tokens)
    headers: {
      Authorization: `Token ${token}`,
    },
  })

  if (error)
    throw error
  return data
}

type PersonalOrderUpdateData = Awaited<ReturnType<typeof updatePersonalOrder>>
type PersonalOrderUpdateError = unknown

export function usePersonalOrderUpdateMutation({
  onSuccess,
  onError,
  ...options
}: Omit<UseMutationOptions<PersonalOrderUpdateData, PersonalOrderUpdateError, PersonalOrderUpdateVariables>, 'mutationFn' > = {}) {
  const { message } = App.useApp()

  return useMutation<PersonalOrderUpdateData, PersonalOrderUpdateError, PersonalOrderUpdateVariables>({
    mutationFn: vars => updatePersonalOrder(vars),
    async onSuccess(data, variables, context) {
      await onSuccess?.(data, variables, context)
    },
    async onError(data, variables, context) {
      if (onError === undefined)
        message.error(`Failed to mutate: updatePersonalOrder`)
      else
        onError(data, variables, context)
    },
    ...options,
  })
}
