import { Button, Col, Flex, Form, Input, Row, Select } from 'antd'
import { useLingui } from '@lingui/react'
import { t } from '@lingui/macro'
import { SearchOutlined } from '@ant-design/icons'
import type { FormState } from './types'
import { getRequestTypeOptinos, getStatusOptions } from './utils'
import { rowGutter } from './contants'

interface Props {
  onFinish: (values: FormState) => void
  onReset: () => void
  initialValues?: FormState
}

export default function OrderReceivedFiltersForm({ onFinish, initialValues, onReset }: Props) {
  useLingui()
  const [form] = Form.useForm<FormState>()

  const statusOptions = getStatusOptions()
  const requestsOptions = getRequestTypeOptinos()

  function handleReset() {
    form.setFieldsValue({ query: undefined, type: 'all', status: 'all' })
    onReset()
  }

  return (
    <Form
      form={form}
      name="order-received-filters"
      layout="inline"
      onFinish={onFinish}
      initialValues={{
        query: initialValues?.query,
        status: initialValues?.status ?? statusOptions[0].value,
        type: initialValues?.type ?? requestsOptions[0].value,
      }}
      style={{ display: 'flex', flexDirection: 'column', gap: 8 }}
    >
      <Row gutter={[rowGutter, rowGutter]}>
        <Col xs={24} md={8}>
          <Form.Item<FormState> name="query" style={{ margin: 0 }}>
            <Input placeholder={t`Поиск по названию`} prefix={<SearchOutlined />} allowClear />
          </Form.Item>
        </Col>

        <Col xs={24} md={8}>
          <Form.Item<FormState> name="status" label={t`Статус`} style={{ margin: 0 }}>
            <Select options={statusOptions} />
          </Form.Item>
        </Col>

        <Col xs={24} md={8}>
          <Form.Item<FormState> name="type" label={t`Тип запроса`} style={{ margin: 0 }}>
            <Select options={requestsOptions} />
          </Form.Item>
        </Col>
      </Row>

      <Flex justify="flex-end" gap="small">
        <Button type="primary" htmlType="submit">{t`Применить`}</Button>
        <Button htmlType="button" onClick={handleReset}>{t`Сбросить`}</Button>
      </Flex>
    </Form>
  )
}
