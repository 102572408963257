import axios from 'axios'

import { USER_TOKEN_STORAGE_KEY } from '~/api/user-api'
import { ACTIVE_COMPANY_TOKEN_STORAGE_KEY } from '~/domain/company/api'

axios.defaults.baseURL = __API_DOMAIN__

axios.interceptors.request.use((config) => {
  if (config.tokenType === null)
    return config

  const token
    = config.tokenType === 'company'
      ? localStorage.getItem(ACTIVE_COMPANY_TOKEN_STORAGE_KEY)
      : localStorage.getItem(USER_TOKEN_STORAGE_KEY)

  if (token)
    config.headers.Authorization = `Token ${token}`

  return config
})

export default axios
