import clsx from 'clsx'

interface Props {
  className?: string
  count?: number
  containerClassName?: string
}

export default function Skeleton({ className, count = 1, containerClassName }: Props) {
  const items = Array.from({ length: count }, (_, index) => index + 1)

  return (
    <div className={containerClassName}>
      {items.map(item => (
        <span key={item} className={clsx('shimmer block', className)} />
      ))}
    </div>
  )
}
