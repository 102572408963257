import { i18n } from '@lingui/core'
import { detect, fromCookie } from '@lingui/detect-locale'

export const DEFAULT_LOCALE = 'ru'
export const LOCALE_COOKIE_NAME = 'lang'

interface PoMessages {
  messages: {
    [key: string]: string
  }
}

export async function loadCatalog(locale: string) {
  const { messages } = (await import(`../../locales/${locale}.po`)) as PoMessages
  i18n.loadAndActivate({ locale, messages })
}

export function getSavedLocale() {
  return detect(fromCookie(LOCALE_COOKIE_NAME), () => DEFAULT_LOCALE) ?? DEFAULT_LOCALE
}
