import type { ListParams } from '~/shared/api'

export const userKeys = {
  all: ['user'],
  me: () => [...userKeys.all, 'me'],
  invitedCompanyData: (token: string) => [...userKeys.all, 'invitedCompanyData', token],
  telegramInfo: () => [...userKeys.all, 'telegramInfo'],
  notifications: ({ limit, page }: Omit<ListParams, 'q'>) => [
    ...userKeys.all,
    'notifications',
    { limit, page },
  ],
  notification: (id: number) => [...userKeys.all, 'notification', id],
  notificationState: () => [...userKeys.all, 'notificationState'],
  searchCompanyByInn: (inn: string) => [...userKeys.all, 'searchCompanyByInn', inn],
  searchOkved: (q: string) => [...userKeys.all, 'searchOkved', q],
}
