import clsx from 'clsx'

export const StatusTagType = {
  Neutral: 'neutral',
  Success: 'success',
  Error: 'error',
  Warning: 'warning',
} as const

interface StatusTagProps {
  type: Values<typeof StatusTagType>
  className?: string
  message?: string
}

export default function StatusTag({ type, className, message }: StatusTagProps) {
  return (
    <span
      className={clsx(
        'inline-flex h-6 items-center rounded px-2 text-md',
        {
          'bg-blue/20 text-blue': type === StatusTagType.Neutral,
          'bg-green/20 text-green': type === StatusTagType.Success,
          'bg-red/20 text-red': type === StatusTagType.Error,
          'bg-yellow/20 text-yellow': type === StatusTagType.Warning,
        },
        className,
      )}
    >
      {message}
    </span>
  )
}
