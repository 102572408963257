import { FileExcelOutlined } from '@ant-design/icons'
import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import type { UploadProps } from 'antd'
import { Alert, App, Modal, Upload } from 'antd'
import { useState } from 'react'

import { buildApiUrl } from '~/api/utils'
import { ACTIVE_COMPANY_TOKEN_STORAGE_KEY } from '~/domain/company/api'

const { Dragger } = Upload

interface Props {
  open: boolean
  onCancel: () => void
}

const props: UploadProps = {
  name: 'file',
  multiple: true,
  showUploadList: { showRemoveIcon: false },
  accept: '.xls, .xlsx, .xltx, .xlsm, .xltm, .csv, .ods, .ots, .pdf',
  action: buildApiUrl('/api/products/product/create/file/'),
}

export default function TableUploadModal({ onCancel, open }: Props) {
  useLingui()
  const { message } = App.useApp()

  const [companyToken, setCompanyToken] = useState('')

  const handleChange: UploadProps<any>['onChange'] = (info) => {
    const { status, name: fileName } = info.file

    if (status === 'done')
      message.success(t`Файл ${fileName} успешно загружен.`)
    else if (status === 'error')
      message.error(info?.file?.response?.detail ?? t`Не удалось загрузить файл ${fileName}`)
  }

  function handleBeforeUpload() {
    setCompanyToken(localStorage.getItem(ACTIVE_COMPANY_TOKEN_STORAGE_KEY) ?? '')
  }

  return (
    <Modal title={t`Загрузить таблицу`} open={open} onCancel={onCancel} footer={null} centered>
      <Alert
        type="info"
        message={t`Обратите внимание, обработка загруженных таблиц может занять некоторое время`}
        style={{ marginBottom: 16 }}
        banner
      />

      <Dragger
        beforeUpload={handleBeforeUpload}
        headers={{ authorization: `Token ${companyToken}` }}
        onChange={handleChange}
        {...props}
      >
        <p className="ant-upload-drag-icon">
          <FileExcelOutlined />
        </p>
        <p className="ant-upload-text">{t`Нажмите или перетащите файл в эту область, чтобы загрузить`}</p>
        <p className="ant-upload-hint">
          {t`Поддержка одиночной или массовой загрузки. Строго запрещено загружать данные компании или другие запрещенные файлы.`}
        </p>
      </Dragger>
    </Modal>
  )
}
