import { useLocalStorage } from 'usehooks-ts'

interface State {
  m: number
  s: number
}

export function useConfirmEmailStorage() {
  const [confirmEmail, setConfirmEmail] = useLocalStorage<string>('confirmationEmail', '')
  const [emailResendDelayTimer, setEmailResendDelayTimer] = useLocalStorage<State | null>(
    'resendConfirmationEmailTimer',
    null,
  )

  function clearConfirmEmailStorage() {
    setConfirmEmail('')
    setEmailResendDelayTimer(null)
  }

  return {
    confirmEmail,
    setConfirmEmail,
    emailResendDelayTimer,
    setEmailResendDelayTimer,
    clearConfirmEmailStorage,
  }
}
