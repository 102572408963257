import { Trans } from '@lingui/macro'

import { priceFormat } from '~/shared/lib/format'
import { cn } from '~/shared/lib/react'

interface Props {
  price: number
  isOverLimit?: boolean
}

export default function TotalPriceSummary({ price, isOverLimit }: Props) {
  return (
    <div className="truncate space-y-1">
      <p className="text-sm text-gray-text-2 font-medium">
        <Trans>Общая цена</Trans>
        :
      </p>

      <div className="flex flex-col">
        <span className={cn('text-lg font-medium', { 'text-red': isOverLimit })}>
          {Number.isNaN(price) ? priceFormat(0) : priceFormat(price)}
        </span>

        {isOverLimit && (
          <span className="text-xs text-red">
            <Trans>Превышен лимит цены</Trans>
          </span>
        )}
      </div>
    </div>
  )
}
