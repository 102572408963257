import type { CreatedOrderStatusEnum, ReceivedOrderStatusEnum } from './enums'
import type { CreatedOrderDetailsResponse, CreatedOrdersPayload, CreatedOrdersResponse, ReceivedOrdersPayload, ReceivedOrdersResponse } from './types'
import { buildApiUrl, paginateOffset } from '~/api/utils'
import axios from '~/lib/utils/axios-utils'
import type {
  CustomFieldEntry,
  PublicDocument,
  PublicImage,
} from '~/shared/api'
import type { ProductCategory } from '~/shared/types/common'

export interface ReceivedOrderTableRow {
  created_at: string
  id: number
  status: ReceivedOrderStatusEnum
  product_request: {
    amount: number
    company: string
    id: number
    is_personal_request: boolean
    name: string
    preferable_time: string
  }
}

interface PersonalOrderDetails {
  request: Nullable<string>
  description: Nullable<string>
  files: PublicDocument[]
  images: PublicImage[]
  preferable_time: Nullable<string>
  preferable_price: Nullable<number>
  amount: number
  extra_fields: CustomFieldEntry[]
  address: Nullable<string>
  category: ProductCategory[]
  status: CreatedOrderStatusEnum
  is_personal_request: boolean
  delivery_type: unknown[]
}

type UpdatePersonalOrderPayload = Partial<{
  address: string
  amount: number
  categories: string[]
  description: string
  extra_fields: CustomFieldEntry[]
  id: number
  preferable_price: number
  preferable_time: string
  request: string
}>

interface DeleteOrderFilePayload {
  file_id: number
  request_id: number
}

interface DeleteOrderImagePayload {
  file_id: number
  request_id: number
}

interface AddChainFilePayload {
  orderChainId: number
  formData: FormData
}

interface DeleteChainFilePayload {
  file_id: number
  order_chain_id: number
}

interface AddChainImagePayload {
  orderChainId: number
  formData: FormData
}

interface DeleteChainImagePayload {
  file_id: number
  order_chain_id: number
}

type CreateNewOrderPayload = unknown
type AcceptOrderPayload = unknown

const RECEIVED_ORDERS_PATH = '/api/orders/received/list/'
const CREATED_ORDERS_PATH = '/api/orders/created/list/'
const CREATED_ORDER_DETAILS_PATH = '/api/orders/created/info/'
const RECEIVED_ORDER_DETAILS_PATH = '/api/orders/receive/info/'
const ACCEPT_ORDER_PATH = '/api/orders/request/accept/'
const ARCHIVATE_ORDER_PATH = '/api/orders/request/archive/'
const REJECT_ORDER_PATH = '/api/orders/request/reject/'
const CREATE_NEW_ORDER_PATH = '/api/orders/request/create/'
const DELETE_ORDER_PATH = '/api/orders/request/delete/'
const ADD_ORDER_IMAGE_PATH = '/api/orders/request/image/add/'
const DELETE_ORDER_IMAGE_PATH = '/api/orders/request/image/delete/'
const ADD_ORDER_FILE_PATH = '/api/orders/request/file/add/'
const DELETE_ORDER_FILE_PATH = '/api/orders/request/file/delete/'
const ACTIVE_PERSONAL_ORDER_PATH = '/api/orders/request/personal/activate/'
const CREATE_PERSONAL_ORDER_PATH = '/api/orders/request/personal/create/'
const UPDATE_PERSONAL_ORDER_PATH = '/api/orders/request/personal/update/'
const PERSONAL_ORDER_DETAILS_PATH = '/api/orders/request/personal/:orderId/'
const ADD_CHAIN_FILE_PATH = '/api/orders/receive/files/add/:orderChainId/'
const DELETE_CHAIN_FILE_PATH = '/api/orders/receive/files/delete/:orderChainId/'
const ADD_CHAIN_IMAGE_PATH = '/api/orders/receive/images/add/:orderChainId/'
const DELETE_CHAIN_IMAGE_PATH = '/api/orders/receive/images/delete/:orderChainId/'

// FIXME: Убрать дублирование кода
export function getReceivedOrders({ limit, page, q, status, type }: ReceivedOrdersPayload) {
  const url = buildApiUrl(RECEIVED_ORDERS_PATH)
  const params = {
    limit,
    offset: paginateOffset(page, limit),
    q: q || undefined,
    status: status === 'all' ? undefined : status,
    is_personal_request: type === 'all' ? undefined : type === 'personal',
  }
  return axios.get<ReceivedOrdersResponse>(url, { params, tokenType: 'company' })
}

export function getCreatedOrders({ limit, page, q, type, answers, status }: CreatedOrdersPayload) {
  const url = buildApiUrl(CREATED_ORDERS_PATH)
  const params = {
    status: status === 'all' ? undefined : status,
    limit,
    offset: paginateOffset(page, limit),
    q: q || undefined,
    is_personal_request: type === 'all' ? undefined : type === 'personal',
    answers: answers === 'all' ? undefined : answers === 'with',
  }
  return axios.get<CreatedOrdersResponse>(url, { params, tokenType: 'company' })
}

export function getCreatedOrderDetails(id: number) {
  const url = buildApiUrl(CREATED_ORDER_DETAILS_PATH)
  return axios.get<CreatedOrderDetailsResponse>(url, { params: { id }, tokenType: 'company' })
}

export function getReceivedOrderDetails(id: number) {
  const url = buildApiUrl(RECEIVED_ORDER_DETAILS_PATH)
  return axios.get(url, { params: { id }, tokenType: 'company' })
}

export function acceptOrder(payload: AcceptOrderPayload) {
  const url = buildApiUrl(ACCEPT_ORDER_PATH)
  return axios.post(url, payload, { tokenType: 'company' })
}

export function archivateOrder(id: number) {
  const url = buildApiUrl(ARCHIVATE_ORDER_PATH)
  return axios.post(url, { id }, { tokenType: 'company' })
}

export function rejectOrder(orderId: number) {
  const url = buildApiUrl(REJECT_ORDER_PATH)
  return axios.post(url, { order_id: orderId }, { tokenType: 'company' })
}

export function createNewOrder(payload: CreateNewOrderPayload) {
  const url = buildApiUrl(CREATE_NEW_ORDER_PATH)
  return axios.post(url, payload, { tokenType: 'company' })
}

export function deleteOrder(id: number) {
  const url = buildApiUrl(DELETE_ORDER_PATH)
  return axios.post(url, { id }, { tokenType: 'company' })
}

export function addOrderImage(payload: FormData) {
  const url = buildApiUrl(ADD_ORDER_IMAGE_PATH)
  return axios.post(url, payload, { tokenType: 'company' })
}

export function deleteOrderImage(payload: DeleteOrderImagePayload) {
  const url = buildApiUrl(DELETE_ORDER_IMAGE_PATH)
  return axios.post(url, payload, { tokenType: 'company' })
}

export function addOrderFile(payload: FormData) {
  const url = buildApiUrl(ADD_ORDER_FILE_PATH)
  return axios.post(url, payload, { tokenType: 'company' })
}

export function deleteOrderFile(payload: DeleteOrderFilePayload) {
  const url = buildApiUrl(DELETE_ORDER_FILE_PATH)
  return axios.post<any>(url, payload, { tokenType: 'company' })
}

export function addChainFile({ formData, orderChainId }: AddChainFilePayload) {
  const url = buildApiUrl(ADD_CHAIN_FILE_PATH).replace(':orderChainId', encodeURIComponent(orderChainId))
  return axios.post<File>(url, formData, { tokenType: 'company' })
}

export function deleteChainFile({ file_id, order_chain_id }: DeleteChainFilePayload) {
  const url = buildApiUrl(DELETE_CHAIN_FILE_PATH).replace(
    ':orderChainId',
    encodeURIComponent(order_chain_id),
  )
  const payload = { file_id, order_chain_id }
  return axios.post<File>(url, payload, { tokenType: 'company' })
}

export function addChainImage({ formData, orderChainId }: AddChainImagePayload) {
  const url = buildApiUrl(ADD_CHAIN_IMAGE_PATH).replace(':orderChainId', encodeURIComponent(orderChainId))
  return axios.post<File>(url, formData, { tokenType: 'company' })
}

export function deleteChainImage({ file_id, order_chain_id }: DeleteChainImagePayload) {
  const url = buildApiUrl(DELETE_CHAIN_IMAGE_PATH).replace(
    ':orderChainId',
    encodeURIComponent(order_chain_id),
  )
  const payload = { file_id, order_chain_id }
  return axios.post<File>(url, payload, { tokenType: 'company' })
}

export function activatePersonalOrder(id: number) {
  const url = buildApiUrl(ACTIVE_PERSONAL_ORDER_PATH)
  return axios.post<unknown>(url, { id }, { tokenType: 'company' })
}

export function createPersonalOrder(requestName: string) {
  const url = buildApiUrl(CREATE_PERSONAL_ORDER_PATH)
  return axios.post<{ product_request_id: number }>(url, { request: requestName }, { tokenType: 'company' })
}

export function updatePersonalOrder(payload: UpdatePersonalOrderPayload) {
  const url = buildApiUrl(UPDATE_PERSONAL_ORDER_PATH)
  return axios.post<{ id: number }>(url, payload, { tokenType: 'company' })
}

export function getPersonalOrderDetails(id: number) {
  const url = buildApiUrl(PERSONAL_ORDER_DETAILS_PATH).replace(':orderId', encodeURIComponent(id))
  return axios.get<PersonalOrderDetails>(url, { tokenType: 'company' })
}
