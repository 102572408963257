import React from 'react'

import { cn } from '../../lib/react'

import { ReactComponent as AddFile } from './assets/add-file.svg'
import { ReactComponent as Archive } from './assets/archive.svg'
import { ReactComponent as ArrowDown } from './assets/arrow-down.svg'
import { ReactComponent as ArrowIn } from './assets/arrow-in.svg'
import { ReactComponent as ArrowLeft } from './assets/arrow-left.svg'
import { ReactComponent as ArrowOut } from './assets/arrow-out.svg'
import { ReactComponent as ArrowRight } from './assets/arrow-right.svg'
import { ReactComponent as ArrowUp } from './assets/arrow-up.svg'
import { ReactComponent as Arrows } from './assets/arrows.svg'
import { ReactComponent as AttentionCircle } from './assets/attention-circle.svg'
import { ReactComponent as Cart } from './assets/cart.svg'
import { ReactComponent as Chat } from './assets/chat.svg'
import { ReactComponent as Check } from './assets/check.svg'
import { ReactComponent as ChevronLeft } from './assets/chevron-left.svg'
import { ReactComponent as Close } from './assets/close.svg'
import { ReactComponent as Company } from './assets/company.svg'
import { ReactComponent as Copy } from './assets/copy.svg'
import { ReactComponent as Cry } from './assets/cry.svg'
import { ReactComponent as Developing } from './assets/developing.svg'
import { ReactComponent as Document } from './assets/document.svg'
import { ReactComponent as Download } from './assets/download.svg'
import { ReactComponent as Edit } from './assets/edit.svg'
import { ReactComponent as EmptyPack } from './assets/empty-pack.svg'
import { ReactComponent as Eng } from './assets/eng.svg'
import { ReactComponent as Error } from './assets/error.svg'
import { ReactComponent as Expand } from './assets/expand.svg'
import { ReactComponent as Geo } from './assets/geo.svg'
import { ReactComponent as Help } from './assets/help.svg'
import { ReactComponent as Home } from './assets/home.svg'
import { ReactComponent as Image } from './assets/image.svg'
import { ReactComponent as In } from './assets/in.svg'
import { ReactComponent as Info } from './assets/info.svg'
import { ReactComponent as Logout } from './assets/logout.svg'
import { ReactComponent as Menu } from './assets/menu.svg'
import { ReactComponent as Milk } from './assets/milk.svg'
import { ReactComponent as Minus } from './assets/minus.svg'
import { ReactComponent as NoNotify } from './assets/no-notify.svg'
import { ReactComponent as NoProducts } from './assets/no-products.svg'
import { ReactComponent as NoResult } from './assets/no-result.svg'
import { ReactComponent as NotFound } from './assets/not-found.svg'
import { ReactComponent as Notifications } from './assets/notifications.svg'
import { ReactComponent as Ok } from './assets/ok.svg'
import { ReactComponent as Options } from './assets/options.svg'
import { ReactComponent as Out } from './assets/out.svg'
import { ReactComponent as Partner } from './assets/partner.svg'
import { ReactComponent as Phone } from './assets/phone.svg'
import { ReactComponent as Plus } from './assets/plus.svg'
import { ReactComponent as Reload } from './assets/reload.svg'
import { ReactComponent as Rus } from './assets/rus.svg'
import { ReactComponent as SD } from './assets/sd.svg'
import { ReactComponent as Search } from './assets/search.svg'
import { ReactComponent as Send } from './assets/send.svg'
import { ReactComponent as ServerError } from './assets/server-error.svg'
import { ReactComponent as Settings } from './assets/settings.svg'
import { ReactComponent as Sort } from './assets/sort.svg'
import { ReactComponent as Spark } from './assets/spark.svg'
import { ReactComponent as Spin } from './assets/spin.svg'
import { ReactComponent as Star } from './assets/star.svg'
import { ReactComponent as Telegram } from './assets/telegram.svg'
import { ReactComponent as Telephone } from './assets/telephone.svg'
import { ReactComponent as Ua } from './assets/ua.svg'
import { ReactComponent as Upload } from './assets/upload.svg'
import { ReactComponent as User } from './assets/user.svg'
import { ReactComponent as VisibilityOff } from './assets/visibility-off.svg'
import { ReactComponent as Visibility } from './assets/visibility.svg'
import { DEFAULT_SIZE } from './constant'
import { calculateIconSize } from './utils/iconSizeUtil'

const iconMap = {
  'copy': Copy,
  'add-file': AddFile,
  'arrow-down': ArrowDown,
  'arrow-in': ArrowIn,
  'arrow-left': ArrowLeft,
  'arrow-out': ArrowOut,
  'arrow-right': ArrowRight,
  'arrow-up': ArrowUp,
  'cart': Cart,
  'company': Company,
  'close': Close,
  'document': Document,
  'download': Download,
  'edit': Edit,
  'eng': Eng,
  'error': Error,
  'expand': Expand,
  'geo': Geo,
  'help': Help,
  'home': Home,
  'image': Image,
  'in': In,
  'info': Info,
  'logout': Logout,
  'menu': Menu,
  'minus': Minus,
  'notifications': Notifications,
  'ok': Ok,
  'options': Options,
  'out': Out,
  'partner': Partner,
  'plus': Plus,
  'reload': Reload,
  'rus': Rus,
  'sd': SD,
  'search': Search,
  'arrows': Arrows,
  'sort': Sort,
  'spark': Spark,
  'spin': Spin,
  'star': Star,
  'ua': Ua,
  'upload': Upload,
  'user': User,
  'visibility-off': VisibilityOff,
  'visibility': Visibility,
  'developing': Developing,
  'cry': Cry,
  'server-error': ServerError,
  'empty-pack': EmptyPack,
  'no-notify': NoNotify,
  'no-products': NoProducts,
  'not-found': NotFound,
  'check': Check,
  'archive': Archive,
  'chat': Chat,
  'telegram': Telegram,
  'telephone': Telephone,
  'no-result': NoResult,
  'settings': Settings,
  'milk': Milk,
  'send': Send,
  'phone': Phone,
  'attention-circle': AttentionCircle,
  'chevron-left': ChevronLeft,
} as const

export type IconName = Keys<typeof iconMap>

export interface OwnProps {
  antIcon?: boolean
  name: IconName
  size?: number | string
  containerClassName?: string
}

type Props = OwnProps & React.ComponentProps<'svg'>

export default function Icon({
  name,
  size = DEFAULT_SIZE,
  // eslint-disable-next-line react/prop-types
  width,
  // eslint-disable-next-line react/prop-types
  height,
  // eslint-disable-next-line react/prop-types
  className,
  containerClassName,
  antIcon,
  ...props
}: Props) {
  const Component = iconMap[name]
  const iconSize = calculateIconSize(size, width, height)

  const icon = (
    <Component

      {...props}
      width={iconSize}
      height={iconSize}
      className={className}
    />
  )

  if (antIcon)
    return icon

  return <span className={cn('inline-flex', containerClassName)}>{icon}</span>
}
