import { t } from '@lingui/macro'
import { nanoid } from 'nanoid'

function getStatsItems() {
  return [
    { id: nanoid(), name: t`Клиентов`, value: '50+' },
    { id: nanoid(), name: t`Зарубежных компаний`, value: '10+' },
    { id: nanoid(), name: t`Поставщиков на сайте`, value: '40+' },
    { id: nanoid(), name: t`Создано успешных связей`, value: '10+' },
  ]
}

export default function LandingStats() {
  return (
    <section className="py-4 lg:py-8">
      <h2 className="sr-only">
        {t`Статистика`}
      </h2>
      <div>
        <dl className="grid grid-cols-[repeat(auto-fit,minmax(300px,1fr))] gap-7 text-lg lg:gap-5">
          {getStatsItems().map(({ name, value, id }) => (
            <div className="grid justify-center gap-2 lg:gap-4" key={id}>
              <dd className="text-center text-3xl font-extrabold lg:text-big">{value}</dd>
              <dt className="lg:text-lg">{name}</dt>
            </div>
          ))}
        </dl>
      </div>
    </section>
  )
}
