import { RoutePathEnum } from '~/enums/route-path-enum'

/**
 * Переносит параметры поиска из текущего пути к новому пути.
 */
export function buildPathsWithParams(pathname: string) {
  const searchParams = new URLSearchParams(location.search)
  return `${pathname}?${searchParams.toString()}`
}

export function getReturnToPath(fallback: string = RoutePathEnum.DASHBOARD) {
  const searchParams = new URLSearchParams(location.search)
  const returnTo = searchParams.get('returnTo') ?? fallback
  searchParams.delete('returnTo')

  const remainingSearchParams = searchParams.toString()
  let validReturnTo

  // разрешать только возврат на внутренние страницы. например /dashboard
  try {
    // если returnTo является относительным путем, это приведет к ошибке
    // eslint-disable-next-line no-new
    new URL(returnTo)
    // если нет ошибок, returnTo является действительным URL-адресом, а НЕ внутренней страницей.
    validReturnTo = fallback
  }
  catch (_) {
    validReturnTo = returnTo
  }

  return validReturnTo + (remainingSearchParams ? `?${remainingSearchParams}` : '')
}
