import { useMutation } from '@tanstack/react-query'
import { createPersonalOrder } from '~/domain/order/api'

type Variables = Parameters<typeof createPersonalOrder>[0]

export function useCreatePersonalOrder() {
  return useMutation({
    mutationFn: async (variables: Variables) => {
      const { data } = await createPersonalOrder(variables)
      return data
    },
  })
}
