import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { Suspense, lazy } from 'react'

import { withProviders } from './providers'

import { LazyCookiesNotification } from '~/features/cookies-notification'
import Routing from '~/pages'
import ModalContainer from '~/components/modal/ModalContainer'

const HelpFloatButton = lazy(() => import('~/shared/components/help-float-button'))

function App() {
  return (
    <>
      <Suspense fallback={null}>
        <Routing />
      </Suspense>

      <ModalContainer />
      <LazyCookiesNotification />

      <Suspense>
        <HelpFloatButton />
      </Suspense>

      <ReactQueryDevtools position="bottom-right" />
    </>
  )
}

export default withProviders(App)
