import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'

import Orders from './components/orders'
import PageHeader from '~/components/common/PageHeader'
import { RoutePathEnum } from '~/enums/route-path-enum'

export default function Page() {
  useLingui()

  return (
    <>
      <PageHeader
        title={t`Входящие запросы`}
        backRoutePath={RoutePathEnum.DASHBOARD}
      />
      <Orders />
    </>
  )
}
