import { useMutation } from '@tanstack/react-query'
import { updatePersonalOrder } from '~/domain/order/api'

type Response = Awaited<ReturnType<typeof updatePersonalOrder>>['data']
type Variables = Parameters<typeof updatePersonalOrder>[0]

export function useUpdatePersonalOrder() {
  return useMutation<Response, Error, Variables>({
    mutationFn: async (variables) => {
      const { data } = await updatePersonalOrder(variables)
      return data
    },
  })
}
