import { t } from '@lingui/macro'
import { useEventListener, useUnmount } from 'ahooks'
import { lazy, useState } from 'react'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'

import VerificationStep from './components/verification-step'
import {
  clearVerificationStep,
  getFirstStepTitle,
  getSavedVerificationStep,
  saveVerificationStep,
} from './utils'

import { RoutePathEnum } from '~/enums/route-path-enum'
import { VerificationMethod } from '~/enums/users-enum'
import AuthenticationPagesLayout from '~/layouts/authentication'
import { getReturnToPath } from '~/lib/paths'

const VerifyEmailForm = lazy(() => import('~/authentication/forms/verify-email'))
const VerifyPhoneForm = lazy(() => import('~/authentication/forms/verify-phone'))
const EditProfileForm = lazy(() => import('~/users/forms/edit'))

export default function IdentityVerificationPage() {
  const location = useLocation()
  const navigate = useNavigate()

  const [currentStep, setCurrentStep] = useState(getSavedVerificationStep())

  function nextStep() {
    setCurrentStep((prevStep) => {
      const newStep = prevStep + 1
      saveVerificationStep(newStep)
      return newStep
    })
  }

  function completeVerification() {
    const url = new URL(window.location.href)
    const hasReturnTo = url.searchParams.get('returnTo')

    if (hasReturnTo) {
      navigate(getReturnToPath())
    }
    else {
      navigate({
        pathname: location?.state?.from?.pathname ?? RoutePathEnum.DASHBOARD,
        search: 'new_user',
      })
    }

    clearVerificationStep()
  }

  useUnmount(() => {
    clearVerificationStep()
  })

  useEventListener('beforeunload', () => {
    clearVerificationStep()
  })

  const visiblePhone = location.state?.phone as string | undefined
  const visibleEmail = location.state?.email as string | undefined
  const verificationMethod = location.state?.method as VerificationMethod | undefined
  if (!verificationMethod)
    return <Navigate to={RoutePathEnum.SIGN_UP} replace />

  return (
    <AuthenticationPagesLayout>
      <div className="mb-5 text-center space-y-2">
        <p className="text-lg font-bold">{t`Завершите регистрацию`}</p>
        <p>
          {t`Подтвердите почту и настройте профиль для максимального удобства!`}
          {/* {t`Подтвердите почту, настройте профиль и привяжите Telegram для максимального
          удобства!`} */}
        </p>
      </div>

      <div className="grid gap-2">
        <VerificationStep
          title={getFirstStepTitle(verificationMethod)}
          open={currentStep === 1}
          completed={currentStep > 1}
        >
          {visiblePhone && <VerifyPhoneForm visiblePhone={visiblePhone} onFinish={nextStep} />}
          {visibleEmail && <VerifyEmailForm visibleEmail={visibleEmail} onConfimed={nextStep} />}
        </VerificationStep>

        <VerificationStep
          title={t`Шаг 2: Заполните профиль`}
          open={currentStep === 2}
          completed={currentStep > 2}
        >
          <EditProfileForm
            disabledFields={[verificationMethod === VerificationMethod.EMAIL ? 'email' : 'phone']}
            initialValues={{ email: visibleEmail, phone: visiblePhone }}
            onFinish={completeVerification}
            skipButtonProps={{ children: t`Пропустить`, onClick: completeVerification }}
          />
        </VerificationStep>
      </div>
    </AuthenticationPagesLayout>
  )
}
