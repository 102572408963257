export function isNil(value: unknown) {
  return value == null
}

export function isEmptyString(value: unknown) {
  if (typeof value === 'string')
    return value.trim() === ''

  return false
}

export function mbToByte(mb: number) {
  const byte = 1e6
  return mb * byte
}

export function convertDateToInputValue(date: Date) {
  return date.toISOString().slice(0, 10)
}

export function convertInputValueToDate(date: string) {
  if (isEmptyString(date))
    return ''

  return new Date(date).toISOString()
}

/**
 * Это следует использовать в любое время, когда путь для редиректа предоставляется пользователем
 * (Например, редирект обратно из страниц входа/регистрации).
 * Это позволит избежать **open-redirect** уязвимостей
 */
export function safeRedirect(to: string, defaultRedirect = '/') {
  if (isEmptyString(to))
    return defaultRedirect

  if (!to.startsWith('/') || to.startsWith('//'))
    return defaultRedirect

  return to
}
