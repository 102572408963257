import ReactSelect from 'react-select'

import type { Option } from './interface'
import SelectDropdownIndicator from './select-dropdown-indicatior'
import SelectOption from './select-option'
import SelectValueContainer from './select-value-container'

interface Props {
  options: Option[]
  onChange: (newValue: Nullable<Option>) => void
  value: Option
  defaultValue?: Option
  label: string
  disabled?: boolean
}

export default function Select({
  label,
  options,
  onChange,
  value,
  defaultValue,
  disabled = false,
}: Props) {
  return (
    <ReactSelect
      components={{
        ValueContainer: SelectValueContainer,
        Option: SelectOption,
        DropdownIndicator: SelectDropdownIndicator,
      }}
      placeholder={label}
      onChange={onChange}
      value={value}
      isDisabled={disabled}
      defaultValue={defaultValue}
      isSearchable={false}
      options={options}
      classNames={{
        menuList: () => 'popover mt-2 overflow-auto',
        control: () => 'bg-white relative rounded-lg !h-[3.25rem]',
      }}
      unstyled
    />
  )
}
