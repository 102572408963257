import type { CatalogCategory } from '~/domain/product/api'

export function findCategoryByCode(
  data: CatalogCategory | Indexed<string, CatalogCategory>,
  code: string,
): CatalogCategory | null {
  if (data.code === code)
    return data as CatalogCategory

  for (const key in data) {
    if (data[key] instanceof Object && 'code' in data[key]) {
      const category = findCategoryByCode(data[key] as CatalogCategory, code)

      if (category)
        return category
    }
  }

  return null
}
