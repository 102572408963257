import { cva } from 'class-variance-authority'

export const buttonVariants = cva(['btn'], {
  variants: {
    color: {
      blue: [
        'text-white',
        'hover:bg-blue-dark hover:text-white',
        'focus:bg-blue-dark focus:border-blue-light focus:text-white',
        'active:bg-blue-darken active:border-transparent active:text-white',
      ],
      green: [
        'text-white',
        'hover:bg-green-light hover:text-white',
        'focus:bg-green-light focus:border-green-dark focus:text-white',
        'active:bg-green-dark active:border-transparent active:text-white',
      ],
      red: [
        'text-white',
        'hover:bg-red-dark hover:text-white',
        'focus:border-red-darken focus:bg-red-dark focus:text-white',
        'active:bg-red-darken active:border-transparent active:text-white',
      ],
      yellow: [
        'text-white',
        'hover:bg-yellow-dark hover:text-white',
        'focus:border-yellow-darken focus:bg-yellow-dark focus:text-white',
        'active:bg-yellow-darken active:border-transparent active:text-white',
      ],
      black: [
        'text-white',
        'bg-black text-white',
        'hover:bg-black-light',
        'focus:border-gray-border-2',
        'active:bg-black',
      ],
      gray: '',
    },
    variant: {
      solid: '',
      soft: '',
      outline: [
        'bg-transparent border-gray-border-1 text-black',
        'hover:bg-blue hover:text-white',
        'focus:text-black focus:border-blue focus:bg-transparent',
        'active:border-transparent active:text-white active:bg-blue-darken',
      ],
      link: 'bg-transparent hover:bg-transparent focus:bg-transparent active:bg-transparent',
    },
    size: {
      xs: 'px-2.5 py-[calc(0.25rem_-_2px)] text-md leading-4',
      sm: 'px-3.5 py-[calc(0.5rem_-_2px)] text-md',
      md: 'px-6 py-[calc(0.75rem_-_2px)] text-base',
      lg: 'px-8 py-[calc(1rem_-_2px)] text-base',
    },
    disabled: {
      true: 'text-gray-text-1 bg-gray-border-1',
      false: null,
    },
    onlyIcon: {
      true: '-indent-[9999px]',
      false: null,
    },
  },

  defaultVariants: {
    color: 'blue',
    variant: 'solid',
    size: 'md',
    onlyIcon: false,
  },

  compoundVariants: [
    {
      color: 'blue',
      variant: 'solid',
      className: 'bg-blue',
    },
    {
      color: 'blue',
      variant: 'soft',
      className: 'bg-blue/20 text-blue',
    },
    {
      color: 'blue',
      variant: 'link',
      className:
        'text-blue hover:text-blue-dark focus:text-blue-dark active:text-blue-darken',
    },

    {
      color: 'green',
      variant: 'solid',
      className: 'bg-green',
    },
    {
      color: 'green',
      variant: 'soft',
      className: 'bg-green/20 text-green',
    },
    {
      color: 'green',
      variant: 'link',
      className:
        'text-green hover:text-green-dark focus:text-green-dark active:text-green-darken',
    },

    {
      color: 'yellow',
      variant: 'solid',
      className: 'bg-yellow',
    },
    {
      color: 'yellow',
      variant: 'soft',
      className: 'bg-yellow/20 text-yellow',
    },
    {
      color: 'yellow',
      variant: 'link',
      className:
        'text-yellow hover:text-yellow-dark focus:text-yellow-dark active:text-yellow-darken',
    },

    {
      color: 'red',
      variant: 'solid',
      className: 'bg-red',
    },
    {
      color: 'red',
      variant: 'soft',
      className: 'bg-red/20 text-red',
    },
    {
      color: 'red',
      variant: 'link',
      className:
        'text-red hover:text-red-dark focus:text-red-dark active:text-red-darken',
    },

    {
      color: 'gray',
      variant: 'link',
      className:
        'text-gray-text-2 hover:text-gray-text-3 focus:text-gray-text-3 active:text-gray-text-3',
    },

    {
      variant: 'link',
      size: ['sm', 'xs', 'md', 'lg'],
      className: 'p-1',
    },

    { onlyIcon: true, size: 'xs', className: 'p-1' },
    { onlyIcon: true, size: 'sm', className: 'p-2' },
    { onlyIcon: true, size: 'md', className: 'p-3' },
    { onlyIcon: true, size: 'lg', className: 'p-3.5' },
  ],
})
