import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { i18n } from '@lingui/core'
import { t } from '@lingui/macro'
import { useQueryClient } from '@tanstack/react-query'
import { App, Button, Spin } from 'antd'
import useToken from 'antd/es/theme/useToken'
import { lazy, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import ProductCardMetaInfo from '~/components/products/ProductCardMetaInfo'
import ProductsCard from '~/components/products/ProductsCard'
import StatusTag from '~/core/ui/StatusTag'
import { ReceivedOrderStatusEnum } from '~/domain/order/enums'
import { RoutePathEnum } from '~/enums/route-path-enum'
import OrderReceivedResponseDialog from '~/features/order-received-response-dialog'
import { mapReceivedOrderStatusToTKey, mapReceivedOrderStatusToTagType } from '~/lib/requests/constants'
import { useRejectOrder } from '~/pages/orders/received/mutations/use-reject-order'
import { useReceivedOrderDetails } from '~/pages/orders/received/queries/use-received-order-details'
import { Icon } from '~/shared/components/icon'
import PageHeader from '~/components/common/PageHeader'
import { Result } from '~/shared/components/result'
import { companyKeys } from '~/shared/tanstack/keys/company'
import { concatenateUserName } from '~/entities/user'

const OrderApprovalDetails = lazy(() => import('~/components/order/OrderApprovalDetails'))

function selectRecievedOrder(data: any) {
  const isPersonalOrder = data.product_request.is_personal_request
  const product = isPersonalOrder ? data.product_request : data.product_request.product
  // Если не указано имя, будет показан емейл
  const author = data.responsible_user
    ? concatenateUserName(
      {
        first_name: data?.responsible_user?.first_name,
        last_name: data?.responsible_user?.last_name,
        middle_name: data?.responsible_user?.middle_name,
      },
      data.responsible_user.email,
    )
    : null

  return {
    chainInfo: {
      comment: data?.comment ?? null,
      files: data?.files ?? [],
      images: data?.images ?? [],
      offeredPrice: data?.price ?? null,
      offeredDate: data?.time ?? null,
      createdAt: data?.time_answer ?? null,
      author,
    },
    company: product?.company?.name,
    id: data.id,
    status: data.status,
    amount: data.product_request.amount,
    preferablePrice: data.product_request.preferable_price,
    preferableTime: data.product_request.preferable_time,
    address: data.product_request.address,
    email: data.product_request.email,
    product: {
      id: product.id,
      description: product.description,
      name: isPersonalOrder ? product.request : product.name,
      extraFields: product.extra_fields,
      condition: product.condition,
      files: product.files,
      images: product.images,
    },
  }
}

export default function ReceivedOrderDetailsPage() {
  const { id: orderId } = useParams()

  const { data: order, status } = useReceivedOrderDetails(
    { id: Number(orderId) },
    {
      // @ts-expect-error FIXME:
      select: selectRecievedOrder,
      refetchOnWindowFocus: true,
      retry: false,
    },
  )

  if (status === 'loading')
    return <Spin />

  if (status === 'error') {
    return (
      <Result
        icon={<Icon name="not-found" width={126} height={106} />}
        title={t`Страница не найдена`}
        description={t`Страница, которую вы ищете, удалена или временно недоступна.`}
      />
    )
  }

  return (
    <>
      <PageHeader title={t`Детали запроса`} backRoutePath={RoutePathEnum.RECEIVED_ORDERS} />
      <Order order={order} />
    </>
  )
}

function Order({ order }: any) {
  const { chainInfo } = order

  const { modal } = App.useApp()
  const [, token] = useToken()
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const [showConfirmForm, setShowConfirmForm] = useState(false)

  const { mutate: reject } = useRejectOrder()

  function invalidateOrder() {
    queryClient.invalidateQueries({ queryKey: companyKeys.receivedOrder(order.id) })
  }

  function handleRejectClick() {
    modal.confirm({
      title: t`Вы уверены, что хотите отклонить запрос?`,
      centered: true,
      onOk: () => {
        reject(order.id, { onSuccess: invalidateOrder })
      },
    })
  }

  function handleConfirm() {
    invalidateOrder()
    navigate('/o/received')
  }

  const canShowOrderActions = order.status === ReceivedOrderStatusEnum.WAITING
  const isConfirmedOrder = order.status === ReceivedOrderStatusEnum.CONFIRMED

  let extraNode
  if (canShowOrderActions) {
    extraNode = (
      <div className="grid gap-2 sm:grid-cols-[repeat(2,auto)] sm:justify-start">
        <Button
          icon={<CheckOutlined />}
          type="primary"
          size="large"
          style={{ backgroundColor: token.colorSuccess }}
          onClick={() => {
            setShowConfirmForm(true)
          }}
        >
          {t`Ответить`}
        </Button>

        <Button
          icon={<CloseOutlined />}
          type="primary"
          size="large"
          style={{ backgroundColor: token.colorError }}
          onClick={handleRejectClick}
        >
          {t`Отклонить`}
        </Button>
        {/* <Button
          icon={<Icon name="close" />}
          variant="solid"
          color="red"
          onClick={() => setShowRejectConfirm(true)}
        >
          <Trans>Отклонить</Trans>
        </Button> */}
      </div>
    )
  }

  return (
    <>
      <ProductsCard
        status={(
          <StatusTag
          // @ts-expect-error FIXME:
            message={i18n._(mapReceivedOrderStatusToTKey[order.status])}
            // @ts-expect-error FIXME:
            type={mapReceivedOrderStatusToTagType[order.status]}
          />
        )}
        product={{
          category: order.category?.name,
          id: order.product.id,
          description: order.product.description,
          name: order.product.name,
          extra_fields: order.product.extraFields,
          condition: order.product.condition,
          files: order.product.files,
          images: order.product.images,
        }}
        meta={(
          <ProductCardMetaInfo
            email={order.email}
            amount={order.amount}
            price={order.preferablePrice}
            deliveryDate={order.preferableTime}
            deliveryAddress={order.address}
            company={order.company}
          />
        )}
        extra={extraNode}
      />

      {isConfirmedOrder && (
        <OrderApprovalDetails
          offeredPrice={chainInfo.offeredPrice}
          offeredDate={chainInfo.offeredDate}
          author={chainInfo.author}
          files={chainInfo.files.concat(chainInfo.images)}
          createdDate={chainInfo.createdAt}
          comment={chainInfo.comment}
        />
      )}

      <OrderReceivedResponseDialog
        orderId={order.id}
        productImageUrl={order.product.images[0]?.links[0]}
        productName={order.product.name}
        open={showConfirmForm}
        onClose={() => setShowConfirmForm(false)}
        onOk={handleConfirm}
      />
    </>
  )
}
