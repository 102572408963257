import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import React from 'react'

import { Button } from '~/shared/components/button'
import { TextField } from '~/shared/components/text-field'

interface Props {
  onAdd: (fieldName: string) => void
  onClose: () => void
}

export default function Form({ onAdd, onClose }: Props) {
  useLingui()

  const [fieldName, setFieldName] = React.useState('')

  const trimmedFieldName = fieldName.trim()
  const hasEmptyFieldName = trimmedFieldName.length === 0

  function handleAdd() {
    if (hasEmptyFieldName)
      return

    onAdd(trimmedFieldName)
    onClose()
    setFieldName('')
  }

  return (
    <div className="grid gap-4">
      <TextField
        label={t`Название характеристики`}
        className="w-full"
        value={fieldName}
        onChange={event => setFieldName(event.target.value)}
      />

      <Button className="w-full" onClick={handleAdd} disabled={hasEmptyFieldName}>
        {t`Добавить поле`}
      </Button>
    </div>
  )
}
