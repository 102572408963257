import useUrlState from '@ahooksjs/use-url-state'
import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { lazy } from 'react'
import { useSearchProducts } from '../hooks/use-sarch-products'
import { DEFAULT_PAGE } from '../constants'

const TableSearchBox = lazy(() => import('~/shared/components/table-search-box'))
const ProductsTable = lazy(() => import('./products-table'))

export default function Products() {
  useLingui()
  const [urlState, setUrlState] = useUrlState({ page: DEFAULT_PAGE, q: '' })

  const query = String(urlState.q).trim()
  const page = Number(urlState.page)

  const { data: products, isFetching } = useSearchProducts({ q: query, page: Number(urlState.page) })

  function onSearch(value: string) {
    setUrlState({ q: value })
  }

  function onChangePage(value: number) {
    setUrlState({ page: value })
  }

  return (
    <>
      <TableSearchBox defaultValue={urlState.q} onSearch={onSearch} placeholder={t`Я хочу найти...`} enterButton allowClear />

      {products?.data && (
        <ProductsTable
          products={products.data}
          loading={isFetching}
          pagination={{ current: page, total: products.count, onChange: onChangePage }}
        />
      )}
    </>
  )
}
