import { t } from '@lingui/macro'
import { nanoid } from 'nanoid'

function getCategoriesItems() {
  return [
    { id: nanoid(), title: t`Электротехника`, img: '/landing/chastotnyj-invertor.webp', img2x: undefined, count: 5 },
    { id: nanoid(), title: t`Промышленные контроллеры`, img: '/landing/promyshlennye-kontrollery.jpg', img2x: undefined, count: 5 },
    { id: nanoid(), title: t`Оборудование для пищевой промышленности`, img: '/landing/equipment.png', img2x: '/landing/equipment@2x.png 2x', count: 0 },
    { id: nanoid(), title: t`Упаковочные материалы`, img: '/landing/medical.png', img2x: '/landing/medical@2x.png 2x', count: 400 },
    { id: nanoid(), title: t`Сельско-хозяйственная техника`, img: '/landing/packing.png', img2x: '/landing/packing@2x.png 2x', count: 0 },
  ]
}

export default function LandingPopularCategories() {
  return (
    <section className="py-5 lg:py-12">
      <h2 className="mb-5 text-xl font-bold lg:mb-12 lg:text-3xl">{t`Популярные категории`}</h2>

      <div className="flex gap-2 overflow-x-scroll lg:grid lg:grid-cols-[repeat(auto-fit,minmax(400px,1fr))] lg:gap-5 lg:overflow-visible">
        {getCategoriesItems().map(({ count, img, title, img2x, id }) => (
          <div
            className="w-[240px] flex flex-shrink-0 flex-col gap-2 rounded-2xl bg-white p-4 lg:w-auto lg:flex-row lg:justify-between lg:p-7"
            key={id}
          >
            <img
              className="aspect-square h-32 w-32 object-contain lg:order-1"
              src={img}
              srcSet={img2x}
              width={130}
              height={130}
              loading="lazy"
              alt=""
            />

            <div className="h-full flex flex-col gap-2">
              <h3 className="grow text-lg font-semibold lg:text-xxl">{title}</h3>
              <span className="text-blue font-medium">{t`${count} предложений`}</span>
            </div>
          </div>
        ))}

        <div className="w-[240px] flex flex-shrink-0 flex-col items-center justify-center gap-2 rounded-2xl bg-white lg:w-auto">
          <span className="text-big font-semibold">10 000+</span>
          <span className="text-lg">{t`других товаров`} </span>
        </div>
      </div>
    </section>
  )
}
