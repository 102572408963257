import { Tour } from 'antd'
import { Outlet } from 'react-router-dom'

import MobileBottomNavBar from './components/mobile-bottom-nav-bar'
import Header from './header'
import LeftMenu from './left-menu'

import { useWelcomeTour } from '~/welcome-tour/context'

export default function AppLayout() {
  const { close, open, steps } = useWelcomeTour()

  return (
    <div className="relative pb-20 md:pb-0 md:pl-20 xl:pl-72">
      <LeftMenu />

      <div className="min-h-[calc(100vh_-_5rem)] flex flex-col md:min-h-screen">
        <Header />

        <div className="flex grow">
          <main className="mx-auto w-full flex flex-col px-4 py-5 2xl:max-w-[84rem] 2xl:px-12 md:py-7 xl:px-7">
            <Outlet />
          </main>
        </div>
      </div>

      <MobileBottomNavBar />

      <Tour open={open} onClose={close} steps={steps} />
    </div>
  )
}
