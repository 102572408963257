import { t } from '@lingui/macro'

interface UserNames {
  first_name?: string
  middle_name?: string
  last_name?: string
}

function getFallbackName() {
  return t`Без имени`
}

export function concatenateUserName(user: UserNames, fallback = getFallbackName()): string {
  const names = Object.values(user)
    .filter(Boolean)
    .map(name => `${name} `)
    .join('')
    .trim()

  return names || fallback
}
