import SkeletonCircle from './skeleton-circle'
import SkeletonLine from './skeleton-line'
import SkeletonRoot from './skeleton-root'

const Skeleton = {
  Root: SkeletonRoot,
  Circle: SkeletonCircle,
  Line: SkeletonLine,
}

export { Skeleton }
