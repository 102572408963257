import type { UseMutationOptions } from '@tanstack/react-query'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { App } from 'antd'
import { post } from '../fetchers'
import { getCompanyToken } from '~/api/user-api'
import { LOCAL_STORAGE_KEYS } from '~/lib/constants'
import type { CompanyTypesEnum } from '~/enums/company-enums'

export interface CreateCompanyVariables {
  name: string
  inn?: string
  okved: number[]
  website?: string
  company_type: CompanyTypesEnum
  legal_address: string
}

export async function createCompany(payload: CreateCompanyVariables) {
  const { data, error } = await post(`/users/company/create/`, {
    // @ts-expect-error FIXME(swagger)
    body: payload,
  })

  if (error)
    throw error

  return data
}

type CreateCompanyData = Awaited<ReturnType<typeof createCompany>>
type CreateCompanyError = unknown

export function useCreateCompanyMutation({
  onSuccess,
  onError,
  ...options
}: Omit<UseMutationOptions<CreateCompanyData, CreateCompanyError, CreateCompanyVariables>, 'mutationFn' > = {}) {
  const queryClient = useQueryClient()
  const { message } = App.useApp()

  return useMutation<CreateCompanyData, CreateCompanyError, CreateCompanyVariables>({
    mutationFn: vars => createCompany(vars),
    async onSuccess(data, variables, context) {
      const createdCompanyId = data.id
      if (!createdCompanyId)
        throw new Error('createdCompanyId is required')

      const { data: newCompanyToken } = await getCompanyToken(createdCompanyId)
      localStorage.setItem(LOCAL_STORAGE_KEYS.ACTIVE_COMPANY_TOKEN, newCompanyToken.Token)
      localStorage.setItem(LOCAL_STORAGE_KEYS.ACTIVE_COMPANY_ID, String(createdCompanyId))

      await queryClient.resetQueries()
      await onSuccess?.(data, variables, context)
    },
    async onError(data, variables, context) {
      if (onError === undefined)
        message.error(`Failed to mutate: createCompany`)
      else
        onError(data, variables, context)
    },
    ...options,
  })
}
