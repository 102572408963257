import { t } from '@lingui/macro'
import { Link } from 'react-router-dom'

const PRIVACY_POLICY_URL = 'https://supplydirector.ru/static/docs/consent_processing_registration.pdf'

export default function LandingFooter() {
  return (
    <footer className="flex flex-col items-center justify-between gap-5 pb-8 text-gray-text-3 md:flex-row md:py-5">
      <span>© Supply Director, 2023</span>
      <Link className="hover:text-blue hover:underline" target="_blank" to={PRIVACY_POLICY_URL}>
        {t`Политика конфиденциальности`}
      </Link>
    </footer>
  )
}
