import { useLingui } from '@lingui/react'
import { useState } from 'react'
import { Flex } from 'antd'
import useUrlState from '@ahooksjs/use-url-state'
import { DEFAULT_PAGE } from '../constants'
import { useCreatedOrders } from '../hooks/use-created-orders'
import type { FilterParams } from '../types'
import OrdersTable from './orders-table'
import OrderCraetedFiltersForm from '~/features/order-created-filters-form'

export default function Orders() {
  useLingui()
  const [page, setPage] = useState(DEFAULT_PAGE)
  const [urlState, setUrlState] = useUrlState<FilterParams>()

  const { data: allOrders, isLoading: isLoadingOrders, isSuccess: isSuccessOrders } = useCreatedOrders({
    page,
    q: urlState.query,
    type: urlState.type,
    answers: urlState.answers,
    status: urlState.status,
  }, { keepPreviousData: true })

  function onChangeFilters(params: FilterParams) {
    setUrlState(params)
  }

  function onResetFilters() {
    setUrlState({ query: undefined, type: undefined, status: undefined, answers: undefined })
  }

  return (
    <Flex gap="large" vertical>
      <Flex gap="small" vertical>
        <OrderCraetedFiltersForm
          onReset={onResetFilters}
          onFinish={onChangeFilters}
          initialValues={{
            query: urlState.query,
            type: urlState.type,
            answers: urlState.answers,
            status: urlState.status,
          }}
        />

        {isSuccessOrders && (
          <OrdersTable
            orders={allOrders.data}
            loading={isLoadingOrders}
            pagination={{ current: page, total: allOrders.count, onChange: setPage }}
          />
        )}
      </Flex>
    </Flex>
  )
}
