import { t } from '@lingui/macro'
import { nanoid } from 'nanoid'

import { Panel } from '~/shared/components/panel'

function getOurServiceItems() {
  return [
    {
      id: nanoid(),
      img: 'wallet',
      title: t`для покупателей`,
      items: [
        t`Простой, удобный и быстрый процесс поиска оборудования и других товаров`,
        t`Собранные по категориям предлагаемых товаров списки продавцов`,
        t`Автоматизация процесса создания и управления заявками`,
      ],
    },
    {
      id: nanoid(),
      img: 'bag',
      title: t`для продавцов`,
      items: [
        t`Размещение и рекомендация вашей продукции и товаров на площадке`,
        t`Возможность моментально реагировать на самые выгодные заявки`,
        t`Удобная сортировка входящих запросов по цене, количеству и другим важным параметрам`,
      ],
    },
  ]
}

export default function LandingOurService() {
  return (
    <section className="grid grid-cols-[repeat(auto-fit,minmax(300px,1fr))] gap-2 py-5 lg:py-12">
      <h2 className="sr-only">{t`Для кого наш сервис`}</h2>

      {getOurServiceItems().map(({ items, title, img, id }) => (
        <Panel key={id}>
          <div className="grid gap-4 px-4 py-5 lg:gap-7 lg:p-7">
            <div className="flex items-center gap-4 lg:flex-col lg:items-start lg:gap-7">
              <img
                className="lg:h-[90px] lg:w-[90px]"
                src={`/landing/${img}.png`}
                srcSet={`/landing/${img}@2x.png 2x`}
                width={60}
                height={60}
                alt=""
                loading="lazy"
              />
              <h3 className="max-w-[176px] text-xl font-bold lg:max-w-[280px] lg:text-3xl">
                Supply Director
                {' '}
                <span className="text-blue">{title}</span>
              </h3>
            </div>

            <ul className="grid gap-5 text-md font-medium">
              {items.map((item, index) => (
                <li
                  className="min-h-[1rem] bg-[length:1rem_1rem] bg-[url('/landing/check.svg')] bg-left-top bg-no-repeat pl-7 lg:min-h-[1.5rem] lg:bg-[length:1.5rem_1.5rem] lg:pl-10 lg:text-lg"
                  key={index}
                >
                  {item}
                </li>
              ))}
            </ul>
          </div>
        </Panel>
      ))}
    </section>
  )
}
