import { msg } from '@lingui/macro'

import { StatusTagType } from '~/core/ui/StatusTag'
import { CreatedOrderStatusEnum, ReceivedOrderStatusEnum } from '~/domain/order/enums'

/** @deprecated */
export const mapReceivedOrderStatusToTKey = {
  [ReceivedOrderStatusEnum.COMPLETED]: msg`Завершено`,
  [ReceivedOrderStatusEnum.WAITING]: msg`Ожидание`,
  [ReceivedOrderStatusEnum.REJECT]: msg`Отклонено`,
  [ReceivedOrderStatusEnum.CONFIRMED]: msg`Подтверждено`,
} as const

export const mapReceivedOrderStatusToTagType = {
  [ReceivedOrderStatusEnum.COMPLETED]: StatusTagType.Neutral,
  [ReceivedOrderStatusEnum.CONFIRMED]: StatusTagType.Success,
  [ReceivedOrderStatusEnum.WAITING]: StatusTagType.Warning,
  [ReceivedOrderStatusEnum.REJECT]: StatusTagType.Error,
} as const

/** @deprecated */
export const mapCreatedOrderStatusToTagType = {
  [CreatedOrderStatusEnum.HISTORY]: StatusTagType.Error,
  [CreatedOrderStatusEnum.CONSTRUCTION]: StatusTagType.Neutral,
  [CreatedOrderStatusEnum.ACTIVE]: StatusTagType.Success,
  [CreatedOrderStatusEnum.ARCHIVE]: StatusTagType.Warning,
  [CreatedOrderStatusEnum.REJECT]: StatusTagType.Error,
} as const

/** @deprecated */
export const mapCreatedOrderStatusToTKey = {
  [CreatedOrderStatusEnum.HISTORY]: msg`Устарел`,
  [CreatedOrderStatusEnum.CONSTRUCTION]: msg`Создан`,
  [CreatedOrderStatusEnum.ACTIVE]: msg`Активно`,
  [CreatedOrderStatusEnum.ARCHIVE]: msg`В архиве`,
  [CreatedOrderStatusEnum.REJECT]: msg`Отклонён`,
} as const

/** @deprecated */
export const orderChainStatusToTagTKeyMap = {
  [ReceivedOrderStatusEnum.COMPLETED]: msg`Подтвержден`,
  [ReceivedOrderStatusEnum.REJECT]: msg`Отклонён`,
  [ReceivedOrderStatusEnum.CONFIRMED]: msg`Новый`,
} as const

/** @deprecated */
export const orderChainStatusToTagTypeMap = {
  [ReceivedOrderStatusEnum.COMPLETED]: StatusTagType.Success,
  [ReceivedOrderStatusEnum.CONFIRMED]: StatusTagType.Neutral,
  [ReceivedOrderStatusEnum.REJECT]: StatusTagType.Error,
} as const
