import { t } from '@lingui/macro'
import type { TagProps } from 'antd'
import { OrderChainStatusEnum } from '../../enums'

export function getStatusName(status: OrderChainStatusEnum) {
  const names = {
    [OrderChainStatusEnum.COMPLETED]: t`ПОДТВЕРЖДËН`,
    [OrderChainStatusEnum.REJECT]: t`ОТКЛОНËН`,
    [OrderChainStatusEnum.CONFIRMED]: t`НОВЫЙ`,
    [OrderChainStatusEnum.WAITING]: t`ОЖИДАНИЕ`,
  }
  return names[status]
}

export function getStatusColor(status: OrderChainStatusEnum) {
  const colors: Indexed<OrderChainStatusEnum, TagProps['color'] > = {
    [OrderChainStatusEnum.COMPLETED]: 'success',
    [OrderChainStatusEnum.REJECT]: 'error',
    [OrderChainStatusEnum.CONFIRMED]: 'processing',
    [OrderChainStatusEnum.WAITING]: 'warning',
  }
  return colors[status]
}
