import { Trans } from '@lingui/macro'
import { Link } from 'react-router-dom'

import { RoutePathEnum } from '~/enums/route-path-enum'

export default function SignInLink() {
  return (
    <div className="text-center">
      <Trans>
        У вас уже есть аккаунт?&nbsp;
        <Link className="ant-typography text-blue" to={RoutePathEnum.SIGN_IN}>
          Войти
        </Link>
      </Trans>
    </div>
  )
}
