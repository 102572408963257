import { t } from '@lingui/macro'
import { App, Button, Form, Input } from 'antd'
import ResendCodeButton from '../resend-code-button'
import { BEFORE_RESEND_PHONE_CODE_STORAGE_KEY } from '~/authentication/forms/verify-phone/constants'
import { useConfirmCodeMutation } from '~/data/auth/confirm-code-mutation'
import { useSendPhoneCodeMutation } from '~/data/auth/send-phone-code-mutation'

interface Props {
  visiblePhone: string
  onConfirm: (accessToken: string) => void
  onConfirmFailed?: () => void
}

interface FormState {
  code: string
}

export default function UserPhoneConfirmForm({ onConfirm, onConfirmFailed, visiblePhone }: Props) {
  const { message } = App.useApp()
  const [form] = Form.useForm<FormState>()

  const { mutate: confirm, isLoading: isConfirming } = useConfirmCodeMutation({
    onSuccess({ Token: accessToken }) {
      if (!accessToken)
        return message.error(t`Произошла внутренняя ошибка сервера. Пожалуйста, попробуйте позже или свяжитесь с технической поддержкой.`)

      localStorage.removeItem(BEFORE_RESEND_PHONE_CODE_STORAGE_KEY)
      onConfirm(accessToken)
    },
    onError() {
      message.error(t`Код авторизации не верен или его срок действия истёк`)
      onConfirmFailed?.()
    },
  })
  const { mutate: sendCode, isLoading: isSending } = useSendPhoneCodeMutation()

  function onSubmit({ code }: FormState) {
    confirm({ code, username: visiblePhone })
  }

  function onSendCode() {
    sendCode({ phone: visiblePhone })
  }

  return (
    <>
      <div className="mb-3 text-md text-gray-text-3">{t`Номер получателя: +${visiblePhone}`}</div>

      <Form layout="vertical" form={form} onFinish={onSubmit} disabled={isConfirming || isSending}>
        <Form.Item name="code" label={t`Код для продолжения`} rules={[{ required: true }]}>
          <Input maxLength={6} />
        </Form.Item>

        <div className="mb-4">
          <ResendCodeButton
            onResend={onSendCode}
            storageKey={BEFORE_RESEND_PHONE_CODE_STORAGE_KEY}
          />
        </div>

        <Button
          loading={isConfirming || isSending}
          htmlType="submit"
          type="primary"
          block
        >
          {t`Подтвердить`}
        </Button>
      </Form>
    </>
  )
}
