import { useMutation } from '@tanstack/react-query'
import { deleteCompanyProductFile } from '~/domain/company/api'

interface Variables {
  productId: number
  fileId: number
}

export function useDeleteProductImage() {
  return useMutation({
    mutationFn: async ({ fileId, productId }: Variables) => {
      const { data } = await deleteCompanyProductFile({ fileId, productId, fileType: 'image' })
      return data
    },
  })
}
