import clsx from 'clsx'
import type { DropdownIndicatorProps } from 'react-select'
import { components } from 'react-select'

import { Icon } from '../icon'

import type { Option } from './interface'

const { DropdownIndicator } = components

export default function SelectDropdownIndicator(
  props: DropdownIndicatorProps<Option, false>,
) {
  const { isFocused } = props

  return (
    <DropdownIndicator {...props} className="pr-3.5">
      <Icon
        name="arrow-down"
        className={clsx(
          'cursor-pointer text-black transition-transform hover:text-blue',
          isFocused && 'rotate-180',
        )}
      />
    </DropdownIndicator>
  )
}
