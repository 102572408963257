import { z } from 'zod'

export default z.object({
  name: z
    .string()
    .min(1, 'Обязательное поле')
    .max(150, `Максимальная длина ${150} символов`),
  description: z.string().max(4000, `Максимальная длина ${4000} символов`).optional(),
  vendorCode: z.string().max(100, `Максимальная длина ${100} символов`).optional(),
  series: z.string().max(100, `Максимальная длина ${100} символов`).optional(),
  site: z.string()
    .max(1000, `Максимальная длина ${1000} символов`)
    .url('Сайт должен начинаться с http:// или https://')
    .or(z.literal('')),
  categories: z.array(z.object({ name: z.string(), id: z.string() })),
  price: z.number().max(2147483647, `Максимальная цена - ${2147483647}`).optional(),
  condition: z.object({ label: z.string(), value: z.number() }),
  extra_fields: z.array(
    z.object({
      name: z.string(),
      value: z.string().min(1, 'Обязательное поле'),
    }),
  ),
})
