import { t } from '@lingui/macro'
import { App, Button, Form, Input } from 'antd'

import { useConfirmEmailStorage } from '~/entities/auth'
import { useSignUp } from '~/data/auth/sign-up-mutation'
import { clearSavedCompanyToken } from '~/authentication/forms/sign-up/utils/company-tokens'

interface Props {
  onSignUp: (email: string) => void
  onSignUpFailed?: () => void
}

interface FormState {
  username: string
  password: string
  confirm_password: string
}

const signUpErrorMessage = () => t`Ошибка регистрации: К сожалению, мы не можем обработать ваш запрос на регистрацию в данный момент. Пожалуйста, повторите попытку позже.`

export default function UserSignUpForm({ onSignUp, onSignUpFailed }: Props) {
  const { message } = App.useApp()
  const [form] = Form.useForm<FormState>()

  const { setConfirmEmail } = useConfirmEmailStorage()
  const { mutate: signUp, isLoading: isSigningUp } = useSignUp({
    onSuccess(_, { username: email }) {
      clearSavedCompanyToken() // удалить текущие сохранённые токены если они есть
      setConfirmEmail(email)

      onSignUp(email)
    },
    onError(error) {
      // @ts-expect-error FIXME: Присылать нормальную ошибку с бэка
      const text = error?.username[0] ?? signUpErrorMessage()
      message.error(text)
      onSignUpFailed?.()
    },
  })

  function onFinish({ password, username }: FormState) {
    const credentials = { password, username }
    signUp(credentials)
  }

  return (
    <Form layout="vertical" name="email-signup" form={form} onFinish={onFinish} disabled={isSigningUp}>
      <Form.Item<FormState>
        label={t`Email`}
        name="username"
        rules={[{ required: true }, { type: 'email' }]}
        hasFeedback
      >
        <Input type="email" autoComplete="none" inputMode="email" />
      </Form.Item>

      <Form.Item<FormState>
        label={t`Пароль`}
        name="password"
        rules={[
          { required: true },
          { min: 8, message: t`Пароль должен быть больше или равен 8 символов` },
        ]}
        hasFeedback
      >
        <Input.Password autoComplete="new-password" />
      </Form.Item>

      <Form.Item<FormState>
        name="confirm_password"
        label={t`Пароль (повторно)`}
        dependencies={['password']}
        hasFeedback
        rules={[
          { required: true },
          {
            validator(_, value) {
              if (!value || form.getFieldValue('password') === value)
                return Promise.resolve()

              return Promise.reject(new Error(t`Пароли должны совпадать`))
            },
          },
        ]}
      >
        <Input.Password autoComplete="new-password" />
      </Form.Item>

      <Button type="primary" htmlType="submit" loading={isSigningUp} block>
        {t`Регистрация`}
      </Button>
    </Form>

  )
}
