interface Props {
  fields: string[]
}

export default function IncompletedFieldsList({ fields }: Props) {
  return (
    <ul className="list-disc list-inside">
      {fields.map(field => (
        <li key={field}>{field}</li>
      ))}
    </ul>
  )
}
