import type { UseQueryOptions } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'

import { DEFAULT_LIMIT, DEFAULT_PAGE } from '../constants'
import type { ListParams } from '~/shared/api'
import { companyKeys } from '~/shared/tanstack/keys/company'
import { getCompanyProducts } from '~/domain/company/api'

type Response = Awaited<ReturnType<typeof getCompanyProducts>>['data']
type Params = Partial<ListParams>
type Options = UseQueryOptions<Response, Error>

export function useCompanyProducts(
  { page = DEFAULT_PAGE, limit = DEFAULT_LIMIT, q = '' }: Params,
  options: Options,
) {
  return useQuery({
    keepPreviousData: true,
    queryKey: companyKeys.products({ limit, page, q }),
    queryFn: async () => {
      const { data } = await getCompanyProducts({ limit, page, q })
      return data
    },
    ...options,
  })
}
