import { t } from '@lingui/macro'
import { RoutePathEnum } from '~/enums/route-path-enum'

import ProductDetailsForm from '~/products/forms/details'
import PageHeader from '~/components/common/PageHeader'

export default function NewCompanyProductPage() {
  return (
    <div className="max-w-xl">
      <PageHeader title={t`Добавить новый товар`} backRoutePath={RoutePathEnum.COMPANY_PRODUCTS} />

      <ProductDetailsForm />
    </div>
  )
}
