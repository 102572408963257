import clsx from 'clsx'
import React from 'react'

interface Props {
  children: React.ReactNode
  className?: string
}

export default function Panel({ children, className }: Props) {
  return <div className={clsx('rounded-xl bg-white shadow', className)}>{children}</div>
}
