import { t } from '@lingui/macro'

import { Panel } from '~/shared/components/panel'

function getFeaturesItems() {
  return [
    {
      imgUrl: '/landing/best-providers',
      title: t`Лучшие поставщики в одном месте`,
      description: t`На нашей платформе Вы сможете выбрать наилучшего поставщика для исполнения вашего заказа из списка откликнувшихся`,
    },
    {
      imgUrl: '/landing/automated-process',
      title: t`Автоматизированный процесс сделки`,
      description: t`Не нужно искать и обзванивать поставщиков в поисках необходимого товара, достаточно разместить запрос и ждать предложений`,
    },
    {
      imgUrl: '/landing/magic-trick',
      title: t`От простого товара до уникальных заказов`,
      description: t`Платформа позволяет быстро и просто найти решения даже на самые сложные и редкие запросы и наименования`,
    },
  ]
}

export default function LandingFeatures() {
  return (
    <section className="scroll-mt-3 py-4 lg:py-5" id="features">
      <h2 className="sr-only">{t`Наши преимущества`}</h2>

      <ul className="grid grid-cols-[repeat(auto-fit,minmax(300px,1fr))] gap-2 lg:gap-7">
        {getFeaturesItems().map(({ description, imgUrl, title }) => (
          <li className="flex" key={imgUrl}>
            <Panel>
              <div className="grid gap-2 p-4 lg:p-6">
                <img
                  className="lg:h-[100px] lg:w-[100px]"
                  src={`${imgUrl}.png`}
                  srcSet={`${imgUrl}@2x.png 2x`}
                  width={60}
                  height={60}
                  alt=""
                  role="presentation"
                  loading="lazy"
                />

                <h3 className="text-lg font-bold lg:text-xl">{title}</h3>
                <p className="text-gray-text-3 lg:text-lg">{description}</p>
              </div>
            </Panel>
          </li>
        ))}
      </ul>
    </section>
  )
}
