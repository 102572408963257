import clsx from 'clsx'
import React from 'react'

interface Props {
  title: string
  value?: React.ReactNode
  bold?: boolean
}

export default function Ceil({ title, value, bold }: Props) {
  return (
    <div className="grid gap-2.5">
      <span className="text-sm text-gray-text-2">{title}</span>
      <div className={clsx('truncate whitespace-pre-line text-md text-black', { 'font-semibold': bold })}>
        {value ?? '-'}
      </div>
    </div>
  )
}
