import 'virtual:uno.css'
import './index.css'
import 'react-loading-skeleton/dist/skeleton.css'

import ReactDOM from 'react-dom/client'

import App from './app'

const root = ReactDOM.createRoot(document.getElementById('root') as Element, {
  identifierPrefix: 'sd-app-',
})

root.render(<App />)
