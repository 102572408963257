import type { CompanyProductTableRow } from './types'
import type { CompanyProductsResponse } from '~/shared/types/company'

export function prepareTableData(products: CompanyProductsResponse['data']): CompanyProductTableRow[] {
  return products.map((product) => {
    return {
      companyName: product.company,
      country: product.country,
      imageUrl: product.image[0] ?? null,
      isAvailable: product.is_available,
      key: product.id,
      price: product.price,
      productId: product.id,
      productName: product.name,
      rating: product.rating,
      region: product.region,
    }
  })
}
