import { t } from '@lingui/macro'
import { Avatar, Space, Table, type TableProps, Typography } from 'antd'
import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { useLingui } from '@lingui/react'
import { prepareTableData } from '../utils'
import type { CompanyProductTableRow } from '../types'

import TableActionMenu from './table-action-menu'
import HiddenProductIndicator from './hidden-product-indicator'
import type { CompanyProductsResponse } from '~/shared/types/company'
import { numberFormat } from '~/shared/lib/format'

const { Text } = Typography
const { Column } = Table

interface Props {
  products: CompanyProductsResponse['data']
  loading: TableProps<CompanyProductTableRow>['loading']
  pagination: TableProps<CompanyProductTableRow>['pagination']
}

export default function ProductsTable({ products, loading, pagination }: Props) {
  useLingui()

  const dataSource = useMemo(() => prepareTableData(products), [products])

  return (
    <Table<CompanyProductTableRow>
      pagination={pagination}
      dataSource={dataSource}
      loading={loading}

    >
      <Column<CompanyProductTableRow>
        key="productName"
        title={t`Название товара`}
        dataIndex="productName"
        render={(_value, record) => {
          return (
            <Space>
              {record.imageUrl && <Avatar src={record.imageUrl} />}
              <Link to={`/company/p/${record.productId}`}>{record.productName}</Link>
              {!record.isAvailable && <HiddenProductIndicator />}
            </Space>
          )
        }}
      />
      <Column<CompanyProductTableRow>
        key="region"
        title={t`Регион`}
        dataIndex="region"
        responsive={['sm']}
        render={value => value || <Text type="secondary">{t`Не указано`}</Text>}
      />
      <Column<CompanyProductTableRow>
        key="price"
        title={t`Цена`}
        dataIndex="price"
        responsive={['sm']}
        render={(_, record) => {
          return record.price === null ? t`Цена по запросу` : numberFormat(record.price)
        }}
      />
      <Column<CompanyProductTableRow>
        align="right"
        key="actions"
        title={t`Действия`}
        dataIndex="actions"
        render={(_, record) => {
          return <TableActionMenu isAvailable={Boolean(record.isAvailable)} productId={record.productId} />
        }}
      />
    </Table>
  )
}
