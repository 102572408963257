import type { UseQueryOptions } from '@tanstack/react-query'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useCallback, useState } from 'react'

import { get } from '../fetchers'
import { userKeys } from './keys'
import { LOCAL_STORAGE_KEYS } from '~/lib/constants'

export async function getUser(signal?: AbortSignal) {
  const { data, error } = await get(`/users/user/`, { signal })
  if (error)
    throw error

  const hasCompanies = data.companies.length > 0
  const savedActiveCompanyId = Number(localStorage.getItem(LOCAL_STORAGE_KEYS.ACTIVE_COMPANY_ID)) || null

  if (!savedActiveCompanyId && hasCompanies) {
    const defaultCompanyId = data.companies[0].id
    localStorage.setItem(LOCAL_STORAGE_KEYS.ACTIVE_COMPANY_ID, defaultCompanyId!.toString())
  }

  return data
}

export type UserData = Awaited<ReturnType<typeof getUser>>
export type UserError = unknown

export function useUserQuery<TData = UserData>(
  { enabled = true, ...options }: UseQueryOptions<UserData, UserError, TData> = {},
) {
  const [accessToken] = useState(localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN) ?? '')

  return useQuery<UserData, UserError, TData>({
    refetchOnWindowFocus: false,
    queryKey: userKeys.me(),
    queryFn: ({ signal }) => getUser(signal),
    enabled: enabled && !!accessToken,
    ...options,
  })
}

export function useUserPrefetch() {
  const client = useQueryClient()

  return useCallback(() => {
    client.prefetchQuery(userKeys.me(), ({ signal }) => getUser(signal))
  }, [])
}
