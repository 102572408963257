import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'

import { Icon } from '../icon'
import Result from '~/core/ui/Result'

export default function MaintanceMessage() {
  useLingui()

  return (
    <Result
      icon={<Icon name="developing" width={64} height={64} />}
      title={t`Сервис ещё дорабатывается`}
      subtitle={t`Мы скоро доделаем эту функцию и уведомим Вас!`}
    />
  )
}
