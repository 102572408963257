import { UserOutlined } from '@ant-design/icons'
import { t } from '@lingui/macro'
import { Avatar, Button } from 'antd'
import { Link, useNavigate } from 'react-router-dom'

import { RoutePathEnum } from '~/enums/route-path-enum'
import { useIsLoggedIn } from '~/lib/auth'

export default function LandingStartButton() {
  const navigate = useNavigate()

  const isLoggedIn = useIsLoggedIn()

  return isLoggedIn
    ? (
      <div className="flex items-center">
        <Button type="primary" onClick={() => navigate(RoutePathEnum.DASHBOARD)}>
          {t`Начать работу`}
        </Button>

        <Link to={RoutePathEnum.DASHBOARD}>
          <Avatar className="ml-2 bg-blue" icon={<UserOutlined />} />
        </Link>
      </div>
      )
    : (
      <Button
        type="primary"
        onClick={() => navigate(RoutePathEnum.SIGN_IN)}
      >
        {t`Вход/Регистрация`}
      </Button>
      )
}
