import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { useMutation } from '@tanstack/react-query'
import { App } from 'antd'

import { showUnknownError } from '~/core/utils/toasts'
import { rejectOrder } from '~/domain/order/api'

export function useRejectOrder() {
  useLingui()
  const { message } = App.useApp()

  // TODO: Сервер возвращает новый статус. Нужно обновить его вручную минуя запрос на сервер
  return useMutation({
    mutationFn: rejectOrder,
    onSuccess: () => message.success(t`Запрос отклонён`),
    onError: showUnknownError,
  })
}
