import type { UseMutationOptions } from '@tanstack/react-query'
import { useMutation } from '@tanstack/react-query'

import { App } from 'antd'
import { post } from '../fetchers'

export interface ConfirmCodeVariables {
  username: string
  code: string
}

export async function confirmCode({ code, username }: ConfirmCodeVariables) {
  const { data, error } = await post('/users/user/code/verify/', {
    // @ts-expect-error FIXME: Исправить в сваггере
    body: { code, username },
  })

  if (error)
    throw error
  return data
}

type ConfirmCodeData = Awaited<ReturnType<typeof confirmCode>>
type ConfirmCodeError = unknown

export function useConfirmCodeMutation({
  onSuccess,
  onError,
  ...options
}: Omit<UseMutationOptions<ConfirmCodeData, ConfirmCodeError, ConfirmCodeVariables>, 'mutationFn' > = {}) {
  const { message } = App.useApp()

  return useMutation<ConfirmCodeData, ConfirmCodeError, ConfirmCodeVariables>({
    mutationFn: vars => confirmCode(vars),
    async onSuccess(data, variables, context) {
      await onSuccess?.(data, variables, context)
    },
    async onError(data, variables, context) {
      if (onError === undefined)
        message.error(`Failed to mutate: confirmCode`)
      else
        onError(data, variables, context)
    },
    ...options,
  })
}
