import React from 'react'
import { Link } from 'react-router-dom'

interface Props {
  url: string
  children: React.ReactNode
}

export default function BreadcrumbLink({ url, children }: Props) {
  return (
    <Link to={url} className="text-blue hover:text-blue-dark">
      {children}
    </Link>
  )
}
