import type { UseMutationOptions } from '@tanstack/react-query'
import { useMutation } from '@tanstack/react-query'

import { App } from 'antd'
import { post } from '../fetchers'

export interface SignUpVariables {
  username: string
  password: string
}

export async function signUp({ password, username }: SignUpVariables) {
  const { data, error } = await post('/users/auth/', {
    body: { password, username },
  })

  if (error)
    throw error
  return data
}

type SignUpData = Awaited<ReturnType<typeof signUp>>
type SignUpError = unknown

export function useSignUp({
  onSuccess,
  onError,
  ...options
}: Omit<UseMutationOptions<SignUpData, SignUpError, SignUpVariables>, 'mutationFn' > = {}) {
  const { message } = App.useApp()

  return useMutation<SignUpData, SignUpError, SignUpVariables>({
    mutationFn: vars => signUp(vars),
    async onSuccess(data, variables, context) {
      await onSuccess?.(data, variables, context)
    },
    async onError(data, variables, context) {
      if (onError === undefined)
        message.error(`Failed to mutate: signUp`)
      else
        onError(data, variables, context)
    },
    ...options,
  })
}
