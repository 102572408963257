import clsx from 'clsx'

function createInitials(name: string) {
  return name
    .split(' ')
    .map(word => word[0])
    .join('')
    .slice(0, 2) // Обрезать до 2-ух символов
}

const AvatarSize = {
  Large: 'large',
  Small: 'small',
} as const

const mapSizeNameToSize = {
  [AvatarSize.Large]: 100,
  [AvatarSize.Small]: 24,
} as const

const INITIALS_FONT_SIZE_SCALE = 2.5

interface AvatarProps {
  name?: string
  image?: string
  imageAlt: string
  size?: Values<typeof AvatarSize> | number
  fallbackClassName?: string
  fallback?: boolean
  className?: string
}

function Avatar({
  name,
  image,
  imageAlt,
  size = AvatarSize.Small,
  fallbackClassName,
  fallback = true,
  className,
}: AvatarProps) {
  const hasImage = Boolean(image)
  const avatarSize = typeof size === 'string' ? mapSizeNameToSize[size] : size
  const hasName = name && name.trim().length > 0

  const initials = createInitials(name ?? '')

  if (hasImage) {
    return (
      <img
        className={clsx('shrink-0 rounded-full', className)}
        src={image}
        width={avatarSize}
        height={avatarSize}
        alt={imageAlt}
        loading="lazy"
      />
    )
  }

  return (
    <span
      className={clsx(
        'flex shrink-0 select-none items-center justify-center overflow-hidden rounded-full bg-green uppercase text-white',
        {
          'h-6 w-6 text-sm': size === AvatarSize.Small,
          'h-[100px] w-[100px] font-bold': size === AvatarSize.Large,
          'invisible': !fallback, // Скрывать если не нужен дефолтный аватар. Например для товаров
        },
        fallbackClassName,
      )}
      style={{
        width: avatarSize,
        height: avatarSize,
        fontSize: avatarSize / INITIALS_FONT_SIZE_SCALE,
      }}
    >
      {hasName ? initials : '😎'}
    </span>
  )
}

export default Avatar
