export enum CompanyTypesEnum {
  PRODUCER = 1,
  PROVIDER = 2,
}

export enum CompanyRolesEnum {
  OWNER = 1,
  MANAGER = 2,
  STAFF = 3,
}

export enum CompanyPermissionsEnum {
  COMPANY_INFO = 'company_info_permission',
  PRODUCT_INTERACTION = 'product_interaction_permission',
  ORDERING = 'ordering_permission',
  FACTORY = 'factory_permission',
  ADD_USERS = 'add_users_permission',
  PROPOSAL = 'proposal_permission',
}
