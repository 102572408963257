import type { UseQueryOptions } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'
import { getCompanyProduct } from '~/domain/company/api'

import { companyKeys } from '~/shared/tanstack/keys/company'

interface Params {
  id: number
}

type Response = Awaited<ReturnType<typeof getCompanyProduct>>['data']
type Options = UseQueryOptions<Response, Error>

export function useCompanyProductDetails({ id }: Params, options?: Options) {
  return useQuery({
    queryKey: companyKeys.product(id),
    queryFn: async () => {
      const { data } = await getCompanyProduct(id)
      return data
    },
    ...options,
  })
}
