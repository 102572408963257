import { Trans } from '@lingui/macro'
import { Link } from 'react-router-dom'

export default function CompanyCreatePolicyLink() {
  return (
    <span className="text-md text-gray-text-3">
      <Trans>
        Нажимая кнопку Создать компанию Вы соглашаетесь с{' '}
        <Link
          to="https://supplydirector.ru/static/docs/consent_processing_registration.pdf"
          className="text-blue underline hover:text-blue-darken focus-visible:ring focus-visible:ring-blue"
        >
          Политикой конфиденциальности
        </Link>
      </Trans>
    </span>
  )
}
