import { QueryClientProvider } from '@tanstack/react-query'
import React from 'react'
import { useRootQueryClient } from '~/data/query-client'

export function withQuery(Component: React.ComponentType) {
  const displayName = Component.displayName || Component.name || 'Component'

  function ComponentsWithQuery() {
    const queryClient = useRootQueryClient()

    return (
      <QueryClientProvider client={queryClient}>
        <Component />
      </QueryClientProvider>
    )
  }

  ComponentsWithQuery.displayName = `withQuery(${displayName})`
  return ComponentsWithQuery
}
