import { t } from '@lingui/macro'
import LandingSupplierSearchRequestButton from './LandingSupplierSearchRequestButton'
import LandingSupplierBecomeButton from './LandingSupplierBecomeButton'

export default function LandingHero() {
  return (
    <section className="py-5 lg:flex lg:items-center lg:pt-0">
      <img
        className="max-w-[40.438rem] w-full lg:order-1"
        src="/landing/hero.png"
        srcSet="/landing/hero@2x.png 2x"
        width={320}
        height={224}
        alt="Демонстрация интерфейса сервиса"
        loading="lazy"
      />

      <div className="grid justify-items-start gap-4 lg:gap-6">
        <h2 className="text-xxl font-bold lg:text-3xl lg:leading-10">
          {t`Умный поиск поставщиков оборудования и запчастей`}
        </h2>
        <p className="lg:text-xl">
          {t`Получите доступ к широкой сети заказчиков и поставщиков промышленного оборудования и запчастей. Год бесплатно!`}
        </p>

        <div className="flex flex-wrap gap-2">
          <LandingSupplierSearchRequestButton />
          <LandingSupplierBecomeButton />
        </div>

      </div>
    </section>
  )
}
