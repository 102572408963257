import { Trans, t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { Button, Empty, Space } from 'antd'
import type { AxiosError } from 'axios'
import { HttpStatusCode } from 'axios'
import { useNavigate } from 'react-router-dom'

import { INDEX_PAGE_PATH_FROM_FALLBACK } from '../contants'

interface ErrorBoundaryFallbackProps {
  /**
   * when ErrorBoundary catch error, you can use this error
   */
  error: Error
  /**
   * when you want to reset caught error, you can use this reset
   */
  reset: () => void
}

type Props = ErrorBoundaryFallbackProps

export default function RejectedFallback({ reset, error }: Props) {
  useLingui()

  const navigate = useNavigate()

  function goToIndexPage() {
    navigate(INDEX_PAGE_PATH_FROM_FALLBACK, { replace: true })
  }

  const axiosError = error as AxiosError
  const isTokenExpired = axiosError.response?.status === HttpStatusCode.NotFound

  return (
    <div>
      {isTokenExpired
        ? (
          <Empty
            description={t`Извините, эта пригласительная ссылка недействительна. Пожалуйста, убедитесь, что вы используете корректную ссылку для принятия приглашения в компанию.`}
          >
            <Button type="primary" onClick={goToIndexPage}>
              <Trans>На главную</Trans>
            </Button>
          </Empty>
          )
        : (
          <Empty
            description={t`Произошла внутренняя ошибка сервера. Пожалуйста, попробуйте позже или свяжитесь с технической поддержкой.`}
          >
            <Space>
              <Button type="default" onClick={goToIndexPage}>
                <Trans>На главную</Trans>
              </Button>

              <Button type="primary" onClick={reset}>
                <Trans>Повторить</Trans>
              </Button>
            </Space>
          </Empty>
          )}
    </div>
  )
}
