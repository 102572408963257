import { useMutation } from '@tanstack/react-query'
import { createCompanyProduct } from '~/domain/company/api'

type Variables = Parameters<typeof createCompanyProduct>[0]

export function useCreateCompanyProduct() {
  return useMutation({
    mutationFn: async (variables: Variables) => {
      const { data } = await createCompanyProduct(variables)
      return data
    },
  })
}
