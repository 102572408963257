import { useState } from 'react'
import { Flex } from 'antd'
import useUrlState from '@ahooksjs/use-url-state'
import { useReceivedOrders } from '../hooks/use-received-orders'
import { DEFAULT_PAGE } from '../constants'
import type { FilterParams } from '../types'
import OrdersTable from './orders-table'

import OrderReceivedFiltersForm from '~/features/order-received-filters-form'

export default function Orders() {
  const [page, setPage] = useState(DEFAULT_PAGE)
  const [urlState, setUrlState] = useUrlState<FilterParams>()

  const { data: orders, isFetching } = useReceivedOrders({
    page,
    q: urlState.query,
    type: urlState.type,
    status: urlState.status,
  })

  function onChangeFilters(params: FilterParams) {
    setUrlState(params)
  }

  function onResetFilters() {
    setUrlState({ query: undefined, type: undefined, status: undefined })
  }

  return (
    <Flex gap="large" vertical>
      {orders?.data && (
        <Flex gap="small" vertical>
          <OrderReceivedFiltersForm
            onReset={onResetFilters}
            onFinish={onChangeFilters}
            initialValues={{
              query: urlState.query,
              type: urlState.type,
              status: urlState.status,
            }}
          />

          <OrdersTable
            orders={orders.data}
            loading={isFetching}
            pagination={{ current: page, total: orders.count, onChange: setPage }}
          />
        </Flex>
      )}
    </Flex>
  )
}
