import Cookies from 'js-cookie'
import React from 'react'

type UseCookieReturn = [
  string | null,
  (newValue: string, options?: Cookies.CookieAttributes) => void,
  () => void,
]

export function useCookie(cookieName: string): UseCookieReturn {
  const [value, setValue] = React.useState<Nullable<string>>(
    () => Cookies.get(cookieName) || null,
  )

  const updateCookie = React.useCallback(
    (newValue: string, options?: Cookies.CookieAttributes) => {
      Cookies.set(cookieName, newValue, options)
      setValue(newValue)
    },
    [cookieName],
  )

  const deleteCookie = React.useCallback(() => {
    Cookies.remove(cookieName)
    setValue(null)
  }, [cookieName])

  return [value, updateCookie, deleteCookie]
}
