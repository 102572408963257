import { i18n } from '@lingui/core'

import { t } from '@lingui/macro'
import type { FormValues } from './interface'

import { FormMode } from './constants'
import type { PRODUCT_CONDITION } from '~/lib/products/constants'
import { PRODUCT_CONDITION_NAME } from '~/lib/products/constants'
import type { CompanyProductDetails } from '~/domain/company/api'

function getConditionOption(value: PRODUCT_CONDITION) {
  return { label: i18n._(PRODUCT_CONDITION_NAME[value]), value }
}

export function toDefaultValues(product: CompanyProductDetails): FormValues {
  return {
    price: product?.price ?? undefined,
    description: product.description,
    name: product.name,
    extra_fields: product.extra_fields,
    categories: product.categories,
    condition: getConditionOption(product.condition),
    site: product.product_link ?? '',
    vendorCode: product.article_number ?? '',
    series: product.product_series ?? '',
  }
}

export function prepareProductData(values: FormValues, productId?: number) {
  return {
    ...values,
    ...(productId && { id: productId }), // Для редактирования продукта
    condition: values.condition.value,
    price: values.price ?? null,
    categories: values.categories.map(c => c.id),
    article_number: values.vendorCode || null,
    product_series: values.series || null,
    product_link: values.site || null,
  }
}

export function getSubmitButtonText(formMode: FormMode) {
  const text = {
    [FormMode.EDIT]: t`Сохранить`,
    [FormMode.CREATE]: t`Добавить товар`,
  }

  return text[formMode]
}
