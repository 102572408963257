import { t } from '@lingui/macro'
import { CheckOutlined, CloseOutlined, DeleteOutlined, EditOutlined, EyeInvisibleOutlined, EyeOutlined, InboxOutlined, RollbackOutlined, ShoppingCartOutlined } from '@ant-design/icons'
import type Icon from '@ant-design/icons'
import type { GlobalToken } from 'antd'
import type { Actions } from './types'

export function getActionLabel(action: Actions) {
  const labes: Indexed<Actions, string> = {
    reject: t`Отклонить`,
    confirm: t`Ответить`,
    archivate: t`В архив`,
    unarchivate: t`Вернуть`,
    delete: t`Удалить`,
    edit: t`Редактировать`,
    hide: t`Скрыть`,
    repeat: t`Повторить`,
    show: t`Показать`,
    order: t`Заказать`,
  }

  return labes[action]
}

export function getActionIcon(action: Actions) {
  const icons: Indexed<Actions, typeof Icon> = {
    reject: CloseOutlined,
    confirm: CheckOutlined,
    archivate: InboxOutlined,
    unarchivate: RollbackOutlined,
    delete: DeleteOutlined,
    edit: EditOutlined,
    hide: EyeOutlined,
    repeat: RollbackOutlined,
    show: EyeInvisibleOutlined,
    order: ShoppingCartOutlined,
  }

  return icons[action]
}

export function getActionIconColor(action: Actions) {
  const colors: Indexed<Actions, keyof GlobalToken> = {
    reject: 'colorError',
    confirm: 'colorSuccess',
    archivate: 'colorWarning',
    unarchivate: 'colorWarning',
    delete: 'colorError',
    edit: 'colorPrimary',
    hide: 'colorSuccess',
    repeat: 'colorSuccess',
    show: 'colorSuccess',
    order: 'colorSuccess',
  }

  return colors[action]
}
