import type { AvatarSize } from '../interface'

const avatarSizeMap: Indexed<AvatarSize, number> = {
  large: 100,
  small: 24,
}

export function getAvatarSize(size: AvatarSize) {
  return typeof size === 'string' ? avatarSizeMap[size] : size
}
