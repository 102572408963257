import { LeftOutlined } from '@ant-design/icons'
import { t } from '@lingui/macro'
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom'

import VerifyPhoneForm from '~/authentication/forms/verify-phone'
import { RoutePathEnum } from '~/enums/route-path-enum'
import AuthenticationPagesLayout from '~/layouts/authentication'

export default function PhoneVerificationPage() {
  const location = useLocation()
  const navigate = useNavigate()

  const visiblePhone = location.state?.phone as string | undefined
  if (!visiblePhone)
    return <Navigate to={RoutePathEnum.SIGN_IN} replace />

  return (
    <AuthenticationPagesLayout>
      <div className="grid gap-5">
        <Link to={RoutePathEnum.SIGN_IN} className="grid grid-cols-[min-content,auto] gap-2 text-blue">
          <LeftOutlined />
          {t`Назад`}
        </Link>

        <VerifyPhoneForm onFinish={() => navigate(RoutePathEnum.DASHBOARD)} visiblePhone={visiblePhone} />
      </div>
    </AuthenticationPagesLayout>
  )
}
