import type { ReactNode } from 'react'

// ----------------------------------------------------------------------

interface ResultProps {
  extra?: ReactNode
  icon?: ReactNode
  title?: string
  subtitle?: string
  children?: ReactNode
}

// ----------------------------------------------------------------------

export default function Result({ extra, icon, title, subtitle, children }: ResultProps) {
  let titleNode: ReactNode
  if (title)
    titleNode = <p className="text-center text-xxl text-black font-bold">{title}</p>

  let subtitleNode: ReactNode
  if (subtitle)
    subtitleNode = <p className="text-center text-base text-gray-text-2">{subtitle}</p>

  let extraNode: ReactNode
  if (extra)
    extraNode = <div className="mt-6 text-center">{extra}</div>

  return (
    <div className="flex flex-col items-center px-5 py-8 space-y-3">
      {icon}
      {titleNode}
      {subtitleNode}
      {extraNode}

      {children}
    </div>
  )
}
