import { t } from '@lingui/macro'
import type { RefSelectProps, SelectProps } from 'antd'
import { Select } from 'antd'
import type { Ref } from 'react'
import { forwardRef } from 'react'
import { CompanyTypesEnum } from '~/enums/company-enums'

function getOptions(): SelectProps['options'] {
  return [
    { label: t`Продавать оборудование`, value: CompanyTypesEnum.PRODUCER },
    { label: t`Покупать оборудование`, value: CompanyTypesEnum.PROVIDER },
  ]
}

const CompanyTypeSelect = forwardRef((props, ref: Ref<RefSelectProps>) => {
  return <Select placeholder={t`Выберите действие`} options={getOptions()} ref={ref} {...props} />
})

CompanyTypeSelect.displayName = 'CompanyTypeSelect'

export default CompanyTypeSelect
