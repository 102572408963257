import { i18n } from '@lingui/core'
import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { Alert, Space, Tag, Typography } from 'antd'

import ProductCardSectionItem from './ProductCardSectionItem'
import ProductsImageGallery from './ProductsImageGallery'

import DownloadDocLink from '~/core/ui/DownloadDocLink'
import { isNil } from '~/core/utils/common'
import { mapToProductConditionTKey } from '~/lib/products/constants'
import { Icon } from '~/shared/components/icon'
import { Panel } from '~/shared/components/panel'
import { AVAILABLE_STATUS } from '~/shared/constants'
import { numberFormat } from '~/shared/lib/format'

const { Link } = Typography

export default function ProductsCard({
  site = null,
  series = null,
  vendorCode = null,
  product = null,
  status = null,
  alert = null,
  meta = null,
  extra = null,
  actions = null,
}: any) {
  useLingui()

  const {
    factory_producer: factoryProducer,
    condition,
    name,
    price,
    description,
    images,
    files = [],
    rating,
    categories = [],
    extra_fields: paramsFields = [],
    is_available: isAvailable,
  } = product

  const hasFiles = files.length !== 0
  const isHidden = isAvailable === AVAILABLE_STATUS.inactive
  const hasCondition = !isNil(condition)

  let footerNode
  if (meta || extra) {
    footerNode = (
      <div className="col-span-1 flex flex-col gap-5 border-t border-gray-border-1 pt-5 sm:col-span-2 group-data-[hidden=true]:pointer-events-none group-data-[hidden=true]:opacity-50">
        {meta}
        {extra}
      </div>
    )
  }

  let categoriesNode
  if (categories.length > 0) {
    categoriesNode = (
      <Space size={[0, 8]} wrap>
        {categories.map(({ id, name }: any) => (
          <Tag key={id}>{name}</Tag>
        ))}
      </Space>
    )
  }

  return (
    <Panel>
      <div className="group flex flex-col p-7 space-y-10" data-hidden={isHidden}>
        <div className="flex flex-col gap-5 md:flex-row">
          <ProductsImageGallery images={images} />

          <div className="grow space-y-4">
            {actions}
            {alert}
            {isAvailable === AVAILABLE_STATUS.inactive && (
              <Alert
                type="warning"
                message={t`Товар скрыт и не будет отображаться в поиске другим пользователям`}
                showIcon
              />
            )}

            <div className="space-y-4 group-data-[hidden=true]:pointer-events-none group-data-[hidden=true]:select-none group-data-[hidden=true]:opacity-50">
              <div className="flex flex-col md:flex-row md:justify-between">
                <div className="md:pr-4">
                  <h1 className="text-lg text-black font-bold md:text-xl">{name}</h1>
                </div>
                {status && <div className="order-first mb-5 md:order-none md:mb-0">{status}</div>}
              </div>

              {categoriesNode}

              <SpecInfo vendorCode={vendorCode} series={series} site={site} />

              <RatingAndPrice rating={rating} price={price} />

              {description && <Description description={description} />}

              {hasFiles && <Files files={files} />}

              {paramsFields.length > 0 && <Params fields={paramsFields} />}

              {hasCondition && (
                <ProductCardSectionItem title={t`Состояние`}>
                  {/* @ts-expect-error ... */}
                  {i18n._(mapToProductConditionTKey[condition])}
                </ProductCardSectionItem>
              )}

              {factoryProducer && (
                <ProductCardSectionItem title={t`Завод`}>{factoryProducer.name}</ProductCardSectionItem>
              )}
            </div>
          </div>
        </div>

        {footerNode}
      </div>
    </Panel>
  )
}

const priceFormatOptions = {
  style: 'currency',
  currency: 'RUB',
  maximumFractionDigits: 0,
}

function RatingAndPrice({ rating, price }: any) {
  useLingui()

  if (!rating && price === undefined)
    return null

  return (
    <div className="max-w-[200px] flex justify-between gap-3">
      <div className="grid gap-2">
        <span className="text-md text-gray-text-3">{t`Цена`}</span>
        <span className="text-lg text-black font-bold">
          {price === null ? t`Цена по запросу` : numberFormat(price, priceFormatOptions)}
        </span>
      </div>

      {rating !== null && (
        <div className="grid gap-2">
          <span className="text-md text-gray-text-3">{t`Рейтинг`}</span>
          <div className="flex items-center gap-1">
            <Icon name="star" size={16} />
            <span className="text-md text-black">{rating}</span>
          </div>
        </div>
      )}
    </div>
  )
}

function Description({ description }: any) {
  useLingui()

  return (
    <ProductCardSectionItem title={t`Описание`} collapsed open>
      <div className="whitespace-pre-line">
        {description.replace(/\\t/g, '\t').replace(/\\n/g, '\n') || '-'}
      </div>
    </ProductCardSectionItem>
  )
}

function Files({ files = [] }: any) {
  useLingui()

  return (
    <ProductCardSectionItem title={t`Документация`} collapsed>
      <div className="grid justify-start gap-2">
        {files.map(({ name, link, id }: any) => (
          <DownloadDocLink href={link} key={id}>
            {name}
          </DownloadDocLink>
        ))}
      </div>
    </ProductCardSectionItem>
  )
}

function Params({ fields = [] }: any) {
  return (
    <ProductCardSectionItem title={t`Характеристики`} collapsed open>
      <table>
        <tbody>
          {fields.map(({ name, value }: any) => (
            <tr className="text-left align-top" key={value}>
              <th className="w-1/2 py-2 text-md text-gray-text-2 font-normal">{name}</th>
              <td className="w-1/2 py-2 pl-3 text-md text-black">{value}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </ProductCardSectionItem>
  )
}

function SpecInfo({ series, vendorCode, site }: NullableAll<{ site: string, series: string, vendorCode: string }>) {
  if (!series && !vendorCode && !site)
    return null

  return (
    <div flex="~ items-center wrap" gap="2">
      {Boolean(series) && (
        <div>
          <span text="md gray-text-2">{t`Серия`}:</span>
          <span text="black md" pl="2">{series}</span>
        </div>
      )}

      {Boolean(vendorCode) && (
        <div>
          <span text="md gray-text-2">{t`Артикул`}:</span>
          <span text="black md" pl="2">{vendorCode}</span>
        </div>
      )}

      {site !== null && (
        <Link href={site} target="_blank" rel="noreferrer nofollow">{t`Сайт производителя`}</Link>
      )}
    </div>
  )
}
