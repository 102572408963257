import { CloudUploadOutlined } from '@ant-design/icons'
import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { Button } from 'antd'
import { useState } from 'react'

import TableUploadModal from './table-upload-modal'

export default function TableUpload() {
  useLingui()

  const [isModalOpen, setIsModalOpen] = useState(false)

  function showModal() {
    setIsModalOpen(true)
  }

  function handleCancel() {
    setIsModalOpen(false)
  }

  return (
    <div>
      <Button onClick={showModal} type="text" icon={<CloudUploadOutlined className="text-blue" />}>
        {t`Загрузить таблицу`}
      </Button>

      <TableUploadModal open={isModalOpen} onCancel={handleCancel} />
    </div>
  )
}
