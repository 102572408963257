import { useQueryClient } from '@tanstack/react-query'
import { useCallback } from 'react'
import { clearUserLocalStorage } from './local-storage'
import { LOCAL_STORAGE_KEYS } from './constants'
import { useUserQuery } from '~/data/user/user-query'

export const useIsUserLoading = () => useUserQuery().isLoading

export function useIsLoggedIn() {
  const user = useUserQuery()
  return typeof user.data !== 'undefined'
}

export function useSignOut() {
  const queryClient = useQueryClient()

  return useCallback(async () => {
    clearUserLocalStorage()
    queryClient.clear()
  }, [queryClient])
}

export function getAccessToken() {
  return localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN) ?? ''
}
