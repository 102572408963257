import { FileOutlined } from '@ant-design/icons'
import React from 'react'

interface Props extends React.ComponentProps<'a'> {
  children: React.ReactNode
}

export default function FileLink({ children, ...otherProps }: Props) {
  return (
    <a {...otherProps} className="group/link inline-flex items-center gap-1">
      <FileOutlined style={{ fontSize: 18, color: 'black' }} />

      <span className="text-md text-blue font-medium underline-offset-3 group-hover/link:underline">
        {children}
      </span>
    </a>
  )
}
