import NiceModal, { useModal } from '@ebay/nice-modal-react'

import Form from './form'

import { Modal } from '~/shared/components/modal'

interface Props {
  title: string
  onAdd: (fieldName: string) => void
}

const AddFormFieldModal = NiceModal.create<Props>(({ title, onAdd }) => {
  const modal = useModal()

  return (
    <Modal title={title} opened={modal.visible} onClose={() => modal.hide()}>
      <Form onClose={() => modal.hide()} onAdd={onAdd} />
    </Modal>
  )
})

export default AddFormFieldModal
