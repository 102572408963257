import { i18n } from '@lingui/core'
import { I18nProvider } from '@lingui/react'
import React from 'react'

import { LOCALE_COOKIE_NAME, getSavedLocale, loadCatalog } from '~/shared/lib/i18n'
import { LocaleProvider, useCookie } from '~/shared/lib/react'

export function withI18n(Component: React.ComponentType) {
  const displayName = Component.displayName || Component.name || 'Component'

  function ComponentsWithI18n() {
    const [locale, setLocale] = React.useState(getSavedLocale())

    const [, updateCookie] = useCookie(LOCALE_COOKIE_NAME)

    React.useEffect(() => {
      loadCatalog(getSavedLocale())
    }, [])

    async function changeLocale(newLocale: string) {
      await loadCatalog(newLocale)
      setLocale(newLocale)
      updateCookie(newLocale)
    }

    return (
      <I18nProvider i18n={i18n}>
        <LocaleProvider value={{ locale, changeLocale }}>
          <Component />
        </LocaleProvider>
      </I18nProvider>
    )
  }

  ComponentsWithI18n.displayName = `withI18n(${displayName})`
  return ComponentsWithI18n
}
