import { Trans, t } from '@lingui/macro'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import { Link } from 'react-router-dom'

import CompanyDropdownItem from './company-dropdown-item'

import { Icon } from '~/shared/components/icon'
import { useResponsive } from '~/shared/lib/react'
import { useGlobalDialog } from '~/shared/context/global-dialog'
import { LOCAL_STORAGE_KEYS } from '~/lib/constants'

interface Company {
  id: number
  name: string
  avatar?: string
}

interface Props {
  activeCompany: Nullable<Company>
  companies: Company[]
}

export default function CompanyDropdown({ activeCompany, companies }: Props) {
  const { openNewCompanyDialog } = useGlobalDialog()
  const isMobile = useResponsive('down', { key: 768 })

  async function changeActiveCompany(id: number) {
    localStorage.removeItem(LOCAL_STORAGE_KEYS.ACTIVE_COMPANY_TOKEN)
    localStorage.setItem(LOCAL_STORAGE_KEYS.ACTIVE_COMPANY_ID, id.toString())

    window.location.reload()
  }

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger className="focus:ring-0" asChild>
        <button type="button" className="flex items-center justify-center p-2">
          <Icon name={isMobile ? 'menu' : 'arrow-down'} size={24} className="text-black" />
        </button>
      </DropdownMenu.Trigger>

      <DropdownMenu.Portal>
        <>
          <div className="fixed inset-0 z-40 bg-black/20" />
          <DropdownMenu.Content
            className="popover data-[state=open]:data-[side=bottom]:animate-slideUpAndFade data-[state=open]:data-[side=left]:animate-slideRightAndFade data-[state=open]:data-[side=right]:animate-slideLeftAndFade data-[state=open]:data-[side=top]:animate-slideDownAndFade z-50 w-80 rounded-lg px-4 py-2"
            sideOffset={5}
          >
            {activeCompany && (
              <>
                <DropdownMenu.Item className="data-[highlighted]:outline-none" asChild>
                  <Link to="/dashboard">
                    <CompanyDropdownItem>{activeCompany.name}</CompanyDropdownItem>
                  </Link>
                </DropdownMenu.Item>

                <DropdownMenu.Item className="data-[highlighted]:outline-none" asChild>
                  <Link to="/company/edit">
                    <CompanyDropdownItem prefix={<Icon className="text-blue" name="edit" size={20} />}>
                      <Trans>Изменить информацию</Trans>
                    </CompanyDropdownItem>
                  </Link>
                </DropdownMenu.Item>
              </>
            )}

            {isMobile
              ? (
                <>
                  <DropdownMenu.Item className="data-[highlighted]:outline-none" asChild>
                    <Link to="/help">
                      <CompanyDropdownItem>
                        <Trans>Помощь</Trans>
                      </CompanyDropdownItem>
                    </Link>
                  </DropdownMenu.Item>
                  <DropdownMenu.Item className="data-[highlighted]:outline-none" asChild>
                    <Link to="/about">
                      <CompanyDropdownItem>
                        <Trans>О нас</Trans>
                      </CompanyDropdownItem>
                    </Link>
                  </DropdownMenu.Item>
                </>
                )
              : null}

            <hr className="my-1 bg-gray-border-1" />

            {companies
              .filter(c => c.id !== activeCompany?.id)
              .map(({ id, name }) => (
                <DropdownMenu.Item className="data-[highlighted]:ring-0" key={id} asChild>
                  <button type="button" className="w-full" onClick={() => changeActiveCompany(id)}>
                    <CompanyDropdownItem>{name}</CompanyDropdownItem>
                  </button>
                </DropdownMenu.Item>
              ))}

            <DropdownMenu.Item className="data-[highlighted]:ring-0" asChild>
              <button type="button" className="w-full" onClick={openNewCompanyDialog}>
                <CompanyDropdownItem prefix={<Icon className="text-blue" name="plus" size={20} />}>
                  {t`Добавить компанию`}
                </CompanyDropdownItem>
              </button>
            </DropdownMenu.Item>
          </DropdownMenu.Content>
        </>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  )
}
