import { useSuspenseQuery } from '@suspensive/react-query'

import { getInvitedCompanyInfo } from '~/api/user-api'
import { userKeys } from '~/shared/tanstack/keys/user'

export function useInvitedCompanyInfo(token: string) {
  return useSuspenseQuery({
    retry: false,
    enabled: Boolean(token),
    queryKey: userKeys.invitedCompanyData(token),
    queryFn: async () => {
      const { data } = await getInvitedCompanyInfo(token)
      return data
    },
  })
}
