import { t } from '@lingui/macro'
import type { DefaultOptionType } from 'antd/es/select'
import { CreatedOrderStatusEnum } from '~/domain/order/enums'
import { getCreatedOrderStatusNames } from '~/domain/order/utils/status-names'
import { capitalize } from '~/shared/utils/string-utils'

export function getStatusOptions(): DefaultOptionType[] {
  const statuses = Object.values(CreatedOrderStatusEnum).filter(v => !Number.isNaN(Number(v)))
  const firstOption = { label: t`Все`, value: 'all' }

  const options = statuses.map((status) => {
    const statusName = getCreatedOrderStatusNames()[status as CreatedOrderStatusEnum]
    return {
      label: capitalize(statusName),
      value: status.toString(),
    }
  })

  return [{ ...firstOption }, ...options]
}

export function getRequestTypeOptinos(): DefaultOptionType[] {
  return [
    {
      label: t`Все`,
      value: 'all',
    },
    {
      label: t`Персональные`,
      value: 'personal',
    },
    {
      label: t`Только на товар`,
      value: 'product',
    },
  ]
}

export function getAnswersOptinos(): DefaultOptionType[] {
  return [
    {
      label: t`Все`,
      value: 'all',
    },
    {
      label: t`С ответами`,
      value: 'with',
    },
    {
      label: t`Без ответов`,
      value: 'wuthout',
    },
  ]
}
