import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'

import Products from './components/products'
import PageHeader from '~/components/common/PageHeader'
import { RoutePathEnum } from '~/enums/route-path-enum'

export default function Page() {
  useLingui()

  return (
    <>
      <PageHeader title={t`Результаты поиска`} backRoutePath={RoutePathEnum.DASHBOARD} />
      <Products />
    </>
  )
}
