import type { UseMutationOptions } from '@tanstack/react-query'
import { useMutation } from '@tanstack/react-query'
import { deleteOrderImage } from '~/domain/order/api'

type Variables = Parameters<typeof deleteOrderImage>[0]

export function useDeleteOrderImage(options?: UseMutationOptions<unknown, Error, Variables>) {
  return useMutation({
    mutationFn: async (variables) => {
      const { data } = await deleteOrderImage(variables)
      return data
    },
    ...options,
  })
}
