import compose from 'compose-function'

import { withAntdConfig } from './with-antd-config'
import { withI18n } from './with-i18n'
import { withNiceModal } from './with-nice-modal'
import { withNotifications } from './with-notifications'
import { withQuery } from './with-query'
import { withRouter } from './with-router'
import { withYandexMetrika } from './with-yandex-metrika'
import { withGlobalDialog } from './with-global-dialog'

export const withProviders = compose(
  withRouter,
  withYandexMetrika,
  withQuery,
  withI18n,
  withAntdConfig,
  withNotifications,
  withGlobalDialog,
  withNiceModal,
)
