import type { UpdateCompanyMemberPayload } from './types'
import { buildApiUrl, paginateOffset } from '~/api/utils'

import type { COMPANY_ROLE } from '~/company/constants'
import { COMPANY_PERMISSION } from '~/company/constants'
import type { CategoryOption } from '~/entities/category'
import type { Company } from '~/lib/company/types'
import type { PRODUCT_CONDITION } from '~/lib/products/constants'
import axios from '~/lib/utils/axios-utils'
import type {
  ApiResponseWithPagination,
  CustomFieldEntry,
  ListParams,
  PublicDocument,
  PublicImage,
} from '~/shared/api'
import type { AVAILABLE_STATUS } from '~/shared/constants'
import type { CompanyProductsResponse, Okved } from '~/shared/types/company'

export interface CompanyDetails {
  balance: number
  bik: string
  checking_account: unknown
  company_type: number
  correspondent_account: unknown
  email: string
  factories: unknown[]
  id: number
  inn: string
  is_active: boolean
  kpp: string
  leaders: unknown[]
  legal_address: string
  name: string
  ogrn: Nullable<number>
  okpo: number
  okved: number[]
  phone: Nullable<string>
  physical_address: string
  postal_code: Nullable<string>
  rating: number
  relationships: unknown[]
  single_factory: boolean
  users: number[]
  warehouses: unknown[]
  website: string
  x_coordinate: Nullable<number>
  y_coordinate: Nullable<number>
}

export interface CompanyProductDetails {
  article_number: string | null
  product_series: string | null
  product_link: string | null
  name: string
  price: number | null
  description: string
  extra_fields: CustomFieldEntry[]
  id: number
  condition: PRODUCT_CONDITION
  images: PublicImage[]
  files: PublicDocument[]
  rating: number
  is_available: number
  factory_producer: any[]
  company_id: number
  categories: CategoryOption[]
}

interface Info {
  id: number
  okved?: Okved[]
  name: string
  is_active: boolean
  email: string
  phone: string
  balance: number
  inn: string
  kpp: Nullable<string>
  ogrn: Nullable<string>
  okpo: Nullable<string>
  checking_account: Nullable<string>
  correspondent_account: Nullable<string>
  bik: Nullable<string>
  leaders: any[]
  website: Nullable<string>
  single_factory: boolean
  company_type: number
  rating: number
  postal_code?: string
  x_coordinate?: string
  y_coordinate?: string
  physical_address: string
  legal_address: string
  users: number[]
  factories: any[]
  warehouses: any[]
  relationships: any[]
}

export type UpdateCompanyPayload = Pick<
  Info,
  | 'inn'
  | 'ogrn'
  | 'company_type'
  | 'bik'
  | 'okpo'
  | 'website'
  | 'legal_address'
  | 'checking_account'
  | 'correspondent_account'
  | 'name'
  | 'kpp'
> & {
  okved: number[]
}

interface CompanyStats {
  fabrics: number
  partners: number
  products: number
  staff: number
}

interface RequestsStats {
  completed: number
  completed_percent: number
  confirmed: number
  rejected: number
  total: number
  waiting: number
}

interface Statistic {
  company: CompanyStats
  requests: {
    incoming: RequestsStats
    outgoing: RequestsStats
  }
}

export interface CompanyProductDetails {
  name: string
  price: number | null
  description: string
  extra_fields: CustomFieldEntry[]
  id: number
  condition: PRODUCT_CONDITION
  images: PublicImage[]
  files: PublicDocument[]
  rating: number
  is_available: number
  factory_producer: any[]
  company_id: number
  categories: CategoryOption[]
}

interface EditProductPayload {
  name: string
  description?: string
  categories: string[]
  id?: number
  condition: number
  price: number | null
  extra_fields: CustomFieldEntry[]
}

interface UploadProductTablePayload {
  name: string
  description?: string
  category: Nullable<number>
  id?: number
  condition: number
  price: number
  extra_fields: CustomFieldEntry[]
}

export interface CompanyMembersResponse {
  position: Nullable<string>
  user: {
    id: number
    first_name: string
    last_name: string
    email: string
    telephone: Nullable<string>
    organization_account: boolean
  }
  is_leader: boolean
}

export interface CompanyMember {
  product_interaction_permission: boolean
  ordering_permission: boolean
  company_info_permission: boolean
  factory_permission: boolean
  add_users_permission: boolean
  proposal_permission: boolean
  position: string | null
  first_name: string
  last_name: string
  telephone: string
  email: string
}

type Permissions = Values<typeof COMPANY_PERMISSION>[]

interface UpdateProductStatusPayload {
  id: number
  availablesValue: AVAILABLE_STATUS
}

interface CompanyMemberPermissions {
  permissions: Permissions
  role: string
  is_member: boolean
}

interface CreateCompanyProductPayload {
  name: string
  price: Nullable<number>
  condition: number
  categories: string[]
  description?: string
  extra_fields: CustomFieldEntry[]
}

export enum PRODUCTS_PROCESSING_FILE_STATUS {
  inProgress = 1,
  confirmed = 2,
  rejected = 3,
}

interface ProductsProcessingFile {
  document: number | null
  status: PRODUCTS_PROCESSING_FILE_STATUS
}

type CompanyMembers = ApiResponseWithPagination<CompanyMembersResponse[]>
interface ProductsProcessingFiles {
  list: ProductsProcessingFile[]
}

export const ACTIVE_COMPANY_TOKEN_STORAGE_KEY = 'active_company_token'
export const ACTIVE_COMPANY_ID_STORAGE_KEY = 'active_company_id'

const COMPANY_DATA_PATH = '/api/organizations/company/get/'
const UPDATE_COMPANY_PATH = '/api/organizations/company/update/'
const PRODUCTS_PATH = '/api/products/company/products/list/'
const PRODUCT_PATH = 'api/products/product/mycompany/get/'
const ADD_USERS_PATH = '/api/organizations/company/users/add/'
const INVITE_USERS_PATH = '/api/organizations/invitation/link/send/email/'
const DELETE_FILE_PATH = '/api/products/product/:fileType/delete/'
const CREATE_PRODUCT_PATH = '/api/products/product/create/'
const EDIT_PRODUCT_PATH = '/api/products/product/update/'
const DELETE_PRODUCT_PATH = '/api/products/product/delete/'
const UPLOAD_PRODUCT_TABLE_PATH = '/api/products/product/create/file/'
const UPDATE_PRODUCT_STATUS_PATH = '/api/products/product/update/available/'
const PRODUCT_TABLE_INFO_PATH = '/api/products/factory/products/files/'
const ORDER_STATS_PATH = '/api/orders/request/statistics/'
const COMPANY_MEMBERS_PATH = '/api/organizations/company/staff/list/'
const COMPANY_MEMBER_DETAILS_PATH = '/api/organizations/company/staff/profile/'
const UPDATE_COMPANY_MEMBER_PATH = '/api/organizations/company/staff/profile/update/'
const COMPANY_MEMBER_PERMISSIONS_PATH = '/api/organizations/company/permissions/'

export function getCompanyData() {
  const url = buildApiUrl(COMPANY_DATA_PATH)
  return axios.get<Company>(url, { tokenType: 'company' })
}

export function getCompanyProduct(id: number) {
  const url = buildApiUrl(PRODUCT_PATH)
  return axios.get<CompanyProductDetails>(url, { params: { id }, tokenType: 'company' })
}

export function createCompanyProduct(payload: CreateCompanyProductPayload) {
  const url = buildApiUrl(CREATE_PRODUCT_PATH)
  return axios.post<{ id: number }>(url, payload, { tokenType: 'company' })
}

export function getCompanyProducts({ limit, page, q }: ListParams) {
  const url = buildApiUrl(PRODUCTS_PATH)
  const params = { limit, offset: paginateOffset(page, limit), q }
  return axios.get<CompanyProductsResponse>(url, { params, tokenType: 'company' })
}

export function updateCompany(payload: UpdateCompanyPayload) {
  const url = buildApiUrl(UPDATE_COMPANY_PATH)
  return axios.post<Company>(url, payload, { tokenType: 'company' })
}

export function getCompanyMembers({ limit, page, q }: ListParams) {
  const url = buildApiUrl(COMPANY_MEMBERS_PATH)
  const params = { limit, offset: paginateOffset(page, limit), q }
  return axios.get<CompanyMembers>(url, { params, tokenType: 'company' })
}

export function createUsersInCompany(payload: {
  amount: number
  permissions: Values<typeof COMPANY_PERMISSION>[]
}) {
  const url = buildApiUrl(ADD_USERS_PATH)
  return axios.post(url, payload, { tokenType: 'company' })
}

export function inviteUsersInCompany({
  permissions,
  role,
  email,
}: {
  role: Values<typeof COMPANY_ROLE>
  permissions: Values<typeof COMPANY_PERMISSION>[]
  email?: string
}) {
  const url = buildApiUrl(INVITE_USERS_PATH)
  const emailList = email?.split(',').map(item => item.trim()) ?? []
  const permissionFlags = Object.values(COMPANY_PERMISSION).reduce((acc, rule) => {
    return { ...acc, [rule]: permissions.includes(rule) }
  }, {})
  const data = { role, email_list: emailList, ...permissionFlags }

  return axios.post<{ link?: string }>(url, data, { tokenType: 'company' })
}

export function deleteCompanyProductFile({
  fileId,
  fileType,
  productId,
}: {
  productId: number
  fileId: number
  fileType: 'image' | 'file'
}) {
  const url = buildApiUrl(DELETE_FILE_PATH).replace(':fileType', encodeURIComponent(fileType))
  const fileKey = fileType === 'file' ? 'file_id' : 'image_id'
  const data = { product_id: productId, [fileKey]: fileId }

  return axios.post(url, data, { tokenType: 'company' })
}

export function editCompanyProduct(payload: EditProductPayload) {
  const url = buildApiUrl(EDIT_PRODUCT_PATH)
  return axios.post(url, payload, { tokenType: 'company' })
}

export function deleteCompanyProduct(id: number) {
  const url = buildApiUrl(DELETE_PRODUCT_PATH)
  return axios.get(url, { params: { id }, tokenType: 'company' })
}

export function uploadCompanyProductTable(payload: UploadProductTablePayload) {
  const url = buildApiUrl(UPLOAD_PRODUCT_TABLE_PATH)
  return axios.post(url, payload, { tokenType: 'company' })
}

export function updateCompanyProductStatus({ id, availablesValue }: UpdateProductStatusPayload) {
  const url = buildApiUrl(UPDATE_PRODUCT_STATUS_PATH)
  const data = { product_id: id, is_available: availablesValue }
  return axios.post(url, data, { tokenType: 'company' })
}

export function getCompanyProductProcessingFiles(factoryId?: number) {
  const url = buildApiUrl(PRODUCT_TABLE_INFO_PATH)
  return axios.get<ProductsProcessingFiles>(url, { params: { factory: factoryId }, tokenType: 'company' })
}

export function getCompanyOrderStats() {
  const url = buildApiUrl(ORDER_STATS_PATH)
  return axios.get<Statistic>(url, { tokenType: 'company' })
}

export function getCompanyMemberDetails(id?: number) {
  if (typeof id === 'undefined')
    Promise.reject(new Error('Invalid id'))

  const url = buildApiUrl(COMPANY_MEMBER_DETAILS_PATH)
  return axios.get<CompanyMember>(url, { params: { user: id }, tokenType: 'company' })
}

export function getCompanyMemberPermissions() {
  const url = buildApiUrl(COMPANY_MEMBER_PERMISSIONS_PATH)
  return axios.get<CompanyMemberPermissions>(url, { tokenType: 'company' })
}

export function updateCompanyMember(payload: UpdateCompanyMemberPayload) {
  const url = buildApiUrl(UPDATE_COMPANY_MEMBER_PATH)
  return axios.post<{ permissions: Permissions }>(url, payload, { tokenType: 'company' })
}
