import { t } from '@lingui/macro'
import type { AntdPhoneInputValue } from '#/antd-phone-input'

export function parseAntdPhoneInput(phone: AntdPhoneInputValue) {
  return `${phone.countryCode}${phone.areaCode}${phone.phoneNumber}`
}

export function checkPhone(_: any, value: AntdPhoneInputValue) {
  if (value.valid())
    return Promise.resolve()
  return Promise.reject(new Error(t`Неправильный номер телефона`))
}
