function makeInitials(name: string) {
  return name
    .split(' ')
    .map(word => word[0])
    .join('')
    .slice(0, 2) // Обрезать до 2-ух символов
}

export function hasName(name = ''): name is string {
  return name.trim().length > 0
}

export function getFallbackName(name?: string) {
  return hasName(name) ? makeInitials(name) : '😎'
}
