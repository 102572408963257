import { DEFAULT_SIZE } from '../constant'

function isNumber(value: unknown): value is number {
  return typeof value === 'number' && !Number.isNaN(value)
}

function parseSize(size: number | string): number | null {
  if (isNumber(size))
    return size

  const parsedSize = Number.parseInt(String(size), 10)
  return Number.isNaN(parsedSize) ? null : parsedSize
}

function parseWidthHeight(value?: number | string): number | null {
  if (value === undefined)
    return null
  return isNumber(value) ? value : parseSize(value)
}

export function calculateIconSize(
  size: number | string,
  width?: number | string,
  height?: number | string,
): number {
  const sizeOptions = {
    width: parseWidthHeight(width),
    height: parseWidthHeight(height),
    size: parseSize(size),
  }

  const iconSize = Object.values(sizeOptions).find(value => value !== null)

  return iconSize || DEFAULT_SIZE
}
