import type { TagProps } from 'antd'
import { CreatedOrderStatusEnum } from '~/domain/order/enums'
import { getCreatedOrderStatusNames } from '~/domain/order/utils/status-names'

export function getStatusName(status: CreatedOrderStatusEnum) {
  const names = getCreatedOrderStatusNames()
  return names[status]
}

export function getStatusColor(status: CreatedOrderStatusEnum) {
  const colors: Indexed<CreatedOrderStatusEnum, TagProps['color'] > = {
    [CreatedOrderStatusEnum.HISTORY]: 'error',
    [CreatedOrderStatusEnum.CONSTRUCTION]: 'processing',
    [CreatedOrderStatusEnum.ACTIVE]: 'success',
    [CreatedOrderStatusEnum.ARCHIVE]: 'warning',
    [CreatedOrderStatusEnum.REJECT]: 'error',
  }
  return colors[status]
}
