import { t } from '@lingui/macro'
import { Link } from 'react-router-dom'

import { RoutePathEnum } from '~/enums/route-path-enum'
import { Button } from '~/shared/components/button'

export default function LandingGetStartedButton() {
  return (
    <Button size="lg" asChild>
      <Link to={RoutePathEnum.SIGN_IN}>
        {t`Начать работу`}
      </Link>
    </Button>
  )
}
