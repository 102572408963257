import { t } from '@lingui/macro'
import { Button } from 'antd'

import CreateOrderProductModalTrigger from '~/components/products/CreateOrderProductTrigger'
import type { ProductDetails } from '~/domain/product/api'
import { useActiveCompanyDetail } from '~/hooks/use-active-company'
import { getImgUrl } from '~/products/utils'
import { useGetUser } from '~/shared/tanstack/queries/use-get-user'

interface Props {
  product: ProductDetails
}

export default function CreateOrderButton({ product }: Props) {
  const { data: user } = useGetUser()
  const { data: company } = useActiveCompanyDetail()

  const hasCompany = user && user?.companies.length > 0
  const isNotOwnProduct = hasCompany && product.company_id !== company?.id

  if (!isNotOwnProduct)
    return null

  return (
    <div className="flex flex-wrap items-center gap-5">
      <CreateOrderProductModalTrigger
        product={{
          productId: product.id,
          productName: product.name,
          productImageUrl: getImgUrl(product.images),
        }}
        trigger={(
          <Button type="primary" size="large">
            {t`Заказать`}
          </Button>
        )}
      />
    </div>
  )
}
