import { Trans, t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { useCountDown, useEventListener, useUnmount } from 'ahooks'
import { Button } from 'antd'
import { useState } from 'react'

import { getSavedTargetDate } from './utils'

interface Props {
  onResend: () => void
  storageKey: string
}

export default function ResendCodeButton({ onResend, storageKey }: Props) {
  useLingui()

  const [targetDate, setTargetDate] = useState(getSavedTargetDate(storageKey) ?? Date.now() + 60 * 1000)

  const [countdown] = useCountDown({ targetDate, onEnd: () => localStorage.removeItem(storageKey) })

  useUnmount(() => {
    localStorage.setItem(storageKey, JSON.stringify(countdown))
  })

  useEventListener('beforeunload', () => {
    localStorage.setItem(storageKey, JSON.stringify(countdown))
  })

  function handleResend() {
    setTargetDate(Date.now() + 60 * 1000)
    onResend()
  }

  const isResendable = countdown === 0

  return (
    <div className="flex items-center">
      {isResendable && (
        <Button type="link" size="small" onClick={handleResend}>
          {t`Отправить повторно`}
        </Button>
      )}

      {!isResendable && (
        <div className="text-md">
          <Trans>
            Отправить повторно через{' '}
            <span className="text-black font-semibold">{Math.round(countdown / 1000)}</span>{' '}
            сек
          </Trans>
        </div>
      )}
    </div>
  )
}
