import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { useResponsive } from 'ahooks'
import { Button, Tooltip } from 'antd'

import BottomNavBarItem from './bottom-nav-bar-item'

import { Icon } from '~/shared/components/icon'
import { useGetUser } from '~/shared/tanstack/queries/use-get-user'
import WelcomeTourTarget from '~/welcome-tour/target'
import { useGlobalDialog } from '~/shared/context/global-dialog'

export default function MobileBottomNavBar() {
  useLingui()
  const response = useResponsive()
  const { openCreateOrderDialog } = useGlobalDialog()

  const { data: user } = useGetUser()

  const hasCompany = user?.companies.length !== 0

  return (
    <div className="fixed bottom-0 left-0 right-0 flex items-center justify-center border-t border-gray-border-2 bg-gray-border-1 px-4 shadow-sm md:hidden">
      <div className="grid grid-cols-[repeat(5,auto)] items-center justify-center gap-2 sm:gap-5">
        <WelcomeTourTarget name="companyManagement" visible={!response.md}>
          <BottomNavBarItem name="Компания" icon="home" to="/dashboard" />
        </WelcomeTourTarget>
        <WelcomeTourTarget name="catalog" visible={!response.md}>
          <BottomNavBarItem name="Каталог" icon="search" to="/catalog" />
        </WelcomeTourTarget>

        <WelcomeTourTarget name="createRequest" visible={!response.md}>
          <Tooltip title={!hasCompany ? t`Создайте компанию чтобы отправлять запросы` : undefined}>
            <Button
              size="large"
              type="primary"
              onClick={openCreateOrderDialog}
              icon={<Icon name="plus" className="text-white" />}
              disabled={!hasCompany}
            />
          </Tooltip>
        </WelcomeTourTarget>

        <WelcomeTourTarget
          name="requests"
          visible={!response.md}
          rootClassName="grid grid-cols-2 gap-2 sm:gap-5"
        >
          <BottomNavBarItem name="Входящие" icon="in" to="/o/received" />
          <BottomNavBarItem name="Исходящие" icon="out" to="/o/created" />
        </WelcomeTourTarget>
      </div>
    </div>
  )
}
