import { t } from '@lingui/macro'

import type { ProductRequest } from '~/entities/product-request/types'

const UNCOMPLETED_FIELDS = ['preferable_price', 'address', 'amount', 'preferable_time'] as const

function getUncompletedFieldsErrors(field: UncomplitedKey) {
  return {
    preferable_price: t`Предпочтительная цена`,
    address: t`Желаемая дата доставки`,
    amount: t`Адрес`,
    preferable_time: t`Количество`,
  }[field]
}

type OrderKey = Keys<ProductRequest['request']>
type UncomplitedKey = typeof UNCOMPLETED_FIELDS[number]

export function getIncompletedFields(order: ProductRequest['request']) {
  return Object.keys(order)
    .filter(key => UNCOMPLETED_FIELDS.includes(key) && order[key as OrderKey] === null)
    .map(key => getUncompletedFieldsErrors(key as UncomplitedKey))
}
