import * as RadixAvatar from '@radix-ui/react-avatar'

import { cn } from '../../lib/react'

import { INITIALS_FONT_SIZE_SCALE } from './constant'
import type { AvatarSize } from './interface'
import { getFallbackName } from './utils/name-util'
import { getAvatarSize } from './utils/size-util'

export interface AvatarProps {
  src?: string
  alt?: string
  name: string
  /** @deprecated Вместо этого используйте `v2` и `src` */
  image?: string
  /** @deprecated Вместо этого используйте `v2` и `alt` */
  imageAlt?: string
  size?: AvatarSize
  /** @deprecated */
  fallbackClassName?: string
  /** @deprecated */
  fallback?: boolean
  /** @deprecated */
  className?: string
  v2?: boolean
}

export default function Avatar({
  name,
  image,
  alt = '',
  src,
  imageAlt = '',
  size = 'small',
  fallbackClassName,
  fallback = true,
  className,
  v2,
}: AvatarProps) {
  const avatarSize = getAvatarSize(size)
  const fallbackName = getFallbackName(name)

  const hasImage = Boolean(image)

  if (v2) {
    return (
      <RadixAvatar.Root
        style={{ width: avatarSize, height: avatarSize }}
        className="inline-flex select-none items-center justify-center overflow-hidden rounded-full align-middle"
      >
        <RadixAvatar.Image
          className="h-full w-full rounded-[inherit] object-cover"
          src={image ?? src}
          alt={imageAlt ?? alt}
          width={avatarSize}
          height={avatarSize}
        />

        <RadixAvatar.Fallback
          className="h-full w-full flex items-center justify-center bg-green text-white font-medium uppercase"
          delayMs={600}
          style={{
            width: avatarSize,
            height: avatarSize,
            fontSize: avatarSize / INITIALS_FONT_SIZE_SCALE,
          }}
        >
          {fallbackName}
        </RadixAvatar.Fallback>
      </RadixAvatar.Root>
    )
  }

  if (hasImage) {
    return (
      <img
        className={cn('shrink-0 rounded-full', className)}
        src={image}
        width={avatarSize}
        height={avatarSize}
        alt={imageAlt}
        loading="lazy"
      />
    )
  }

  return (
    <span
      className={cn(
        'flex shrink-0 select-none items-center justify-center overflow-hidden rounded-full bg-green uppercase text-white',
        {
          'h-6 w-6 text-sm': size === 'small',
          'h-[100px] w-[100px] font-bold': size === 'large',
          'invisible': !fallback, // Скрывать если не нужен дефолтный аватар. Например для товаров
        },
        fallbackClassName,
      )}
      style={{
        width: avatarSize,
        height: avatarSize,
        fontSize: avatarSize / INITIALS_FONT_SIZE_SCALE,
      }}
    >
      {fallbackName}
    </span>
  )
}
