import { useQuery } from '@tanstack/react-query'

import { DEFAULT_PAGE } from '../constants'
import { companyKeys } from '~/shared/tanstack/keys/company'
import { getReceivedOrders } from '~/domain/order/api'

type Params = Partial<Parameters<typeof getReceivedOrders>[0]>

export function useReceivedOrders({ page = DEFAULT_PAGE, limit = 10, q = '', status = 'all', type = 'all' }: Params) {
  return useQuery({
    keepPreviousData: true,
    queryKey: companyKeys.receivedOrders({ limit, page, q, type, status }),
    queryFn: async () => {
      const { data } = await getReceivedOrders({ limit, page, q, status, type })
      return data
    },
  })
}
