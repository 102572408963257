import { QueryClient } from '@tanstack/react-query'
import { useState } from 'react'

export function getQueryClient() {
  return new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 1000 * 60 * 1,
        retry: (failureCount, error) => {
          // Не повторять при 404-ых кодах
          if (
            typeof error === 'object'
            && error !== null
            && 'code' in error
            && (error as any).code === 404
          )
            return false

          if (failureCount < 3)
            return true

          return false
        },
      },
    },
  })
}

export function useRootQueryClient() {
  const [_queryClient] = useState(() => getQueryClient())

  return _queryClient
}
