import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { isAxiosError } from 'axios'
import { useNavigate } from 'react-router-dom'
import { deleteOrder } from '~/domain/order/api'

import { showMessage } from '~/shared/show-message'
import { companyKeys } from '~/shared/tanstack/keys/company'

export default function useDeleteOrder() {
  useLingui()

  const queryClient = useQueryClient()
  const navigate = useNavigate()

  return useMutation({
    mutationFn: deleteOrder,
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: companyKeys.createdOrders({}) })
      navigate('/o/created')
      showMessage(t`Запрос успешно удалён`, 'success')
    },
    onError: (error) => {
      if (isAxiosError(error)) {
        showMessage(
          error.response?.data?.detail ?? t`Произошла ошибка при удалении запроса. Попробуйте позже`,
          'error',
        )
      }
    },
  })
}
