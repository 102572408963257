import { Trans, t } from '@lingui/macro'
import { useLingui } from '@lingui/react'

import CategoriesLinks from './categories-links'
import ProductsCount from './products-count'

import { KeyWordSearchNavigator } from '~/features/key-word-search-navigator'
import { Button } from '~/shared/components/button'

export default function Section() {
  useLingui()

  return (
    <section className="grid my-4 gap-5 rounded-3xl bg-blue p-10">
      <div className="grid gap-2.5 text-white">
        <h2 className="text-3xl font-bold">
          <Trans>Найти товары на нашей площадке:</Trans>
        </h2>

        <ProductsCount />
      </div>

      <KeyWordSearchNavigator
        searchPath="/p"
        searchFieldLabel={t`Я хочу найти...`}
        renderSubmitButton={onClick => (
          <Button
            className="w-full leading-none"
            size="lg"
            color="black"
            onClick={onClick}
          >
            <Trans>Найти</Trans>
          </Button>
        )}
      />

      <CategoriesLinks />
    </section>
  )
}
