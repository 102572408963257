import useUrlState from '@ahooksjs/use-url-state'
import type { ComponentType } from 'react'
import { useEffect, useState } from 'react'
import { GlobalDialogProvider } from '~/shared/context/global-dialog'
import { getDisplayName } from '~/shared/utils/react'

interface UrlStateParams {
  show?: string
  new_user?: any
  afterCreate?: string
}

const CREATE_COMPANY_ID = 'createCompany'

export function withGlobalDialog(Component: ComponentType) {
  function ComponentsWithGlobalDialog() {
    const [urlState, setUrlState] = useUrlState<UrlStateParams>()

    // Открывать окно создания компании только после онбординга нового юзера
    // FIXME: перенсти на бэк
    const initialOpenNewCompanyDialog = urlState.show === CREATE_COMPANY_ID && typeof urlState.new_user === 'undefined'

    const [editOrderDialogOrderId, setEditOrderDialogOrderId] = useState<Nullable<number>>(null)
    const [isEditOrderDialogOpen, setIsEditOrderDialogOpen] = useState(false)

    const [isCreateOrderDialogOpen, setIsCreateOrderDialogOpen] = useState(false)

    const [isNewCompanyDialogOpen, setIsNewCompanyDialogOpen] = useState(initialOpenNewCompanyDialog)

    const [isUserSignUpWithSupplierSearchModalOpen, setIsUserSignUpWithSupplierSearchModalOpen] = useState(false)

    const openCreateOrderDialog = () => setIsCreateOrderDialogOpen(true)
    const closeCreateOrderDialog = () => setIsCreateOrderDialogOpen(false)

    function openEditOrderDialog(orderId: number) {
      setEditOrderDialogOrderId(orderId)
      setIsEditOrderDialogOpen(true)
    }

    function closeEditOrderDialog() {
      setEditOrderDialogOrderId(null)
      setIsEditOrderDialogOpen(false)
    }

    function openNewCompanyDialog() {
      setIsNewCompanyDialogOpen(true)
    }

    function closeNewCompanyDialog() {
      setUrlState({ show: undefined })
      setIsNewCompanyDialogOpen(false)
    }

    const openUserSignUpWithSupplierSearchModal = () => setIsUserSignUpWithSupplierSearchModalOpen(true)
    const closeUserSignUpWithSupplierSearchModal = () => setIsUserSignUpWithSupplierSearchModalOpen(false)

    useEffect(() => {
      if (initialOpenNewCompanyDialog)
        setIsNewCompanyDialogOpen(true)
    }, [initialOpenNewCompanyDialog])

    return (
      <GlobalDialogProvider
        value={{
          isCreateOrderDialogOpen,
          openCreateOrderDialog,
          closeCreateOrderDialog,
          //
          editOrderDialogOrderId,
          isEditOrderDialogOpen,
          openEditOrderDialog,
          closeEditOrderDialog,
          //
          isNewCompanyDialogOpen,
          openNewCompanyDialog,
          closeNewCompanyDialog,
          //
          isUserSignUpWithSupplierSearchModalOpen,
          openUserSignUpWithSupplierSearchModal,
          closeUserSignUpWithSupplierSearchModal,
        }}
      >
        <Component />
      </GlobalDialogProvider>
    )
  }

  ComponentsWithGlobalDialog.displayName = `withGlobalDialog(${getDisplayName(Component)})`
  return ComponentsWithGlobalDialog
}
