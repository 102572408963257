import { z } from 'zod'

const MIN_PASSWORD_LENGTH = 8

export const PASSWORD_SCHEMA = z
  .string()
  .min(MIN_PASSWORD_LENGTH, `Минимальная длинна пароля ${MIN_PASSWORD_LENGTH} символов`)

export const EMAIL_SCHEMA = z
  .string()
  .nonempty(`Обязательное поле`)
  .email(`Неверный формат электронной почты`)
