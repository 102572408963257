import React from 'react'

export default function EmptyIcon(props: React.ComponentProps<'svg'>) {
  return (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" {...props}>
      <path
        d="M50.047 23.89c-6.39-6.375-6.25-6.36-6.688-6.547L32.422 12.36a1.564 1.564 0 10-1.281 2.844l7.812 3.547L25.5 24.843 12.094 18.75l7.812-3.547a1.563 1.563 0 00-1.28-2.844c-7.61 3.453-11.142 5.078-10.938 4.984-.375.188-.204.078-6.688 6.547a1.562 1.562 0 00.469 2.531l17.187 7.813a1.563 1.563 0 001.75-.313l3.532-3.593v15.625L9.875 39.624v-5.25a1.562 1.562 0 10-3.125 0c0 6.907 0 6.563.281 7.094a.938.938 0 00.47.453c.155.11 0 .078.202.141C26.422 50.562 25.016 50 25.5 50c.485 0-.781.5 17.828-7.954a1.562 1.562 0 00.922-1.422v-11.78l5.328-2.423a1.562 1.562 0 00.469-2.53zm-31.14 7.047L4.843 24.515l3.812-3.89 14.141 6.421-3.89 3.891zm22.218 8.687l-14.062 6.391V30.39c3.843 3.781 3.828 3.984 4.687 3.984.516 0 .11.11 9.375-4.109v9.36zm-9.031-8.687l-3.89-3.89 14.14-6.422 3.89 3.89-14.14 6.422z"
        fill="#ADB3BE"
      />
      <path
        d="M23.938 13.921a5.64 5.64 0 013.125-4.937 3.125 3.125 0 001.563-3.313 3.125 3.125 0 10-6.032 1.563 1.562 1.562 0 01-.937 1.984 1.563 1.563 0 01-2-.953 6.25 6.25 0 118.906 3.39 2.61 2.61 0 00-1.5 2.267 1.562 1.562 0 01-3.125 0zm1.563 6.391a1.562 1.562 0 110-3.124 1.562 1.562 0 010 3.124z"
        fill="#6266EB"
      />
    </svg>
  )
}
