import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { Button } from 'antd'
import { useNavigate } from 'react-router-dom'

import Result from '~/core/ui/Result'
import { RoutePathEnum } from '~/enums/route-path-enum'
import { Icon } from '~/shared/components/icon'

export default function Page() {
  useLingui()
  const navigate = useNavigate()

  return (
    <Result
      icon={<Icon name="not-found" width={126} height={106} />}
      title={t`Страница не найдена`}
      subtitle={t`Страница, которую вы ищете, удалена или временно недоступна.`}
      extra={
        <Button type="primary" onClick={() => navigate(RoutePathEnum.ROOT)}>{t`На главную`}</Button>
      }
    />
  )
}
