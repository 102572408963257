import type { UseMutationOptions } from '@tanstack/react-query'
import { useMutation } from '@tanstack/react-query'

import { App } from 'antd'
import { post } from '../fetchers'
import { LOCAL_STORAGE_KEYS } from '~/lib/constants'

export interface PersonalOrderActivateVariables {
  orderId: number
}

export async function activatePersonalOrder({ orderId }: PersonalOrderActivateVariables) {
  const token = localStorage.getItem(LOCAL_STORAGE_KEYS.ACTIVE_COMPANY_TOKEN)
  if (!token)
    throw new Error('token is required')

  const { data, error } = await post('/orders/request/personal/activate/', {
    body: { id: orderId },
    // FIXME(company tokens)
    headers: {
      Authorization: `Token ${token}`,
    },
  })

  if (error)
    throw error
  return data
}

type PersonalOrderActivateData = Awaited<ReturnType<typeof activatePersonalOrder>>
type PersonalOrderActivateError = unknown

export function usePersonalOrderActivateMutation({
  onSuccess,
  onError,
  ...options
}: Omit<UseMutationOptions<PersonalOrderActivateData, PersonalOrderActivateError, PersonalOrderActivateVariables>, 'mutationFn' > = {}) {
  const { message } = App.useApp()

  return useMutation<PersonalOrderActivateData, PersonalOrderActivateError, PersonalOrderActivateVariables>({
    mutationFn: vars => activatePersonalOrder(vars),
    async onSuccess(data, variables, context) {
      await onSuccess?.(data, variables, context)
    },
    async onError(data, variables, context) {
      if (onError === undefined)
        message.error(`Failed to mutate: activatePersonalOrder`)
      else
        onError(data, variables, context)
    },
    ...options,
  })
}
