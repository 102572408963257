import { zodResolver } from '@hookform/resolvers/zod'
import { Trans, t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { useQueryClient } from '@tanstack/react-query'
import { useForm } from 'react-hook-form'

import NumberField from '../NumberField'
import ProductModalHeader from '../ProductModalHeader'

import { Modal, ModalTrigger } from '~/core/ui/Modal/Modal'
import Result from '~/core/ui/Result'
import { convertInputValueToDate } from '~/core/utils/common'
import { createOrderProductSchema } from '~/lib/requests/schemas'
import { Button } from '~/shared/components/button'
import { TextArea } from '~/shared/components/text-area'
import { TextField } from '~/shared/components/text-field'
import { TotalPriceSummary } from '~/shared/components/total-price-summary'
import { showMessage } from '~/shared/show-message'
import { companyKeys } from '~/shared/tanstack/keys/company'
import { useCreateNewOrder } from '~/shared/tanstack/mutations/use-create-new-order'
import { useGetUser } from '~/shared/tanstack/queries/use-get-user'

export function CreateOrderProductModal({ opened, onClose, product }: any) {
  useLingui()

  const { productId, productName, productImageUrl } = product

  const user = useGetUser()

  // @ts-expect-error ...
  const hasCompany = user.data?.companies.length > 0

  return (
    <Modal
      onClose={onClose}
      opened={opened}
      renderHeader={hasCompany && <Header productImageUrl={productImageUrl} productName={productName} />}
    >
      {hasCompany
        ? (
          <Form productId={productId} onClose={onClose} />
          )
        : (
          <Result
            title={t`У вас ещё нет компании`}
            subtitle={t`Зарегистрируйте компанию, чтобы заказать товар`}
          />
          )}
    </Modal>
  )
}

export default function CreateOrderProductModalTrigger({ product, trigger }: any) {
  useLingui()

  const { productId, productName, productImageUrl } = product

  return (
    <ModalTrigger
      renderHeader={(
        <ProductModalHeader
          title={t`Заказ товара`}
          productImageUrl={productImageUrl}
          productName={productName}
        />
      )}
      trigger={trigger}
    >
      {/* @ts-expect-error ... */}
      {({ close }) => <Form productId={productId} onClose={close} />}
    </ModalTrigger>
  )
}

function Form({ productId, onClose }: any) {
  useLingui()

  const queryClient = useQueryClient()

  const { mutate: createOrder, isLoading } = useCreateNewOrder()
  const { register, formState, handleSubmit, control, watch } = useForm({
    resolver: zodResolver(createOrderProductSchema),
    defaultValues: {
      preferable_price: '',
      preferable_time: '',
      amount: '',
      description: '',
    },
  })

  const { errors } = formState

  const [amount, pricePerUnit] = watch(['amount', 'preferable_price'])
  // @ts-expect-error ...
  const totalPrice = amount * pricePerUnit
  // @ts-expect-error ...
  function onSubmit(values) {
    const payload = {
      ...values,
      preferable_time: convertInputValueToDate(values.preferable_time),
      product: Number(productId),
      preferable_price: totalPrice,
    }

    createOrder(payload, {
      onSuccess: async () => {
        queryClient.invalidateQueries({ queryKey: companyKeys.createdOrders({}) })
        showMessage(t`Запрос успешно отправлен`, 'success')
        onClose()
      },
      onError: () => {
        showMessage(t`Ошибка при создании запроса. Попробуйте позже`, 'error')
      },
    })
  }

  return (
    <form className="flex flex-col space-y-4" onSubmit={handleSubmit(onSubmit)}>
      <NumberField
        name="preferable_price"
        // @ts-expect-error ...
        control={control}
        label={t`Желаемая цена за 1шт`}
        errorMessage={errors?.preferable_price?.message}
        required
      />

      <TextField
        {...register('preferable_time')}
        label={t`Необходимые сроки`}
        errorMessage={errors?.preferable_time?.message}
        type="date"
        required
      />

      <NumberField
        name="amount"
        // @ts-expect-error ...
        control={control}
        label={t`Желаемое количество`}
        errorMessage={errors?.amount?.message}
        required
      />

      <TextArea
        {...register('description')}
        label={t`Текст сообщения`}
        errorMessage={errors?.description?.message}
      />

      <TotalPriceSummary price={totalPrice} />

      <Button disabled={isLoading} size="lg" type="submit" className="w-full">
        <Trans>Заказать</Trans>
      </Button>
    </form>
  )
}

function Header({ productName, productImageUrl = '' }: any) {
  return (
    <div className="flex items-center space-x-5">
      <img
        className="h-[60px] w-[60px] flex-shrink-0 rounded-full object-contain"
        src={productImageUrl}
        width={60}
        height={60}
        alt="Product preview"
        loading="lazy"
      />

      <div className="grid gap-1">
        <h3 className="text-xl font-bold">
          <Trans>Заказ товара</Trans>
        </h3>
        <span className="truncate">{productName}</span>
      </div>
    </div>
  )
}
