import { Trans } from '@lingui/macro'

export default function EmailCheckMessage() {
  return (
    <div className="space-y-5">
      <p className="text-center font-medium">
        <Trans>
          Спасибо! Письмо с&nbsp;инструкциями по&nbsp;сбросу пароля успешно отправлено на&nbsp;указанный вами
          адрес электронной почты. Пожалуйста, проверьте свою почту и&nbsp;следуйте указанным в&nbsp;письме
          шагам, чтобы восстановить доступ к&nbsp;вашей учетной записи.
        </Trans>
      </p>

      <p className="text-md text-gray-text-3">
        <Trans>
          Если вы&nbsp;не&nbsp;видите письмо в&nbsp;папке &laquo;Входящие&raquo;, проверьте папку
          &laquo;Спам&raquo; или &laquo;Нежелательная почта&raquo;, так как иногда письма могут попадать туда
          по&nbsp;ошибке.
        </Trans>
      </p>
    </div>
  )
}
