import { t } from '@lingui/macro'
import FaqList from '~/shared/components/faq-list'

export default function LandingFAQ() {
  return (
    <section className="py-5 lg:py-12" id="faq">
      <h2 className="mb-5 text-xl font-bold lg:mb-12 lg:text-center lg:text-3xl">
        {t`Вопрос и ответ`}
      </h2>

      <FaqList />
    </section>
  )
}
