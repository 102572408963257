import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { App, Modal } from 'antd'

import Form from './components/form'
import Header from './components/header'

interface Props {
  open: boolean
  onClose: () => void
  onOk: () => void
  orderId: number
  productImageUrl?: string
  productName?: string
}

export default function OrderReceivedResponseDialog({
  open,
  onClose,
  onOk,
  orderId,
  productImageUrl,
  productName,
}: Props) {
  useLingui()
  const { modal } = App.useApp()

  function handleClose() {
    onClose()

    modal.success({
      onOk,
      title: t`Запрос подтверждён`,
      centered: true,
      content: t` Дождитесь сообщений от заказчика. Убедитесь, что ваш номер телефона указан в профиле, чтобы клиент мог связаться с вами.`,
    })
  }

  return (
    <Modal
      onCancel={onClose}
      open={open}
      title={
        <Header title={t`Ответить на запрос`} productImageUrl={productImageUrl} productName={productName} />
      }
      footer={null}
      width={450}
      centered
    >
      <Form orderId={orderId} onClose={handleClose} />
    </Modal>
  )
}
