import type { UseMutationOptions } from '@tanstack/react-query'
import { useMutation } from '@tanstack/react-query'

import { App } from 'antd'
import { post } from '../fetchers'

export interface SendEmailCodeVariables {
  email: string
}

export async function sendEmailCode({ email }: SendEmailCodeVariables) {
  const { data, error } = await post('/users/user/code/send/', {
    body: { email },
  })

  if (error)
    throw error
  return data
}

type SendEmailCodeData = Awaited<ReturnType<typeof sendEmailCode>>
type SendEmailCodeError = unknown

export function useSendEmailCodeMutation({
  onSuccess,
  onError,
  ...options
}: Omit<UseMutationOptions<SendEmailCodeData, SendEmailCodeError, SendEmailCodeVariables>, 'mutationFn' > = {}) {
  const { message } = App.useApp()

  return useMutation<SendEmailCodeData, SendEmailCodeError, SendEmailCodeVariables>({
    mutationFn: vars => sendEmailCode(vars),
    async onSuccess(data, variables, context) {
      await onSuccess?.(data, variables, context)
    },
    async onError(data, variables, context) {
      if (onError === undefined)
        message.error(`Failed to mutate: sendEmailCode`)
      else
        onError(data, variables, context)
    },
    ...options,
  })
}
