import type { CatalogCategory } from '~/domain/product/api'

export function findParentsCategories(tree: Indexed<string, CatalogCategory>, code: string) {
  const parents = []
  const codePath = code.split('.')
  let currentElement = tree

  for (let i = 0; i < codePath.length; i += 1) {
    // Рутовые элементы категорий имеют ключи без "."
    const key = i > 0 ? `.${codePath[i]}` : codePath[i]

    if (currentElement[key]) {
      const { code: url, names } = currentElement[key]

      parents.push({ url, names })
      currentElement = currentElement[key]
    }
  }

  return parents
}
