import { t } from '@lingui/macro'
import { message } from 'antd'

export function showUnknownError() {
  message.error(t`Неизвестная ошибка`)
}

export function showCompanyCreateError() {
  message.error(t`Произошла ошибка при создании компании. Попробуйте ещё раз`)
}
