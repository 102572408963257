import { Dialog, Transition } from '@headlessui/react'
import { Fragment, cloneElement, useState } from 'react'

import { Icon } from '~/shared/components/icon'

function ModalWrapper({
  title = '',
  opened,
  onClose,
  children = null,
  renderHeader = null,
  closable = true,
}: any) {
  let closeNode
  if (closable) {
    closeNode = (
      <button onClick={onClose} type="button">
        <Icon name="close" className="hover:text-blue" />
      </button>
    )
  }

  return (
    <Transition show={opened} as={Fragment} appear>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-75"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="min-h-full flex items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-75"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="max-w-md w-full transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title as="header" className="flex items-center justify-between gap-5">
                  {title
                    ? (
                      <h3 className="text-xl text-black font-bold">{title}</h3>
                      )
                    : (
                      <div>{renderHeader}</div>
                      )}

                  {closeNode}
                </Dialog.Title>

                <div className="pt-5">{children}</div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

export function Modal({ children = null, ...props }: any) {
  return (
    <ModalWrapper {...props}>
      {children}
    </ModalWrapper>
  )
}

export function ModalTrigger({ title, trigger, children, renderHeader }: any) {
  const [isOpen, setIsOpen] = useState(false)

  function closeModal() {
    setIsOpen(false)
  }

  return (
    <>
      {cloneElement(trigger, {
        onClick: () => setIsOpen(true),
      })}

      <Modal onClose={closeModal} title={title} opened={isOpen} renderHeader={renderHeader}>
        {children({ close: closeModal })}
      </Modal>
    </>
  )
}
