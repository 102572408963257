import { t } from '@lingui/macro'

import type { InnCompany } from '~/entities/company'

interface Props {
  company: InnCompany
  okved?: string
}

export default function CompanyDetailsSearchResult({ company, okved }: Props) {
  const { name, address, inn, kpp, ogrn, okpo } = company

  return (
    <div className="py-3">
      <div>
        <p className="text-base font-semibold leading-none">{name}</p>
        <p className="mt-1 text-md text-gray-text-3">{address}</p>
      </div>

      <div className="mt-2 border-t border-gray-border-1">
        <dl className="sm:divide-y sm:divide-gray-border-1">
          <div className="py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-2">
            <dt className="text-sm text-gray-text-3 font-medium">
              {t`ИНН`}
            </dt>
            <dd className="mt-1 text-sm sm:col-span-2 sm:mt-0">{inn}</dd>
          </div>

          <div className="py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-2">
            <dt className="text-sm text-gray-text-3 font-medium">
              {t`ОГРН`}
            </dt>
            <dd className="mt-1 text-sm sm:col-span-2 sm:mt-0">{ogrn}</dd>
          </div>

          <div className="py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-2">
            <dt className="text-sm text-gray-text-3 font-medium">
              {t`КПП`}
            </dt>
            <dd className="mt-1 text-sm sm:col-span-2 sm:mt-0">{kpp}</dd>
          </div>

          <div className="py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-2">
            <dt className="text-sm text-gray-text-3 font-medium">
              {t`ОКВЭД (Сфера деятельности)`}
            </dt>
            <dd className="mt-1 text-sm sm:col-span-2 sm:mt-0">{okved ?? '-'}</dd>
          </div>

          <div className="py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-2">
            <dt className="text-sm text-gray-text-3 font-medium">
              {t`ОКПО`}
            </dt>
            <dd className="mt-1 text-sm sm:col-span-2 sm:mt-0">{okpo ?? '-'}</dd>
          </div>
        </dl>
      </div>
    </div>
  )
}
