import { i18n } from '@lingui/core'

/**
 * Форматирование числовых значений
 * @param value
 * @param options
 */
export function numberFormat(value: number, options?: Intl.NumberFormatOptions) {
  return new Intl.NumberFormat(i18n.locale, options).format(value)
}
