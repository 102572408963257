import EntityPreviewSKeleton from './entity-preview-skeleton'
import { escapeNoName } from './utils'
import { cn } from '~/shared/lib/react'

import { Avatar } from '~/shared/components/avatar'

interface Props {
  avatarSrc?: string
  primaryText: string
  secondaryText?: string
  avatarFallback?: string
  loading?: boolean
  onClick?: () => void
}

export default function EntityPreviewRoot({
  primaryText,
  avatarSrc,
  secondaryText,
  loading,
  onClick,
}: Props) {
  if (loading)
    return <EntityPreviewSKeleton />

  return (
    <div className={cn('inline-flex items-center', onClick && 'cursor-pointer')} tabIndex={onClick ? 0 : undefined} onClick={onClick}>
      {avatarSrc && (
        <div className="mr-3">
          <Avatar v2 size={42} src={avatarSrc} name={escapeNoName(primaryText)} />
        </div>
      )}

      <div className="hidden flex-col md:flex">
        <span className="text-md font-medium">{primaryText}</span>
        {secondaryText && (
          <span className="mt-1 text-sm text-gray-text-3">{secondaryText}</span>
        )}
      </div>
    </div>
  )
}
