import type { OptionProps } from 'react-select'
import { components } from 'react-select'

import { Icon } from '../icon'

import type { Option } from './interface'

import { cn } from '~/shared/lib/react'

const { Option: ReactSelectOption } = components

export default function SelectOption(props: OptionProps<Option, false>) {
  const { data, isSelected } = props

  return (
    <ReactSelectOption
      {...props}
      className={cn(
        'group relative cursor-default select-none py-2 pl-8 pr-4 text-gray-900',
        'hover:bg-blue hover:text-white',
      )}
    >
      {isSelected && (
        <span className="absolute inset-y-0 left-0 flex items-center pl-1.5">
          <Icon
            name="check"
            className="h-5 w-5 text-blue group-hover:text-white"
            size={20}
          />
        </span>
      )}

      <span
        className={cn('block truncate', {
          'font-semibold': isSelected,
          'font-normal': !isSelected,
        })}
      >
        {data.label}
      </span>
    </ReactSelectOption>
  )
}
