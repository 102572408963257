import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { Modal } from 'antd'
import { Navigate, useParams } from 'react-router-dom'

import AcceptInvitationForm from '~/company/accept-invitation-form'
import { RoutePathEnum } from '~/enums/route-path-enum'

export default function JoinCompanyPage() {
  useLingui()

  const { token } = useParams<{ token: string }>()

  if (!token)
    return <Navigate to={RoutePathEnum.ROOT} />

  return (
    <Modal title={t`Приглашение в компанию`} closable={false} footer={null} open centered>
      <AcceptInvitationForm inviteToken={token} />
    </Modal>
  )
}
