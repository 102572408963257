import type { UseQueryOptions } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'

import { filterOrdersWithoutArchived } from '../utils'
import { DEFAULT_PAGE } from '../constants'
import { companyKeys } from '~/shared/tanstack/keys/company'
import { getCreatedOrders } from '~/domain/order/api'

type Response = Awaited<ReturnType<typeof getCreatedOrders>>['data']
type Params = Partial<Parameters<typeof getCreatedOrders>[0]>
type Options = UseQueryOptions<Response, Error>

export function useCreatedOrders({
  limit = 10,
  page = DEFAULT_PAGE,
  q = '',
  answers = 'all',
  type = 'all',
  status = 'all',
}: Params, options?: Options) {
  return useQuery({
    queryKey: companyKeys.createdOrders({ limit, page, q, answers, type, status }),
    queryFn: async () => {
      const { data } = await getCreatedOrders({ limit, page, q, answers, type, status })
      return filterOrdersWithoutArchived(data)
    },
    ...options,
  })
}
