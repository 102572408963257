import { Link, useNavigate } from 'react-router-dom'

import { EntityPreview } from '~/components/entity-preview'
import { concatenateUserName } from '~/entities/user'
import { useSignOut } from '~/lib/auth'
import { Button } from '~/shared/components/button'
import { Icon } from '~/shared/components/icon'
import { useGetUser } from '~/shared/tanstack/queries/use-get-user'

export default function UserEntity() {
  const navigate = useNavigate()

  const userQuery = useGetUser()
  const signOut = useSignOut()

  if (userQuery.isLoading)
    return <EntityPreview.Skeleton />

  // TODO: Показывать ошибку
  if (userQuery.isError)
    return <EntityPreview.Skeleton />

  function hadleSignOut() {
    navigate('/', { replace: true })
    signOut()
  }

  const name = concatenateUserName({
    last_name: userQuery.data.last_name,
    middle_name: userQuery.data.middle_name,
    first_name: userQuery.data.first_name,
  })

  return (
    <div className="flex items-center">
      <Link to="/profile">
        <EntityPreview.Root
          primaryText={name ?? userQuery.data.username}
          secondaryText={userQuery.data.username}
          avatarSrc="/empty-user.svg"
        />
      </Link>

      <Button
      //  @ts-expect-error FIXME: Заменить на antd кнопку
        icon={<Icon name="logout" />}
        variant="link"
        color="gray"
        size="lg"
        onClick={hadleSignOut}
      />
    </div>
  )
}
