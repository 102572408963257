import { Trans } from '@lingui/macro'

import { useDeleteProductDocument } from '../hooks/use-delete-product-document'

import { buildApiUrl } from '~/api/utils'
import FileUpload from '~/components/file-upload'
import type { PublicDocument } from '~/shared/api'
import { Button } from '~/shared/components/button'
import { Icon } from '~/shared/components/icon'
import { ACTIVE_COMPANY_TOKEN_STORAGE_KEY } from '~/domain/company/api'

interface Props {
  productId: number
  defaultDocumentList: PublicDocument[]
}

export default function DocumentUpload({ defaultDocumentList, productId }: Props) {
  const { mutateAsync: deleteFile } = useDeleteProductDocument()

  function handleRemove(fileId: number) {
    return deleteFile({ productId, fileId })
  }

  return (
    <FileUpload
      onRemove={handleRemove}
      action={buildApiUrl('/api/products/product/file/add/')}
      defaultFileList={defaultDocumentList}
      data={{ product: productId }}
      headers={{ authorization: `Token ${localStorage.getItem(ACTIVE_COMPANY_TOKEN_STORAGE_KEY)}` }}
      accept=".xls, .xlsx, .xltx, .xlsm, .xltm, .csv, .ods, .ots, .pdf"
      trigger={(
        //  @ts-expect-error FIXME: Заменить на antd кнопку
        <Button size="sm" variant="link" icon={<Icon name="document" className="text-blue" />}>
          <Trans>Загрузить документ</Trans>
        </Button>
      )}
    />
  )
}
