import { t } from '@lingui/macro'
import { App, Button, Checkbox, Form, Input, Select } from 'antd'
import { useMemo, useState } from 'react'
import CompanyTypeSelect from './CompanyTypeSelect'
import { useDebouncedState } from '~/core/hooks/use-debaunced-state'
import { useCreateCompanyMutation } from '~/data/company/company-create-mutation'
import { type Okved, useSearchOkvedQuery } from '~/data/company/search-okved-query'
import type { CompanyTypesEnum } from '~/enums/company-enums'

interface Props {
  onAdd: () => void
  onAddFailed?: () => void
}

interface FormState {
  name: string
  inn: string
  foreign: boolean
  okved: string
  website: string
  company_type: CompanyTypesEnum
  legal_address: string
}

export default function CompanyAddManualForm({ onAdd, onAddFailed }: Props) {
  const { message } = App.useApp()

  const [selectedOkved, setSelectedOkved] = useState<Nullable<Okved>>(null)
  const [okvedQuery, , setOkvedQuery] = useDebouncedState('')

  const { data: searchedOkveds, isFetching: isSearchingOkveds } = useSearchOkvedQuery({ query: okvedQuery })
  const { mutate: createCompany, isLoading: isCreatingCompany } = useCreateCompanyMutation({
    onSuccess: onAdd,
    onError: onAddFailed,
  })

  const okvedsOptions = useMemo(() => searchedOkveds?.results.map(okved => ({ ...okved, label: `${okved.code} - ${okved.name}`, value: okved.code })) ?? [], [searchedOkveds])

  function handleFinish(values: FormState) {
    if (selectedOkved === null) {
      message.error(t`Ошибка. Невозможно отправить форму`)
      return
    }

    createCompany({ ...values, okved: [selectedOkved!.id!] })
  }

  return (
    <Form
      name="company-add-manual"
      layout="vertical"
      disabled={isCreatingCompany}
      onFinish={handleFinish}
    >
      <Form.Item<FormState>
        name="name"
        label={t`Название`}
        rules={[{ required: true, message: t`Обязательное поле` }]}
      >
        <Input />
      </Form.Item>

      <Form.Item<FormState>
        name="inn"
        label={t`ИНН (или аналог)`}
        rules={[{ required: true, message: t`Обязательное поле` }]}
        hasFeedback
      >
        <Input />
      </Form.Item>

      <Form.Item<FormState>
        name="okved"
        label={t`ОКВЭД (Сфера деятельности)`}
        rules={[{ required: true, message: t`Обязательное поле` }]}
        hasFeedback
      >
        <Select
          placeholder={t`Найти...`}
          filterOption={false}
          onSearch={setOkvedQuery}
          notFoundContent={null}
          loading={isSearchingOkveds}
          options={okvedsOptions}
          onSelect={(_value, option) => setSelectedOkved(option)}
          showSearch
        />
      </Form.Item>

      <Form.Item<FormState>
        name="website"
        label={t`Сайт`}
        rules={[{ type: 'url', message: t`Неверный формат URL` }]}
      >
        <Input placeholder="https://example.com" />
      </Form.Item>

      <Form.Item<FormState>
        name="company_type"
        label={t`Что вы хотите делать на платформе?`}
        rules={[{ required: true, message: t`Обязательное поле` }]}
        hasFeedback
      >
        <CompanyTypeSelect />
      </Form.Item>

      <Form.Item<FormState>
        name="legal_address"
        label={t`Юридический адрес`}
        rules={[{ required: true, message: t`Обязательное поле` }]}
      >
        <Input.TextArea
          autoSize={{ minRows: 3, maxRows: 6 }}
          maxLength={200}
          placeholder={t`Например Россия, Москва, 117312, ул. Вавилова, д. 19 `}
          showCount
        />
      </Form.Item>

      <Form.Item<FormState> name="foreign" valuePropName="checked" style={{ marginBottom: 4 }}>
        <Checkbox>{t`Иностранная организация`}</Checkbox>
      </Form.Item>

      <Form.Item>
        <Button
          size="large"
          htmlType="submit"
          type="primary"
          loading={isCreatingCompany}
          block
        >
          {t`Создать компанию`}
        </Button>
      </Form.Item>
    </Form>
  )
}
