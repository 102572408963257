import { useState } from 'react'
import { useDebounce } from 'usehooks-ts'

const DEFAULT_DELAY = 300 // ms

type UseDebouncedStateResult<TInitialState> = [
  TInitialState,
  TInitialState,
  React.Dispatch<React.SetStateAction<TInitialState>>,
]

export function useDebouncedState<TInitialState>(
  initialValue: TInitialState | (() => TInitialState),
  delay = DEFAULT_DELAY,
): UseDebouncedStateResult<TInitialState> {
  const [value, setValue] = useState(initialValue)
  const debouncedValue = useDebounce(value, delay)

  return [debouncedValue, value, setValue]
}
