import useUrlState from '@ahooksjs/use-url-state'
import { Spin } from 'antd'

import { CatalogParamsProvider } from './context'
import { DEFAULT_PAGE } from './constants'
import { useCatalog } from './hooks/use-catalog'
import CategoriesCatalog from './components/categories-catalog'
import SubCategoriesCatalog from './components/sub-categories-catalog'
import type { PageQueryParams } from './types'
import { toBoolean } from '~/lib/utils/common-utils'

export default function CatalogPage() {
  const [urlState, setUrlState] = useUrlState<PageQueryParams>(
    { page: DEFAULT_PAGE, query: '', code: '', order: '' },
    {
      stringifyOptions: { skipEmptyString: true, encode: false },
      parseOptions: { decode: false },
    },
  )
  const { data, status: catalogStatus } = useCatalog()

  if (catalogStatus === 'loading')
    return <Spin />

  if (catalogStatus === 'error')
    return <div>ERROR!</div>

  return (
    <CatalogParamsProvider
      value={{
        params: {
          region: urlState.region,
          onlyRussia: toBoolean(urlState.onlyRussia),
          order: urlState.order,
          code: urlState.code,
          page: Number(urlState.page),
          query: urlState.query,
        },
        setParams: setUrlState,
      }}
    >
      {urlState.code ? <SubCategoriesCatalog categories={data} /> : <CategoriesCatalog /> }
    </CatalogParamsProvider>
  )
}
