import Avatar from '~/core/ui/Avatar'

interface Props {
  title: string
  productName?: string
  productImageUrl?: string
}

export default function Header(props: Props) {
  const { title, productName, productImageUrl } = props

  return (
    <div className="flex items-center space-x-5">
      {productImageUrl && (
        <Avatar image={productImageUrl} name={productName} size={60} imageAlt="" />
      )}

      <div className="grid gap-1">
        <h3 className="text-xl font-bold">{title}</h3>
        {productName && <span className="line-clamp-2">{productName}</span>}
      </div>
    </div>
  )
}
