import { Dialog, Transition } from '@headlessui/react'
import React from 'react'
import { twMerge } from 'tailwind-merge'

import { Icon } from '../icon'

import TabGroup from './tab-group'

interface Props {
  title: React.ReactNode
  opened: boolean
  onClose: () => void
  wrapClassName?: string
  /**
   * Показывать кнопку закрытия.
   */
  closable?: boolean
  children: React.ReactNode
}

type CompoundedComponent<TProps> = {
  TabGroup: typeof TabGroup
} & React.FC<TProps>

const Modal: CompoundedComponent<Props> = ({
  onClose,
  closable = true,
  opened,
  title,
  children,
  wrapClassName,
}: Props) => {
  let closeNode
  if (closable) {
    closeNode = (
      <button onClick={onClose} type="button" className="inline-flex">
        <Icon name="close" className="hover:text-blue" size={24} />
      </button>
    )
  }

  return (
    <Transition show={opened} as={React.Fragment} appear>
      <Dialog className="relative z-50" as="div" onClose={onClose}>
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-75"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="min-h-full flex items-center justify-center p-4 text-center">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-75"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="max-w-md w-full transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="header"
                  className="flex items-center justify-between gap-5 text-xl text-black font-bold"
                >
                  {title}
                  {closeNode}
                </Dialog.Title>

                <div className={twMerge('pt-5', wrapClassName)}>{children}</div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

Modal.TabGroup = TabGroup

export default Modal
