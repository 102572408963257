import { FileExcelOutlined } from '@ant-design/icons'
import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { useQueryClient } from '@tanstack/react-query'
import { Button } from 'antd'

import { useDeleteOrderFile } from '../hooks/use-delete-order-file'

import { buildApiUrl } from '~/api/utils'
import FileUpload from '~/components/file-upload'
import { useCreatedOrderDetails } from '~/pages/orders/created/queries/use-created-order-details'
import type { PublicDocument } from '~/shared/api'
import { companyKeys } from '~/shared/tanstack/keys/company'
import { ACTIVE_COMPANY_TOKEN_STORAGE_KEY } from '~/domain/company/api'

interface Props {
  orderId: number
}

export default function DocumentUpload({ orderId }: Props) {
  useLingui()

  const queryClient = useQueryClient()

  function updateOrderData() {
    queryClient.invalidateQueries({ queryKey: companyKeys.createdOrder(orderId) })
  }

  const { mutateAsync: deleteFiles } = useDeleteOrderFile({ onSuccess: updateOrderData })
  const { data } = useCreatedOrderDetails({ id: orderId })

  const files = data?.request.files ?? []

  function handleRemove(fileId: number) {
    return deleteFiles({ file_id: fileId, request_id: orderId })
  }

  return (
    <FileUpload
      onSuccess={updateOrderData}
      onRemove={handleRemove}
      action={buildApiUrl('/api/orders/request/file/add/')}
      defaultFileList={files as unknown as PublicDocument[]}
      data={{ request_id: orderId }}
      headers={{ authorization: `Token ${localStorage.getItem(ACTIVE_COMPANY_TOKEN_STORAGE_KEY)}` }}
      accept=".xls, .xlsx, .xltx, .xlsm, .xltm, .csv, .ods, .ots, .pdf"
      trigger={(
        <Button type="link" icon={<FileExcelOutlined />}>
          {t`Загрузить документ`}
        </Button>
      )}
    />
  )
}
