import type { ReactNode } from 'react'

import LocaleSelect from '~/components/locale-select'

interface Props {
  children: ReactNode
  title?: ReactNode
  description?: ReactNode
}

export default function AuthenticationPagesLayout({ children, title, description }: Props) {
  return (
    <div className="mx-auto min-h-screen flex flex-col bg-white px-2 md:px-4">
      <div className="grid grid-rows-[auto,1fr,auto] grow gap-6 pt-12">
        <div className="flex justify-center">
          <img src="/logo-desktop.svg" alt="" width={190} />
        </div>

        <div className="mx-auto max-w-4xl w-full container">
          <div className="grid justify-items-center gap-2 empty:hidden">
            {title && <div className="text-xl font-semibold">{title}</div>}
            {description && <div className="text-md text-gray-500">{description}</div>}
          </div>

          <div className="mx-auto md:w-[55%] md:p-3">{children}</div>
        </div>

        <div className="border-t py-5">
          <div className="max-w-4xl flex justify-end container">
            <LocaleSelect />
          </div>
        </div>
      </div>
    </div>
  )
}
