import { Trans, t } from '@lingui/macro'
import { Alert, App, Button, Form, Input, Spin } from 'antd'
import { useEffect } from 'react'
import { useTimer } from 'react-timer-hook'
import { useConfirmCodeMutation } from '~/data/auth/confirm-code-mutation'
import { useSendEmailCodeMutation } from '~/data/auth/send-email-code-mutation'
import { useConfirmEmailStorage } from '~/entities/auth'

interface Props {
  visibleEmail: string
  onConfirm: (accessToken: string) => void
  onConfirmFailed?: () => void
}

interface FormState {
  code: string
}

export const DEFAULT_RESEND_CODE_DELAY = 5

function addMinutes(date: Date, minutes: number, seconds = 0) {
  const dateCopy = new Date(date.getTime())
  dateCopy.setMinutes(dateCopy.getMinutes() + minutes)
  dateCopy.setSeconds(dateCopy.getSeconds() + seconds)

  return dateCopy
}

export default function UserEmailConfirmForm({ onConfirm, onConfirmFailed, visibleEmail }: Props) {
  const { message } = App.useApp()
  const [form] = Form.useForm<FormState>()

  const {
    emailResendDelayTimer,
    clearConfirmEmailStorage,
    setEmailResendDelayTimer,
  } = useConfirmEmailStorage()
  const { minutes, seconds, restart, isRunning } = useTimer({
    autoStart: true,
    expiryTimestamp: addMinutes(new Date(), DEFAULT_RESEND_CODE_DELAY),
  })

  const { mutate: confirm, isLoading: isConfirming, isError: isConfirmError } = useConfirmCodeMutation({
    onSuccess: ({ Token }) => {
      if (!Token)
        return message.error(t`Произошла внутренняя ошибка сервера. Пожалуйста, попробуйте позже или свяжитесь с технической поддержкой.`)

      clearConfirmEmailStorage()
      onConfirm(Token)
    },
    onError: () => {
      message.error(t`Код авторизации не верен или его срок действия истёк`)
      onConfirmFailed?.()
    },
  })
  const { mutate: sendCode } = useSendEmailCodeMutation({
    onError() {
      message.error(t`Не удалось отправить код подтверждения. Попробуйте ещё раз`)
    },
  })

  function restorePreviousTimer() {
    // восстановить таймер если подтверждение ещё не завершенно
    if (emailResendDelayTimer)
      restart(addMinutes(new Date(), emailResendDelayTimer.m, emailResendDelayTimer.s))
  }

  function updateEmailResendDelayTimer(newMinutes: number, newSeconds: number) {
    // Очистить хранилище если не найдена последняя сохраненная почта
    if (!visibleEmail)
      clearConfirmEmailStorage()

    setEmailResendDelayTimer({ m: newMinutes, s: newSeconds })
  }

  useEffect(() => {
    restorePreviousTimer()
  }, [])

  useEffect(() => {
    if (isRunning && visibleEmail)
      updateEmailResendDelayTimer(minutes, seconds)
  }, [seconds])

  function handleFinish({ code }: FormState) {
    confirm({ code, username: visibleEmail })
  }

  function handleResend() {
    sendCode({ email: visibleEmail })
    restart(addMinutes(new Date(), DEFAULT_RESEND_CODE_DELAY))
  }

  const canResendCode = seconds <= 0 && minutes <= 0
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes
  const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds

  return (
    <Spin spinning={isConfirming}>
      {isConfirmError && (
        <Alert
          type="error"
          message={t`Код авторизации не верен или его срок действия истёк`}
          style={{ marginBottom: 16 }}
          showIcon
        />
      )}

      <div className="mb-3 text-md text-gray-text-3">
        <Trans>
          На почту&nbsp;
          <span className="text-black font-bold">{`${visibleEmail} `}</span>
          было отправлено письмо с кодом
          подтверждения.
        </Trans>
      </div>

      <Form layout="vertical" form={form} onFinish={handleFinish}>
        <Form.Item name="code" label={t`Код для продолжения`} rules={[{ required: true }]}>
          <Input maxLength={6} />
        </Form.Item>

        <div className="mb-4 flex items-center">
          {canResendCode && (
            <Button type="link" size="small" onClick={handleResend}>
              {t`Отправить код повторно`}
            </Button>
          )}

          {!canResendCode && (
            <div className="text-md">
              {t`Запросить код повторно через`}
              <span className="text-black font-semibold">{` ${formattedMinutes}:${formattedSeconds}`}</span>
            </div>
          )}

        </div>

        <Button htmlType="submit" type="primary" block>{t`Отправить`}</Button>
      </Form>
    </Spin>
  )
}
