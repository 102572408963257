import { MailOutlined, MobileOutlined } from '@ant-design/icons'
import { t } from '@lingui/macro'
import { Tabs } from 'antd'
import type { ComponentType, ReactNode } from 'react'

interface Props {
  phone: ReactNode
  email: ReactNode
}

function renderLabel(Icon: ComponentType, label: string) {
  return (
    <span>
      <Icon />
      {label}
    </span>
  )
}

export default function UserAuthMethodTabs({ phone, email }: Props) {
  return (
    <Tabs
      defaultActiveKey="phone"
      items={[
        { key: 'phone', label: renderLabel(MobileOutlined, t`По телефону`), children: phone },
        { key: 'email', label: renderLabel(MailOutlined, t`По почте`), children: email },
      ]}
      centered
    />
  )
}
