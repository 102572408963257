import React from 'react'
import { NumericFormat } from 'react-number-format'

import type { BaseFieldProps } from '../base-form-control'
import { TextField } from '../text-field'

import { DEFAULT_THOUSANDSEPARATOR } from './contant'

interface Props extends BaseFieldProps {
  thousandSeparator?: string
  onChange: (value?: number) => void
}

const NumberField = React.forwardRef<HTMLInputElement, Props>(
  (
    { thousandSeparator = DEFAULT_THOUSANDSEPARATOR, onChange, ...props },
    forwardedRef,
  ) => {
    return (
      <NumericFormat
        {...props}
        getInputRef={forwardedRef}
        thousandSeparator={thousandSeparator}
        onValueChange={values => onChange(values?.floatValue)}
        customInput={TextField}
      />
    )
  },
)

NumberField.displayName = 'NumberField'

export default NumberField
