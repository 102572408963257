import type { CraetedOrderTableRow } from './types'
import type { CreatedOrdersResponse } from '~/domain/order/types'
import { CreatedOrderStatusEnum } from '~/domain/order/enums'

export function filterOrdersWithoutArchived(orders: CreatedOrdersResponse): CreatedOrdersResponse {
  return { ...orders, data: orders.data.filter(o => o.status !== CreatedOrderStatusEnum.ARCHIVE) }
}

export function prepareTableData(orders: CreatedOrdersResponse['data']): CraetedOrderTableRow[] {
  return orders.map((order) => {
    return {
      amount: order.amount,
      answersCount: order.answers_amount,
      id: order.id,
      isPersonalRequest: order.is_personal_request,
      key: order.id,
      preferablePrice: order.preferable_price,
      productName: order.request,
      requestTime: order.request_time,
      status: order.status,
    }
  })
}
