import { Trans, t } from '@lingui/macro'
import { Link } from 'react-router-dom'

import { RoutePathEnum } from '~/enums/route-path-enum'
import { Button } from '~/shared/components/button'
import type { IconName } from '~/shared/components/icon'
import { Icon } from '~/shared/components/icon'

const socials = [
  { name: 'telegram', href: 'https://t.me/SupplyDirector' },
] satisfies { name: IconName, href: string }[]

export default function LandingEmailSubscribe() {
  return (
    <section className="overflow-hidden pb-5 pt-10 lg:py-12" id="go">
      <div className="relative flex flex-wrap items-center gap-8 rounded-2xl bg-blue px-4 py-5 lg:gap-24 lg:px-12 lg:py-7">
        <img
          className="lg:inset-y-center absolute -right-[30px] -top-[45px] lg:right-0 lg:h-[266px] lg:w-[294px]"
          src="/landing/chain.png"
          srcSet="/landing/chain@2x.png 2x"
          width={180}
          height={180}
          loading="lazy"
          alt=""
        />

        <div className="grid gap-5">
          <h2 className="text-xxl text-white">
            <Trans>
              <span className="font-bold">Регистрируйтесь</span>
              , это бесплатно
            </Trans>
          </h2>

          <Button
            className="bg-[black!important] hover:bg-[white!important] hover:text-black"
            size="lg"
            asChild
          >
            <Link to={RoutePathEnum.SIGN_IN}>{t`Начать работу`} </Link>
          </Button>
        </div>

        <div className="flex flex-col gap-4">
          <span className="text-lg text-white font-bold">{t`Мы в соцсетях:`}</span>

          <ul className="flex gap-5">
            {socials.map(({ name, href }) => (
              <li key={name}>
                <a
                  className="flex items-center rounded-lg bg-white p-1.5 transition-opacity hover:opacity-80"
                  href={href}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Icon name={name} />
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  )
}
