import AuthenticationPagesLayout from '~/layouts/authentication'
import NewPasswordForm from '~/users/forms/new-passord'

export default function NewPasswordPage() {
  return (
    <AuthenticationPagesLayout>
      <NewPasswordForm />
    </AuthenticationPagesLayout>
  )
}
