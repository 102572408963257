import { Avatar } from 'antd'

interface Props {
  name: string
}

export default function CompanyName({ name }: Props) {
  return (
    <div className="grid gap-3">
      <Avatar style={{ backgroundColor: '#87d068' }} alt={name} size={80}>{name[0]}</Avatar>
      <span className="line-clamp-1 break-words text-xl text-black font-bold">{name}</span>
    </div>
  )
}
