import { LOCAL_STORAGE_KEYS } from './constants'

const LOCAL_STORAGE_KEYS_ALLOWLIST = [
  LOCAL_STORAGE_KEYS.ACTIVE_COMPANY_ID,
  LOCAL_STORAGE_KEYS.ACTIVE_COMPANY_TOKEN,
]

export function cleaLocalStorage() {
  for (const key in localStorage) {
    if (!LOCAL_STORAGE_KEYS_ALLOWLIST.includes(key))
      localStorage.removeItem(key)
  }
}

export function clearUserLocalStorage() {
  localStorage.removeItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN)
  localStorage.removeItem(LOCAL_STORAGE_KEYS.VERIFICATION_STEP)
  localStorage.removeItem(LOCAL_STORAGE_KEYS.BEFORE_RESEND_PHONE_CODE)
}

export function clearCompanyLocalStorage() {
  localStorage.removeItem(LOCAL_STORAGE_KEYS.ACTIVE_COMPANY_ID)
  localStorage.removeItem(LOCAL_STORAGE_KEYS.ACTIVE_COMPANY_TOKEN)
}
