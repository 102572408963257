import type { UseMutationOptions } from '@tanstack/react-query'
import { useMutation } from '@tanstack/react-query'
import { deleteOrderFile } from '~/domain/order/api'

type Variables = Parameters<typeof deleteOrderFile>[0]

export function useDeleteOrderFile(options?: UseMutationOptions<unknown, Error, Variables>) {
  return useMutation({
    ...options,
    mutationFn: async (variables) => {
      const { data } = await deleteOrderFile(variables)
      return data
    },
  })
}
