import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { lazy } from 'react'
import { RoutePathEnum } from '~/enums/route-path-enum'
import PageHeader from '~/components/common/PageHeader'

const UserProfileCard = lazy(() => import('~/users/profile-card'))
const TelegramConnect = lazy(() => import('~/users/telegram-connect'))

export default function ProfilePage() {
  useLingui()

  return (
    <>
      <PageHeader title={t`Управление профилем`} backRoutePath={RoutePathEnum.DASHBOARD} />

      <div className="grid grid-cols-1 gap-7 lg:grid-cols-2">
        <div className="row-span-2">
          <UserProfileCard />
        </div>

        <TelegramConnect />
      </div>
    </>
  )
}
