import { useQuery } from '@tanstack/react-query'

import { productsKeys } from '../keys/products'
import { getProductsCountInDatabase } from '~/domain/product/api'

export function useProductsCountInDatabse() {
  return useQuery({
    queryKey: productsKeys.database(),
    queryFn: async () => {
      const { data } = await getProductsCountInDatabase()
      return data.result
    },
  })
}
