import { i18n } from '@lingui/core'
import { msg } from '@lingui/macro'
import { useResponsive } from 'ahooks'
import { nanoid } from 'nanoid'

import NavigationItem from './navigation-item'

import LocaleSelect from '~/components/locale-select'

const ITEMS = [
  {
    id: nanoid(),
    name: msg`Помощь`,
    icon: 'help',
    href: '/help',
  },
  {
    id: nanoid(),
    name: msg`О нас`,
    icon: 'sd',
    href: '/about',
  },
] as const

export default function SecondaryNavigation() {
  const responsive = useResponsive()

  return (
    <div className="grid mt-auto justify-items-center gap-2 -mx-4 xl:justify-items-start">
      {ITEMS.map(({ icon, name, href, id }) => (
        <NavigationItem href={href} key={id} name={i18n._(name)} icon={icon} />
      ))}

      <LocaleSelect showLabel={responsive.xl} />
    </div>
  )
}
