import { t } from '@lingui/macro'

import type { ReceivedOrderTableRow } from './types'
import { ReceivedOrderTypeEnum } from '~/domain/order/enums'
import type { ReceivedOrdersResponse } from '~/domain/order/types'

export function getOrderTypeName(type: ReceivedOrderTypeEnum) {
  const names = {
    [ReceivedOrderTypeEnum.PRODUCT]: t`Товар`,
    [ReceivedOrderTypeEnum.PERSONAL]: t`Персональный`,
  }
  return names[type]
}

/**
 * Тип входящего запроса временно приходит как true/false
 * false - product
 * true - personal
 */
function parseOrderType(type: boolean): ReceivedOrderTypeEnum {
  return Number(type)
}

export function prepareTableData(orders: ReceivedOrdersResponse['data']): ReceivedOrderTableRow[] {
  return orders.map((order) => {
    return {
      amount: order.product_request.amount,
      createdAt: order.created_at,
      customer: order.product_request.company,
      key: order.id,
      orderType: parseOrderType(order.product_request.is_personal_request),
      productName: order.product_request.name,
      status: order.status,
      preferableTime: order.product_request.preferable_time,
      orderId: order.id,
    }
  })
}
