import type { NotificationInstance } from 'antd/es/notification/interface'

import { createSafeContext } from '~/shared/lib/react'

interface NotificationsContext {
  open: NotificationInstance['open']
  destroy: NotificationInstance['destroy']
}

export const [NotificationsProvider, useNotifications] = createSafeContext<NotificationsContext>({
  name: 'NotificationsContext',
  providerName: 'NotificationsProvider',
  hookName: 'useNotifications',
  strict: true,
})
