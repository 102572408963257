import { t } from '@lingui/macro'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import OrderChainCard from '~/domain/order/components/order-chain-card'
import OrderChainStatusTag from '~/domain/order/components/order-chain-status-tag'
import type { OrderChain } from '~/domain/order/types'

import { ChainDetailsModal } from '~/features/chain-details-modal'
import { useCreatedOrderDetails } from '~/pages/orders/created/queries/use-created-order-details'
import { Result } from '~/shared/components/result'

export default function CratedOrderChainsList() {
  const { id: orderId } = useParams()

  const [chainDetatils, setChainDetails] = useState<Nullable<OrderChain>>(null)
  const [isShowingDetails, setIsShowingDetails] = useState(false)

  const { data } = useCreatedOrderDetails({ id: Number(orderId) })

  const chains = data?.request.chains ?? []
  const hasChains = chains.length !== 0
  if (!hasChains) {
    return (
      <Result
        status="empty"
        title={t`Пока нет ответов на запрос`}
        description={t`Здесь будут ответы поставщиков`}
      />
    )
  }

  function openDetails(chain: OrderChain) {
    setIsShowingDetails(true)
    setChainDetails(chain)
  }

  function closeDetails() {
    setIsShowingDetails(false)
  }

  return (
    <section className="grid gap-4">
      <h2 className="text-xl text-black font-bold">{t`Ответы на запрос`}</h2>
      <ul className="flex flex-col gap-2">
        {chains.map(chain => (
          <li key={chain.id}>
            <OrderChainCard
              status={status => <OrderChainStatusTag status={status} />}
              chain={chain}
              orderId={Number(orderId)}
              onOpenDetails={openDetails}
            />
          </li>
        ))}
      </ul>

      <ChainDetailsModal isOpen={isShowingDetails} chain={chainDetatils} onClose={closeDetails} />
    </section>
  )
}
