export enum RoutePathEnum {
  ROOT = '/',
  SIGN_IN = '/sign_in',
  SIGN_UP = '/sign_up',
  PHONE_VERIFICATION = '/phone_verification',
  IDENTITY_VERIFICATION = '/indentity_verification',
  DASHBOARD = '/dashboard',
  PROFILE = '/profile',
  CATALOG = '/catalog',
  ABOUT = '/about',
  HELP = '/help',
  PARTNERS = '/partners',
  FACTORIES = '/factories',
  PRODUCTS = '/p',
  PRODUCT = '/p/:id',
  COMPANY_EDIT = '/company/edit',
  COMPANY_PRODUCTS = '/company/p',
  COMPANY_PRODUCT_DETAILS = '/company/p/:id',
  COMPANY_PRODUCT_EDIT = '/company/p/:id/edit',
  COMPANY_PRODUCT_NEW = '/company/p/new',
  COMPANY_MEMBERS = '/company/members',
  COMPANY_JOIN_TOKEN = '/joincompany/:token',
  CREATED_ORDERS = '/o/created',
  CREATED_ORDER = '/o/created/:id',
  CREATED_ORDER_EDIT = '/o/created/:id/edit',
  RECEIVED_ORDERS = '/o/received',
  RECEIVED_ORDER = '/o/received/:id',
  NOTIFICATIONS = '/notifications',
  NOTIFICATION = '/notifications/:id',
  RESET_PASSWORD = '/password/reset/confirm/:uid/:token',
  NEW_PASSWORD = '/password/new',
}
