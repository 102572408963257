import type { UseMutationOptions } from '@tanstack/react-query'
import { useMutation } from '@tanstack/react-query'

import { App } from 'antd'
import { post } from '../fetchers'

export interface SignUpWithPhoneVariables {
  phone: string
}

export async function signUpWithPhone({ phone }: SignUpWithPhoneVariables) {
  const { data, error } = await post('/users/auth/phone/', {
    // @ts-expect-error TODO: Заполнить в сваггере
    body: { username: phone },
  })

  if (error)
    throw error
  return data
}

type SignUpWithPhoneData = Awaited<ReturnType<typeof signUpWithPhone>>
type SignUpWithPhoneError = unknown

export function useSignUpWithPhone({
  onSuccess,
  onError,
  ...options
}: Omit<UseMutationOptions<SignUpWithPhoneData, SignUpWithPhoneError, SignUpWithPhoneVariables>, 'mutationFn' > = {}) {
  const { message } = App.useApp()

  return useMutation<SignUpWithPhoneData, SignUpWithPhoneError, SignUpWithPhoneVariables>({
    mutationFn: vars => signUpWithPhone(vars),
    async onSuccess(data, variables, context) {
      await onSuccess?.(data, variables, context)
    },
    async onError(data, variables, context) {
      if (onError === undefined)
        message.error(`Failed to mutate: signUpWithPhone`)
      else
        onError(data, variables, context)
    },
    ...options,
  })
}
