import type { UseQueryOptions } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'
import { getProductsAmountByCategory } from '~/domain/product/api'

import { productsKeys } from '~/shared/tanstack/keys/products'

interface Params {
  ids: string[]
}
type Response = Awaited<ReturnType<typeof getProductsAmountByCategory>>['data']

export function useProductsAmountByCategory({ ids }: Params, options?: UseQueryOptions<Response, Error>) {
  return useQuery({
    queryKey: productsKeys.productsAmount(ids),
    queryFn: async () => {
      const { data } = await getProductsAmountByCategory(ids)
      return data
    },
    ...options,
  })
}
