import { t } from '@lingui/macro'
import type { UploadFile, UploadProps } from 'antd'
import { Upload } from 'antd'
import React from 'react'

import { getRemovedImageId, toAntFileList } from './utils'

import type { PublicDocument, PublicImage } from '~/shared/api'
import { showMessage } from '~/shared/show-message'

type File = PublicImage | PublicDocument

interface Props<T> {
  defaultFileList?: File[]
  trigger: React.ReactNode
  listType?: UploadProps<T>['listType']
  accept?: UploadProps<T>['accept']
  data?: UploadProps<T>['data']
  action: UploadProps<T>['action']
  headers?: UploadProps<T>['headers']
  onRemove?: (fileId: number) => Promise<void>
  onSuccess?: () => void
  /** Размер в MB */
  sizeLimit?: number
}

export default function FileUpload({
  defaultFileList,
  trigger,
  accept,
  action,
  data,
  headers,
  sizeLimit = 5,
  listType,
  onRemove,
  onSuccess,
}: Props<File>) {
  const onChange: UploadProps<File>['onChange'] = (info) => {
    // if (info.file.status !== 'uploading') {
    //   console.log(info.file, info.fileList);
    // }
    const fileName = info.file.name

    if (info.file.status === 'done') {
      showMessage(t`Файл ${fileName} успешно загружен`, 'success')
      onSuccess?.()
    }
    else if (info.file.status === 'error') {
      showMessage(t`Не удалось загрузить файл ${fileName}`, 'error')
    }
  }

  const beforeUpload: UploadProps<File>['beforeUpload'] = (file) => {
    // const isPNG = file.type === 'image/png';
    // if (!isPNG) {
    //   showMessage(`${file.name} имеет недопустимый для загрузки формат`, 'error');
    // }

    const isLimitPassed = file.size / 1024 / 1024 < sizeLimit
    if (!isLimitPassed)
      showMessage(`Размер файла должен быть меньше ${sizeLimit}MB!`, 'error')

    return isLimitPassed || Upload.LIST_IGNORE
  }

  async function handleRemove(file: UploadFile<File>) {
    if (file.error)
      return true

    const fileName = file.name

    try {
      const fileId = getRemovedImageId(file)
      await onRemove?.(fileId)

      showMessage(t`Файл ${fileName} удалён`, 'success')
    }
    catch {
      showMessage(t`Ошибка при удалении файла ${fileName}`, 'error')
      return false
    }
  }

  return (
    <Upload
      listType={listType}
      name="file"
      defaultFileList={defaultFileList ? toAntFileList(defaultFileList) : undefined}
      accept={accept}
      data={data}
      action={action}
      headers={headers}
      onChange={onChange}
      onRemove={handleRemove}
      beforeUpload={beforeUpload}
    >
      {trigger}
    </Upload>
  )
}
