import type { NotificationWithStatus } from '~/api/notification-api'
import { RoutePathEnum } from '~/enums/route-path-enum'
import PageHeader from '~/components/common/PageHeader'
import { dateFormat } from '~/shared/lib/format'

interface Props {
  notification: NotificationWithStatus['notification']
}

export default function Content({ notification }: Props) {
  const date = dateFormat(new Date(notification.created_at))

  return (
    <>
      <PageHeader title={notification.title} subtitle={date} backRoutePath={RoutePathEnum.NOTIFICATIONS} />

      <p className="whitespace-pre-wrap">{notification.text}</p>

      {/* <Button variant="secondary" as={Link} to="/app">
            Приступить к работе
          </Button> */}
    </>
  )
}
