import type { UseQueryOptions } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'

import { get } from '../fetchers'
import { notificationKeys } from './keys'
import { useIsLoggedIn } from '~/lib/auth'

const REFETCH_INTERVAL = 1000 * 20 // 20 сек

export async function getNotificationsState(signal?: AbortSignal) {
  const { data, error } = await get(`/users/notifications/system/state/`, { signal })
  if (error)
    throw error

  return data
}

export type NotificationStateData = Awaited<ReturnType<typeof getNotificationsState>>
export type NotificationStateError = unknown

export function useNotificationState<TData = NotificationStateData>(
  { enabled = true, ...options }: UseQueryOptions<NotificationStateData, NotificationStateError, TData> = {},
) {
  const isLoggedIn = useIsLoggedIn()

  return useQuery<NotificationStateData, NotificationStateError, TData>({
    queryKey: notificationKeys.state(),
    queryFn: ({ signal }) => getNotificationsState(signal),
    enabled: enabled && isLoggedIn,
    refetchInterval: REFETCH_INTERVAL,
    ...options,
  })
}
