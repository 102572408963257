import type { UseQueryOptions } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'
import { getReceivedOrderDetails } from '~/domain/order/api'

import { companyKeys } from '~/shared/tanstack/keys/company'

interface Params {
  id: number
}

type Response = Awaited<ReturnType<typeof getReceivedOrderDetails>>
type Options = UseQueryOptions<Response, Error>

export function useReceivedOrderDetails({ id }: Params, options?: Options) {
  return useQuery({
    queryKey: companyKeys.receivedOrder(id),
    queryFn: async () => {
      const { data } = await getReceivedOrderDetails(id)
      return data
    },
    ...options,
  })
}
