import { t } from '@lingui/macro'

import { Button, Table, type TableProps } from 'antd'
import { useLingui } from '@lingui/react'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import type { CraetedOrderTableRow } from '../types'

import { prepareTableData } from '../utils'
import OrderActionMenu from './order-action-menu'
import { dateFormat, numberFormat } from '~/shared/lib/format'
import CreatedOrderStatusTag from '~/shared/components/created-order-status-tag'
import type { CreatedOrdersResponse } from '~/domain/order/types'

const { Column } = Table

interface Props {
  orders: CreatedOrdersResponse['data']
  loading: TableProps<CraetedOrderTableRow>['loading']
  pagination: TableProps<CraetedOrderTableRow>['pagination']
}

export default function OrdersTable({ orders, loading, pagination }: Props) {
  useLingui()
  const navigate = useNavigate()

  const dataSource = useMemo(() => prepareTableData(orders), [orders])

  return (
    <Table<CraetedOrderTableRow>
      pagination={pagination}
      dataSource={dataSource}
      loading={loading}
    >
      <Column<CraetedOrderTableRow>
        key="requestTime"
        title={t`Дата`}
        dataIndex="requestTime"
        responsive={['lg']}
        render={value => dateFormat(new Date(value))}
      />
      <Column<CraetedOrderTableRow>
        key="productName"
        title={t`Название товара`}
        dataIndex="productName"
        render={(value, record) => {
          return <Button type="link" onClick={() => navigate(`/o/created/${record.id}`)}>{value}</Button>
        }}
      />
      <Column<CraetedOrderTableRow>
        key="status"
        title={t`Статус`}
        dataIndex="status"
        responsive={['sm']}
        render={(_, record) => <CreatedOrderStatusTag status={record.status} />}
      />
      <Column<CraetedOrderTableRow>
        key="amount"
        title={t`Кол-во`}
        dataIndex="amount"
        responsive={['sm']}
        render={(value) => {
          const formattedAmount = numberFormat(value)
          return t`${formattedAmount} шт.`
        }}
      />
      <Column<CraetedOrderTableRow>
        key="preferablePrice"
        title={t`Стоимость`}
        dataIndex="preferablePrice"
        responsive={['md']}
        render={(_, record) => {
          return record.preferablePrice === null ? t`Цена по запросу` : numberFormat(record.preferablePrice)
        }}
      />
      <Column<CraetedOrderTableRow>
        key="answersCount"
        title={t`Ответы`}
        dataIndex="answersCount"
        responsive={['lg']}
        render={value => value || t`Нет ответов`}
      />
      <Column<CraetedOrderTableRow>
        align="center"
        key="action"
        title={t`Действие`}
        render={(_, record) => <OrderActionMenu orderId={record.id} orderStatus={record.status} />}
      />
    </Table>
  )
}
