import clsx from 'clsx'

import { Icon } from '~/shared/components/icon'

interface Props {
  value: number
  size?: 'small' | 'medium'
}

export default function RatingValueIcon({ value, size = 'small' }: Props) {
  return (
    <div className="flex items-center gap-1">
      <Icon name="star" width={16} height={16} />
      <span
        className={clsx('font-normal', {
          'text-base': size === 'small',
          'text-xl': size === 'medium',
        })}
      >
        {value}
      </span>
    </div>
  )
}
