import type { TourProps } from 'antd'
import type { RefObject } from 'react'

import { createSafeContext } from '~/shared/lib/react'

interface WelcomeTourContext {
  open: boolean
  close: () => void
  steps: TourProps['steps']
  companyManagementRef?: RefObject<HTMLDivElement>
  productsSearchRef?: RefObject<HTMLDivElement>
  requestsRef?: RefObject<HTMLDivElement>
  companySwitchRef?: RefObject<HTMLDivElement>
  catalogRef?: RefObject<HTMLDivElement>
  createRequestRef?: RefObject<HTMLDivElement>
}

export const [WelcomeTourProvider, useWelcomeTour]
  = createSafeContext<WelcomeTourContext>({
    name: 'WelcomeTourContext',
    providerName: 'WelcomeTourProvider',
    hookName: 'useWelcomeTour',
  })
