import { t } from '@lingui/macro'
import type { CollapseProps } from 'antd'
import { Typography } from 'antd'
import { nanoid } from 'nanoid'
import type { CSSProperties } from 'react'

const { Paragraph, Title, Text } = Typography

export function getItems(panelStyle: CSSProperties): CollapseProps['items'] {
  return [
    {
      key: nanoid(),
      label: <Title level={5}>{t`Как работает сайт`}</Title>,
      children: (
        <>
          <Paragraph>{t`На нашей площадке действия могут производиться только юридическими лицами (Компаниями). Любая Компания на площадке может играть роль как заказчика, так и продавца, то есть как размещать запросы, так и принимать их.`}</Paragraph>
          <Paragraph>{t`Заказчики создают запросы на приобретение продукции с интересующими параметрами. Для создания запроса можно выбрать существующий товар, воспользоваться шаблонами, имеющимися на сайте, или составить индивидуальную заявку. Данный запрос анализируется системой и автоматически рассылается представленным на площадке продавцам соответствующего оборудования. Рассылка осуществляется согласно настройкам пользователя через чат площадке, телеграмм-бот или E-mail. Если продавец желает выполнить заказ, отвечает на пришедший запросы и может выслать заказчику ответное предложение или уточняющие вопросы. Заказчику отображаются все ответы на его запрос. Он может уточнить детали в чате с продавцом и выбрать наиболее подходящее и выгодное предложение. На площадке присутствует рекомендательная система, помогающая подобрать наилучшее предложение на основе рейтинга продавца, рейтинга товара, сроков исполнения, стоимости и других параметров`}</Paragraph>
        </>
      ),
      style: panelStyle,
    },
    {
      key: nanoid(),
      label: <Title level={5}>{t`Создание аккаунта`}</Title>,
      children: (
        <>
          <Paragraph>{t`На нашей площадке действия могут производиться только юридическими лицами, поэтому для работы с сервисом Вам необходимо зарегистрироваться не только как Пользователь, но и как Компания. В одну Компанию можно добавить множество Пользователей (Сотрудников) с разными ролями и разрешениями. В целях безопасности на площадке необходима верификация Пользователей и Компаний путём добавления подтверждающих документов.`}</Paragraph>
          <Paragraph>{t`Любая Компания на площадке может играть роль как заказчика, так и продавца, то есть как размещать запросы, так и принимать их`}</Paragraph>
        </>
      ),
      style: panelStyle,
    },
    {
      key: nanoid(),
      label: <Title level={5}>{t`Создание запроса`}</Title>,
      children: (
        <>
          <Paragraph>{t`Вы можете создать запрос на приобретение продукции/решения с интересующими параметрами. Для создания запроса можно как выбрать уже существующий на площадке товар при помощи поисковой системы, так и воспользоваться шаблонами, имеющимися на сайте или составить индивидуальную заявку. Данный запрос анализируется системой и автоматически рассылается представленным на площадке продавцам и производителями соответствующих товаров в анонимном виде.`}</Paragraph>
          <Paragraph>{t`Следует помнить, что для максимально точного анализа индивидуальной заявки и нахождения исполнителя на площадке запрос стоит формулировать как можно более подробно`}</Paragraph>
        </>
      ),
      style: panelStyle,
    },
    {
      key: nanoid(),
      label: <Title level={5}>{t`Покупка: Входящие предложения`}</Title>,
      children: (
        <>
          <Paragraph>{t`Анонимные входящие запросы приходят согласно настройкам пользователя через чат площадки, телеграмм-бот и/или E-mail. Если Вы желаете выполнить заказ, необходимо ответить на пришедший запрос на сайте площадке. Так же Вы можете выслать заказчику ответное предложение или уточняющие вопросы. Это действие раскрывает Вам контактные данные заказчика, а заказчику – Ваши данные.`}</Paragraph>
          <Paragraph>{t`Ответ на запрос в некоторых случаях может являться платной функцией, по аналогии  с отраслевыми выставочными залами`}</Paragraph>
        </>
      ),
      style: panelStyle,
    },
    {
      key: nanoid(),
      label: <Title level={5}>{t`Совершение сделки`}</Title>,
      children: (
        <>
          <Paragraph>{t`Когда заказчик и продавец договорились, сделка между компаниями заключается самостоятельно, вне текущей площадки. Наш сервис может лишь предложить некоторые дополнительные функции, например автоматическое составление платёжного документа.`}</Paragraph>
          <Paragraph>{t`Площадка не несёт ответственности за исполнение сторонами обязательств сделки`}</Paragraph>
        </>
      ),
      style: panelStyle,
    },
    {
      key: nanoid(),
      label: <Title level={5}>{t`Дополнительный функционал`}</Title>,
      children: (
        <>
          <Paragraph>
            <Text>{t`На текущий момент дополнительный функционал платформы входит:`}</Text>
            <ul>
              <li>{t`менеджмент таблиц товаров`}</li>
              <li>{t`менеджмент ролей и разрешений сотрудников`}</li>
              <li>{t`менеджмент адресов и заводов`}</li>
              <li>{t`информация о фирмах-партнёрах`}</li>
            </ul>
          </Paragraph>
          <Paragraph>
            <Text>{t`В скором времени мы добавим:`}</Text>
            <ul>
              <li>{t`автоматическое формирование платёжного и иных документов для сделки`}</li>
              <li>{t`продвинутый анализ совершённых сделок`}</li>
              <li>{t`поиск запчастей/оборудования/товара по картинке`}</li>
              <li>{t`возможность указывать используемое фирмой оборудование`}</li>
              <li>{t`сервисная история продаж и система уведомлений для ТО`}</li>
              <li>{t`возможность размещения заявок в формате тендера (для государственных предприятий)`}</li>
              <li>{t`продвинутую категоризацию`}</li>
              <li>{t`новости отраслей`}</li>
            </ul>
          </Paragraph>
          <Paragraph>
            <Text>{t`При большом охвате клиентов в рамках одной отрасли планируется добавить:`}</Text>
            <ul>
              <li>{t`помощь в логистике`}</li>
              <li>{t`страховку сделки`}</li>
              <li>{t`отраслевую и межотраслевую аналитику`}</li>
            </ul>
          </Paragraph>
        </>
      ),
      style: panelStyle,
    },
    {
      key: nanoid(),
      label: <Title level={5}>{t`Верификация`}</Title>,
      children: (
        <Paragraph>{t`В целях безопасности на площадке необходима верификация Пользователей и Компаний путём добавления подтверждающих документов. Функционал неверифицированных Пользователей и Компаний ограничен`}</Paragraph>
      ),
      style: panelStyle,
    },
    {
      key: nanoid(),
      label: <Title level={5}>{t`Цели нашей платформы`}</Title>,
      children: (
        <>
          <Paragraph>{t`Наша платформа преследует цели помочь компаниям из различных отраслей наладить цепи поставок и взаимодействие друг с другом. Для этого нам требуется максимальный охват пользователей в рамках каждой отрасли. Это поможет нам получать как можно более полную информацию о состоянии отраслей и улучшать предоставляемые Вам сервисы.`}</Paragraph>
          <Paragraph>{t`Спасибо, что выбираете наш сервис!`}</Paragraph>
        </>
      ),
      style: panelStyle,
    },
  ]
}
