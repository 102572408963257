import { notification } from 'antd'
import React from 'react'

import { NotificationsProvider } from '~/notifications/context'
import { getDisplayName } from '~/shared/lib/react'

export function withNotifications(Component: React.ComponentType) {
  function ComponentsWithNotifications() {
    const [api, contextHolder] = notification.useNotification()

    return (
      <NotificationsProvider value={{ open: api.open, destroy: api.destroy }}>
        {contextHolder}
        <Component />
      </NotificationsProvider>
    )
  }

  ComponentsWithNotifications.displayName = `withNotifications(${getDisplayName(
    Component,
  )})`
  return ComponentsWithNotifications
}
