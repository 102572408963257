import { Tab } from '@headlessui/react'
import clsx from 'clsx'
import type { ReactNode } from 'react'

interface Props {
  tabs: ReactNode[]
  panels: ReactNode[]
}

export default function TabGroup({ tabs, panels }: Props) {
  return (
    <Tab.Group>
      <Tab.List>
        {tabs.map((tab, index) => {
          return (
            <Tab
              className={({ selected }) => {
                return clsx(
                  'w-1/2 border py-3 text-md font-medium',
                  'first-of-type:rounded-l-[100px] last-of-type:rounded-r-[100px]',
                  /* Нужно для избавления от дефолтного фокуса у кнопок, при отрытии он становится сразу виден, даже если нажали мышкой */
                  'focus-visible:border-blue focus-visible:ring-0',
                  {
                    'border-gray-border-1 text-gray-text-1': !selected,
                    'border-blue bg-blue/10 text-blue': selected,
                  },
                )
              }}

              key={index}
            >
              {tab}
            </Tab>
          )
        })}
      </Tab.List>

      <Tab.Panels>
        {panels.map((panel, index) => {
          return (
            <Tab.Panel unmount={false} key={index}>
              {panel}
            </Tab.Panel>
          )
        })}
      </Tab.Panels>
    </Tab.Group>
  )
}
