import { Trans, t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { useQueryClient } from '@tanstack/react-query'
import { Alert, Button } from 'antd'
import { useNavigate } from 'react-router-dom'

import { useAcceptInvite } from '../hooks/use-accept-invite'
import { useInvitedCompanyInfo } from '../hooks/use-invited-company-info'

import { userKeys } from '~/shared/tanstack/keys/user'

interface Props {
  inviteToken: string
}

export default function Form({ inviteToken }: Props) {
  useLingui()
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const { data: company } = useInvitedCompanyInfo(inviteToken)
  const { mutate: acceptInvite, status: acceptInviteStatus, error: acceptInviteError } = useAcceptInvite()

  function handleAccept() {
    acceptInvite(inviteToken, {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: userKeys.all })
        navigate('/dashboard', { replace: true })
      },
    })
  }

  return (
    <div className="grid gap-4">
      {Boolean(acceptInviteError)
      && (
        <Alert
          type="error"
          message={t`Ошибка при принятии приглашения в компанию. Пожалуйста, проверьте пригласительную ссылку и попробуйте снова.`}
          showIcon
        />
      )}

      <div>
        <p>
          <Trans>
            Вас пригласил сотрудник компании{' '}
            <b>{company?.name}</b>.
          </Trans>
        </p>
        <p>
          <Trans>
            Нажмите кнопку{' '}
            <b>Принять приглашение</b>
            , чтобы присоединиться.
          </Trans>
        </p>
      </div>

      <div className="flex justify-center">
        <Button type="primary" size="large" onClick={handleAccept} loading={acceptInviteStatus === 'loading'}>
          {t`Принять приглашение`}
        </Button>
      </div>
    </div>
  )
}
