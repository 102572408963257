import { t } from '@lingui/macro'
import type { ModalFuncProps } from 'antd'
import { App } from 'antd'

export function useConfirmations() {
  const { modal } = App.useApp()

  function unsavedFields(options?: ModalFuncProps) {
    modal.confirm({
      title: t`Внимание!`,
      content: t`Вы пытаетесь закрыть форму с несохраненными изменениями. Ваши данные могут быть утеряны. Вы уверены, что хотите продолжить без сохранения?`,
      centered: true,
      ...options,
    })
  }

  return { unsavedFields }
}
