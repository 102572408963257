import type { UseQueryOptions } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'

import type { AxiosError } from 'axios'
import { get } from '../fetchers'
import type { components } from '../api'
import { companyKeys } from './keys'
import { getCompanyToken } from '~/api/user-api'
import { LOCAL_STORAGE_KEYS } from '~/lib/constants'

export interface CompanyDetailVariables {
  activeCompanyId?: number
}

interface CompanyDetailResponse {
  edit: boolean
  info: components['schemas']['CompanyMainInformation']
  is_member: boolean
  permissions: string[]
  role: string
}

export async function getCompanyDetail({ activeCompanyId }: CompanyDetailVariables, signal?: AbortSignal) {
  if (!activeCompanyId)
    throw new Error('activeCompanyId is required')

  const activeCompanyToken = localStorage.getItem(LOCAL_STORAGE_KEYS.ACTIVE_COMPANY_TOKEN)

  if (!activeCompanyToken) {
    const { data: newCompanyToken } = await getCompanyToken(Number(activeCompanyId))
    localStorage.setItem(LOCAL_STORAGE_KEYS.ACTIVE_COMPANY_TOKEN, newCompanyToken.Token)
  }

  if (!activeCompanyToken)
    throw new Error('activeCompanyToken is required')

  // FIXME(company tokens)
  const headers = new Headers()
  headers.set('Authorization', `Token ${activeCompanyToken}`)

  // FIXME(swagger) опциональный id компании
  const { data, error } = await get(`/organizations/company/get/`, {
    headers,
    signal,
  })

  if (error)
    throw error

  const tempData = data as unknown as CompanyDetailResponse

  return {
    ...tempData.info ?? {},
    permissions: tempData.permissions,
    role: tempData.role,
  }
}

export type CompanyDetailData = Awaited<ReturnType<typeof getCompanyDetail>>
export type CompanyDetailError = AxiosError

export function useCompanyDetail<TData = CompanyDetailData>(
  { activeCompanyId }: CompanyDetailVariables,
  { enabled = true, ...options }: UseQueryOptions<CompanyDetailData, CompanyDetailError, TData> = {},
) {
  return useQuery<CompanyDetailData, CompanyDetailError, TData>({
    queryKey: companyKeys.detail(activeCompanyId),
    queryFn: ({ signal }) => getCompanyDetail({ activeCompanyId }, signal),
    enabled: enabled && typeof activeCompanyId !== 'undefined',
    refetchOnWindowFocus: false,
    ...options,
  })
}
