import { Trans } from '@lingui/macro'
import type { TourProps } from 'antd'
import type { ReactNode } from 'react'
import { createRef, useEffect, useState } from 'react'

import useUrlState from '@ahooksjs/use-url-state'
import { WelcomeTourProvider } from './context'
import { createTourStep } from './utils'

interface Props {
  children: ReactNode
}

const companyManagementRef = createRef<HTMLDivElement>()
const requestsRef = createRef<HTMLDivElement>()
const catalogRef = createRef<HTMLDivElement>()
const companySwitchRef = createRef<HTMLDivElement>()
const productsSearchRef = createRef<HTMLDivElement>()
const createRequestRef = createRef<HTMLDivElement>()

const steps: TourProps['steps'] = [
  createTourStep({
    title: <Trans>Добро пожаловать в Supply Director!</Trans>,
    description: (
      <Trans>
        Желаете ли Вы пройти краткое обучение по работе с платформой? Это займёт не более
        2 минут.
      </Trans>
    ),
  }),
  // createTourStep({
  //   title: <Trans>Здесь вы можете искать товары, размещённые на площадке</Trans>,
  //   target: () => productsSearchRef.current!,
  // }),
  createTourStep({
    title: <Trans>Здесь вы можете переключаться между вашими компаниями</Trans>,
    target: () => companySwitchRef.current!,
  }),
  createTourStep({
    title: (
      <Trans>
        Управляйте своей компанией, добавляйте информацию, товары и сотрудников
      </Trans>
    ),
    target: () => companyManagementRef.current!,
  }),
  createTourStep({
    title: <Trans>Просматривайте каталог товаров по различным категориям</Trans>,
    target: () => catalogRef.current!,
  }),
  createTourStep({
    title: (
      <Trans>
        Создавайте персональные запросы на оборудование/запчасти, чтобы мы могли
        подготовить для Вас список наилучших предложений от различных поставщиков,
        согласно Вашим техническим требованиям
      </Trans>
    ),
    target: () => createRequestRef.current!,
  }),
  createTourStep({
    title: (
      <Trans>
        Работайте с входящими и исходящими запросами на оборудование и другие товары
      </Trans>
    ),
    target: () => requestsRef.current!,
    nextButtonProps: { children: <Trans>Приступить к работе</Trans> },
  }),
]

export default function WelcomeTour({ children }: Props) {
  const [urlState, setUrlState] = useUrlState<{ new_user: any }>()

  const showWelcomeTour = urlState.new_user === null // null значение присваивается когда параметр есть, но без значение напр. /foo?bar=
  const [open, setOpen] = useState(showWelcomeTour)

  useEffect(() => {
    setOpen(showWelcomeTour)
  }, [showWelcomeTour])

  function closeTour() {
    setUrlState({ new_user: undefined })
  }

  return (
    <WelcomeTourProvider
      value={{
        steps,
        open,
        close: closeTour,
        catalogRef,
        companySwitchRef,
        companyManagementRef,
        createRequestRef,
        requestsRef,
        productsSearchRef,
      }}
    >
      {children}
    </WelcomeTourProvider>
  )
}
