import { t } from '@lingui/macro'
import { Alert, App, Button, Form, Input, Select } from 'antd'
import { useMemo, useState } from 'react'

import CompanyDetailsSearchResult from './CompanyDetailsSearchResult'
import CompanyTypeSelect from './CompanyTypeSelect'
import { useDebouncedState } from '~/core/hooks/use-debaunced-state'
import type { InnCompany } from '~/entities/company'
import type { Okved } from '~/shared/types/company'
import { useCreateCompanyMutation } from '~/data/company/company-create-mutation'
import { useSearchOkvedQuery } from '~/data/company/search-okved-query'
import { useSearchCompanyQuery } from '~/data/company/search-company-query'
import type { CompanyTypesEnum } from '~/enums/company-enums'

const { TextArea } = Input

interface Props {
  onCreate: () => void
  onCreateFailed?: () => void
}

interface FormState {
  inn: string
  okved: string
  website: string
  company_type: CompanyTypesEnum
  legal_address: string
}

export default function CompanySearchForm({ onCreate, onCreateFailed }: Props) {
  const [form] = Form.useForm<FormState>()
  const { message } = App.useApp()

  const [selectedCompany, setSelectedCompany] = useState<Nullable<InnCompany>>(null)
  const [selectedOkved, setSelectedOkved] = useState<Nullable<Okved>>(null)

  const [okvedQuery, , setOkvedQuery] = useDebouncedState('')
  const [innQuery, , setInnQuery] = useDebouncedState('')

  const { mutate: createCompany, isLoading: isCreatingCompany } = useCreateCompanyMutation({
    onSuccess: onCreate,
    onError: onCreateFailed,
  })
  const { data: searchedCompanies, isFetching: isSearchingCompany } = useSearchCompanyQuery({
    inn: innQuery,
  })
  const { data: searchedOkveds, isFetching: isSearchingOkveds } = useSearchOkvedQuery(
    { query: okvedQuery },
    {
      onSettled: (data) => {
        if (selectedCompany === null)
          return

        // Обновить информацию о выбранном ОКВЕД-е и добавить код ОКВЕД-а в инпут
        const okvedEntry = data?.results.find(okved => okved.code === okvedQuery)
        if (okvedEntry) {
          setSelectedOkved(okvedEntry)
          form.setFieldValue('okved', okvedEntry.code)
        }
      },
    },
  )

  const companiesOptions = useMemo(() => searchedCompanies?.map(company => ({ ...company, label: company.name, value: company.inn })) ?? [], [searchedCompanies])
  const okvedsOptions = useMemo(() => searchedOkveds?.results.map(okved => ({ ...okved, label: `${okved.code} - ${okved.name}`, value: okved.code })) ?? [], [searchedOkveds])

  function handleSelectCompany(company: InnCompany) {
    setSelectedCompany(company)

    if (company.okved)
      setOkvedQuery(company.okved)

    form.setFieldValue('legal_address', company.address)
  }

  function handleFinish(values: FormState) {
    if (selectedCompany === null && selectedOkved === null) {
      message.error(t`Ошибка! Невозможно отправить форму`)
      return
    }

    createCompany(
      {
        ...values,
        name: selectedCompany!.name ?? '',
        okved: [selectedOkved!.id!],
      },
    )
  }

  return (
    <Form
      form={form}
      name="company-search"
      layout="vertical"

      disabled={isCreatingCompany}
      onFinish={handleFinish}
    >
      <Alert message={t`Доступно только организациям из РФ`} type="info" style={{ marginBottom: 12 }} showIcon />

      <Form.Item<FormState>
        name="inn"
        label={t`ИНН`}
        tooltip={t`Мы автоматически заполним информацию о вашей компании из базы`}
        rules={[{ required: true, message: t`Обязательное поле` }]}
        hasFeedback
      >
        <Select
          placeholder={t`Поиск ИНН`}
          filterOption={false}
          onSearch={setInnQuery}
          notFoundContent={null}
          loading={isSearchingCompany}
          options={companiesOptions}
          onSelect={(_value, option) => handleSelectCompany(option)}
          optionLabelProp="inn"
          showSearch
        />
      </Form.Item>

      <Form.Item<FormState>
        name="okved"
        label={t`ОКВЭД (Сфера деятельности)`}
        tooltip={t`Заполняется автоматически если ОКВЭД указан в базе`}
        rules={[{ required: true }]}
        hasFeedback
      >
        <Select
          placeholder={t`Поиск ОКВЭДа (Сфера деятельности)`}
          filterOption={false}
          onSearch={setOkvedQuery}
          notFoundContent={null}
          loading={isSearchingOkveds}
          options={okvedsOptions}
          onSelect={(_value, option) => setSelectedOkved(option)}
          showSearch
        />
      </Form.Item>

      <Form.Item<FormState>
        name="website"
        label={t`Сайт`}
        rules={[{ type: 'url', message: t`Неверный формат URL` }]}
      >
        <Input placeholder="https://example.com" />
      </Form.Item>

      <Form.Item<FormState>
        name="company_type"
        label={t`Что вы хотите делать на платформе`}
        rules={[{ required: true, message: t`Обязательное поле` }]}
        hasFeedback
      >
        <CompanyTypeSelect />
      </Form.Item>

      <Form.Item<FormState>
        name="legal_address"
        label={t`Юридический адрес`}
        rules={[{ required: true, message: t`Обязательное поле` }]}
      >
        <TextArea
          autoSize={{ minRows: 3, maxRows: 6 }}
          maxLength={200}
          placeholder={t`Например Россия, Москва, 117312, ул. Вавилова, д. 19 `}
          showCount
        />
      </Form.Item>

      {selectedCompany && <CompanyDetailsSearchResult company={selectedCompany} okved={selectedOkved?.code} />}

      <Form.Item>
        <Button
          size="large"
          htmlType="submit"
          type="primary"
          loading={isCreatingCompany}
          block
        >
          {t`Создать компанию`}
        </Button>
      </Form.Item>
    </Form>
  )
}
