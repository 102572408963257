import { Tag } from 'antd'
import { getStatusColor, getStatusName } from './utils'
import type { CreatedOrderStatusEnum } from '~/domain/order/enums'

interface Props {
  status: CreatedOrderStatusEnum
}

export default function CreatedOrderStatusTag({ status }: Props) {
  const name = getStatusName(status)
  const color = getStatusColor(status)

  return <Tag color={color}>{name}</Tag>
}
