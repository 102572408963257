import { Collapse, theme } from 'antd'
import type { CSSProperties } from 'react'
import { CaretRightOutlined } from '@ant-design/icons'
import { useLingui } from '@lingui/react'
import { getItems } from './utils'

export default function FaqList() {
  useLingui()
  const { token } = theme.useToken()

  const panelStyle: CSSProperties = {
    background: token.colorWhite,
    borderRadius: token.borderRadiusSM,
    border: 'none',
  }

  return (
    <Collapse
      bordered={false}
      expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
      style={{ background: token.colorWhite }}
      items={getItems(panelStyle)}
    />
  )
}
