import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import clsx from 'clsx'
import type {
  ClearIndicatorProps,
  DropdownIndicatorProps,
  MultiValueProps,
  MultiValueRemoveProps,
  OptionProps,
} from 'react-select'
import Select, { components } from 'react-select'

import type { CategoryOption } from '../interface'

import { useDebouncedState } from '~/core/hooks/use-debaunced-state'
import { Icon } from '~/shared/components/icon'
import { cn } from '~/shared/lib/react'
import { useSearchProductCategories } from '~/shared/tanstack/queries/use-search-product-categories'

function ClearIndicator(props: ClearIndicatorProps<CategoryOption, true>) {
  const {
    innerProps: { ref, ...restInnerProps },
  } = props
  return (
    <div {...restInnerProps} ref={ref} className="flex cursor-pointer items-center" title="Очистить всё">
      <Icon name="close" className={clsx('mr-1 text-red transition-transform')} />
    </div>
  )
}

function DropdownIndicator(props: DropdownIndicatorProps<CategoryOption, true>) {
  const { isFocused } = props

  return (
    <components.DropdownIndicator {...props}>
      <Icon
        name="arrow-down"
        className={clsx(
          'cursor-pointer text-black transition-transform hover:text-blue',
          isFocused && 'rotate-180',
        )}
      />
    </components.DropdownIndicator>
  )
}

function MultiValueRemove(props: MultiValueRemoveProps<CategoryOption, true>) {
  return (
    <components.MultiValueRemove {...props}>
      <Icon name="close" className="text-white" size={20} />
    </components.MultiValueRemove>
  )
}

function MultiValue(props: MultiValueProps<CategoryOption, true>) {
  const { data } = props

  return <components.MultiValue {...props}>{data.name}</components.MultiValue>
}

function Option(props: OptionProps<CategoryOption, true>) {
  const { data, isFocused } = props

  return (
    <components.Option
      {...props}
      className={cn(
        'group cursor-default select-none py-2 pl-3 pr-9 text-md hover:bg-blue hover:text-white',
        {
          'bg-blue text-white': isFocused,
        },
      )}
    >
      <p className="flex items-center gap-1">
        <span
          className={cn('truncate font-semibold text-black group-hover:text-white', {
            'text-white': isFocused,
          })}
        >
          {data.id}
        </span>
        <span
          className={cn('truncate text-gray-text-3 group-hover:text-white/80', {
            'text-white/80': isFocused,
          })}
        >
          {data.name}
        </span>
      </p>
    </components.Option>
  )
}

interface Props {
  value: CategoryOption
  onChange: (newValue: readonly CategoryOption[]) => void
  isMulti?: true
}

export default function CategorySelect({ value, onChange, isMulti }: Props) {
  useLingui()

  const [query, , setQuery] = useDebouncedState('')

  const { data: options, isFetching } = useSearchProductCategories({ q: query })

  return (
    <Select
      components={{
        DropdownIndicator,
        ClearIndicator,
        MultiValueRemove,
        Option,
        MultiValue,
      }}
      isLoading={isFetching}
      onInputChange={setQuery}
      options={options?.results}
      noOptionsMessage={() => t`Нет результатов`}
      loadingMessage={() => t`Загрузка...`}
      placeholder={t`Найти категорию`}
      getOptionLabel={({ name }) => name}
      getOptionValue={({ id }) => id}
      maxMenuHeight={200}
      openMenuOnClick={false}
      value={value}
      onChange={onChange}
      classNames={{
        noOptionsMessage: () => 'py-2 text-md',
        loadingMessage: () => 'py-2 text-md',
        menuList: () => 'popover mt-2 overflow-auto',
        input: () => '[&>input]:ring-0',
        multiValue: () => 'bg-blue rounded-md',
        multiValueLabel: () => 'py-1.5 pl-3 pr-1.5 text-white font-medium',
        multiValueRemove: () => 'px-1 hover:bg-blue-light/20 focus:bg-blue-light/20 rounded-r-md',
        placeholder: () => 'text-gray-text-3 text-base',
        control: () => 'bg-white rounded-lg py-2 px-3.5 !min-h-[3.25rem]',
        valueContainer: () => 'flex gap-1.5',
      }}
      isMulti={isMulti}
      unstyled
    />
  )
}
