import { t } from '@lingui/macro'
import { useResponsive } from 'ahooks'

import CompanyDropdown from './company-dropdown'

import { EntityPreview } from '~/components/entity-preview'
import { useGetUser } from '~/shared/tanstack/queries/use-get-user'
import WelcomeTourTarget from '~/welcome-tour/target'
import { useGlobalDialog } from '~/shared/context/global-dialog'
import { useActiveCompanyDetail } from '~/hooks/use-active-company'

export default function CompanyEntity() {
  const responsive = useResponsive()
  const { openNewCompanyDialog } = useGlobalDialog()

  const { status: userStatus, data: user } = useGetUser()
  const { data: company, isFetching: isCompanyFetching, isSuccess: isCompanySuccess } = useActiveCompanyDetail()

  if (userStatus === 'error')
    return null

  if (isCompanyFetching || userStatus === 'loading')
    return <EntityPreview.Skeleton />

  const hasCompany = user!.companies.length > 0

  return (
    <div className="hidden md:block">
      {userStatus === 'success'
        ? (
          <WelcomeTourTarget
            name="companySwitch"
            rootClassName="flex items-center"
            visible={Boolean(responsive.md)}
          >
            <EntityPreview.Root
              onClick={hasCompany ? undefined : openNewCompanyDialog}
              avatarSrc="/empty-company.svg"
              avatarFallback="/empty-company.svg"
              secondaryText={hasCompany ? t`Моя компания` : t`Добавьте или вступите`}
              primaryText={hasCompany && isCompanySuccess ? company.name : t`Нет компании`}
            />

            {hasCompany && (
              <CompanyDropdown
                activeCompany={isCompanySuccess ? { id: company.id!, name: company.name } : null}
                companies={user.companies ?? []}
              />
            )}
          </WelcomeTourTarget>
          )
        : null}
    </div>
  )
}
