import type { ProductsCatalogPayload, ProductsCatalogResponse } from './types'
import { buildApiUrl, paginateOffset } from '~/api/utils'
import type { CategoryOption } from '~/entities/category'
import axios from '~/lib/utils/axios-utils'
import type {
  CustomFieldEntry,
  ListParams,
  PublicDocument,
  PublicImage,
} from '~/shared/api'
import type { SearchedProductsResponse } from '~/shared/types/product'

export interface CompanyProducer {
  /** Название компании */
  name: string
  /** ИНН компании */
  inn: string
}

export interface FactoryProducer {
  /** Компания производитель */
  company_producer: CompanyProducer
  /** Название завода */
  name: string
}

export interface ProductDetails {
  product_link: string | null
  article_number: string | null
  product_series: string | null
  /** Название товара */
  name: string
  /** Цена товара */
  price: number
  /** Описание товара */
  description: string
  /** Характеристики товара */
  extra_fields: CustomFieldEntry[]
  /** ID товара */
  id: number
  condition: number
  images: PublicImage[]
  /** Прикреплённые документы */
  files: PublicDocument[]
  /** Рейтинг товара */
  rating: number
  is_available: number
  /** Завод изготовитель */
  factory_producer: FactoryProducer
  /** ID компании производителя */
  company_id: number
  /** Категория товара */
  category: Nullable<CategoryOption>
}

export interface CatalogCategory {
  [key: string]: CatalogCategory | any
  images: string[]
  code: string
  is_leaf: boolean
  descriptions: object
  names: Indexed<string, string>
}

interface Catalog {
  [key: string]: CatalogCategory
  meta:
    | {
      version: string
    }
    | any
}

const SEARCH_PRODUCTS_PATH = '/api/products/search/products/'
const DATABASE_COUNT_PATH = '/api/products/count/'
const SEARCH_PRODUCTS_BY_CATEGORY_PATH = '/api/products/categories/products/'
const SEARCH_CATEGORIES_PATH = '/api/products/categories/search/'
const PRODUCT_DETAILS_PATH = '/api/products/product/get/'
const PRODUCTS_AMOUNT_BY_CATEGORY = '/api/products/categories/products-amount/'
const CATALOG_PATH = '/api/products/catalog/categories/'

export function getProductsAmountByCategory(categoryIds: string[]) {
  const url = buildApiUrl(PRODUCTS_AMOUNT_BY_CATEGORY)
  const params = { categories: categoryIds.join(',') }
  return axios.get<{ [key: string]: { amount: number } }>(url, { params })
}

export function getCatalog() {
  const url = buildApiUrl(CATALOG_PATH)
  return axios.get<Catalog>(url, { tokenType: null })
}

export function getProductDetails(id: number) {
  const url = buildApiUrl(PRODUCT_DETAILS_PATH)
  return axios.get<ProductDetails>(url, { params: { id } })
}

export function searchProductCategories(query: string) {
  const url = buildApiUrl(SEARCH_CATEGORIES_PATH)
  return axios.get<{ results: CategoryOption[] }>(url, { params: { q: query } })
}

export function getProductsByCategory({ limit, page, q, categoryCode, priceLeft, order, onlyRussia, region }: ProductsCatalogPayload) {
  const url = buildApiUrl(SEARCH_PRODUCTS_BY_CATEGORY_PATH)
  const params = {
    country: onlyRussia ? 'Russia' : undefined,
    limit,
    region,
    offset: paginateOffset(page, limit),
    q,
    category: categoryCode,
    price_left: priceLeft,
    order,
  }
  return axios.get<ProductsCatalogResponse>(url, { params })
}

export function fetchProductsByQuery({ limit, page, q }: ListParams) {
  const url = buildApiUrl(SEARCH_PRODUCTS_PATH)
  const params = { limit, offset: paginateOffset(page, limit), q }
  return axios.get<SearchedProductsResponse>(url, { params })
}

export function getProductsCountInDatabase() {
  const url = buildApiUrl(DATABASE_COUNT_PATH)
  return axios.get<{ result: number }>(url)
}
