import { t } from '@lingui/macro'
import { lazy } from 'react'

import AuthenticationPagesLayout from '~/layouts/authentication'

const SignUpForm = lazy(() => import('~/authentication/forms/sign-up'))

export default function SignUpPage() {
  return (
    <AuthenticationPagesLayout
      title={t`Зарегистрироваться`}
      description={t`Введите данные, чтобы создать учетную запись`}
    >
      <SignUpForm />
    </AuthenticationPagesLayout>
  )
}
