import { CloseOutlined, PlusOutlined } from '@ant-design/icons'
import { t } from '@lingui/macro'
import { useQueryClient } from '@tanstack/react-query'
import { Drawer as AntdDrawer, App, Button, DatePicker, Form, Input, InputNumber, Select, Spin } from 'antd'
import { isAxiosError } from 'axios'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'

import {
  DESCRIPTION_ROWS,
  MAX_AMOUNT,
  MAX_DESCRIPTION_LENGTH,
  MAX_REQUEST_LENGTH,
  MIN_AMOUNT,
} from '../constants'
import { useActivatePersonalOrder } from '../hooks/use-activate-personal-order'
import { useCreatePersonalOrder } from '../hooks/use-create-personal-order'
import { useUpdatePersonalOrder } from '../hooks/use-update-personal-order'
import type { FormState } from '../interface'
import { categoriesToOptions, isOverLimit, prepareOrderData } from '../utils/order-util'

import Files from './files'
import TotalPrice from './total-price'

import { useDebouncedState } from '~/core/hooks/use-debaunced-state'
import { useConfirmations } from '~/hooks/use-confirmations'
import { companyKeys } from '~/shared/tanstack/keys/company'
import { useSearchProductCategories } from '~/shared/tanstack/queries/use-search-product-categories'

interface Props {
  open: boolean
  onClose: () => void
  initialValues?: FormState
  defaultOrderId?: number
  loading?: boolean
  title: string
}

export default function Drawer({ onClose, open, initialValues, defaultOrderId, loading, title }: Props) {
  const { message } = App.useApp()
  const [form] = Form.useForm<FormState>()
  const price = Form.useWatch<number>('preferable_price', form)
  const amount = Form.useWatch<number>('amount', form)

  const confirmModal = useConfirmations()
  const queryClient = useQueryClient()

  const [step, setStep] = useState(1)
  const [orderId, setOrderId] = useState<number | null>(null)
  const [categoryQuery, , setCategoryQuery] = useDebouncedState('')

  const { data: options, isFetching } = useSearchProductCategories({ q: categoryQuery })
  const { mutateAsync: createOrderMutation, status: createOrderStatus } = useCreatePersonalOrder()
  const { mutateAsync: activateOrderMutation, status: activateOrderStatus } = useActivatePersonalOrder()
  const { mutateAsync: updateOrderMutation, status: updateOrderStatus } = useUpdatePersonalOrder()

  const totalPrice = price * amount
  const isOverLimitPrice = isOverLimit(totalPrice)

  useEffect(() => {
    if (initialValues)
      setStep(2)
  }, [])

  useEffect(() => {
    if (defaultOrderId)
      setOrderId(defaultOrderId)
  }, [])

  function invalidateQueries() {
    if (!orderId)
      return

    queryClient.invalidateQueries({ queryKey: companyKeys.createdOrders({}) })
    queryClient.invalidateQueries({ queryKey: companyKeys.createdOrder(orderId) })
  }

  function closeWithReset() {
    form.resetFields()
    setStep(1)
    onClose()
  }

  async function createOrder() {
    const values = await form.validateFields()
    if (!values.request)
      return

    await createOrderMutation(values.request, {
      onSuccess: ({ product_request_id }) => {
        setOrderId(product_request_id)
        setStep(2)
      },
      onError: () => {
        message.error(t`Ошибка при создании запроса`)
      },
    })
  }

  async function saveOrder() {
    const values = await form.validateFields()

    if (!orderId) {
      message.error(t`Ошибка при сохранении запроса`)
      return
    }

    const updatedOrder = prepareOrderData(orderId, values)
    if (isOverLimitPrice)
      return

    await updateOrderMutation(updatedOrder, {
      onSuccess: () => {
        message.success(
          t`Запрос на товар успешно создан и сохранён. Активируйте его по вашему желанию в удобное время.`,
        )

        invalidateQueries()
        closeWithReset()
      },
      onError: (error) => {
        if (isAxiosError(error))
          message.error(error.response?.data?.detail ?? t`Неизвестная ошибка`)
      },
    })
  }

  async function saveAndActiveOrder() {
    if (!orderId)
      return

    const values = await form.validateFields()

    const preparedOrder = prepareOrderData(orderId, values)
    if (isOverLimitPrice)
      return

    const updatedOrder = await updateOrderMutation(preparedOrder)
    await activateOrderMutation(updatedOrder.id, {
      onSuccess: () => {
        message.success(t`Персональный запрос на товар успешно отправлен! Ожидайте ответа от продавца.`)
        invalidateQueries()
      },
      onError: () => {
        message.error(
          t`Извините, не удалось отправить ваш запрос. Пожалуйста, попробуйте ещё раз или проверьте подключение к интернету.`,
        )
      },
    })

    closeWithReset()
  }

  function handleClose() {
    const fieldsHasValues = Object.values(form.getFieldsValue()).filter(Boolean).length > 0
    if (fieldsHasValues) {
      confirmModal.unsavedFields({ onOk: closeWithReset })
      return
    }

    closeWithReset()
  }

  const formIsLoading = createOrderStatus === 'loading' || updateOrderStatus === 'loading' || activateOrderStatus === 'loading'

  return (
    <AntdDrawer
      width={700}
      onClose={handleClose}
      open={open}
      title={title}
      extra={(
        <>
          {step === 1 && (
            <Button onClick={createOrder} type="primary" loading={formIsLoading}>
              {t`Далее`}
            </Button>
          )}

          {step !== 1 && (
            <div className="grid gap-2 sm:grid-cols-[auto,auto]">
              <Button onClick={saveOrder} loading={formIsLoading}>{t`Сохранить`}</Button>
              <Button onClick={saveAndActiveOrder} type="primary" loading={formIsLoading}>
                {t`Отправить запрос`}
              </Button>
            </div>
          )}
        </>
      )}
      destroyOnClose
    >
      <Spin spinning={formIsLoading || loading}>
        {step !== 1 && <TotalPrice error={isOverLimitPrice} price={totalPrice || 0} />}

        <Form
          layout="vertical"
          form={form}
          disabled={createOrderStatus === 'loading'}
          initialValues={{
            ...initialValues,
            preferable_time: initialValues?.preferable_time
              ? dayjs(initialValues.preferable_time)
              : undefined,
          }}
        >
          <Form.Item<FormState>
            name="request"
            label={t`Краткое название запроса`}
            rules={[{ required: true }]}
            hasFeedback
          >
            <Input maxLength={MAX_REQUEST_LENGTH} />
          </Form.Item>

          {step === 2
            ? (
              <>
                <div className="grid gap-x-3 md:grid-cols-2">
                  <Form.Item<FormState> name="category" label={t`Категория`}>
                    <Select
                      placeholder={t`Поиск категорий`}
                      filterOption={false}
                      onSearch={setCategoryQuery}
                      notFoundContent={null}
                      loading={isFetching}
                      options={categoriesToOptions(options?.results ?? [])}
                      showSearch
                    />
                  </Form.Item>

                  <Form.Item<FormState>
                    name="preferable_price"
                    label={t`Цена за 1шт`}
                    rules={[
                      { type: 'number', max: MAX_AMOUNT, message: t`Максимальная цена ${MAX_AMOUNT}` },
                      { type: 'number', min: MIN_AMOUNT },
                    ]}
                    hasFeedback
                  >
                    <InputNumber
                      formatter={value => `₽ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                      parser={value => value!.replace(/₽\s?|( *)/g, '')}
                      style={{ width: '100%' }}
                    />
                  </Form.Item>

                  <Form.Item<FormState>
                    name="amount"
                    label={t`Желаемое кол-во`}
                    rules={[{ required: true }]}
                    hasFeedback
                  >
                    <InputNumber style={{ width: '100%' }} min={MIN_AMOUNT} max={MAX_AMOUNT} />
                  </Form.Item>

                  <Form.Item<FormState>
                    name="preferable_time"
                    label={t`Дата получения`}
                    rules={[{ required: true, message: t`Пожалуйста, выберите дату` }, { type: 'date' }]}
                  >
                    <DatePicker
                      style={{ width: '100%' }}
                      disabledDate={d => !d || (d.isBefore(Date.now()) && !d.isSame(Date.now(), 'day'))}
                    />
                  </Form.Item>

                  <Form.Item<FormState>
                    name="address"
                    label={t`Адрес доставки`}
                    rules={[{ required: true }]}
                    hasFeedback
                  >
                    <Input />
                  </Form.Item>
                </div>

                <Form.Item label={t`Характеристики`}>
                  <Form.List name="extra_fields">
                    {(fields, { add, remove }) => {
                      return (
                        <div className="flex flex-col">
                          {fields.map((field) => {
                            return (
                              <div className="grid grid-cols-[1fr,1fr,auto] items-start gap-2" key={field.key}>
                                <Form.Item name={[field.name, 'name']} rules={[{ required: true }]}>
                                  <Input placeholder={t`Название`} />
                                </Form.Item>
                                <Form.Item name={[field.name, 'value']} rules={[{ required: true }]}>
                                  <Input placeholder={t`Значение`} />
                                </Form.Item>

                                <Button
                                  type="link"
                                  onClick={() => remove(field.name)}
                                  icon={<CloseOutlined />}
                                  danger
                                />
                              </div>
                            )
                          })}

                          <Button type="dashed" onClick={() => add()} icon={<PlusOutlined />}>
                            {t`Добавить поле характеристик`}
                          </Button>
                        </div>
                      )
                    }}
                  </Form.List>
                </Form.Item>

                <Form.Item<FormState>
                  name="description"
                  label={t`Подробное описание товара`}
                  rules={[{ required: true }]}
                  hasFeedback
                >
                  <Input.TextArea rows={DESCRIPTION_ROWS} maxLength={MAX_DESCRIPTION_LENGTH} />
                </Form.Item>
              </>
              )
            : null}

          {orderId && <Files orderId={orderId} />}
        </Form>
      </Spin>
    </AntdDrawer>
  )
}
