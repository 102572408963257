import { t } from '@lingui/macro'
import { useLocalStorageState } from 'ahooks'
import { Button, Result, Spin } from 'antd'
import { Outlet } from 'react-router-dom'
import { useCompanyDetail } from '~/data/company/company-detail-query'
import { useUserQuery } from '~/data/user/user-query'
import { LOCAL_STORAGE_KEYS } from '~/lib/constants'

import { Icon } from '~/shared/components/icon'
import { useGlobalDialog } from '~/shared/context/global-dialog'

export default function LayoutCompanyRequire() {
  const { openNewCompanyDialog } = useGlobalDialog()
  const [activeCompanyId] = useLocalStorageState<number | undefined>(LOCAL_STORAGE_KEYS.ACTIVE_COMPANY_ID)

  const { data: user, isLoading: isLoadingUser } = useUserQuery()
  const { data: company, isFetching: isLoadingCompany } = useCompanyDetail({ activeCompanyId })

  if (isLoadingUser || isLoadingCompany)
    return <Spin />

  const hasCompany = user!.companies.length > 0 && typeof company !== 'undefined'

  if (!hasCompany) {
    return (
      <Result
        icon={<Icon name="cry" width={75} height={75} />}
        title={t`У Вас ещё нет компании`}
        subTitle={t`Чтобы просматривать сотрудников зарегистрируйте компанию`}
        extra={(
          <Button type="primary" onClick={openNewCompanyDialog}>
            {t`Создать компанию`}
          </Button>
        )}
      />
    )
  }

  return <Outlet />
}
