import { useQuery } from '@tanstack/react-query'
import { getProductDetails } from '~/domain/product/api'

import { productsKeys } from '~/shared/tanstack/keys/products'

interface Params {
  id: number
}

export function useProductDetails({ id }: Params) {
  return useQuery({
    queryKey: productsKeys.details(id),
    queryFn: async () => {
      const { data } = await getProductDetails(id)
      return data
    },
  })
}
