import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { Alert, Button, Form, Input, Spin } from 'antd'

import { useSendPhoneCode } from '../hooks/use-send-phone-code'

import { BEFORE_RESEND_PHONE_CODE_STORAGE_KEY } from './constants'
import { useVerifyPhoneCode } from './hooks/use-verify-phone-code'
import type { FormState } from './types'

import ResendCodeButton from '~/components/resend-code-button'

interface Props {
  onFinish: () => void
  visiblePhone: string
}

export default function VerifyPhoneForm({ onFinish, visiblePhone }: Props) {
  useLingui()
  const [form] = Form.useForm<FormState>()

  const { mutate: verifyCode, status: verifyCodeStatus } = useVerifyPhoneCode()
  const { mutate: sendCode, status: sendCodeStatus } = useSendPhoneCode()

  function handleFinish({ code }: FormState) {
    verifyCode(
      { code, phone: visiblePhone },
      {
        onSuccess: () => {
          localStorage.removeItem(BEFORE_RESEND_PHONE_CODE_STORAGE_KEY)
          onFinish()
        },
      },
    )
  }

  const isLoading = verifyCodeStatus === 'loading' || sendCodeStatus === 'loading'

  return (
    <Spin spinning={isLoading}>
      {verifyCodeStatus === 'error' && (
        <Alert
          type="error"
          message={t`Код авторизации не верен или его срок действия истёк`}
          style={{ marginBottom: 16 }}
          showIcon
        />
      )}

      <div className="mb-3 text-md text-gray-text-3">{t`Номер получателя: +${visiblePhone}`}</div>

      <Form layout="vertical" form={form} onFinish={handleFinish}>
        <Form.Item name="code" label={t`Код для продолжения`} rules={[{ required: true }]}>
          <Input maxLength={6} />
        </Form.Item>

        <div className="mb-4">
          <ResendCodeButton
            onResend={() => sendCode(visiblePhone)}
            storageKey={BEFORE_RESEND_PHONE_CODE_STORAGE_KEY}
          />
        </div>

        <Button htmlType="submit" type="primary" block>{t`Подтвердить`}</Button>
      </Form>
    </Spin>
  )
}
