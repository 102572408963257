import { msg } from '@lingui/macro'

/**
 * @deprecated Вместо этого используйте `PRODUCT_CONDITION`
 */
export const ProductCondition = {
  NEW: 0,
  USED: 1,
} as const

export enum PRODUCT_CONDITION {
  new = 0,
  used = 1,
}

/**
 * @deprecated Вместо этого используйте `PRODUCT_CONDITION_NAME`
 */
export const mapToProductConditionTKey = {
  [ProductCondition.NEW]: msg`Новый`,
  [ProductCondition.USED]: msg`Б/у`,
} as const

export const PRODUCT_CONDITION_NAME = {
  [PRODUCT_CONDITION.new]: msg`Новый`,
  [PRODUCT_CONDITION.used]: msg`Б/у`,
}
