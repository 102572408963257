import clsx from 'clsx'
import React from 'react'
import type { ValueContainerProps } from 'react-select'
import { components } from 'react-select'

const { ValueContainer, Placeholder } = components

export default function SelectValueContainer<T>(props: ValueContainerProps<T, false>) {
  const { hasValue, children, selectProps } = props

  return (
    <ValueContainer
      {...props}
      className="text-base text-black !static !overflow-visible !pb-1.5 !pl-3.5 !pt-6"
    >
      {/* @ts-expect-error ... */}
      <Placeholder
        {...props}
        isFocused={hasValue}
        className={clsx(
          'pointer-events-none absolute truncate text-gray-text-3 transition-all',
          {
            'left-3.5 top-4 text-base': !hasValue,
            'left-3.5 top-1 text-sm': hasValue,
          },
        )}
      >
        {selectProps.placeholder}
      </Placeholder>

      {React.Children.map(children, child =>
        React.isValidElement(child) && child.type !== Placeholder ? child : null)}
    </ValueContainer>
  )
}
