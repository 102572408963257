import createClient from 'openapi-fetch'
import type { paths } from './api'
import { LOCAL_STORAGE_KEYS } from '~/lib/constants'

const DEFAULT_HEADERS = {
  'Content-Type': 'application/json',
  'Accept': 'application/json',
}

const baseUrl = `${__API_DOMAIN__}/api`

const { get: _get, post: _post } = createClient<paths>({
  referrerPolicy: 'no-referrer-when-downgrade',
  headers: DEFAULT_HEADERS,
  baseUrl,
})

export function constructHeaders(headersInit?: HeadersInit) {
  // const requestId = uuidv4()
  const headers = new Headers(headersInit)

  // headers.set('X-Request-Id', requestId)

  if (!headers.has('Authorization')) {
    const accessToken = localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN)
    if (accessToken)
      headers.set('Authorization', `Token ${accessToken}`)
  }

  return headers
}

export const get: typeof _get = (url, init) => {
  const headers = constructHeaders(init?.headers)
  return _get(url, { ...init, headers })
}

export const post: typeof _post = (url, init) => {
  const headers = constructHeaders(init?.headers)
  return _post(url, { ...init, headers })
}
