import { t } from '@lingui/macro'
import { Radio } from 'antd'
import { useState } from 'react'
import CompanyCreatePolicyLink from './CompanyCreatePolicyLink'
import CompanySearhForm from './CompanySearchForm'
import CompanyAddManualForm from './CompanyAddManualForm'

interface Props {
  onCreate: () => void
  onCreateFailed?: () => void
}

export default function CompanyCreateEntry({ onCreate }: Props) {
  const [creationMethod, setCreationMethod] = useState<'addManual' | 'search'>('search')

  return (
    <>
      <Radio.Group
        onChange={e => setCreationMethod(e.target.value)}
        value={creationMethod}
        optionType="button"
        style={{ marginBottom: 16 }}
        options={[
          { label: t`Найти по ИНН`, value: 'search' },
          { label: t`Добавить вручную`, value: 'addManual' },
        ]}
      />

      <div style={{ display: creationMethod === 'search' ? 'block' : 'none' }}>
        <CompanySearhForm onCreate={onCreate} />
      </div>
      <div style={{ display: creationMethod === 'addManual' ? 'block' : 'none' }}>
        <CompanyAddManualForm onAdd={onCreate} />
      </div>

      <CompanyCreatePolicyLink />
    </>
  )
}
