import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { useQueryClient } from '@tanstack/react-query'
import { isAxiosError } from 'axios'
import { useNavigate } from 'react-router-dom'

import useDeleteCompanyProduct from '~/pages/company/hooks/use-delete-company-product'
import useUpdateCompanyProductStatus from '~/pages/company/hooks/use-update-company-product-status'
import TableActions from '~/shared/components/table-actions'
import { AVAILABLE_STATUS } from '~/shared/constants'
import { showMessage } from '~/shared/show-message'
import { companyKeys } from '~/shared/tanstack/keys/company'

interface Props {
  isAvailable: boolean
  productId: number
}

export default function TableActionMenu({ isAvailable, productId }: Props) {
  useLingui()

  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const { mutate: deleteProduct } = useDeleteCompanyProduct()
  const { mutate: updateStatus } = useUpdateCompanyProductStatus()

  function updateProduct(availablesValue: AVAILABLE_STATUS) {
    updateStatus(
      { id: productId, availablesValue },
      {
        onSuccess: async () => {
          queryClient.invalidateQueries({ queryKey: companyKeys.products({}) })

          if (availablesValue === AVAILABLE_STATUS.active)
            showMessage(t`Товар теперь виден`, 'success')
          else
            showMessage(t`Товар скрыт`, 'success')
        },
        onError: (error) => {
          if (isAxiosError(error))
            showMessage(error?.response?.data?.detail ?? t`Произошла ошибка. Попробуйте ещё раз`, 'error')
        },
      },
    )
  }

  function hideProduct() {
    updateProduct(AVAILABLE_STATUS.inactive)
  }

  function showProduct() {
    updateProduct(AVAILABLE_STATUS.active)
  }

  return (
    <TableActions items={[
      { key: 'edit', visible: true, handler: () => navigate(`/company/p/${productId}/edit`) },
      { key: 'delete', visible: true, handler: () => deleteProduct(productId) },
      { key: 'hide', visible: isAvailable, handler: hideProduct },
      { key: 'show', visible: !isAvailable, handler: showProduct },
    ]}
    />
  )
}
