import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { Tag } from 'antd'
import type { ReactNode } from 'react'

import { cn } from '~/shared/lib/react'

interface Props {
  completed?: boolean
  title: string
  children: ReactNode
  open?: boolean
}

export default function VerificationStep({ completed, children, title, open = false }: Props) {
  useLingui()

  return (
    <div className="border rounded-md px-4">
      <div className={cn('flex items-center justify-between py-4', open && 'border-b')}>
        <span className="font-bold">{title}</span>

        {completed
          ? (
            <Tag color="success" style={{ margin: '0 0 0 8px' }} icon>
              {t`Завершён`}
            </Tag>
            )
          : null}
      </div>

      {open ? <div className="py-4">{children}</div> : null}
    </div>
  )
}
