import { useQuery } from '@tanstack/react-query'
import { searchProductCategories } from '~/domain/product/api'

import type { ListParams } from '~/shared/api'
import { productsKeys } from '~/shared/tanstack/keys/products'

type Params = Pick<ListParams, 'q'>

export function useSearchProductCategories({ q }: Params) {
  return useQuery({
    retry: false,
    enabled: Boolean(q),
    queryKey: productsKeys.searchCategories(q),
    queryFn: async () => {
      const { data } = await searchProductCategories(q)
      return data
    },
  })
}
