import clsx from 'clsx'
import React from 'react'

import type { BaseFieldProps } from '../base-form-control'

type Props = BaseFieldProps & React.ComponentPropsWithoutRef<'textarea'>

const TextArea = React.forwardRef<HTMLTextAreaElement, Props>(
  (
    {
      label,
      placeholder,
      className,
      accent = false,
      variant = 'filled',
      errorMessage,
      required,
      id,
      ...props
    },
    ref,
  ) => {
    const hasPlaceholder = Boolean(placeholder)

    let errorMessageNode: React.ReactNode
    if (errorMessage)
      errorMessageNode = <div className="mt-1 text-sm text-red">{errorMessage}</div>

    return (
      <div className={clsx('relative', className)}>
        <textarea
          {...props}
          id={id}
          ref={ref}
          className={clsx('form-field form-field-no-icon peer min-h-[3.25rem] resize-y', {
            'form-field-accent': accent && variant === 'filled',
            'form-field-accent-off': !accent && variant === 'filled',
          })}
          placeholder={hasPlaceholder ? placeholder : ' '}
        />

        <label className="form-field-label" htmlFor={id}>
          {label}
          {' '}
          {required && <span className="text-red">*</span>}
        </label>

        {errorMessageNode}
      </div>
    )
  },
)

TextArea.displayName = 'TextArea'

export default TextArea
