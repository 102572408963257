import type { ProductsCatalogPayload } from '~/domain/product/types'
import type { ListParams } from '~/shared/api'

export const productsKeys = {
  all: ['products'],
  productsAmount: (ids: string[]) => [...productsKeys.all, 'productsAmount', ids],
  catalog: () => [...productsKeys.all, 'catalog'],
  details: (id: number) => [...productsKeys.all, 'details', id],
  search: (params: ListParams) => [...productsKeys.all, 'search', params],
  searchCategories: (q: string) => [...productsKeys.all, 'searchCategories', q],
  searchByCategory: (params: Partial<ProductsCatalogPayload>) => [...productsKeys.all, 'searchByCategory', params],
  database: () => [...productsKeys.all, 'database'],
}
