import Accordion from '~/core/ui/Accordion'

export default function ProductCardSectionItem({ title, children, collapsed = false, open = false }: any) {
  const renderTitle = <div className="grow py-0.5 text-base font-bold">{title}</div>

  if (collapsed) {
    return (
      <Accordion
        open={open}
        header={renderTitle}
        className="rounded-md px-1 py-0.5 -mx-1 hover:bg-background"
      >
        {children}
      </Accordion>
    )
  }

  return (
    <div>
      <div className="grow py-0.5 text-base font-bold">{title}</div>
      <div className="pt-2">{children}</div>
    </div>
  )
}
