import { i18n } from '@lingui/core'
import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'

import { isNil } from '~/core/utils/common'
import { mapToProductConditionTKey } from '~/lib/products/constants'
import { Icon } from '~/shared/components/icon'
import { dateFormat, priceFormat } from '~/shared/lib/format'

function formatDeliveryDate(date: string) {
  return dateFormat(new Date(date), {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  })
}

export default function ProductCardMetaInfo(props: any) {
  useLingui()

  const {
    email = null,
    price = null,
    rating = null,
    condition = null,
    deliveryDate = null,
    deliveryAddress = null,
    region = null,
    amount = null,
    company = null,
  } = props

  const hasMetaValues = Object.values(props).some(p => !isNil(p))

  // Ничего не рендерить если нету значений
  if (!hasMetaValues)
    return null

  let amountNode
  if (!isNil(amount))
    amountNode = <Ceil name={t`Количество`} value={amount} />

  let ratingNode
  if (!isNil(rating)) {
    ratingNode = (
      <Ceil
        name={t`Рейтинг`}
        value={(
          <div className="flex items-center gap-1">
            <Icon name="star" size={18} />
            <span className="text-lg text-black">{rating}</span>
          </div>
        )}
      />
    )
  }

  let conditionNode
  if (!isNil(condition)) {
    // @ts-expect-error ...
    conditionNode = <Ceil name={t`Состояние`} value={i18n._(mapToProductConditionTKey[condition])} />
  }

  let deliveryDateNode
  if (!isNil(deliveryDate))
    deliveryDateNode = <Ceil name={t`Дата доставки`} value={formatDeliveryDate(deliveryDate as unknown as string)} />

  let deliveryAddressNode
  if (!isNil(deliveryAddress))
    deliveryAddressNode = <Ceil name={t`Адрес доставки`} value={deliveryAddress} />

  let regionNode
  if (!isNil(region))
    regionNode = <Ceil name={t`Регион производства`} value={region} />

  let companyNode
  if (!isNil(company))
    companyNode = <Ceil name={t`Заказчик`} value={company} />

  let emailNode
  if (!isNil(email))
    emailNode = <Ceil name={t`E-mail для связи`} value={email} />

  return (
    <ul className="flex flex-wrap gap-x-12 gap-y-6">
      <Ceil name={t`Цена`} value={price === null ? t`Цена по запросу` : priceFormat(price)} />

      {amountNode}
      {ratingNode}
      {conditionNode}
      {deliveryDateNode}
      {deliveryAddressNode}
      {regionNode}
      {companyNode}
      {emailNode}
    </ul>
  )
}

function Ceil({ name, value }: any) {
  return (
    <div className="flex flex-col gap-2">
      <span className="text-md text-gray-text-3">{name}</span>
      <span className="text-lg text-black font-bold">{value}</span>
    </div>
  )
}
