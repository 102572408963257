import useUrlState from '@ahooksjs/use-url-state'
import { t } from '@lingui/macro'
import { Modal } from 'antd'

import CompanyCreateEntry from './CompanyCreateEntry'

interface Props {
  open: boolean
  onClose: () => void
}

export default function CompanyCreateDialog({ onClose, open }: Props) {
  const [urlState, setUrlState] = useUrlState<{ afterCreate?: string, new_user?: any }>()

  function onCreate() {
    const showWelcomeTour = urlState?.afterCreate === 'new_user'

    setUrlState({ new_user: showWelcomeTour ? null : undefined })
    onClose()
    // window.location.reload() // FXIME: Временно. В дальнейшем нужно пофиксить обновление компании после создания
  }

  return (
    <Modal
      open={open}
      onCancel={onClose}
      title={t`Создать новую компанию`}
      width={400}
      footer={null}
      destroyOnClose
      centered
    >
      <CompanyCreateEntry onCreate={onCreate} />
    </Modal>
  )
}
