import { Icon } from '../../shared/components/icon'

export const SUPPORTED_LOCALE = {
  en: 'en',
  ru: 'ru',
} as const

export const LOCALE_SETTINGS = {
  [SUPPORTED_LOCALE.en]: {
    key: 'en',
    label: 'English',
    icon: <Icon name="eng" size={18} antIcon />,
    disabled: false,
  },
  [SUPPORTED_LOCALE.ru]: {
    key: 'ru',
    label: 'Русский',
    icon: <Icon name="rus" size={18} antIcon />,
    disabled: false,
  },
}
