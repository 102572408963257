import { Tag } from 'antd'
import type { OrderChainStatusEnum } from '../../enums'
import { getStatusColor, getStatusName } from './utils'

interface Props {
  status: OrderChainStatusEnum
}

export default function OrderChainStatusTag({ status }: Props) {
  const name = getStatusName(status)
  const color = getStatusColor(status)

  return <Tag color={color}>{name}</Tag>
}
