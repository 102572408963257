import { CatalogProductsSearch } from '~/widgets/catalog-products-search'
import LandingHero from '~/components/landing/LandingHero'
import LandingHeader from '~/components/landing/LandingHeader'
import LandingStats from '~/components/landing/LandingStats'
import LandingFeatures from '~/components/landing/LandingFeatures'
import LandingInterfacePreview from '~/components/landing/LandingInterfacePreview'
import LandingHowWorks from '~/components/landing/LandingHowWorks'
import LandingOurService from '~/components/landing/LandingOurService'
import LandingPopularCategories from '~/components/landing/LandingPopularCategories'
import LandingHotProducts from '~/components/landing/LandingHotProducts'
import LandingFAQ from '~/components/landing/LandingFAQ'
import LandingEmailSubscribe from '~/components/landing/LandingEmailSubscribe'
import LandingFooter from '~/components/landing/LandingFooter'

export default function LandingPage() {
  return (
    <main className="mx-auto px-3 container">
      <h1 className="sr-only">SupplyDirector | Поставщикам и заказчикам</h1>

      <LandingHeader />
      <LandingHero />
      <CatalogProductsSearch />
      <LandingStats />
      <LandingFeatures />
      <LandingInterfacePreview />
      <LandingHowWorks />
      <LandingOurService />
      <LandingPopularCategories />
      <LandingHotProducts />
      <LandingFAQ />
      <LandingEmailSubscribe />
      <LandingFooter />
    </main>
  )
}
