import { EyeInvisibleOutlined } from '@ant-design/icons'
import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { Tooltip } from 'antd'

export default function HiddenProductIndicator() {
  useLingui()

  return (
    <Tooltip title={t`Этот товар скрыт и не виден пользователям.`}>
      <EyeInvisibleOutlined style={{ fontSize: 16 }} />
    </Tooltip>
  )
}
