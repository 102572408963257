import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { Button } from 'antd'
import { useNavigate } from 'react-router-dom'

import { RoutePathEnum } from '~/enums/route-path-enum'

export default function AuthButton() {
  useLingui()

  const navigate = useNavigate()

  return (
    <Button type="primary" onClick={() => navigate(RoutePathEnum.SIGN_IN)}>
      {t`Вход/Регистрация`}
    </Button>
  )
}
