import type { MenuProps } from 'antd'

import type { SUPPORTED_LOCALE } from './constants'
import { LOCALE_SETTINGS } from './constants'

type LocaleValue = Values<typeof SUPPORTED_LOCALE>

export function isValidLocale(locale: string): locale is LocaleValue {
  return locale === 'en' || locale === 'ru'
}

export function getSelectedMenuItem(locale: LocaleValue) {
  return LOCALE_SETTINGS[locale]
}

export function buildMenuItems(
  onChangeLocale: (locale: string) => void,
): MenuProps['items'] {
  return Object.values(LOCALE_SETTINGS).map(({ disabled, icon, key, label }) => ({
    key,
    label,
    disabled,
    icon,
    onClick: () => onChangeLocale(key),
  }))
}
