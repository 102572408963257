import { t } from '@lingui/macro'

import { DEFAULT_STEP, VERIFICATION_STEP_STORAGE_KEY } from './constants'

import { VerificationMethod } from '~/enums/users-enum'

export function getEmail(email: string) {
  return decodeURIComponent(email)
}

export function hasSavedEmail(email: string) {
  return Boolean(email)
}

export function getFirstStepTitle(verificationMethod: VerificationMethod) {
  return verificationMethod === VerificationMethod.EMAIL
    ? t`Шаг 1: Подтвердите адрес электронной почты`
    : t`Шаг 1: Подтвердите номер телефона`
}

export function getSavedVerificationStep() {
  return (
    (JSON.parse((localStorage.getItem(VERIFICATION_STEP_STORAGE_KEY) as string) ?? '1') as number)
    ?? DEFAULT_STEP
  )
}

export function saveVerificationStep(step: number) {
  localStorage.setItem(VERIFICATION_STEP_STORAGE_KEY, JSON.stringify(step))
}

export function clearVerificationStep() {
  localStorage.removeItem(VERIFICATION_STEP_STORAGE_KEY)
}
