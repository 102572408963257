import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'

import Skeleton from '~/core/ui/Skeleton'
import { numberFormat } from '~/shared/lib/format'
import { useProductsCountInDatabse } from '~/shared/tanstack/queries/use-products-count-in-database'

export default function ProductsCount() {
  useLingui()

  const { data: count, isLoading, isError } = useProductsCountInDatabse()

  if (isLoading)
    return <Skeleton className="h-5 w-96 rounded-md" />

  if (isError)
    return null

  const formattedCount = numberFormat(count)

  return (
    <p className="text-lg">
      {t`${formattedCount} товаров уже доступны в каталоге`}
    </p>
  )
}
