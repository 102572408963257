import { PhoneOutlined, SendOutlined } from '@ant-design/icons'
import { t } from '@lingui/macro'
import { Button, Modal } from 'antd'

import AttachedFilesList from './attached-files-list'
import Ceil from './ceil'

import type { User } from '~/entities/user'
import { concatenateUserName } from '~/entities/user'
import type { PublicDocument } from '~/shared/api'
import { RatingValueIcon } from '~/shared/components/rating-value-icon'
import { dateFormat, priceFormat } from '~/shared/lib/format'
import type { OrderChain } from '~/domain/order/types'
import OrderChainStatusTag from '~/domain/order/components/order-chain-status-tag'

interface ResponsibleUser
  extends Pick<User, 'first_name' | 'last_name' | 'middle_name' | 'email' | 'telephone'> {
  telegram_link: null
}

interface CompanyExecutor {
  id: number
  name: string
  inn: string
  is_active: boolean
  rating: number
}

export interface Chain {
  id: number
  company_executor: CompanyExecutor
  time: Nullable<string>
  price: number
  status: number
  created_at: string
  comment: string
  responsible_user: ResponsibleUser | null
  files: PublicDocument[]
  images: PublicDocument[]
}

interface Props {
  isOpen: boolean
  onClose: () => void
  chain: Nullable<OrderChain>
}

export default function ChainDetailsModal({ chain, onClose, isOpen }: Props) {
  if (!chain)
    return null

  const {
    telephone,
    telegram_link,
    first_name,
    last_name,
    email,
  } = chain?.responsible_user ?? {}

  const userName = concatenateUserName({ first_name, last_name }, '-')
  const attachedFiles = [...chain.files, ...chain.images]

  return (
    <Modal
      title={(
        <div className="flex items-start space-x-3">
          <RatingValueIcon size="medium" value={chain?.company_executor.rating} />
          <p className="break-all">{chain?.company_executor.name}</p>
        </div>
      )}
      open={isOpen}
      onCancel={onClose}
      footer={(
        <>
          {telegram_link && (
            <Button size="large" icon={<SendOutlined />} onClick={() => window.open(telegram_link, '_blank')}>
              {t`Написать`}
            </Button>
          )}

          {telephone && (
            <Button
              size="large"
              icon={<PhoneOutlined />}
              onClick={() => window.open(`tel:${telephone}`, '_blank')}
            >
              {t`Позвонить`}
            </Button>
          )}
        </>
      )}
      centered
    >
      <div className="grid gap5">
        <div className="grid gap5 pb3">
          <Ceil
            title={t`Статус`}
            value={(
              <OrderChainStatusTag status={chain.status} />
            )}
          />

          <Ceil title={t`Сообщение`} value={chain.comment.replace(/\\n/g, '\n') || '-'} />

          <div className="grid-cols-fill-44 grid place-items-start gap5">
            <Ceil title={t`Дата`} value={chain.time ? dateFormat(new Date(chain.time)) : '-'} bold />
            <Ceil title={t`Цена`} value={priceFormat(chain.price)} bold />
            <Ceil title={t`Контактное лицо`} value={userName} bold />
            <Ceil title={t`Email`} value={email ?? '-'} bold />
          </div>

          {attachedFiles.length > 0 && <AttachedFilesList files={attachedFiles} />}
        </div>
      </div>
    </Modal>
  )
}
