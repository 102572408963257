import type { UseQueryOptions } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'

import { get } from '../fetchers'
import { companyKeys } from './keys'

export interface SearchCompanyVariables {
  inn: string
}

interface SearchCompanyResponse {
  name: string
  inn: string
  type: string
  address: string
  kpp: string
  ogrn: string
  okpo: Nullable<string>
  okved: Nullable<string>
  postal_code: string
}

export async function searchCompany({ inn }: SearchCompanyVariables, signal?: AbortSignal) {
  if (!inn)
    throw new Error('inn is required')

  const { data, error } = await get(`/organizations/company/search/inn/`, {
    params: { query: { inn: inn.trim() } },
    signal,
  })
  if (error)
    throw error

  return data as SearchCompanyResponse[]
}

export type SeatchCompanyData = Awaited<ReturnType<typeof searchCompany>>
export type SearchCompanyError = unknown

export function useSearchCompanyQuery<TData = SeatchCompanyData>(
  { inn }: SearchCompanyVariables,
  { enabled = true, ...options }: UseQueryOptions<SeatchCompanyData, SearchCompanyError, TData> = {},
) {
  return useQuery<SeatchCompanyData, SearchCompanyError, TData>({
    queryKey: companyKeys.search(inn),
    queryFn: ({ signal }) => searchCompany({ inn }, signal),
    enabled: enabled && typeof inn !== 'undefined' && inn.trim().length > 0,
    ...options,
  })
}
