import React from 'react'
import type { Control } from 'react-hook-form'
import { Controller } from 'react-hook-form'
import { NumericFormat } from 'react-number-format'

import { TextField } from '~/shared/components/text-field'

type NumberFieldProps = {
  control: Control
  name: string
  thousandSeparator?: string
} & Omit<React.ComponentProps<'input'>, 'type' | 'defaultValue'>

export default function NumberField({ control, name, thousandSeparator = ' ', ...props }: NumberFieldProps) {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, ref, ...field } }) => (
        // @ts-expect-error ...
        <NumericFormat

          {...props}

          {...field}
          getInputRef={ref}
          thousandSeparator={thousandSeparator}
          onValueChange={values => onChange(values?.floatValue ?? null)}
          customInput={TextField}
        />
      )}
    />
  )
}
