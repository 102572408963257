import { useParams } from 'react-router-dom'

import { t } from '@lingui/macro'
import { useProductDetails } from './hooks/use-product-details'
import PageSkeleton from './components/page-skeleton'
import PageError from './components/page-error'
import { USER_TOKEN_STORAGE_KEY } from '~/api/user-api'
import ProductCardMetaInfo from '~/components/products/ProductCardMetaInfo'
import ProductsCard from '~/components/products/ProductsCard'
import CreateOrderButton from '~/products/details/components/create-order-button'
import PageHeader from '~/components/common/PageHeader'
import { RoutePathEnum } from '~/enums/route-path-enum'

export default function ProductDetailsPage() {
  const { id: productId } = useParams<{ id: string }>()

  const productQuery = useProductDetails({ id: Number(productId) })

  if (productQuery.isLoading)
    return <PageSkeleton />

  if (productQuery.isError)
    return <PageError />

  const product = productQuery.data

  return (
    <>
      <PageHeader title={t`Детали товара`} backRoutePath={RoutePathEnum.DASHBOARD} />

      <ProductsCard
        vendorCode={product?.article_number || null}
        series={product?.product_series || null}
        site={product?.product_link || null}
        product={{
          category: product.category?.name ?? null,
          name: product.name,
          description: product.description,
          images: product.images,
          id: product.id,
          extra_fields: product.extra_fields,
          files: product.files,
          condition: product.condition,
        }}
        metaParams={['price', 'rating', 'condition']} // TODO: Изменить condition на region
        meta={<ProductCardMetaInfo price={product.price} rating={product.rating} />}
        extra={localStorage.getItem(USER_TOKEN_STORAGE_KEY) && <CreateOrderButton product={product} />}
      />
    </>
  )
}
