import { Trans, t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { useResponsive } from 'ahooks'

import NavigationItem from './navigation-item'

import WelcomeTourTarget from '~/welcome-tour/target'

export default function PrimaryNavigation() {
  useLingui()

  const responsive = useResponsive()

  return (
    <nav className="mb-8 -mx-4">
      <div className="mb-8">
        <WelcomeTourTarget name="companyManagement" visible={Boolean(responsive.md)}>
          <NavigationItem href="/dashboard" name={t`Моя компания`} icon="home" />
          <NavigationItem href="/company/p" name={t`Товары и услуги`} icon="cart" />
          <NavigationItem href="/partners" name={t`Партнёры`} icon="partner" />
          <NavigationItem href="/company/members" name={t`Сотрудники`} icon="user" />
          <NavigationItem href="/factories" name={t`Заводы и адреса`} icon="company" />
        </WelcomeTourTarget>

        <WelcomeTourTarget name="catalog" visible={Boolean(responsive.md)}>
          <NavigationItem href="/catalog" name={t`Каталог`} icon="search" />
        </WelcomeTourTarget>
      </div>

      <div className="mb-2.5 hidden px-2 text-base text-gray-text-1 xl:block">
        <Trans>Запросы</Trans>
      </div>

      <WelcomeTourTarget name="requests" visible={Boolean(responsive.md)}>
        <div className="space-y-2.5">
          <NavigationItem href="/o/received" name={t`Входящие`} icon="in" />
          <NavigationItem href="/o/created" name={t`Исходящие`} icon="out" />
        </div>
      </WelcomeTourTarget>
    </nav>
  )
}
