import { buildApiUrl, paginateOffset } from './utils'
import type { NotificationStatusEnum } from '~/enums/notification-status-enum'

import axios from '~/lib/utils/axios-utils'
import type { ApiResponseWithPagination, ListParams } from '~/shared/api'

export interface NotificationWithStatus {
  id: number
  status: NotificationStatusEnum
  notification: {
    id: number
    title: string
    text: string
    created_at: string
    description: string
  }

  created_at: string
}

type Notifications = ApiResponseWithPagination<NotificationWithStatus[]>

const NOTIFICATION_DETAILS_PATH = '/api/users/notifications/system/full/'
const NOTIFICATIONS_PATH = '/api/users/notifications/system/list/'
const NOTIFICATION_STATE_PATH = '/api/users/notifications/system/state/'

export function getNotificationDetails(id: number) {
  const url = buildApiUrl(NOTIFICATION_DETAILS_PATH)
  return axios.get<NotificationWithStatus['notification']>(url, { params: { id } })
}

export function getNotifications({ limit, page }: Omit<ListParams, 'q'>) {
  const url = buildApiUrl(NOTIFICATIONS_PATH)
  const params = { limit, offset: paginateOffset(page, limit) }
  return axios.get<Notifications>(url, { params })
}

export function getNotificationsState() {
  const url = buildApiUrl(NOTIFICATION_STATE_PATH)
  return axios.get<{ unread: number }>(url)
}
