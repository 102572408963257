import { t } from '@lingui/macro'
import { Button } from 'antd'
import { useNavigate } from 'react-router-dom'
import { RoutePathEnum } from '~/enums/route-path-enum'
import { useIsLoggedIn } from '~/lib/auth'

export default function LandingSupplierBecomeButton() {
  const navigate = useNavigate()
  const isLoggedIn = useIsLoggedIn()

  function toCompanyProducts() {
    navigate(RoutePathEnum.COMPANY_PRODUCTS)
  }

  function toSignUpWithAddCompany() {
    const redirectLink = `${RoutePathEnum.SIGN_UP}/?returnTo=${encodeURIComponent(`${RoutePathEnum.DASHBOARD}/?show=createCompany&afterCreate=new_user`)}`
    navigate(redirectLink)
  }

  return (
    <Button
      type="primary"
      size="large"
      onClick={isLoggedIn ? toCompanyProducts : toSignUpWithAddCompany}
    >
      {t`Стать поставщиком`}
    </Button>
  )
}
