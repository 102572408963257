import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { lazy } from 'react'
import { RoutePathEnum } from '~/enums/route-path-enum'
import PageHeader from '~/components/common/PageHeader'

const StaffMembers = lazy(() => import('./components/staff-members'))

export default function StaffPage() {
  useLingui()

  return (
    <>
      <PageHeader title={t`Список сотрудников`} backRoutePath={RoutePathEnum.DASHBOARD} />
      <StaffMembers />
    </>
  )
}
