import type { UploadFile as AntUploadFile } from 'antd'

import type { PublicDocument, PublicImage } from '~/shared/api'

type File = PublicImage | PublicDocument

function getThumbUrl(file: File): string {
  return 'links' in file ? file.links[0] : file.link
}

export function toAntFileList(files: File[]): AntUploadFile[] {
  return files.map(file => ({
    id: file.id,
    uid: file.id.toString(),
    name: file.name,
    status: 'done',
    thumbUrl: getThumbUrl(file),
  }))
}

/**
 * При загрузке Ant генерирует новый id и перезаписывает его в file.uid.
 * Если загрузить файл и сразу его удалить, настоящий id будет доступен в `file.response?.id`.
 * Если удалять ранее загруженные файлы, то id будет храниться в `file.uid`
 */
export function getRemovedImageId(file: AntUploadFile<File>) {
  return file.response?.id ?? Number(file.uid)
}
