import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { lazy } from 'react'
import { RoutePathEnum } from '~/enums/route-path-enum'
import PageHeader from '~/components/common/PageHeader'

const FaqList = lazy(() => import('~/shared/components/faq-list'))

export default function Page() {
  useLingui()

  return (
    <>
      <PageHeader title={t`Помощь и поддержка`} backRoutePath={RoutePathEnum.DASHBOARD} />
      <FaqList />
    </>
  )
}
