import { PlusOutlined } from '@ant-design/icons'
import { t } from '@lingui/macro'
import { Button, Space } from 'antd'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import TableUpload from '../components/table-upload'

import { DEFAULT_PAGE } from '../constants'
import { useCompanyProducts } from '../hooks/use-company-products'
import ProductsTable from './products-table'

import UploadTableProgress from './upload-table-progress'
import { useDebouncedState } from '~/core/hooks/use-debaunced-state'
import TableSearchBox from '~/shared/components/table-search-box'

export default function Products() {
  const navigate = useNavigate()

  const [page, setPage] = useState(DEFAULT_PAGE)
  const [debouncedQuery, , setQuery] = useDebouncedState('')

  const { data: products, isFetching } = useCompanyProducts(
    { page, q: debouncedQuery.trim() },
    {
      onSuccess: (data) => {
        if (page === DEFAULT_PAGE)
          return
        // При измененении списка (например удаление) выбранная страница может быть больше чем всего страниц в бд
        // В таком случае устанавливаем количество страниц из бд как активную
        if (page > data.pages)
          setPage(data.pages)
      },
    },
  )

  return (
    <Space direction="vertical">
      <TableSearchBox
        onSearch={setQuery}
        placeholder={t`Поиск по товарам`}
        extra={(
          <Button type="primary" icon={<PlusOutlined />} onClick={() => navigate('/company/p/new')}>
            {t`Добавить товар`}
          </Button>
        )}
        allowClear
      />

      <TableUpload />
      <UploadTableProgress />

      {products?.data && (
        <ProductsTable
          products={products.data}
          loading={isFetching}
          pagination={{ current: page, total: products.count, onChange: setPage }}
        />
      )}
    </Space>
  )
}
