import { z } from 'zod'

const INPUT_NUMBER_MAX_VALUE = 2147483647

export const InputPriceSchema = z
  .number({ invalid_type_error: 'Обязательное поле' })
  .max(INPUT_NUMBER_MAX_VALUE, `Максимальная цена - ${INPUT_NUMBER_MAX_VALUE}`)
  // .refine((val) => val > 0, 'Цена должна быть не меньше 0')
  .nullable()

export const InputAmountSchema = z
  .number({ invalid_type_error: 'Обязательное поле' })
  .int('Количество должно быть целым числом')
  .max(INPUT_NUMBER_MAX_VALUE, `Максимальное количество - ${INPUT_NUMBER_MAX_VALUE}`)
  .refine(val => val > 0, 'Количество должно быть не меньше 1')
