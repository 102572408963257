import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'

import { lazy } from 'react'
import { RoutePathEnum } from '~/enums/route-path-enum'
import PageHeader from '~/components/common/PageHeader'

const NotificationsList = lazy(() => import('~/notifications/list'))

export default function NotificationsPage() {
  useLingui()

  return (
    <>
      <PageHeader title={t`Уведомления`} backRoutePath={RoutePathEnum.DASHBOARD} />
      <NotificationsList />
    </>
  )
}
