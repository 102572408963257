import NiceModal from '@ebay/nice-modal-react'
import { Trans, t } from '@lingui/macro'
import { useForm, zodResolver } from '@mantine/form'
import { useQueryClient } from '@tanstack/react-query'
import { Alert, App, Space, Tooltip } from 'antd'
import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

import formSchema from '../form-schema'
import { useCreateCompanyProduct } from '../hooks/use-create-company-product'
import { useEditCompanyProduct } from '../hooks/use-edit-company-product'
import type { FormValues } from '../interface'
import { getSubmitButtonText, prepareProductData } from '../utils'

import { CREATE_NAVIGATION_PATH, EDIT_NAVIGATION_PATH, FormMode } from '../constants'
import { isNil } from '~/core/utils/common'
import { CategorySelect } from '~/entities/category'
import { AddFormFieldModal } from '~/features/add-form-field-form'
import { PRODUCT_CONDITION } from '~/lib/products/constants'
import { Button } from '~/shared/components/button'
import { Icon } from '~/shared/components/icon'
import { NumberField } from '~/shared/components/number-field'
import { Select } from '~/shared/components/select'
import { TextArea } from '~/shared/components/text-area'
import { TextField } from '~/shared/components/text-field'
import { companyKeys } from '~/shared/tanstack/keys/company'

interface Props {
  defaultValues?: FormValues
  upload?: React.ReactNode
  productId?: number
}

function getConditionOptions() {
  return [
    { label: t`Новый`, value: PRODUCT_CONDITION.new },
    { label: t`Б/У`, value: PRODUCT_CONDITION.used },
  ]
}

export default function ProductDetailsForm({ defaultValues, upload, productId }: Props) {
  const { message } = App.useApp()
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const form = useForm<FormValues>({
    validate: zodResolver(formSchema),
    initialValues: defaultValues ?? {
      name: '',
      categories: [],
      condition: getConditionOptions()[0],
      extra_fields: [],
      vendorCode: '',
      series: '',
      site: '',
    },
  })

  const createProduct = useCreateCompanyProduct()
  const updateProduct = useEditCompanyProduct()

  const formMode = defaultValues ? FormMode.EDIT : FormMode.CREATE
  const isEditForm = formMode === FormMode.EDIT
  const isCreateForm = formMode === FormMode.CREATE

  function showAddFieldForm() {
    NiceModal.show(AddFormFieldModal, {
      title: t`Добавить поле характеристик`,
      onAdd: (fieldName) => {
        form.insertListItem('extra_fields', { name: fieldName, value: '' })
      },
    })
  }

  const onSubmit = form.onSubmit((values) => {
    const product = prepareProductData(values, productId)

    if (isEditForm && productId) {
      updateProduct.mutate(product, {
        onSuccess() {
          const editPath = EDIT_NAVIGATION_PATH.replace(':id', productId.toString())

          queryClient.invalidateQueries({ queryKey: companyKeys.product(Number(productId)) })
          message.success(t`Данные успешно сохранены`)
          navigate(editPath)
        },
      })
    }
    else {
      createProduct.mutate(product, {
        onSuccess({ id }) {
          const createPath = CREATE_NAVIGATION_PATH.replace(':id', id.toString())

          queryClient.invalidateQueries({ queryKey: companyKeys.products({}) })
          message.success(t`Товар успешно добавлен`)
          navigate(createPath)
        },
      })
    }

    form.reset()
  })

  const isSubmitting = createProduct.isLoading || updateProduct.isLoading

  return (
    <form onSubmit={onSubmit} className="grid w-full gap-4">
      <TextField
        {...form.getInputProps('name')}
        label={t`Название товара`}
        valid={form.isValid('name')}
        required
        accent
      />

      <CategorySelect {...form.getInputProps('categories')} isMulti />

      <div grid="~ cols-1 sm:cols-2" gap="2">
        <NumberField {...form.getInputProps('price')} label={t`Цена`} accent />
        <TextField
          {...form.getInputProps('vendorCode')}
          label={t`Артикул`}
          accent
        />

      </div>

      <div grid="~ cols-2" gap="2">
        <TextField
          {...form.getInputProps('series')}
          label={t`Серия товара`}
          accent
        />

        <TextField
          {...form.getInputProps('site')}
          label={t`Сайт производителя`}
          accent
        />
      </div>

      <Select {...form.getInputProps('condition')} label={t`Состояние`} options={getConditionOptions()} />

      <div className="grid gap-4">
        <div>
          {/* @ts-expect-error FIXME: Заменить на antd кнопку */}
          <Button size="sm" icon={<Icon name="plus" />} variant="link" onClick={showAddFieldForm}>
            <Trans>Добавить поле характеристик</Trans>
          </Button>
        </div>

        {form.values.extra_fields.map((_, index) => {
          return (
            <div
              key={index}
              className="flex items-start"
            >
              <TextField
                {...form.getInputProps(`extra_fields.${index}.value`)}
                className="grow"
                label={form.values.extra_fields[index].name}
                valid={form.isValid(`extra_fields.${index}.value`)}
                accent
                required
              />

              <Tooltip title={t`Удалить поле`}>
                <button
                  type="button"
                  className="flex items-center justify-center p-3.5"
                  onClick={() => form.removeListItem('extra_fields', index)}
                >
                  <Icon name="close" className="text-red" />
                </button>
              </Tooltip>
            </div>
          )
        })}
      </div>

      <TextArea rows={5} {...form.getInputProps('description')} label={t`Подробное описание товара`} accent />

      <Space className="empty:hidden" direction="vertical" style={{ width: '100%' }}>
        {isCreateForm
          ? (
            <Alert type="info" message={t`Загрузка файлов будет доступна после добавления товара`} showIcon />
            )
          : null}

        {isNil(form.values.price)
          ? (
            <Alert
              message={t`Eсли вы не указываете цену, мы будем отображать ее как 'Цена по запросу'`}
              type="info"
              showIcon
            />
            )
          : null}
      </Space>

      {upload ? <div className="grid gap-2.5 md:grid-cols-2 md:gap-5">{upload}</div> : null}

      <div className="grid gap-2.5 md:grid-cols-2 md:gap-5">
        <Button className="w-full" type="submit" loading={isSubmitting}>
          {getSubmitButtonText(formMode)}
        </Button>

        <Button className="w-full" color="red" variant="soft" asChild>
          <Link to="/company/p">
            {t`Отменить`}
          </Link>
        </Button>
      </div>
    </form>
  )
}
