import React from 'react'

interface Props {
  prefix?: React.ReactNode
  children: React.ReactNode
}

export default function CompanyDropdownItem({ prefix = null, children }: Props) {
  return (
    <div className="group flex items-center py-2">
      {prefix && <div className="mr-2 inline-flex">{prefix}</div>}

      <div className="text-md text-black font-medium group-hover:text-blue">
        {children}
      </div>
    </div>
  )
}
