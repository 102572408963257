import type { ReactNode } from 'react'

interface Props {
  title: string
  extra?: ReactNode
}

export default function PageTitle({ title, extra }: Props) {
  return (
    <div className="mb-2.5 flex justify-between lg:mb-5">
      <div className="flex items-center">
        <h1 className="text-lg text-black font-bold md:text-xxl">{title}</h1>
      </div>

      {extra}
    </div>
  )
}
