import 'swiper/css'

import { t } from '@lingui/macro'
import { useRef } from 'react'
import { Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

const PRODUCTS = [
  {
    name: 'ПИТПАК Вертикальные машины для фасовки сыпучей продукции',
    category: 'Фасовочное оборудование',
    img: 'https://taurasfenix.com/upload/resize_cache/webp/iblock/890/890aed20dc677a4cc9d99c09663fc82c.webp',
  },
  {
    name: 'Нагревательные установки',
    img: 'https://protex.ru/thumb/2/6kR00nucFXvk7j2zSiJh2Q/360r300/d/heater.jpg',
  },
  {
    name: 'Автомат для фасовки в пакеты типа zip-lock Карусельного типа',
    category: 'Фасовочное оборудование',
    img: 'https://profitex.ru/wp-content/uploads/2020/02/IMGP0784-Edit.png',
  },
]

export default function LandingHotProducts() {
  const sliderRef = useRef(null)

  // const handlePrev = useCallback(() => {
  //   if (!sliderRef.current) return;
  //   sliderRef.current.swiper.slidePrev();
  // }, []);

  // const handleNext = useCallback(() => {
  //   if (!sliderRef.current) return;
  //   sliderRef.current.swiper.slideNext();
  // }, []);

  return (
    <section className="py-5 lg:py-12" id="products">
      <h2 className="mb-5 text-xl font-bold lg:mb-12 lg:text-3xl">
        {t`Самые востребованные товары`}
      </h2>

      <div className="relative lg:flex">
        <Swiper
          ref={sliderRef}
          spaceBetween={10}
          breakpoints={{
            1024: {
              slidesPerView: 'auto',
              spaceBetween: 20,
            },
          }}
          slidesPerView="auto"
          modules={[Navigation]}
          navigation
        >
          {PRODUCTS.map(({ category, img, name }) => (
            <SwiperSlide className="!w-auto" key={name}>
              <div className="w-[15rem] lg:w-[18.125rem]">
                <img
                  className="h-[18.125rem] w-[18.125rem] rounded-lg bg-white object-contain"
                  src={img}
                  width={240}
                  height={240}
                  alt={name}
                  loading="lazy"
                />

                <div className="flex flex-col gap-1 pt-2 lg:gap-2 lg:pt-5">
                  <span className="lg:text-lg">{name}</span>
                  {category && <span className="text-md text-gray-text-3 lg:text-base">{category}</span>}
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>

        {/* <div className="absolute top-0 -left-[55px] -right-[55px] z-10 hidden items-center justify-between inset-y-center xl:flex">
            <button className="p-1" type="button" onClick={handlePrev} aria-label="Предыдущий слайд">
              <Icon name="chevron-left" size={32} className="text-blue" />
            </button>
            <button className="p-1" type="button" onClick={handleNext} aria-label="Предыдущий слайд">
              <Icon name="arrow-right" size={32} className="text-blue" />
            </button>
          </div> */}
      </div>
    </section>
  )
}
