import { msg, t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { useQueryClient } from '@tanstack/react-query'
import { isAxiosError } from 'axios'
import { useNavigate } from 'react-router-dom'
import { CreatedOrderStatusEnum } from '~/domain/order/enums'

import { useArchivateOrder } from '~/pages/orders/created/mutations/use-archivate-order'
import useDeleteOrder from '~/pages/orders/created/mutations/use-delete-order'
import { RowActionMenu } from '~/shared/components/row-action-menu'
import { useGlobalDialog } from '~/shared/context/global-dialog'
import { showMessage } from '~/shared/show-message'
import { companyKeys } from '~/shared/tanstack/keys/company'

interface Props {
  orderId: number
  orderStatus: CreatedOrderStatusEnum
}

export default function OrderActionMenu({ orderId, orderStatus }: Props) {
  useLingui()
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { openEditOrderDialog } = useGlobalDialog()

  const { mutate: deleteOrder } = useDeleteOrder()
  const { mutate: archivate } = useArchivateOrder()

  return (
    <RowActionMenu
      items={[
        {
          icon: 'close',
          iconClassName: 'text-red',
          label: msg`Удалить`,
          handler: () => deleteOrder(orderId),
        },
        {
          icon: 'archive',
          iconClassName: 'text-yellow',
          label: msg`В архив`,
          handler: () =>
            archivate(orderId, {
              onSuccess: async () => {
                queryClient.invalidateQueries({ queryKey: companyKeys.createdOrders({}) })
                showMessage(t`Запрос успешно заархивирован`, 'success')
              },
              onError: (error) => {
                if (isAxiosError(error))
                  showMessage(error?.response?.data?.detail ?? t`Неизвестная ошибка`, 'error')
              },
            }),
          visible: orderStatus !== CreatedOrderStatusEnum.ARCHIVE,
        },
        {
          icon: 'reload',
          iconClassName: 'text-green',
          label: msg`Повторить`,
          handler: () => openEditOrderDialog(orderId),
          disabled: orderStatus === CreatedOrderStatusEnum.ACTIVE,
        },
        {
          icon: 'sort',
          label: msg`Подробнее`,
          handler: () => navigate(`/o/created/${orderId}`),
        },
      ]}
    />
  )
}
