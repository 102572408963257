import { Button, Space, Tooltip, theme } from 'antd'
import type { TableActionsItemType } from './types'
import { getActionIcon, getActionIconColor, getActionLabel } from './utils'

interface Props {
  items: TableActionsItemType[]
}

const { useToken } = theme

export default function TableActions({ items }: Props) {
  const { token } = useToken()

  return (
    <Space size="small">
      {items
        .filter(item => item.visible)
        .map(({ key, disabled, handler, label: ownLabel }) => {
          const ComponentIcon = getActionIcon(key)
          const iconColor = token[getActionIconColor(key)]
          const label = ownLabel ?? getActionLabel(key)

          return (
            <Tooltip title={label} key={key} trigger={['hover', 'focus', 'contextMenu']}>
              <Button
                disabled={disabled}
                onClick={handler}
                type="text"
                icon={<ComponentIcon style={{ color: iconColor as string }} />}
              />
            </Tooltip>
          )
        })}
    </Space>
  )
}
