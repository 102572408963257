import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { Link } from 'react-router-dom'

import { useSearchField } from '~/core/hooks/useSearchField'
import { Icon } from '~/shared/components/icon'
import { TextField } from '~/shared/components/text-field'

export default function SearchInput() {
  useLingui()

  const { handleSearch } = useSearchField()

  return (
    <div className="mb-8 -mx-4">
      <Link to="/p" className="flex items-center px-3 py-2 xl:hidden">
        <Icon name="search" size={24} className="text-gray-text-2" />
      </Link>

      <form onSubmit={handleSearch} autoComplete="off" className="hidden xl:block">
        <TextField
          name="q"
          label={t`Поиск товаров`}
          iconStart={<Icon name="search" size={24} className="text-gray-text-1" />}
        />
      </form>
    </div>
  )
}
