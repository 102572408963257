import type { ElementRef } from 'react'
import { useRef, useState } from 'react'
import { Alert, Button, Progress, Typography } from 'antd'
import { useNavigate } from 'react-router-dom'
import { t } from '@lingui/macro'
import { useQueryClient } from '@tanstack/react-query'
import OrderCreateForm from '../order/OrderCreateForm'
import CompanyCreateEntry from '../company/CompanyCreateEntry'
import OrderUpdateForm from '../order/OrderUpdateForm'
import UserSignUpForm from './UserSignUpForm'
import UserSignUpWithPhoneForm from './UserSignUpWithPhoneForm'
import UserAuthMethodTabs from './UserAuthMethodTabs'
import UserEmailConfirmForm from './UserEmailConfirmForm'
import UserPhoneConfirmForm from './UserPhoneConfirmForm'
import { LOCAL_STORAGE_KEYS } from '~/lib/constants'
import { RoutePathEnum } from '~/enums/route-path-enum'
import { useIsLoggedIn } from '~/lib/auth'
import { userKeys } from '~/data/user/keys'

interface Props {
  onClose: () => void
}

interface CreatedOrder {
  id: number
  name: string
}

const stepsMap = {
  signUp: 1,
  confirmEmail: 2,
  confirmPhone: 2,
  companyCreation: 3,
  createRequest: 4,
  updateRequest: 5,
  complete: 6,
} as const

const stepsCount = Object.keys(stepsMap).length - 1

export default function UserSignUpWithSupplierSearchModal({ onClose }: Props) {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const isLoggedIn = useIsLoggedIn()

  const [step, setStep] = useState<Keys<typeof stepsMap>>('signUp')
  const [confirmableContact, setConfirmableContact] = useState('')
  const [createdOrder, setCreatedOrder] = useState<CreatedOrder | null>(null)

  const updateFormRef = useRef<ElementRef<typeof OrderUpdateForm>>(null)

  const currentStep = stepsMap[step]
  const percent = (currentStep / stepsCount) * 100

  function onSignUpWithEmail(email: string) {
    setConfirmableContact(email)
    setStep('confirmEmail')
  }

  function onSignUpWithPhone(phone: string) {
    setConfirmableContact(phone)
    setStep('confirmPhone')
  }

  async function onConfirmSignUp(accessToken: string) {
    localStorage.setItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN, accessToken)
    await queryClient.ensureQueryData({ queryKey: userKeys.me() }) // Следующий шаг доступен только для юзера
    setStep('companyCreation')
  }

  function onCompanyCreated() {
    setStep('createRequest')
  }

  function onOrderCreated(orderId: number, orderName: string) {
    setCreatedOrder({ id: orderId, name: orderName })
    setStep('updateRequest')
  }

  function onOrderUpdated() {
    setStep('complete')
  }

  function onComplete() {
    onClose()
    navigate(RoutePathEnum.DASHBOARD)
  }

  return (
    <div>
      <Progress percent={percent} format={() => `${currentStep}/${stepsCount}`} status="normal" />

      {step === 'signUp' && (
        <>
          <Typography.Paragraph type="secondary" style={{ margin: 0 }}>
            {t`Шаг 1. Зарегистрируйтесь`}
          </Typography.Paragraph>
          <UserAuthMethodTabs
            email={<UserSignUpForm onSignUp={onSignUpWithEmail} />}
            phone={<UserSignUpWithPhoneForm onSignUp={onSignUpWithPhone} />}
          />
        </>
      )}

      {step === 'confirmEmail' && (
        <>
          <Typography.Paragraph type="secondary" style={{ margin: 0 }}>
            {t`Шаг 2. Подтвердите регистрацию`}
          </Typography.Paragraph>
          <UserEmailConfirmForm
            visibleEmail={confirmableContact}
            onConfirm={onConfirmSignUp}
          />
        </>
      )}
      {step === 'confirmPhone' && (
        <>
          <Typography.Paragraph type="secondary" style={{ margin: 0 }}>
            {t`Шаг 2. Подтвердите регистрацию`}
          </Typography.Paragraph>
          <UserPhoneConfirmForm
            visiblePhone={confirmableContact}
            onConfirm={onConfirmSignUp}
          />
        </>
      )}

      {step === 'companyCreation' && (
        <>
          <Typography.Paragraph type="secondary">
            {t`Шаг 3. Создайте компанию`}
          </Typography.Paragraph>
          {isLoggedIn
            ? <CompanyCreateEntry onCreate={onCompanyCreated} />
            : <Alert type="error" message={t`Ошибка: Перед созданием компании необходимо зарегистрироваться`} />}

        </>
      )}

      {step === 'createRequest' && (
        <>
          <Typography.Paragraph type="secondary">
            {t`Шаг 4. Введите краткое название того, что Вы хотели бы найти`}
          </Typography.Paragraph>
          <OrderCreateForm onCreate={onOrderCreated} />
        </>
      )}

      {step === 'updateRequest' && (
        <>
          <Typography.Paragraph type="secondary">
            {t`Шаг 5. Введите подробную информацию или техническое задание к оборудованию/услугам, которые Вы хотели бы найти`}
          </Typography.Paragraph>
          {createdOrder
            ? <OrderUpdateForm ref={updateFormRef} initialValues={{ request: createdOrder.name }} orderId={createdOrder.id} onUpdate={onOrderUpdated} />
            : <Alert type="error" message={t`Ошибка: Невозможно редактировать несуществующий запрос`} />}

          {createdOrder && (
            <Button
              onClick={() => updateFormRef.current?.saveAndActivateOrder()}
              type="primary"
              block
            >
              {t`Отправить`}
            </Button>
          )}
        </>
      )}

      {step === 'complete' && (
        <>
          <Typography.Paragraph type="secondary">
            {t`Готово! Как только появятся ответы, Вы сможете найти их во вкладке “Исходящие”`}
          </Typography.Paragraph>
          <Typography.Paragraph type="secondary">
            {t`А сейчас мы предлагаем Вам ознакомиться с другими функциями платформы`}
          </Typography.Paragraph>

          <Button type="primary" onClick={onComplete} block>{t`В личный кабинет`}</Button>
        </>
      )}
    </div>
  )
}
