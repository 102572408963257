import React from 'react'

import { Icon } from '~/shared/components/icon'

interface DownloadDocLinkProps {
  href: string
  children: React.ReactNode
}

export default function DownloadDocLink({ href, children }: DownloadDocLinkProps) {
  return (
    <a className="group/link inline-flex items-center gap-1" href={href} download>
      <Icon name="document" size="sm" />
      <span className="text-md text-blue font-medium underline-offset-3 group-hover/link:underline">
        {children}
      </span>
    </a>
  )
}
