import { t } from '@lingui/macro'
import { App, Button, Form, Input } from 'antd'
import { MAX_REQUEST_INPUT_LENGTH } from '~/constants'
import { usePersonalOrderCreateMutation } from '~/data/order/personal-order-create-mutation'

interface Props {
  onCreate: (orderId: number, orderName: string) => void
  onCreateFailed?: () => void
}

interface FormState {
  request: string
}

export default function OrderCreateForm({ onCreate, onCreateFailed }: Props) {
  const { message } = App.useApp()
  const [form] = Form.useForm<FormState>()

  const { mutate: createPersonalOrder, isLoading: isCreating } = usePersonalOrderCreateMutation({
    onSuccess: ({ product_request_id }) => {
      if (!product_request_id)
        return message.error(t`Ошибка: Невозможно отправить форму`)

      const orderName = form.getFieldValue('request')
      if (!orderName)
        return message.error(t`Ошибка: Не удалось сохранить запрос. Попробуйте ещё раз`)

      onCreate(product_request_id, orderName)
    },
    onError: onCreateFailed,
  })

  function onSubmit({ request }: FormState) {
    createPersonalOrder({ request })
  }

  return (
    <Form form={form} layout="vertical" onFinish={onSubmit} disabled={isCreating}>
      <Form.Item<FormState>
        name="request"
        label={t`Название`}
        rules={[{ required: true }]}
        hasFeedback
      >
        <Input maxLength={MAX_REQUEST_INPUT_LENGTH} />
      </Form.Item>

      <Button htmlType="submit" type="primary" size="large" block>{t`Далее`}</Button>
    </Form>
  )
}
