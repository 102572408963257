import React from 'react'

const QUERY = {
  up: 'up',
  down: 'down',
  between: 'between',
  only: 'only',
} as const

interface Options {
  key: number
  start?: number
  end?: number
}

export function useResponsive(
  query: Values<typeof QUERY>,
  { key, end = 0, start = 0 }: Options,
) {
  const isMediaUp = useMediaQuery(`(min-width: ${key}px)`)
  const isMediaDown = useMediaQuery(`(max-width: ${key}px)`)
  const isMediaBetween = useMediaQuery(
    `(min-width: ${start}px) and (max-width: ${end}px)`,
  )
  const isMediaOnly = useMediaQuery(`(width: ${key}px)`)

  if (query === QUERY.up)
    return isMediaUp

  if (query === QUERY.down)
    return isMediaDown

  if (query === QUERY.between)
    return isMediaBetween

  if (query === QUERY.only)
    return isMediaOnly

  return null
}

export function useMediaQuery(query: string): boolean {
  const getMatches = (query: string): boolean => {
    // Prevents SSR issues
    if (typeof window !== 'undefined')
      return window.matchMedia(query).matches

    return false
  }

  const [matches, setMatches] = React.useState<boolean>(getMatches(query))

  function handleChange() {
    setMatches(getMatches(query))
  }

  React.useEffect(() => {
    const matchMedia = window.matchMedia(query)

    // Triggered at the first client-side load and if query changes
    handleChange()

    // Listen matchMedia
    if (matchMedia.addListener)
      matchMedia.addListener(handleChange)
    else
      matchMedia.addEventListener('change', handleChange)

    return () => {
      if (matchMedia.removeListener)
        matchMedia.removeListener(handleChange)
      else
        matchMedia.removeEventListener('change', handleChange)
    }
  }, [query])

  return matches
}
