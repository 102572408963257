import { Slot, Slottable } from '@radix-ui/react-slot'
import React from 'react'
import { twMerge } from 'tailwind-merge'

import { Icon } from '../icon'

import type { ButtonProps, ButtonVariantProps } from './interface'
import { buttonVariants } from './variants'

const sizeToIconSizeMap: Indexed<NonNullable<ButtonVariantProps['size']>, number> = {
  xs: 16,
  sm: 20,
  md: 22,
  lg: 24,
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const {
    asChild,
    children,
    icon,
    loading,
    size = 'md',
    className,
    variant = 'solid',
    color = 'blue',
    type = 'button',
    disabled,
    ...buttonProps
  } = props

  const Element = asChild ? Slot : 'button'
  const defaultType = asChild ? undefined : type
  const iconSize = size ? sizeToIconSizeMap[size] : undefined

  const onlyIcon = !children && children !== 0 && !!icon

  return (
    <Element
      type={defaultType}

      {...buttonProps}
      className={twMerge(buttonVariants({ size, variant, className, onlyIcon, color }))}
      ref={ref}
      disabled={disabled || loading}
    >
      {icon
      && (loading
        ? (
          <Icon name="spin" className="animate-spin" size={iconSize} />
          )
        : (
            React.cloneElement(icon, { size: iconSize })
          ))}

      <Slottable>{children}</Slottable>
    </Element>
  )
})

Button.displayName = 'Button'

export default Button
