import { useQuery } from '@tanstack/react-query'

import { useState } from 'react'
import { userKeys } from '../keys/user'

import { USER_TOKEN_STORAGE_KEY, getUser } from '~/api/user-api'
import { ACTIVE_COMPANY_ID_STORAGE_KEY } from '~/domain/company/api'

function getCompanyId() {
  return Number(localStorage.getItem(ACTIVE_COMPANY_ID_STORAGE_KEY)) || null
}

export function useGetUser() {
  const [accessToken] = useState(localStorage.getItem(USER_TOKEN_STORAGE_KEY) ?? '')

  return useQuery({
    refetchOnWindowFocus: false,
    enabled: !!accessToken,
    queryKey: userKeys.me(),
    queryFn: async () => {
      const { data } = await getUser()

      const hasCompanies = data.companies.length > 0
      const savedActiveCompanyId = getCompanyId()

      if (!savedActiveCompanyId && hasCompanies) {
        const defaultCompanyId = data.companies[0].id
        localStorage.setItem(ACTIVE_COMPANY_ID_STORAGE_KEY, defaultCompanyId.toString())
      }

      return data
    },
  })
}
