import clsx from 'clsx'
import { NavLink } from 'react-router-dom'

import type { IconProps } from '~/shared/components/icon'
import { Icon } from '~/shared/components/icon'

interface Props {
  name: string
  icon: IconProps['name']
  href: string
}

export default function NavigationItem({ href, icon, name }: Props) {
  return (
    <NavLink
      to={href}
      className={({ isActive }) => {
        return clsx(
          'group flex items-center rounded-md p-3 font-medium',
          isActive ? 'text-blue' : 'text-gray-text-2 hover:text-blue-darken',
        )
      }}
    >
      <Icon size={24} name={icon} className="text-inherit xl:mr-3" />
      <span className="hidden text-base xl:inline">{name}</span>
    </NavLink>
  )
}
