import { Badge } from 'antd'
import { Link } from 'react-router-dom'
import { useNotificationState } from '~/data/notifications/notification-state-query'

import { Button } from '~/shared/components/button'
import { Icon } from '~/shared/components/icon'

export default function NotificationLink() {
  const { data: unreadNotifications } = useNotificationState({
    // @ts-expect-error FIXME(swagger)
    select: data => data.unread,
  })

  return (
    <Badge count={unreadNotifications}>
      {/* @ts-expect-error FIXME: Заменить на antd кнопку */}
      <Button variant="link" color="gray" icon={<Icon name="notifications" />} asChild>
        <Link to="/notifications" />
      </Button>
    </Badge>
  )
}
