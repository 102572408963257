import { i18n } from '@lingui/core'
import { msg } from '@lingui/macro'
import { nanoid } from 'nanoid'
import { Link } from 'react-router-dom'

import { Icon } from '~/shared/components/icon'

const ITEMS = [
  {
    id: nanoid(),
    icon: <Icon name="settings" size={24} />,
    name: msg`Промышленные контроллеры`,
    url: '/catalog?code=1',
  },
  {
    id: nanoid(),
    icon: <Icon className="text-white" name="archive" size={24} />,
    name: msg`Фасовочное оборудование`,
    url: '/catalog?code=2.1.2',
  },
  {
    id: nanoid(),
    icon: <Icon name="milk" size={24} />,
    name: msg`Молочная промышленность`,
    url: '/catalog?code=2.1',
  },
]

export default function CategoriesLinks() {
  return (
    <ul className="flex flex-wrap -m-2">
      {ITEMS.map(({ icon, id, name, url }) => (
        <li key={id} className="p-2">
          <Link
            to={url}
            className="inline-flex items-center gap-2.5 border border-white/40 rounded-full px-4 py-2.5 text-white hover:border-white"
          >
            {icon}
            <span>{i18n._(name)}</span>
          </Link>
        </li>
      ))}
    </ul>
  )
}
