import { SearchOutlined } from '@ant-design/icons'
import { useResponsive } from 'ahooks'
import type { InputProps, InputRef } from 'antd'
import { Button, Input, Space } from 'antd'
import { useRef } from 'react'
import type { ChangeEvent, ReactNode } from 'react'

interface Props {
  onSearch: (value: string) => void
  placeholder?: string
  extra?: ReactNode
  rootClassName?: string
  enterButton?: boolean
  allowClear?: InputProps['allowClear']
  prefix?: InputProps['prefix']
  defaultValue?: InputProps['defaultValue']
}

export default function TableSearchBox({
  onSearch: customOnSearch,
  placeholder,
  extra,
  rootClassName,
  enterButton = false,
  allowClear = false,
  prefix,
  defaultValue,
}: Props) {
  const responsive = useResponsive()

  const inputRef = useRef<InputRef | null>(null)

  const spaceDirection = responsive.sm ? 'horizontal' : 'vertical'

  function onChange(event: ChangeEvent<HTMLInputElement>) {
    if (event && event.target && event.type === 'click' && customOnSearch)
      customOnSearch(event.target.value)
  }

  function onSearch() {
    customOnSearch(inputRef.current?.input?.value ?? '')
  }

  function onPressEnter() {
    onSearch()
  }

  return (
    <section className={rootClassName}>
      <h2 className="sr-only">Поиск</h2>

      <Space.Compact
        direction={!enterButton ? spaceDirection : undefined}
        style={{ width: '100%' }}
        size="large"
      >
        <Input
          ref={inputRef}
          onChange={onChange}
          onPressEnter={onPressEnter}
          prefix={prefix}
          placeholder={placeholder}
          allowClear={allowClear}
          defaultValue={defaultValue}
        />
        {enterButton && <Button type="primary" icon={<SearchOutlined />} onClick={onSearch} />}

        {extra}
      </Space.Compact>
    </section>
  )
}
