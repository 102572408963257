import { msg } from '@lingui/macro'

// persmissions
const COMPANY_INFO_PERMISSION = 'company_info_permission' // Can edit information about company
const PRODUCT_INTERACTION_PERMISSION = 'product_interaction_permission' // Can add/delete/edit products
const ORDERING_PERMISSION = 'ordering_permission' // Can search and create order
const FACTORY_PERMISSION = 'factory_permission' // Can add/delete/edit factories
const ADD_USERS_PERMISSION = 'add_users_permission' // Can add new users to company
const PROPOSAL_PERMISSION = 'proposal_permission' // Can accept/reject/react on proposals

export const COMPANY_ROLE = {
  owner: 1,
  manager: 2,
  staff: 3,
} as const

export const COMPANY_PERMISSION = {
  companyInfo: COMPANY_INFO_PERMISSION,
  productInteraction: PRODUCT_INTERACTION_PERMISSION,
  ordering: ORDERING_PERMISSION,
  factory: FACTORY_PERMISSION,
  addUsers: ADD_USERS_PERMISSION,
  proposal: PROPOSAL_PERMISSION,
} as const

export const COMPANY_PERMISSION_NAME = {
  [COMPANY_PERMISSION.companyInfo]: msg`Информация о компании`,
  [COMPANY_PERMISSION.productInteraction]: msg`Добавлять товары`,
  [COMPANY_PERMISSION.ordering]: msg`Исходящие заказы`,
  [COMPANY_PERMISSION.factory]: msg`Управление заводами`,
  [COMPANY_PERMISSION.addUsers]: msg`Управление сотрудниками`,
  [COMPANY_PERMISSION.proposal]: msg`Управление предложениями`,
}

export const COMPANY_ROLE_NAME = {
  [COMPANY_ROLE.owner]: msg`Владелец`,
  [COMPANY_ROLE.manager]: msg`Менеджер по продажам`,
  [COMPANY_ROLE.staff]: msg`Сотрудник`,
}
