import { Button, Dropdown } from 'antd'

import { SUPPORTED_LOCALE } from './constants'
import { buildMenuItems, getSelectedMenuItem, isValidLocale } from './utils'

import { useLocale } from '~/shared/lib/react'

interface Props {
  /** @deprecated use `size` instead */
  showLabel?: boolean
  size?: 'small' | 'large'
}

export default function LocaleSelect({ showLabel = true }: Props) {
  const { locale, changeLocale } = useLocale()

  const selectedLocaleKey = isValidLocale(locale) ? locale : SUPPORTED_LOCALE.en
  const selectedItem = getSelectedMenuItem(selectedLocaleKey)

  return (
    <Dropdown
      menu={{
        items: buildMenuItems(changeLocale),
        selectable: true,
        defaultSelectedKeys: [selectedLocaleKey],
      }}
    >
      <Button icon={selectedItem.icon}>{showLabel ? selectedItem.label : null}</Button>
    </Dropdown>
  )
}
