import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { i18n } from '@lingui/core'
import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { Alert, Button, Spin } from 'antd'
import { useState } from 'react'
import { useParams } from 'react-router-dom'

import { getIncompletedFields } from './lib/alert'

import IncompletedFieldsList from './components/incompleted-fields-list'
import CratedOrderChainsList from '~/components/orders/CratedOrderChainsList'
import ProductCardMetaInfo from '~/components/products/ProductCardMetaInfo'
import ProductsCard from '~/components/products/ProductsCard'
import StatusTag from '~/core/ui/StatusTag'
import { mapCreatedOrderStatusToTKey, mapCreatedOrderStatusToTagType } from '~/lib/requests/constants'
import useDeleteOrder from '~/pages/orders/created/mutations/use-delete-order'
import { useCreatedOrderDetails } from '~/pages/orders/created/queries/use-created-order-details'
import PersonalOrderDrawer from '~/personal-order-drawer'
import { Icon } from '~/shared/components/icon'
import { Result } from '~/shared/components/result'
import { CreatedOrderStatusEnum } from '~/domain/order/enums'
import PageHeader from '~/components/common/PageHeader'
import { RoutePathEnum } from '~/enums/route-path-enum'

export default function CreatedOrderDetailsPage() {
  useLingui()

  const { id: orderId } = useParams()
  const { data: order, status } = useCreatedOrderDetails(
    { id: Number(orderId) },
    // @ts-expect-error ...
    { select: data => data.request },
  )

  if (status === 'loading')
    return <Spin />

  if (status === 'error') {
    return (
      <Result
        icon={<Icon name="not-found" width={126} height={106} />}
        title={t`Страница не найдена`}
        description={t`Страница, которую вы ищете, удалена или временно недоступна.`}
      />
    )
  }

  return (
    <>
      <PageHeader title={t`Детали запроса`} backRoutePath={RoutePathEnum.CREATED_ORDERS} />

      <Card order={order} />
    </>
  )
}

function Card({ order }: any) {
  useLingui()

  const { product } = order

  const [isOpenRequestDrawer, setIsOpenRequestDrawer] = useState(false)

  const { mutate: deleteOrder } = useDeleteOrder()

  const isConstructed = order.status === CreatedOrderStatusEnum.CONSTRUCTION
  const isPersonalOrder = !product

  const incompletedFields = getIncompletedFields(order)
  const hasIncompletedFields = getIncompletedFields(order).length > 0

  let constractionAlertNode
  if (isConstructed) {
    constractionAlertNode = (
      <Alert
        type="warning"
        message={t`Чтобы отправить запрос, нажмите Продолжить и заполните недостающие поля`}
        description={hasIncompletedFields ? <IncompletedFieldsList fields={incompletedFields} /> : undefined}
        showIcon
      />
    )
  }

  let statusNode
  if (!isConstructed) {
    statusNode = (
      <StatusTag
      // @ts-expect-error ...
        message={i18n._(mapCreatedOrderStatusToTKey[order.status])}
      // @ts-expect-error ...
        type={mapCreatedOrderStatusToTagType[order.status]}
      />
    )
  }

  let actionsNode
  if (isConstructed) {
    actionsNode = (
      <div className="flex flex-wrap items-center gap-5">
        <Button
          type="primary"
          size="large"
          icon={<EditOutlined />}
          onClick={() => setIsOpenRequestDrawer(true)}
        >
          {t`Продолжить`}
        </Button>

        <Button
          type="primary"
          size="large"
          icon={<DeleteOutlined />}
          onClick={() => deleteOrder(order.id)}
          danger
        >
          {t`Удалить`}
        </Button>
      </div>
    )
  }

  return (
    <>
      <div className="flex flex-col gap-8">
        <ProductsCard
          status={statusNode}
          alert={constractionAlertNode}
          product={{
            category: order.category?.name,
            name: isPersonalOrder ? order?.request : product?.name,
            description: isPersonalOrder ? order?.description : product?.description,
            images: isPersonalOrder ? order?.images : product?.images,
            is_available: isPersonalOrder ? order?.is_available : product?.is_available,
            id: isPersonalOrder ? order?.id : product?.id,
            extra_fields: isPersonalOrder ? order?.extra_fields : product?.extra_fields,
            files: isPersonalOrder ? order?.files : product?.files,
          }}
          meta={(
            <ProductCardMetaInfo
              amount={order.amount}
              price={order.preferable_price}
              condition={product?.condition}
              deliveryDate={order.preferable_time}
              deliveryAddress={order.address}
            />
          )}
          actions={actionsNode}
        />

        <CratedOrderChainsList />
      </div>

      <PersonalOrderDrawer
        open={isOpenRequestDrawer}
        onClose={() => setIsOpenRequestDrawer(false)}
        mode="edit"
        orderId={order.id}
      />
    </>
  )
}
