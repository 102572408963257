import { i18n } from '@lingui/core'
import { Trans } from '@lingui/macro'
import React from 'react'
import { useLocation } from 'react-router-dom'

import { breadcrumbNameMap } from '../lib/breadcrumb-name-map'

import BreadcrumbLink from './breadcrumb-link'
import { Icon } from '~/shared/components/icon'

interface RouteItem {
  key: React.Key
  title: React.ReactNode
}

interface Props {
  items?: RouteItem[]
}

export default function Breadcrumbs({ items: extraBreadcrumbItems = [] }: Props) {
  const location = useLocation()

  const pathSnippets = location.pathname.split('/').filter(i => i)
  const routedBreadcrumbItems = pathSnippets.map((_, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join('/')}`

    return {
      key: url,
      title: <BreadcrumbLink url={url}>{i18n._(breadcrumbNameMap[url])}</BreadcrumbLink>,
    }
  })

  const breadcrumbItems = [
    {
      title: (
        <BreadcrumbLink url="/dashboard">
          <Trans>Главная</Trans>
        </BreadcrumbLink>
      ),
      key: 'home',
    },
    ...routedBreadcrumbItems,
    ...extraBreadcrumbItems,
  ]

  return (
    <ul className="mb-4 flex flex-wrap items-center text-md text-gray-text-2 font-medium md:mb-5">
      {breadcrumbItems.map(({ key, title }, index) => (
        <React.Fragment key={key}>
          <li>{title}</li>

          {index !== breadcrumbItems.length - 1 && (
            <li className="mx-1.5 flex">
              <Icon size={16} name="arrow-right" className="text-gray-text-2" />
            </li>
          )}
        </React.Fragment>
      ))}
    </ul>
  )
}
