import React from 'react'
import { BrowserRouter } from 'react-router-dom'

export function withRouter(Component: React.ComponentType) {
  const displayName = Component.displayName || Component.name || 'Component'

  function ComponentsWithRouter() {
    return (
      <BrowserRouter>
        <Component />
      </BrowserRouter>
    )
  }

  ComponentsWithRouter.displayName = `withTheme(${displayName})`
  return ComponentsWithRouter
}
