import type { TagProps } from 'antd'
import { ReceivedOrderStatusEnum } from '~/domain/order/enums'
import { getReceivedOrderStatusNames } from '~/domain/order/utils/status-names'

export function getStatusName(status: ReceivedOrderStatusEnum) {
  const names = getReceivedOrderStatusNames()
  return names[status]
}

export function getStatusColor(status: ReceivedOrderStatusEnum) {
  const colors: Indexed<ReceivedOrderStatusEnum, TagProps['color'] > = {
    [ReceivedOrderStatusEnum.COMPLETED]: 'processing',
    [ReceivedOrderStatusEnum.WAITING]: 'warning',
    [ReceivedOrderStatusEnum.REJECT]: 'error',
    [ReceivedOrderStatusEnum.CONFIRMED]: 'success',
  }
  return colors[status]
}
