import { t } from '@lingui/macro'
import { Spin } from 'antd'
import { lazy } from 'react'
import CompanyName from './components/company-name'
import PageHeader from '~/components/common/PageHeader'
import { RoutePathEnum } from '~/enums/route-path-enum'
import { useActiveCompanyDetail } from '~/hooks/use-active-company'
import type { Okved } from '~/data/company/search-okved-query'

const EditCompanyForm = lazy(() => import('~/company/forms/edit'))

export default function Page() {
  const { data: company, status, fetchStatus } = useActiveCompanyDetail()

  if (status === 'loading' || fetchStatus === 'fetching')
    return <Spin size="large" />

  if (status === 'error')
  // TODO: Показать компонент с описанием ошибки
    return <p>ERROR</p>

  return (
    <>
      <PageHeader title={t`Информация о компании`} backRoutePath={RoutePathEnum.DASHBOARD} />

      <div className="grid gap-5">
        <CompanyName name={company.name} />
        <EditCompanyForm initialValues={{
          // FIXME(swagger)
          name: company.name,
          inn: company.inn,
          bik: company.bik!,
          checking_account: company.checking_account!,
          company_type: company.company_type,
          correspondent_account: company.correspondent_account!,
          kpp: company.kpp!,
          legal_address: company.legal_address as unknown as string,
          ogrn: company.ogrn!,
          okpo: company.okpo!,
          okved: company.okved as unknown as Okved[],
          website: company.website!,
        }}
        />
      </div>
    </>
  )
}
