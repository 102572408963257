import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'

import { Suspense, lazy } from 'react'
import PageHeader from '~/components/common/PageHeader'

const Widgets = lazy(() => import('./components/widgets'))

export default function DashboardPage() {
  useLingui()

  return (
    <>
      <PageHeader title={t`Панель управления компании`} />

      <Suspense fallback={null}>
        <Widgets />
      </Suspense>
    </>
  )
}
