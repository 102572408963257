import { useQuery } from '@tanstack/react-query'

import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from '../constants'
import type { ListParams } from '~/shared/api'
import { productsKeys } from '~/shared/tanstack/keys/products'
import { fetchProductsByQuery } from '~/domain/product/api'

type Params = Partial<ListParams>

export function useSearchProducts({ limit = DEFAULT_PER_PAGE, page = DEFAULT_PAGE, q = '' }: Params) {
  return useQuery({
    retry: false,
    enabled: Boolean(q),
    keepPreviousData: true,
    queryKey: productsKeys.search({ q, limit, page }),
    queryFn: async () => {
      const { data } = await fetchProductsByQuery({ q, limit, page })
      return data
    },
  })
}
