import { i18n } from '@lingui/core'
import { Trans, msg, t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { nanoid } from 'nanoid'
import { RoutePathEnum } from '~/enums/route-path-enum'
import PageHeader from '~/components/common/PageHeader'

const CONTACTS_ITEMS = [
  { id: nanoid(), title: msg`Телефон для связи`, value: '+7 (916) 698 27 77' },
  { id: nanoid(), title: msg`Email`, value: 'supplydirector@yandex.ru' },
  {
    id: nanoid(),
    title: msg`Юридический адрес`,
    value: '125414, г. Москва, Фестивальная 28',
  },
] as const

const COMPANY_DETAILS_ITEMS = [
  { id: nanoid(), title: msg`Краткое наименование`, value: 'ООО “Сапплай Директор”' },
  {
    id: nanoid(),
    title: msg`Юридический адрес`,
    value:
      '125414, г. Москва, вн. тер. г. муниципальный округ Ховрино, ул. Фестивальная, д. 28, эт.1, пом.5, офис 9',
  },
  { id: nanoid(), title: msg`ИНН`, value: '7743408681' },
  { id: nanoid(), title: msg`КПП`, value: '774301001' },
  { id: nanoid(), title: msg`ОГРН`, value: '1111122233445' },
  { id: nanoid(), title: msg`ОКВЭД (Сфера деятельности)`, value: '47.76' },
  { id: nanoid(), title: msg`ОКПО`, value: '17401000000' },
  { id: nanoid(), title: msg`Расчетный счёт`, value: '40702810600000000067' },
  {
    id: nanoid(),
    title: msg`Полное наименование банка`,
    value: 'Мытищинское отделение №1265 ОАО “Сбербанк России”, г. Москва',
  },
  { id: nanoid(), title: msg`БИК`, value: '041501555' },
  {
    id: nanoid(),
    title: msg`К/с банка`,
    value: '30101810400000000555',
  },
] as const

export default function Page() {
  useLingui()

  return (
    <div className="gap10" grid="~">
      <div>
        <PageHeader
          title={t`О нас`}
          backRoutePath={RoutePathEnum.DASHBOARD}
        />

        <div>
          <p>
            <Trans>
              На платформе Supply Director Вы можете воспользоваться автоматизированным поиском новых
              поставщиков оборудования из различных отраслей, включая пищевую и сельскохозяйственную. Мы
              автоматизировали процесс создания и управления заявками, чтобы Вы могли быстро и эффективно
              заказывать нужное оборудование.
            </Trans>
          </p>
          <p>
            <Trans>
              На нашей платформе Вы можете разместить свою продукцию и товары, а также получать рекомендации
              по их продвижению. Вы также можете моментально реагировать на выгодные заявки и удобно
              сортировать входящие запросы по различным параметрам, таким как цена и количество. Мы стремимся
              помочь компаниям из разных отраслей установить эффективное взаимодействие и оптимизировать цепи
              поставок. Для этого мы нацелены на максимальный охват пользователей в каждой отрасли, чтобы
              получать полную информацию о состоянии рынка и улучшать нашу платформу.
            </Trans>
          </p>
        </div>
      </div>

      <section flex="~ wrap" gap="5 md:10">
        <h2 className="sr-only">Контакты</h2>

        {CONTACTS_ITEMS.map(({ title, value, id }) => (
          <div grid="~" gap="2" key={id}>
            <span text="md gray-text-3">{i18n._(title)}</span>
            <span font="bold">{value}</span>
          </div>
        ))}
      </section>

      <section>
        <h2 mb="4" text="lg" font="bold">
          {t`Реквизиты компании`}
        </h2>

        <div flex="~ col" gap="4 lg:2.5">
          {COMPANY_DETAILS_ITEMS.map(({ title, value, id }) => (
            <div
              key={id}
              flex="~ col gap2.5"
              lg:flex="items-center row gap0"
            >
              <span
                text="sm gray-text-2 md:md"
                className="relative"
                lg:flex="grow"
                lg:after="absolute bottom-[2px] left-0 block h-[20px] w-full border-b border-gray-border-1 content-[quoted:_]"
              >
                <span
                  lg="z1 inline-flex w-max bg-background pr1.5"
                  className="relative"
                >
                  {i18n._(title)}
                </span>
              </span>

              <span lg="pl1.5 text-right">{value}</span>
            </div>
          ))}
        </div>
      </section>
    </div>
  )
}
