import type { ReactNode, RefObject } from 'react'

import { useWelcomeTour } from '../context'

type TargetName =
  | 'productsSearch'
  | 'companySwitch'
  | 'companyManagement'
  | 'catalog'
  | 'createRequest'
  | 'requests'

interface Props {
  children: ReactNode
  name: TargetName
  visible?: boolean
  rootClassName?: string
}

export default function WelcomeTourTarget({
  children,
  name,
  visible,
  rootClassName,
}: Props) {
  const {
    companyManagementRef,
    catalogRef,
    companySwitchRef,
    createRequestRef,
    requestsRef,
    productsSearchRef,
  } = useWelcomeTour()

  if (!visible)
    return children

  const ref: Record<TargetName, RefObject<HTMLDivElement>> = {
    productsSearch: productsSearchRef!,
    companyManagement: companyManagementRef!,
    requests: requestsRef!,
    catalog: catalogRef!,
    createRequest: createRequestRef!,
    companySwitch: companySwitchRef!,
  }

  return (
    <div ref={ref[name]} className={rootClassName}>
      {children}
    </div>
  )
}
