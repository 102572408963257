import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import type { ReactNode } from 'react'
import { createPortal } from 'react-dom'

interface Props {
  children?: ReactNode
  loading?: boolean
  logo?: boolean
}

export default function LockScreen({ children, loading, logo = true }: Props) {
  useLingui()

  return createPortal(
    <div className="fixed inset-0 z-[9999] flex items-center justify-center bg-white">
      <div className="grid justify-items-center gap-1">
        {logo && (
          <svg width="74" height="44" viewBox="0 0 54 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="logo" className={loading ? 'animate-pulse' : ''}>
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.87328 8.25136C0.933922 5.09794 3.32486 1.90527 7.5236 1.90527H30.7832C35.3137 1.90527 39.1903 1.91903 42.2648 2.28609C45.2829 2.64642 48.1114 3.39997 50.0704 5.3589C53.5677 8.85603 54.4265 14.915 50.0705 19.2711C49.0641 20.2774 47.986 21.0383 46.693 21.55C45.4318 22.0491 44.1039 22.2556 42.6846 22.342C41.2825 22.4273 39.6457 22.401 37.7522 22.3597C37.5046 22.3543 37.2514 22.3486 36.9922 22.3428C35.2368 22.3032 33.2059 22.2574 30.7832 22.2574H28.1665V9.7554H33.3999V17.0408C34.7799 17.0581 36.0131 17.0859 37.1142 17.1108C37.3721 17.1166 37.6228 17.1222 37.8663 17.1275C39.7984 17.1697 41.2173 17.1881 42.3668 17.1182C43.4992 17.0493 44.2189 16.9008 44.7673 16.6838C45.2838 16.4794 45.7771 16.1633 46.3699 15.5705C48.4102 13.5302 48.1063 10.7959 46.3699 9.05957C45.7121 8.40179 44.401 7.81172 41.6444 7.4826C38.944 7.1602 35.4105 7.13869 30.7832 7.13869H7.5236C6.88873 7.13869 6.58649 7.34787 6.43117 7.51049C6.24195 7.70859 6.11229 8.01076 6.10573 8.35198C6.09922 8.69046 6.21406 8.94914 6.36155 9.10567C6.47701 9.22819 6.77448 9.46465 7.5236 9.46465H19.4442C21.5236 9.46465 23.3385 10.1198 24.647 11.3721C25.9437 12.6131 26.5621 14.2746 26.5306 15.9114C26.4651 19.3156 23.6863 22.2574 19.4442 22.2574H1.99943V17.024H19.4442C20.309 17.024 20.7495 16.7555 20.9582 16.5582C21.1863 16.3426 21.2932 16.0697 21.2981 15.8107C21.303 15.5576 21.2127 15.3293 21.0284 15.153C20.856 14.9879 20.4176 14.6981 19.4442 14.6981H7.5236C5.51063 14.6981 3.77289 13.9896 2.55273 12.6948C1.36461 11.4339 0.843446 9.80273 0.87328 8.25136Z"
                fill="#6266EB"
              />
            </g>
          </svg>
        )}

        {loading && <p>{t`Загрузка...`}</p>}

        {children}
      </div>
    </div>,
    document.body,
  )
}
