import { App, Button, Form } from 'antd'
import { t } from '@lingui/macro'
import PhoneInput from 'antd-phone-input'
import type { AntdPhoneInputValue } from '#/antd-phone-input'
import { checkPhone, parseAntdPhoneInput } from '~/lib/utils/phone-utils'
import { useSignUpWithPhone } from '~/data/auth/sign-up-with-phone-mutation'
import { clearSavedCompanyToken } from '~/authentication/forms/sign-up/utils/company-tokens'

interface Props {
  onSignUp: (phone: string) => void
  onSignUpFailed?: () => void
}

interface FormState {
  phone: AntdPhoneInputValue
}

export default function UserSignUpWithPhoneForm({ onSignUp, onSignUpFailed }: Props) {
  const { message } = App.useApp()
  const [form] = Form.useForm<FormState>()

  const { mutate: signUp, isLoading: isSigningUp } = useSignUpWithPhone({
    onSuccess: (_, { phone }) => {
      clearSavedCompanyToken()
      onSignUp(phone)
    },
    onError: (error) => {
      // @ts-expect-error FIXME: Присылать нормальную ошибку с бэка
      const text = error?.username[0] === 'A user with that username already exists.'
        ? t`Пользователь с таким номером телефона уже существует.`
        : t`Ошибка: Неправильный указан телефон`

      message.error(text)
      onSignUpFailed?.()
    },
  })

  function onFinish(values: FormState) {
    const phone = parseAntdPhoneInput(values.phone)
    signUp({ phone })
  }

  return (
    <Form layout="vertical" name="phone-signup" form={form} onFinish={onFinish} disabled={isSigningUp}>
      <Form.Item<FormState> name="phone" label={t`Телефон`} rules={[{ validator: checkPhone }]}>
        <PhoneInput disabled={isSigningUp} enableSearch />
      </Form.Item>

      <Button type="primary" htmlType="submit" loading={isSigningUp} block>
        {t`Получить код`}
      </Button>
    </Form>
  )
}
