import { Trans, t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { Link } from 'react-router-dom'

import Result from '~/core/ui/Result'
import { Button } from '~/shared/components/button'
import { Icon } from '~/shared/components/icon'

interface NotFoundProductProps {
  pathToBack?: string
}

export default function NotFoundProduct({ pathToBack = '' }: NotFoundProductProps) {
  useLingui()

  let backButtonNode
  if (pathToBack) {
    backButtonNode = (
      <Button size="sm" asChild>
        <Link to={pathToBack}>
          <Trans>Вернуться к списку товаров</Trans>
        </Link>
      </Button>
    )
  }

  return (
    <Result
      title={t`Товар не найден`}
      icon={<Icon name="no-products" width={147} height={118} />}
      extra={backButtonNode}
    />
  )
}
