import { zodResolver } from '@hookform/resolvers/zod'
import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { Button } from 'antd'
import { useForm } from 'react-hook-form'

import NumberField from '~/components/NumberField'
import { convertDateToInputValue, convertInputValueToDate } from '~/core/utils/common'
import { showUnknownError } from '~/core/utils/toasts'
import { confirmOrderFormSchema } from '~/lib/requests/schemas'
import { useAcceptOrder } from '~/pages/orders/received/mutations/use-accept-order'
import { useReceivedOrderDetails } from '~/pages/orders/received/queries/use-received-order-details'
import { TextArea } from '~/shared/components/text-area'
import { TextField } from '~/shared/components/text-field'

interface Props {
  orderId: number
  onClose: () => void
}

export default function Form({ orderId, onClose }: Props) {
  useLingui()

  const { data: order } = useReceivedOrderDetails({ id: orderId })
  const { mutate: confirm, isLoading } = useAcceptOrder()

  const { register, handleSubmit, formState, control } = useForm({
    resolver: zodResolver(confirmOrderFormSchema),
    /* Ререндерит форму как успешно получит данные */
    values: {
      // @ts-expect-error ...
      amount: order?.product_request?.amount,
      comment: '',
      // @ts-expect-error ...
      price: order?.product_request?.preferable_price,
      // @ts-expect-error ...
      time: order?.product_request?.preferable_time
      // @ts-expect-error ...
        ? convertDateToInputValue(new Date(order?.product_request?.preferable_time))
        : null,
    },
  })

  function onSubmit(values: any) {
    const payload = {
      ...values,
      id: orderId,
      time: convertInputValueToDate(values.time),
    }

    confirm(payload, {
      onSuccess: onClose,
      onError: showUnknownError,
    })
  }

  const { errors } = formState

  return (
    <form className="flex flex-col gap-4" onSubmit={handleSubmit(onSubmit)}>
      <NumberField
        // @ts-expect-error ...
        control={control}
        name="price"
        label={t`Желаемая цена`}
        errorMessage={errors?.price?.message}
        required
      />
      <TextField
        {...register('time')}
        label={t`Необходимые сроки`}
        errorMessage={errors?.time?.message}
        type="date"
        required
      />
      <NumberField
        // @ts-expect-error ...
        control={control}
        name="amount"
        label={t`Желаемое количество`}
        errorMessage={errors?.amount?.message}
        required
      />
      <TextArea
        {...register('comment')}
        className="h-28"
        label={t`Текст сообщения`}
        errorMessage={errors?.comment?.message}
      />

      <Button disabled={isLoading} size="large" type="primary" htmlType="submit" block>
        {t`Подтвердить`}
      </Button>
    </form>
  )
}
