import type { SetStateAction } from 'react'
import type { PageQueryParams } from './types'
import { createSafeContext } from '~/shared/lib/react'

interface CatalogContext {
  params: PageQueryParams
  setParams: (s: SetStateAction<Partial<PageQueryParams>>) => void
}

export const [CatalogParamsProvider, useCatalogParams] = createSafeContext<CatalogContext>({
  name: 'CatalogContext',
  providerName: 'CatalogParamsProvider',
  hookName: 'useCatalogParams',
  strict: true,
})
