import { Button, Typography } from 'antd'
import type { ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'
import { ArrowLeftOutlined } from '@ant-design/icons'
import type { RoutePathEnum } from '~/enums/route-path-enum'

const { Title } = Typography

interface Props {
  title: string
  subtitle?: string
  extra?: ReactNode
  onBack?: () => void
  backRoutePath?: RoutePathEnum
}

export default function PageHeader({ title, backRoutePath, onBack, subtitle, extra }: Props) {
  const navigate = useNavigate()

  const isBackButtonVisible = backRoutePath || onBack

  function handleBack() {
    if (onBack)
      onBack()
    else if (backRoutePath)
      navigate(backRoutePath)
  }

  return (
    <div className="mb5" flex="~ items-start justify-between">
      <div flex="~ items-center" className="gap2">
        {isBackButtonVisible && (
          <Button
            type="text"
            icon={<ArrowLeftOutlined />}
            onClick={handleBack}
          />
        )}

        <Title level={4} style={{ marginBottom: 0 }}>{title}</Title>
        {subtitle && (
          <Title
            level={5}
            type="secondary"
            style={{ marginBottom: 0 }}
          >
            {subtitle}
          </Title>
        )}
      </div>

      {extra}
    </div>
  )
}
