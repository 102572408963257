import { Trans } from '@lingui/macro'

import { useDeleteProductImage } from '../hooks/use-delete-product-image'

import { buildApiUrl } from '~/api/utils'
import FileUpload from '~/components/file-upload'
import type { PublicImage } from '~/shared/api'
import { Button } from '~/shared/components/button'
import { Icon } from '~/shared/components/icon'
import { ACTIVE_COMPANY_TOKEN_STORAGE_KEY } from '~/domain/company/api'

interface Props {
  productId: number
  defaulImageList: PublicImage[]
}

export default function ImageUpload({ productId, defaulImageList }: Props) {
  const { mutateAsync: deleteFile } = useDeleteProductImage()

  function handleRemove(fileId: number) {
    return deleteFile({ productId, fileId })
  }

  return (
    <FileUpload
      onRemove={handleRemove}
      defaultFileList={defaulImageList}
      action={buildApiUrl('/api/products/product/image/add/')}
      headers={{ authorization: `Token ${localStorage.getItem(ACTIVE_COMPANY_TOKEN_STORAGE_KEY)}` }}
      data={{ product: productId }}
      accept=".jpg, .jpeg, .png"
      trigger={(
     //  @ts-expect-error FIXME: Заменить на antd кнопку
        <Button size="sm" variant="link" icon={<Icon name="image" className="text-blue" />}>
          <Trans>Загрузить фото</Trans>
        </Button>
      )}
    />
  )
}
