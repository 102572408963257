import { lazy } from 'react'
import BurgerMenuWithCompany from './components/burger-menu-with-company'
import CompanyEntity from './components/company-entity'
import NotificationLink from './components/notification-link'
import UserEntity from './components/user-entity'

import { AuthButton } from '~/shared/components/auth-button'
import { useGetUser } from '~/shared/tanstack/queries/use-get-user'

const LocaleSelect = lazy(() => import('~/components/locale-select'))

export default function Header() {
  const { status: userStatus } = useGetUser()

  const hasUser = userStatus === 'success'

  return (
    <header className="relative left-0 right-0 top-0 z-20">
      <div className="mx-auto h-14 w-full flex px-2.5 2xl:max-w-[84rem] md:h-20 2xl:px-12 xl:px-7">
        <div className="md:justify-normal w-full flex items-center justify-between border-b border-gray-border-1">

          <div className="grid grid-cols-2 items-center gap-1.5 md:hidden">
            {hasUser && <BurgerMenuWithCompany /> }
            <LocaleSelect size="small" showLabel={false} />
          </div>

          {hasUser && <CompanyEntity />}

          <div className="ml-auto flex items-center space-x-6">
            {hasUser && <NotificationLink />}
            {hasUser ? <UserEntity /> : <AuthButton />}
          </div>
        </div>
      </div>
    </header>
  )
}
