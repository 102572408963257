import { z } from 'zod'

import { InputAmountSchema, InputPriceSchema } from '~/core/utils/schemas'

export const createOrderProductSchema = z.object({
  description: z.string().max(4000, 'Максимальная длина 4000 символов'),
  preferable_time: z.string().min(1, 'Обязательное поле'),
  preferable_price: InputPriceSchema,
  amount: InputAmountSchema,
})

export const confirmOrderFormSchema = z.object({
  comment: z.string(),
  time: z.string().nonempty('Обязательное поле'),
  amount: InputAmountSchema,
  price: InputPriceSchema,
})
