import clsx from 'clsx'
import React from 'react'

interface Props {
  title: string
  value: React.ReactNode
  className?: string
  /**
   * Не показывать ячейку если `value` null
   */
  hiddenWhenNull?: boolean
}

export default function Ceil({ title, value, className, hiddenWhenNull }: Props) {
  if (hiddenWhenNull && value === null)
    return null

  return (
    <div className="grid gap-1">
      <span className="text-gray-text-2 xl:hidden">{title}</span>
      <span className={clsx('text-black', className)}>{value}</span>
    </div>
  )
}
