import { useResponsive } from 'ahooks'

import CompanyDropdown from './company-dropdown'

import { useGetUser } from '~/shared/tanstack/queries/use-get-user'
import WelcomeTourTarget from '~/welcome-tour/target'
import { useActiveCompanyDetail } from '~/hooks/use-active-company'

export default function BurgerMenuWithCompany() {
  const responsive = useResponsive()

  const { data: company } = useActiveCompanyDetail()
  const { data: user } = useGetUser()

  return (
    <WelcomeTourTarget name="companySwitch" visible={!responsive.md}>
      <CompanyDropdown
      /* FIXME(swagger): опциональный айди у компании */
        activeCompany={company ? { id: company.id!, name: company.name } : null}
        companies={user?.companies ?? []}
      />
    </WelcomeTourTarget>
  )
}
