import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { useQueryClient } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'

import { useEffect } from 'react'
import { Spin } from 'antd'
import { useNotificationDetails } from '../queries/use-notification-details'

import Content from './content'

import Result from '~/core/ui/Result'
import { userKeys } from '~/shared/tanstack/keys/user'

export default function Page() {
  useLingui()
  const queryClient = useQueryClient()
  const { id: notificationId } = useParams()

  const { data: notification, status: notificationStatus } = useNotificationDetails(Number(notificationId))

  useEffect(() => {
    queryClient.invalidateQueries({ queryKey: userKeys.notificationState() })
  }, [])

  if (notificationStatus === 'loading')
    return <Spin />

  if (notificationStatus === 'error')
    return <Result title={t`Неизвестная ошибка`} />

  return <Content notification={notification} />
}
