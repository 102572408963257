import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { useParams } from 'react-router-dom'

import { Spin } from 'antd'
import DocumentUpload from './components/document-upload'
import ImageUpload from './components/image-upload'

import NotFoundProduct from '~/core/ui/error-screens/NotFoundProduct'
import ProductDetailsForm from '~/products/forms/details'
import { toDefaultValues } from '~/products/forms/details/utils'
import { useCompanyProductDetails } from '~/shared-queries/use-product-details.query'
import PageHeader from '~/components/common/PageHeader'
import { RoutePathEnum } from '~/enums/route-path-enum'

export default function CompanyProductsEditPage() {
  useLingui()
  const { id: productId } = useParams()

  const { data: product, status } = useCompanyProductDetails({ id: Number(productId) })

  if (status === 'loading')
    return <Spin size="large" />

  if (status === 'error')
    return <NotFoundProduct />

  return (
    <div className="max-w-xl">
      <PageHeader
        title={t`Редактировать товар`}
        backRoutePath={RoutePathEnum.COMPANY_PRODUCT_DETAILS.replace(':id', productId!) as RoutePathEnum}
      />

      <ProductDetailsForm
        defaultValues={toDefaultValues(product)}
        productId={Number(productId)}
        upload={(
          <>
            <ImageUpload defaulImageList={product.images} productId={Number(productId)} />
            <DocumentUpload defaultDocumentList={product.files} productId={Number(productId)} />
          </>
        )}
      />
    </div>
  )
}
