import type { UseQueryOptions } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'

import { get } from '../fetchers'
import type { components } from '../api'
import { companyKeys } from './keys'

export interface SearchOkvedVariables {
  query: string
}

export interface Okved {
  name: string
  code: string
  // FIXME(swagger)
  id?: number
}

interface SearchOkvedResponse {
  // FIXME(swagger)
  results: components['schemas']['Okved'][]
}

export async function searchOkved({ query }: SearchOkvedVariables, signal?: AbortSignal) {
  const { data, error } = await get(`/organizations/okved/search/`, {
    // @ts-expect-error FIXME(swagger): исправить в сваггере
    params: { query: { q: query.trim() } },
    signal,
  })
  if (error)
    throw error

  return data as unknown as SearchOkvedResponse
}

export type SearchOkvedData = Awaited<ReturnType<typeof searchOkved>>
export type SearchOkvedError = unknown

export function useSearchOkvedQuery<TData = SearchOkvedData>(
  { query }: SearchOkvedVariables,
  { enabled = true, ...options }: UseQueryOptions<SearchOkvedData, SearchOkvedError, TData> = {},
) {
  return useQuery<SearchOkvedData, SearchOkvedError, TData>({
    queryKey: companyKeys.okvedSearch(query),
    queryFn: ({ signal }) => searchOkved({ query }, signal),
    enabled: enabled && typeof query !== 'undefined' && query.trim().length > 0,
    ...options,
  })
}
