import { t } from '@lingui/macro'

import Products from './components/products'
import PageHeader from '~/components/common/PageHeader'
import { RoutePathEnum } from '~/enums/route-path-enum'

export default function CompanyProductsPage() {
  return (
    <>
      <PageHeader title={t`Список товаров`} backRoutePath={RoutePathEnum.DASHBOARD} />
      <Products />
    </>
  )
}
