export enum CreatedOrderStatusEnum {
  HISTORY = 0,
  CONSTRUCTION = 1,
  ACTIVE = 2,
  ARCHIVE = 3,
  REJECT = 4,
}

export enum CreatedOrderTypeEnum {
  PRODUCT = 0,
  PERSONAL = 1,
}

export enum ReceivedOrderStatusEnum {
  REJECT = 0,
  WAITING = 1,
  CONFIRMED = 2,
  COMPLETED = 3,
}

export enum ReceivedOrderTypeEnum {
  PRODUCT = 0,
  PERSONAL = 1,
}

export enum OrderChainStatusEnum {
  REJECT = 0,
  WAITING = 1,
  CONFIRMED = 2,
  COMPLETED = 3,
}
