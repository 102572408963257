import Logo from './components/logo'
import PrimaryNavigation from './components/primary-navigation'
import RequestButtonWithInfo from './components/request-button-with-info'
import SearchInput from './components/search-input'
import SecondaryNavigation from './components/secondary-navigation'

import { useGetUser } from '~/shared/tanstack/queries/use-get-user'

// TODO: Выпилить. Заменить на инпута из antd
export default function LeftMenu() {
  const { isSuccess: hasUser } = useGetUser()

  return (
    <div className="fixed bottom-0 left-0 top-0 z-30 hidden flex-col items-start overflow-auto scroll-smooth bg-white p-8 md:w-20 xl:w-72 md:flex">
      <Logo />
      <SearchInput />
      <PrimaryNavigation />
      {hasUser && <RequestButtonWithInfo />}
      <SecondaryNavigation />
    </div>
  )
}
