import { useResponsive } from 'ahooks'
import { Image as AntImage } from 'antd'
import clsx from 'clsx'
import { useState } from 'react'

import type { PublicImage } from '~/shared/api'

interface Props {
  images: PublicImage[]
}

export default function ProductsImageGallery({ images = [] }: Props) {
  const responsive = useResponsive()

  const [selectedIndex, setSelectedIndex] = useState(0)

  function selectImage(index: number) {
    setSelectedIndex(index)
  }

  const currentPreview = images[selectedIndex]?.links[2]
  const previewItems = images.map(img => img.links[2])

  return (
    <div className="group-data-[hidden=true]:pointer-events-none group-data-[hidden=true]:opacity-50">
      <AntImage.PreviewGroup
        items={previewItems}
        preview={{ current: selectedIndex, onChange: current => selectImage(current) }}
      >
        <AntImage
          preview={Boolean(currentPreview)}
          width={responsive.sm ? 283 : undefined}
          height={283}
          src={currentPreview}
          fallback="/no-photo.svg"
          style={{ objectFit: 'contain' }}
        />
      </AntImage.PreviewGroup>

      {images.length > 0 && (
        <div className="max-w-[19rem] flex flex-wrap pt-2 -m-1">
          {images.map(({ links, id }, index) => (
            <button
              className={clsx(
                'm-1 h-[60px] w-[60px] shrink-0 overflow-hidden rounded outline-none transition',
                { 'ring ring-blue ring-offset-1': index === selectedIndex },
              )}
              type="button"
              onClick={() => selectImage(index)}
              key={id}
            >
              <img
                className="object-cover"
                src={links[0]}
                width={60}
                alt="Мини превью товара"
                loading="lazy"
              />
            </button>
          ))}
        </div>
      )}
    </div>
  )
}
