import { Skeleton } from '../skeleton'

export default function EntityPreviewSKeleton() {
  return (
    <div className="flex items-center">
      <Skeleton.Circle className="mr-3 h-11 w-11" />
      <div className="grid w-36 gap-2">
        <Skeleton.Line className="h-2.5 w-full rounded-full" />
        <Skeleton.Line className="h-1.5 w-16 rounded-full" />
      </div>
    </div>
  )
}
