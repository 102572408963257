import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'

import { useCatalog } from '../hooks/use-catalog'
import { useProductsAmountByCategory } from '../hooks/use-products-amount-by-category'

import PageHeader from '~/components/common/PageHeader'
import { RoutePathEnum } from '~/enums/route-path-enum'
import CategoryCard from '~/domain/category/components/category-card'

export default function CategoriesCatalog() {
  const { i18n } = useLingui()

  const { data: catalog, status } = useCatalog()
  const { data: amounts } = useProductsAmountByCategory(
    { ids: status === 'success' ? Object.keys(catalog) : [] },
    { enabled: status === 'success' },
  )

  const products = status === 'success' ? Object.values(catalog) : []

  return (
    <>
      <PageHeader title={t`Каталог товаров`} backRoutePath={RoutePathEnum.DASHBOARD} />

      <div className="grid gap-4 lg:grid-cols-2">
        {products.map(({ code, names, images }) => (
          <CategoryCard
            key={code}
            code={code}
            count={amounts ? amounts[code].amount : 0}
            name={names[i18n.locale]}
            imageSrc={images[0]}
          />
        ))}
      </div>
    </>
  )
}
