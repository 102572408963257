import { useMutation } from '@tanstack/react-query'

import { sendPhoneCode } from '~/api/user-api'

type Response = Awaited<ReturnType<typeof sendPhoneCode>>['data']
type Variables = Parameters<typeof sendPhoneCode>[0]

export function useSendPhoneCode() {
  return useMutation<Response, Error, Variables>({
    mutationFn: async (variables) => {
      const { data } = await sendPhoneCode(variables)
      return data
    },
  })
}
