import { i18n } from '@lingui/core'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'

import { cn } from '../../lib/react'
import { Icon } from '../icon'

import type { Item } from './interface'

interface Props {
  items: Item[]
}

export default function Menu({ items }: Props) {
  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>
        <button className="group inline-flex p-2" type="button">
          <Icon
            name="options"
            size={24}
            className="text-gray-text-2 group-hover:text-blue"
          />
        </button>
      </DropdownMenu.Trigger>

      <DropdownMenu.Portal>
        <DropdownMenu.Content
          className={cn(
            'data-[side=bottom]:animate-slideUpAndFade data-[side=left]:animate-slideRightAndFade data-[side=right]:animate-slideLeftAndFade data-[side=top]:animate-slideDownAndFade ',
            'popover p-3.5 will-change-[opacity,transform] ',
          )}
          sideOffset={5}
        >
          <div className="grid -m-1">
            {items.map(
              ({ icon, visible = true, label, handler, disabled, iconClassName }) =>
                visible
                  ? (
                    <DropdownMenu.Item
                      disabled={disabled}
                      onClick={handler}
                      key={label.id}
                      className="group inline-flex select-none items-center p-1 outline-none data-[disabled]:pointer-events-none data-[disabled]:opacity-40"
                    >
                      <Icon name={icon} size={24} className={cn('mr-2.5', iconClassName)} />
                      <span className="text-md text-black font-medium group-data-[disabled]:text-[inherit] group-data-[highlighted]:text-blue">
                        {i18n._(label)}
                      </span>
                    </DropdownMenu.Item>
                    )
                  : null,
            )}
          </div>
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  )
}
