import { QueryAsyncBoundary } from '@suspensive/react-query'
import { Spin } from 'antd'

import Form from './components/form'
import RejectedFallback from './components/rejected-fallback'

interface Props {
  inviteToken: string
}

export default function AcceptInvitationForm({ inviteToken }: Props) {
  return (
    <QueryAsyncBoundary
      pendingFallback={(
        <div className="p-5 text-center">
          <Spin size="large" />
        </div>
      )}
      rejectedFallback={RejectedFallback}
    >
      <Form inviteToken={inviteToken} />
    </QueryAsyncBoundary>
  )
}
