import { PlusOutlined } from '@ant-design/icons'
import { t } from '@lingui/macro'
import { useResponsive } from 'ahooks'
import { Button, Tooltip } from 'antd'

import { Icon } from '~/shared/components/icon'
import { useGlobalDialog } from '~/shared/context/global-dialog'
import { useGetUser } from '~/shared/tanstack/queries/use-get-user'
import WelcomeTourTarget from '~/welcome-tour/target'

export default function RequestButtonWithInfo() {
  const responsive = useResponsive()
  const { openCreateOrderDialog } = useGlobalDialog()

  const { data: user } = useGetUser()

  const hasCompany = user?.companies.length !== 0

  return (
    <WelcomeTourTarget
      name="createRequest"
      visible={Boolean(responsive.md)}
      rootClassName="-mx-3 mb-8 flex items-center"
    >
      <Button
        title={!hasCompany ? t`Создайте компанию чтобы отправлять запросы` : undefined}
        disabled={!hasCompany}
        size="large"
        type="primary"
        className="hidden xl:inline-block"
        icon={<PlusOutlined />}
        onClick={openCreateOrderDialog}
      >
        {t`Создать Запрос`}
      </Button>

      <Tooltip title={!hasCompany ? t`Создайте компанию чтобы отправлять запросы` : undefined}>
        <Button
          size="large"
          type="primary"
          onClick={openCreateOrderDialog}
          className="xl:hidden"
          icon={<PlusOutlined />}
          disabled={!hasCompany}
        />
      </Tooltip>

      <Tooltip
        title={t`Оставьте нам свои пожелания и технические требования для покупки товара, чтобы мы могли подготовить для вас персональное предложение, учитывающее все ваши предпочтения.`}
      >
        <button className="hidden p-2 -mr-2 xl:inline-flex" type="button">
          <Icon name="info" size={22} />
        </button>
      </Tooltip>
    </WelcomeTourTarget>
  )
}
