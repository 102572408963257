import { createSafeContext } from '../lib/react'

interface GlobalDialogContext {
  isCreateOrderDialogOpen: boolean
  openCreateOrderDialog: () => void
  closeCreateOrderDialog: () => void
  //
  editOrderDialogOrderId: Nullable<number>
  isEditOrderDialogOpen: boolean
  openEditOrderDialog: (orderId: number) => void
  closeEditOrderDialog: () => void
  //
  isNewCompanyDialogOpen: boolean
  openNewCompanyDialog: () => void
  closeNewCompanyDialog: () => void
  //
  isUserSignUpWithSupplierSearchModalOpen: boolean
  openUserSignUpWithSupplierSearchModal: () => void
  closeUserSignUpWithSupplierSearchModal: () => void
}

export const [GlobalDialogProvider, useGlobalDialog] = createSafeContext<GlobalDialogContext>({
  name: 'GlobalDialogContext',
  providerName: 'GlobalDialogProvider',
  hookName: 'useGlobalDialog',
  strict: true,
})
