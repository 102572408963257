import { t } from '@lingui/macro'

import Drawer from './components/drawer'
import { getInitialValues } from './utils/order-util'

import { useCreatedOrderDetails } from '~/pages/orders/created/queries/use-created-order-details'

interface EditModeProps {
  open: boolean
  onClose: () => void
  orderId: number
  mode: 'edit'
}

interface CreateModeProps {
  open: boolean
  onClose: () => void
  mode: 'create'
  orderId?: never
}

type Props = EditModeProps | CreateModeProps

export default function PersonalOrderDrawer({ onClose, open, orderId, mode }: Props) {
  const { data: order, isFetching, isSuccess } = useCreatedOrderDetails(
    { id: orderId! },
    {
      enabled: Boolean(orderId) && mode === 'edit',
      // @ts-expect-error FIXME(swagger):
      select: data => data.request,
      retry: false,
    },
  )

  // FIXME: Обновить формы создания запроса
  if (mode === 'create') {
    return (
      <Drawer
        title={t`Создание запроса`}
        onClose={onClose}
        open={open}
        loading={false}
      />
    )
  }

  return isSuccess && (
    <Drawer
      title={t`Редактировать`}
      onClose={onClose}
      open={open}
      defaultOrderId={orderId}
      initialValues={order ? getInitialValues(order) : undefined}
      loading={isFetching}
    />
  )
}
