import { FileImageOutlined } from '@ant-design/icons'
import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { useQueryClient } from '@tanstack/react-query'
import { Button } from 'antd'

import { useDeleteOrderImage } from '../hooks/use-delete-order-image'

import { buildApiUrl } from '~/api/utils'
import FileUpload from '~/components/file-upload'
import { useCreatedOrderDetails } from '~/pages/orders/created/queries/use-created-order-details'
import type { PublicImage } from '~/shared/api'
import { companyKeys } from '~/shared/tanstack/keys/company'
import { ACTIVE_COMPANY_TOKEN_STORAGE_KEY } from '~/domain/company/api'

interface Props {
  orderId: number
}

const ADD_ORDER_IMAGE_PATH = '/api/orders/request/image/add/'

export default function ImageUpload({ orderId }: Props) {
  useLingui()

  const queryClient = useQueryClient()

  function updateOrderData() {
    queryClient.invalidateQueries({ queryKey: companyKeys.createdOrder(orderId) })
  }

  const { data: images } = useCreatedOrderDetails(
    { id: orderId },
    // @ts-expect-error FIXME:
    { select: data => data.request.images, useErrorBoundary: true },
  )
  const { mutateAsync: deleteFile } = useDeleteOrderImage({ onSuccess: updateOrderData })

  function handleRemove(fileId: number) {
    return deleteFile({ file_id: fileId, request_id: orderId })
  }

  return (
    <FileUpload
      onSuccess={updateOrderData}
      onRemove={handleRemove}
      defaultFileList={images as unknown as PublicImage[]}
      action={buildApiUrl(ADD_ORDER_IMAGE_PATH)}
      headers={{ authorization: `Token ${localStorage.getItem(ACTIVE_COMPANY_TOKEN_STORAGE_KEY)}` }}
      data={{ request_id: orderId }}
      accept=".jpg, .jpeg, .png"
      trigger={<Button type="link" icon={<FileImageOutlined />}>{t`Загрузить фото`}</Button>}
    />
  )
}
