import { Plural } from '@lingui/macro'
import { Link } from 'react-router-dom'

interface Props {
  name: string
  imageSrc: string
  count: number
  code: string
}

export default function CategoryCard({ name, imageSrc = '', count, code }: Props) {
  const hasImageSrc = imageSrc.length > 0

  return (
    <Link
      to={{ search: `code=${code}` }}
      className="relative h-[7.5rem] overflow-hidden rounded-2xl bg-white p-5 transition-shadow delay-75 xl:h-[12.5rem] hover:shadow-2xl"
    >
      <div className="h-full flex flex-col justify-between pr-[8.125rem] xl:pr-[13.75rem]">
        <p className="line-clamp-2 font-medium xl:line-clamp-4">{name}</p>
        <span className="text-md text-gray-text-2">
          <Plural
            value={count}
            _0="нет товаров"
            one="# товар"
            few="# товара"
            other="# товаров"
          />
        </span>
      </div>

      {hasImageSrc && (
        <img
          className="absolute top-6 h-[7.5rem] w-[8.125rem] object-contain -right-6 xl:top-8 xl:h-[12.5rem] xl:w-[13.75rem] xl:-right-8"
          src={imageSrc}
          width={130}
          height={120}
          alt=""
        />
      )}
    </Link>
  )
}
