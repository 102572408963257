import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import clsx from 'clsx'

import type { ReactNode } from 'react'
import type { OrderChain } from '../../types'
import Ceil from './ceil'

import { Icon } from '~/shared/components/icon'
import { Panel } from '~/shared/components/panel'
import { RatingValueIcon } from '~/shared/components/rating-value-icon'
import { dateFormat, priceFormat } from '~/shared/lib/format'

interface Props {
  chain: OrderChain
  orderId: number
  onOpenDetails: (chain: OrderChain) => void
  status?: (status: OrderChain['status']) => ReactNode
}

export default function OrderChainCard({ chain, onOpenDetails, status: renderStatus }: Props) {
  useLingui()

  const { status, company_executor: companyExecutor, comment, time, price } = chain

  return (
    <Panel className="group hover:shadow-panel-hover transition-shadow">
      <button
        onClick={() => onOpenDetails(chain)}
        type="button"
        className={clsx(
          'grid w-full grid-cols-[1fr_min-content] justify-items-start gap-y-2 p-4 text-left text-md',
          'xl:grid-cols-[2fr_2fr_1fr_min-content_min-content] xl:items-center xl:gap-x-4 xl:gap-y-0',
        )}
      >
        {renderStatus && (
          <div className="xl:col-start-4">
            {renderStatus(status)}
          </div>
        )}

        <div className="col-start-2 xl:col-start-5">
          <Icon size={24} name="arrow-right" className="group-hover:text-blue" />
        </div>

        <div className="flex items-center gap-4 text-black xl:col-start-1 xl:row-start-1">
          <RatingValueIcon value={companyExecutor.rating} />
          <p className="break-all font-medium xl:order-1">{companyExecutor.name}</p>
        </div>

        <p
          className={clsx(
            'col-start-1 line-clamp-2 text-gray-text-3',
            'xl:col-start-2 xl:row-start-1 xl:w-full xl:max-w-[500px]',
          )}
        >
          {comment.replace(/\\n/g, ' ')}
        </p>

        <div
          className={clsx('col-start-1 flex gap-7 pt-2', 'xl:col-start-3 xl:row-start-1 xl:gap-5 xl:pt-0')}
        >
          <Ceil title={t`Дата`} value={time ? dateFormat(new Date(time)) : null} hiddenWhenNull />
          <Ceil className="font-bold" title={t`Стоимость`} value={priceFormat(price)} />
        </div>
      </button>
    </Panel>
  )
}
