import { t } from '@lingui/macro'
import { Button, Result } from 'antd'
import { lazy, useState } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'

import { USER_TOKEN_STORAGE_KEY } from '~/api/user-api'
import LockScreen from '~/components/lock-screen'
import { RoutePathEnum } from '~/enums/route-path-enum'
import { useGetUser } from '~/shared/tanstack/queries/use-get-user'

const TelegramTip = lazy(() => import('~/notifications/telegram-tip'))

export default function RequireAuth() {
  const location = useLocation()
  const navigate = useNavigate()

  const [token] = useState<Nullable<string>>(localStorage.getItem(USER_TOKEN_STORAGE_KEY))

  const { status: userStatus, isLoading } = useGetUser()

  if (!token || userStatus === 'error') {
    return (
      <Result
        status="error"
        title={t`Ошибка`}
        subTitle={t`Доступ к данному разделу разрешен только авторизованным пользователям.`}
        extra={[
          <Button
            type="primary"
            key="home"
            onClick={() => {
              navigate(RoutePathEnum.SIGN_IN, {
                state: { from: location },
                replace: true,
              })
            }}
          >
            {t`Войти`}
          </Button>,
        ]}
      />
    )
  }

  if (isLoading)
    return <LockScreen loading />

  return (
    <>
      <Outlet />
      <TelegramTip />
    </>
  )
}
