import type { CreatedOrdersPayload, ReceivedOrdersPayload } from '~/domain/order/types'
import type { ListParams } from '~/shared/types/api'

export const companyKeys = {
  product: (companyId: number) => ['company', companyId, 'product'] as const,
  products: (params: Partial<ListParams>) => ['company', params, 'products'] as const,
  detail: (companyId?: number) => ['company', companyId, 'info'] as const,
  memberDetail: (memberId?: number) => ['company', memberId, 'member'] as const,
  members: (params: Partial<ListParams>) => ['company', 'members', params] as const,
  memberPersmissions: () => ['company', 'member', 'permissions'] as const,
  receivedOrders: (params: Partial<ReceivedOrdersPayload>) => ['company', 'receivedOrders', params] as const,
  receivedOrderDetail: (orderId: number) => ['company', 'receivedOrders', orderId, 'detail'] as const,
  createdOrders: (params: Partial<CreatedOrdersPayload>) => ['company', 'createdOrders', params] as const,
  createdOrderDetail: (orderId: number) => ['company', 'createdOrders', orderId, 'detail'] as const,
  createdPersonalOrderDetail: (orderId: number) => ['company', 'createdPersonalOrders', orderId, 'detail'] as const,
  productFilesProcess: () => ['company', 'productFilesProcess'] as const,
  orderStats: () => ['company', 'orderStats'] as const,
  search: (inn: string) => ['company', inn, 'search'] as const,
  okvedSearch: (query: string) => ['company', query, 'okvedSearch'] as const,
}
