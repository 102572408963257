import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import clsx from 'clsx'
import { lazy, useMemo } from 'react'
import { Link } from 'react-router-dom'

import { findCategoryByCode } from '../utils/find-category-by-code'
import { findParentsCategories } from '../utils/find-parents-categories'

import { useCatalogParams } from '../context'

import { Breadcrumbs } from '~/shared/components/breadcrumbs'
import PageTitle from '~/shared/components/page-title'
import type { CatalogCategory } from '~/domain/product/api'

const SubCategoriesList = lazy(() => import('./sub-categories-list'))
const Products = lazy(() => import('./products'))

interface Props {
  categories: Indexed<string, CatalogCategory>
}

export default function SubCategoriesCatalog({ categories }: Props) {
  const { i18n } = useLingui()

  const { params } = useCatalogParams()
  const { code } = params

  const category = useMemo(() => findCategoryByCode(categories, code), [code])
  const parents = useMemo(() => findParentsCategories(categories, code), [code])

  if (!category)
    return <div>{t`Не найдено`}</div>

  const breadcrumbs = parents.map(({ names, url }) => ({
    key: url,
    title: (
      <Link
        className={clsx('text-blue hover:text-blue-dark', {
          'pointer-events-none text-gray-text-2': code === url,
        })}
        to={{ search: `code=${url}` }}
      >
        {names[i18n.locale]}
      </Link>
    ),
  }))

  return (
    <>
      <Breadcrumbs items={breadcrumbs} />
      <PageTitle title={category.names[i18n.locale]} />

      <div className="grid gap-4 md:gap-9">
        {!category.is_leaf && <SubCategoriesList category={category} />}
        <Products categoryCode={category.code} />
      </div>
    </>
  )
}
