import clsx from 'clsx'
import { forwardRef } from 'react'

import type { BaseFieldProps } from '../base-form-control'

interface OwnProps {
  iconStart?: React.ReactNode
  iconEnd?: React.ReactNode
}

export type Props = BaseFieldProps & OwnProps & React.ComponentPropsWithoutRef<'input'>

const TextField = forwardRef<HTMLInputElement, Props>(
  (
    {
      variant = 'filled',
      className,
      placeholder,
      error,
      errorMessage,
      label,
      id,
      iconStart,
      iconEnd,
      required,
      valid,
      accent = false,
      ...props
    },
    ref,
  ) => {
    const hasPlaceholder = Boolean(placeholder)
    const hasIcon = iconEnd || iconStart

    let errorMessageNode: React.ReactNode
    if (errorMessage || error) {
      errorMessageNode = (
        <div className="mt-1 text-sm text-red">{errorMessage || error}</div>
      )
    }

    let iconStartNode: React.ReactNode
    if (iconStart)
      iconStartNode = <div className="inset-y-center absolute left-3.5">{iconStart}</div>

    let iconEndNode: React.ReactNode
    if (iconEnd)
      iconEndNode = <div className="inset-y-center absolute right-4">{iconEnd}</div>

    return (
      <div className={clsx('relative', className)}>
        <input
          {...props}
          id={id}
          ref={ref}
          className={clsx('form-field peer h-[3.25rem]', {
            'form-field-outlined': variant === 'outlined',
            'form-field-accent': accent && variant === 'filled',
            'form-field-accent-off': !accent && variant === 'filled',
            'form-field-start-icon': iconStart,
            'form-field-end-icon': iconEnd,
            'form-field-no-icon': !hasIcon,
            'form-field-valid': Boolean(valid),
            'form-field-invalid': Boolean(errorMessage) || Boolean(error),
          })}
          placeholder={hasPlaceholder ? placeholder : ' '}
        />

        <label
          data-icon-left={!!iconStart}
          className={[
            'form-field-label',
            'data-[icon-left=true]:left-12',
            'data-[icon-left=false]:left-3.5',
          ].join(' ')}
          htmlFor={id}
        >
          {label}
          {' '}
          {required && <span className="text-red">*</span>}
        </label>

        {iconStartNode}
        {iconEndNode}
        {errorMessageNode}
      </div>
    )
  },
)

TextField.displayName = 'TextField'

export default TextField
