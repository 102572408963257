import React from 'react'
import { useLocation } from 'react-router-dom'

export function withYandexMetrika(Component: React.ComponentType) {
  const displayName = Component.displayName || Component.name || 'Component'

  function ComponentsWithYandexMetrika() {
    const location = useLocation()

    const { pathname } = location

    React.useEffect(() => {
      if ('ym' in window)
        window.ym(Number(__YANDEX_METRIKA_ID__), 'hit', pathname)
    }, [pathname])

    return <Component />
  }

  ComponentsWithYandexMetrika.displayName = `withYandexMetrika(${displayName})`
  return ComponentsWithYandexMetrika
}
