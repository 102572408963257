import type { TableProps } from 'antd'
import { Table } from 'antd'
import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import type { ReceivedOrderTableRow } from '../types'
import { getOrderTypeName, prepareTableData } from '../utils'
import TableActionMenu from './table-action-menu'
import { dateFormat } from '~/shared/lib/format'
import ReceivedOrderStatusTag from '~/shared/components/received-order-status-tag'
import type { ReceivedOrdersResponse } from '~/domain/order/types'

const { Column } = Table

interface Props {
  orders: ReceivedOrdersResponse['data']
  loading: TableProps<ReceivedOrderTableRow>['loading']
  pagination: TableProps<ReceivedOrderTableRow>['pagination']
}

export default function OrdersTable({ orders, loading, pagination }: Props) {
  useLingui()

  const dataSource = useMemo(() => prepareTableData(orders), [orders])

  return (
    <Table<ReceivedOrderTableRow>
      pagination={pagination}
      dataSource={dataSource}
      loading={loading}
    >
      <Column<ReceivedOrderTableRow>
        key="createdAt"
        title={t`Дата`}
        dataIndex="createdAt"
        responsive={['lg']}
        render={value => dateFormat(new Date(value))}
      />
      <Column<ReceivedOrderTableRow>
        key="productName"
        title={t`Название товара`}
        dataIndex="productName"
        render={(value, record) => {
          return <Link to={`/o/received/${record.orderId}`}>{value}</Link>
        }}
      />
      <Column<ReceivedOrderTableRow>
        key="status"
        title={t`Статус`}
        dataIndex="status"
        responsive={['sm']}
        render={(_, record) => <ReceivedOrderStatusTag status={record.status} />}
      />
      <Column<ReceivedOrderTableRow>
        key="orderType"
        title={t`Тип запроса`}
        dataIndex="orderType"
        responsive={['sm']}
        render={(_, record) => getOrderTypeName(record.orderType)}
      />
      <Column<ReceivedOrderTableRow>
        key="amount"
        title={t`Количество`}
        dataIndex="amount"
        responsive={['md']}
      />
      <Column<ReceivedOrderTableRow>
        key="preferableTime"
        title={t`Срок до`}
        dataIndex="preferableTime"
        responsive={['lg']}
        render={(_, record) => dateFormat(new Date(record.preferableTime))}
      />
      <Column<ReceivedOrderTableRow>
        align="center"
        key="action"
        title={t`Действие`}
        render={(_, record) => <TableActionMenu orderId={record.orderId} status={record.status} productName={record.productName} />}
      />
    </Table>
  )
}
