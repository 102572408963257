import { forwardRef } from 'react'
import { Link, useLocation } from 'react-router-dom'

import { cn } from '~/shared/lib/react'
import type { IconName } from '~/shared/components/icon'
import { Icon } from '~/shared/components/icon'

interface Props {
  name: string
  to: string
  icon: IconName
}

const BottomNavBarItem = forwardRef<HTMLAnchorElement, Props>(
  ({ icon, name, to }, forwardedRef) => {
    const location = useLocation()

    return (
      <Link
        ref={forwardedRef}
        to={to}
        className="h-16 w-14 flex flex-col items-center justify-center space-y-1.5"
      >
        <Icon
          name={icon}
          size={24}
          className={cn('text-black', {
            'text-blue': location.pathname === to,
          })}
        />
        <span className="text-xs text-gray-text-3">{name}</span>
      </Link>
    )
  },
)

BottomNavBarItem.displayName = 'BottomNavBarItem'

export default BottomNavBarItem
