import { cn } from '~/shared/lib/react'

interface Props {
  className?: string
  count?: number
  containerClassName?: string
}

export default function SkeletonRoot({
  className,
  containerClassName,
  count = 1,
}: Props) {
  const items = Array.from({ length: count }, (_, index) => index + 1)

  return (
    <div className={containerClassName}>
      {items.map(item => (
        <span key={item} className={cn('shimmer block', className)} />
      ))}
    </div>
  )
}
