import { t } from '@lingui/macro'
import { configResponsive } from 'ahooks'
import { App, ConfigProvider } from 'antd'
import enUS from 'antd/es/locale/en_US'
import ruRu from 'antd/es/locale/ru_RU'
import dayjs from 'dayjs'
import React from 'react'

// TODO: Обновлять при изменении языка
import 'dayjs/locale/ru'

import { getSavedLocale } from '~/shared/lib/i18n'
import { getDisplayName } from '~/shared/lib/react'

dayjs.locale('ru')

configResponsive({
  'sm': 640,
  'md': 768,
  'lg': 1024,
  'xl': 1280,
  '2xl': 1536,
})

const LOCALE = {
  ru: ruRu,
  en: enUS,
}

export function withAntdConfig(Component: React.ComponentType) {
  const savedLocale = getSavedLocale()

  function ComponentsWithAntdConfig() {
    return (
      <ConfigProvider

        form={{
          validateMessages: {
            required: t`Обязательное поле`,
            types: {
              email: t`Неверный формат электронной почты`,
              url: t`Неверный формат URL`,
            },
          },
        }}
        pagination={{ showSizeChanger: false }}
        locale={LOCALE[savedLocale as keyof typeof LOCALE]}
        theme={{
          components: {
            Notification: { width: 500 },
          },
          token: {
            motion: false,
            fontFamily: 'Inter',
            colorPrimary: '#6266eb',
            colorInfo: '#6266eb',
            colorTextBase: '#0c0c0c',
            // colorBgBase: '#0c0c0c',
            // fontSize: 16,
            borderRadius: 6,
          },
        }}
      >
        <App>
          <Component />
        </App>
      </ConfigProvider>
    )
  }

  ComponentsWithAntdConfig.displayName = `withAntdConfig(${getDisplayName(Component)})`
  return ComponentsWithAntdConfig
}
