import { Modal } from 'antd'
import { t } from '@lingui/macro'
import UserSignUpWithSupplierSearchModal from '../user/UserSignUpWithSupplierSearchModal'
import CompanyCreateDialog from '~/components/company/CompanyCreateDialog'
import PersonalOrderDrawer from '~/personal-order-drawer'
import { useGlobalDialog } from '~/shared/context/global-dialog'

export default function ModalContainer() {
  const {
    closeCreateOrderDialog,
    isCreateOrderDialogOpen,
    //
    isNewCompanyDialogOpen,
    closeNewCompanyDialog,
    //
    editOrderDialogOrderId,
    isEditOrderDialogOpen,
    closeEditOrderDialog,
    //
    isUserSignUpWithSupplierSearchModalOpen,
    closeUserSignUpWithSupplierSearchModal,
  } = useGlobalDialog()

  return (
    <>
      <Modal
        open={isUserSignUpWithSupplierSearchModalOpen}
        onCancel={closeUserSignUpWithSupplierSearchModal}
        closable={false}
        footer={null}
        title={t`Запрос на оборудование`}
        width={400}
        destroyOnClose
        centered
      >
        <UserSignUpWithSupplierSearchModal onClose={closeUserSignUpWithSupplierSearchModal} />
      </Modal>

      <CompanyCreateDialog open={isNewCompanyDialogOpen} onClose={closeNewCompanyDialog} />

      <PersonalOrderDrawer open={isCreateOrderDialogOpen} onClose={closeCreateOrderDialog} mode="create" />

      {editOrderDialogOrderId && (
        <PersonalOrderDrawer
          open={isEditOrderDialogOpen}
          onClose={closeEditOrderDialog}
          orderId={editOrderDialogOrderId}
          mode="edit"
        />
      )}
    </>
  )
}
