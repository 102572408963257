import { useQuery } from '@tanstack/react-query'
import omit from 'lodash.omit'
import { getCatalog } from '~/domain/product/api'

import { productsKeys } from '~/shared/tanstack/keys/products'

export function useCatalog() {
  return useQuery({
    queryKey: productsKeys.catalog(),
    queryFn: async () => {
      const { data } = await getCatalog()
      return omit(data, ['meta'])
    },
  })
}
