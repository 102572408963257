import type { SelectProps } from 'antd'
import dayjs from 'dayjs'

import { TOTAL_PRICE_LIMIT } from '../constants'
import type { FormState } from '../interface'

import type { CategoryOption } from '~/entities/category'

export function getCategoryCode(category: CategoryOption) {
  return category.id
}

function getTotalPrice(formValues: FormState) {
  return formValues.preferable_price * formValues.amount
}

export function prepareOrderData(orderId: number, formValues: FormState) {
  return {
    address: formValues.address,
    extra_fields: formValues.extra_fields,
    description: formValues.description,
    request: formValues.request,
    id: orderId,
    category: formValues.category ? getCategoryCode(formValues.category) : null,
    preferable_price: getTotalPrice(formValues),
    preferable_time: formValues?.preferable_time?.toISOString(),
  }
}

export function isOverLimit(value: number) {
  return value > TOTAL_PRICE_LIMIT
}

export function getInitialValues(order: any): FormState {
  return {
    category: order?.category?.name,
    request: order.request ?? '',
    amount: order.amount,
    description: order.description ?? '',
    preferable_time: order?.preferable_time ? dayjs(order.preferable_time) : undefined,
    preferable_price: order?.preferable_price,
    address: order.address,
    extra_fields: order.extra_fields,
  }
}

export function categoriesToOptions(
  categories: CategoryOption[],
): SelectProps<string, CategoryOption>['options'] {
  return categories.map(category => ({
    ...category,
    label: category.name,
    value: category.id,
  }))
}
