import type React from 'react'
import { useNavigate } from 'react-router-dom'

interface FormValues {
  q: string
}

export function useSearchField() {
  const navigate = useNavigate()

  const handleSearch = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const { q: query } = Object.fromEntries(
      new FormData(event.currentTarget),
    ) as unknown as FormValues

    if (query.trim()) {
      navigate({
        pathname: '/p',
        search: `?q=${query}`,
      })
    }
  }

  return { handleSearch }
}
