import { DeleteOutlined, EditOutlined, EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons'
import { t } from '@lingui/macro'
import { useQueryClient } from '@tanstack/react-query'
import { Button, Spin } from 'antd'
import useToken from 'antd/es/theme/useToken'
import { useNavigate, useParams } from 'react-router-dom'

import ProductsCard from '~/components/products/ProductsCard'
import NotFoundProduct from '~/core/ui/error-screens/NotFoundProduct'
import useDeleteCompanyProduct from '~/pages/company/hooks/use-delete-company-product'
import useUpdateCompanyProductStatus from '~/pages/company/hooks/use-update-company-product-status'
import { AVAILABLE_STATUS } from '~/shared/constants'
import { showMessage } from '~/shared/show-message'
import { companyKeys } from '~/shared/tanstack/keys/company'
import { useCompanyProductDetails } from '~/shared-queries/use-product-details.query'
import PageHeader from '~/components/common/PageHeader'
import { RoutePathEnum } from '~/enums/route-path-enum'

export default function CompanyProductPage() {
  const { id: productId } = useParams()

  const { data: product, status } = useCompanyProductDetails(
    { id: Number(productId) },
    { refetchOnWindowFocus: true },
  )

  if (status === 'loading')
    return <Spin size="large" />

  if (status === 'error')
    return <NotFoundProduct />

  return (
    <>
      <PageHeader title={t`Детали товара`} backRoutePath={RoutePathEnum.COMPANY_PRODUCTS} />
      <Product product={product} />
    </>
  )
}

function Product({ product }: any) {
  const [, token] = useToken()

  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const { mutate: deleteProduct } = useDeleteCompanyProduct()
  const { mutate: updateStatus } = useUpdateCompanyProductStatus()

  const isAvailable = product.is_available === AVAILABLE_STATUS.active

  function handleUpdateStatus() {
    const newStatus = isAvailable ? AVAILABLE_STATUS.inactive : AVAILABLE_STATUS.active

    updateStatus(
      { id: product.id, availablesValue: newStatus },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries({
            queryKey: companyKeys.product(product.id),
          })

          if (newStatus === AVAILABLE_STATUS.active)
            showMessage(t`Товар теперь виден`, 'success')
          else
            showMessage(t`Товар скрыт`, 'success')
        },
        onError: () => showMessage(t`Произошла ошибка. Попробуйте ещё раз`, 'error'),
      },
    )
  }

  function handleDeleteProduct() {
    deleteProduct(product.id, {
      onSuccess: () => navigate('/company/p'),
    })
  }

  return (
    <ProductsCard
      vendorCode={product?.article_number || null}
      series={product?.product_series || null}
      site={product?.product_link || null}
      product={{
        category: product.category?.name ?? null,
        name: product.name,
        description: product.description,
        images: product.images,
        id: product.id,
        extra_fields: product.extra_fields,
        files: product.files,
        condition: product.condition,
        price: product.price,
        rating: product.rating,
        is_available: product.is_available,
        categories: product.categories,
      }}
      actions={(
        <div className="flex flex-wrap items-center gap-5">
          <Button
            type="primary"
            size="large"
            icon={<EditOutlined />}
            onClick={() => navigate(`/company/p/${product.id}/edit`)}
          >
            {t`Редактировать`}
          </Button>

          <Button
            type="primary"
            size="large"
            icon={isAvailable ? <EyeInvisibleOutlined /> : <EyeOutlined />}
            onClick={handleUpdateStatus}
            style={{ backgroundColor: isAvailable ? token.colorWarning : token.colorSuccess }}
          >
            {isAvailable ? t`Скрыть` : t`Показать`}
          </Button>

          <Button type="primary" size="large" icon={<DeleteOutlined />} onClick={handleDeleteProduct} danger>
            {t`Удалить`}
          </Button>
        </div>
      )}
    />
  )
}
