import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { lazy } from 'react'

import AuthenticationPagesLayout from '~/layouts/authentication'

const SignInForm = lazy(() => import('~/authentication/forms/sign-in'))

export default function SignInPage() {
  useLingui()

  return (
    <AuthenticationPagesLayout title={t`Войти`}>
      <SignInForm />
    </AuthenticationPagesLayout>
  )
}
