import { t } from '@lingui/macro'
import clsx from 'clsx'
import { motion } from 'framer-motion'
import { nanoid } from 'nanoid'
import LandingGetStartedButton from './LandingGetStartedButton'

function getHowWorksItems() {
  return [
    {
      id: nanoid(),
      title: t`Создание заявки`,
      description: t`Покупатели создают запросы на приобретение продукции с интересующими параметрами. Для создания запроса можно выбрать существующий товар, воспользоваться шаблонами, имеющимися на сайте, или составить индивидуальную заявку`,
    },
    {
      id: nanoid(),
      title: t`Рассылка поставщикам`,
      description: t`Данный запрос анализируется системой и автоматически рассылается представленным на площадке продавцам соответствующего оборудования`,
    },
    {
      id: nanoid(),
      title: t`Предложения продавцов`,
      description: t`Продавцы отвечают на входящие запросы и высылают покупателю встречные ответы и предложения`,
    },
    {
      id: nanoid(),
      title: t`Выбор поставщика`,
      description: t`Покупателю отображаются различные ответы на его запрос. Он может уточнить детали в чате и выбрать наиболее подходящее и выгодное предложение`,
    },
    {
      id: nanoid(),
      title: t`Заключение сделки`,
      description: t`Покупатель выходит на сделку с продавцом выбранного предложения`,
    },
  ]
}

export default function LandingHowWorks() {
  return (
    <section className="py-5 lg:py-10" id="how_works">
      <h2 className="text-center text-xxl font-bold">
        {t`Как работает платформа?`}
      </h2>

      <motion.div className="[counter-reset:works] flex overflow-x-scroll pt-10 lg:flex-col lg:overflow-visible lg:pb-10">
        {getHowWorksItems().map(({ description, title, id }) => (
          <motion.div
            className={clsx(
              'group relative min-w-[240px] border-t-2 border-blue pr-5 pt-[90px] lg:gap-4',
              'lg:flex lg:w-full lg:border-t-0 lg:p-0 lg:even:justify-end',
              'after:absolute after:top-4 after:text-big after:font-bold after:text-gray-border-1 after:content-["0"counter(works)] after:[counter-increment:works]',
              'lg:after:top-1/2 lg:after:text-[64px] lg:after:odd:right-[40%] lg:after:even:left-[40%]',
              'before:absolute before:top-[-6px] before:h-[10px] before:w-[10px] before:rounded-full before:bg-blue before:content-[""]',
              'lg:before:left-1/2 lg:before:top-0 lg:before:inset-x-center',
            )}
            key={id}
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.9 }}
            variants={{
              offscreen: { opacity: 0.1 },
              onscreen: {
                opacity: 1,
                transition: { delay: 0.3 },
              },
            }}
          >
            <div className="inset-x-center absolute bottom-0 top-0 hidden h-full w-[2px] bg-blue lg:block" />
            <div
              className={clsx(
                'flex flex-col space-y-2',
                'lg:max-w-1/2 lg:pt-11 lg:group-odd:pr-11 lg:group-even:pl-11',
                'xl:pt-16 xl:group-odd:pr-16 xl:group-even:pl-16',
              )}
            >
              <h3 className="text-lg font-bold md:text-xxl">{title}</h3>
              <p className="text-md text-gray-text-3 md:text-lg">{description}</p>
            </div>
          </motion.div>
        ))}
      </motion.div>

      <div className="hidden justify-center lg:flex">
        <LandingGetStartedButton />
      </div>
    </section>
  )
}
