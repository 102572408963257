import NiceModal from '@ebay/nice-modal-react'
import React from 'react'

export function withNiceModal(Component: React.ComponentType) {
  const displayName = Component.displayName || Component.name || 'Component'

  function ComponentsWithNiceModal() {
    return (
      <NiceModal.Provider>
        <Component />
      </NiceModal.Provider>
    )
  }

  ComponentsWithNiceModal.displayName = `withNiceModal(${displayName})`
  return ComponentsWithNiceModal
}
