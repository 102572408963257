import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { Statistic } from 'antd'

interface Props {
  error: boolean
  price: number
}

export default function TotalPrice({ error, price }: Props) {
  useLingui()

  return (
    <div className="mb-2">
      <Statistic
        valueStyle={error ? { color: 'red' } : undefined}
        suffix="₽"
        title={t`Общая цена:`}
        value={price}
        groupSeparator=" "
      />

      {error && <p className="text-red">{t`Превышен лимит цены`}</p>}
    </div>
  )
}
