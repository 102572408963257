import clsx from 'clsx'
import React, { useRef } from 'react'
import { useFocusRing } from 'react-aria'
import { useToggle } from 'react-use'

import { focusRingClasses } from '~/core/utils/classes'
import { Icon } from '~/shared/components/icon'

interface AccordionProps {
  header?: React.ReactNode
  children: React.ReactNode
  className?: string
  open?: boolean
}

export default function Accordion({
  header,
  children,
  className,
  open = true,
}: AccordionProps) {
  const [isOpen, toggleOpen] = useToggle(open)

  const { focusProps, isFocusVisible } = useFocusRing()
  const contentRef = useRef<HTMLDivElement | null>(null)

  return (
    <div>
      {header && (
        <button
          {...focusProps}
          type="button"
          className={clsx(
            'flex w-full gap-1 text-start outline-none',
            isFocusVisible && focusRingClasses,
            className,
          )}
          onClick={toggleOpen}
        >
          {header}
          <Icon
            name="arrow-down"
            size={24}
            className={clsx('text-blue transition-transform', isOpen && 'rotate-180')}
          />
        </button>
      )}

      <div
        ref={contentRef}
        className={clsx('overflow-hidden transition-all', {
          'h-auto': isOpen,
          'h-0': !isOpen,
        })}
        style={isOpen ? { height: contentRef.current?.scrollHeight } : { height: '0px' }}
      >
        <div className="pt-2">{children}</div>
      </div>
    </div>
  )
}
