import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { Icon } from '~/shared/components/icon'
import { TextField } from '~/shared/components/text-field'

interface Props {
  searchPath?: string
  searchQueryKey?: string
  searchFieldLabel: string
  renderSubmitButton?: (onClick: () => void) => React.ReactNode
}

function isValidSearchQuery(query: string) {
  return query.trim() !== ''
}

export default function Navigator({
  searchPath,
  renderSubmitButton,
  searchFieldLabel,
  searchQueryKey,
}: Props) {
  const [searchQuery, setSearchQuery] = React.useState('')

  const navigate = useNavigate()
  const location = useLocation()

  function navigateToSearchPage() {
    navigate(
      {
        pathname: searchPath,
        search: `${searchQueryKey ?? 'q'}=${encodeURIComponent(searchQuery)}`,
      },
      { state: location },
    )
  }

  function handleSearch() {
    if (isValidSearchQuery(searchQuery))
      navigateToSearchPage()
  }

  function handleKeyPress(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === 'Enter' && isValidSearchQuery(searchQuery))
      navigateToSearchPage()
  }

  return (
    <div className="grid gap-4 sm:grid-cols-[1fr,auto]">
      <TextField
        iconEnd={(
          <div className="flex items-center">
            <Icon name="search" size={24} />
          </div>
        )}
        className="w-full"
        label={searchFieldLabel}
        value={searchQuery}
        onChange={e => setSearchQuery(e.target.value)}
        onKeyDown={handleKeyPress}
      />

      {renderSubmitButton?.(handleSearch)}
    </div>
  )
}
