import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import { PRODUCTS_PROCESSING_FILE_STATUS, getCompanyProductProcessingFiles } from '~/domain/company/api'

import { companyKeys } from '~/shared/tanstack/keys/company'

type Files = Awaited<ReturnType<typeof getCompanyProductProcessingFiles>>['data']['list']

function aggregateFilesStatuses(files: Files) {
  return files.reduce(
    (acc, table) => {
      switch (table.status) {
        case PRODUCTS_PROCESSING_FILE_STATUS.confirmed:
          return { ...acc, confirmed: acc.confirmed + 1 }

        case PRODUCTS_PROCESSING_FILE_STATUS.inProgress:
          return { ...acc, inProgress: acc.inProgress + 1 }

        case PRODUCTS_PROCESSING_FILE_STATUS.rejected:
          return { ...acc, rejected: acc.rejected + 1 }

        default:
          return acc
      }
    },
    { inProgress: 0, confirmed: 0, rejected: 0, total: files.length },
  )
}

export function useCompanyProductProcessingFiles() {
  const { data: files = [] } = useQuery({
    select: data => data.list,
    queryKey: companyKeys.productsProcessingFiles(),
    queryFn: async () => {
      const { data } = await getCompanyProductProcessingFiles()
      return data
    },
  })

  const statusesCount = useMemo(() => aggregateFilesStatuses(files), [files])
  const hasFilesInProgress = useMemo(
    () => files.some(t => t.status === PRODUCTS_PROCESSING_FILE_STATUS.inProgress),
    [files],
  )

  return { hasFilesInProgress, statusesCount }
}
