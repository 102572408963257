import type { CreatedOrdersPayload, ReceivedOrdersPayload } from '~/domain/order/types'
import type { ListParams } from '~/shared/api'

export const companyKeys = {
  all: ['company'],
  product: (id: number) => [...companyKeys.all, 'product', id],
  products: (params: Partial<ListParams>) => [...companyKeys.all, 'products', params],
  info: () => [...companyKeys.all, 'info'],
  member: (id?: number) => [...companyKeys.all, 'member', id],
  members: (params: Partial<ListParams>) => [...companyKeys.all, 'members', params],
  memberPersmissions: () => [...companyKeys.all, 'memberPersmissions'],
  receivedOrder: (id: number) => [...companyKeys.all, 'receivedOrder', id],
  receivedOrders: (params: Partial<ReceivedOrdersPayload>) => [...companyKeys.all, 'receivedOrders', params],
  createdOrder: (id: number) => [...companyKeys.all, 'createdOrder', id],
  createdPersonalOrder: (id: number) => [...companyKeys.all, 'createdPersonalOrder', id],
  createdOrders: (params: Partial<CreatedOrdersPayload>) => [...companyKeys.all, 'createdOrders', params],
  productsProcessingFiles: () => [...companyKeys.all, 'productsProcessingFiles'],
  orderStats: () => [...companyKeys.all, 'orderStats'],
}
