import { t } from '@lingui/macro'

import { isPublicImage } from '../utils'

import Ceil from './ceil'

import type { PublicDocument, PublicImage } from '~/shared/api'
import FileLink from '~/shared/components/file-link'

interface Props {
  files: (PublicDocument | PublicImage)[]
}

export default function AttachedFilesList({ files }: Props) {
  return (
    <Ceil
      title={t`Прикреплённые файлы`}
      value={(
        <div className="flex flex-col items-start space-y-2">
          {files.map(file =>
            isPublicImage(file)
              ? (
                <FileLink href={Array.isArray(file.links) ? file.links[0] : file.links} key={file.id}>
                  {file.name}
                </FileLink>
                )
              : (
                <FileLink href={Array.isArray(file.link) ? file.link[0] : file.link} key={file.id}>
                  {file.name}
                </FileLink>
                ),
          )}
        </div>
      )}
    />
  )
}
