import { useQuery } from '@tanstack/react-query'

import { getNotificationDetails } from '~/api/notification-api'
import { userKeys } from '~/shared/tanstack/keys/user'

export function useNotificationDetails(id: number) {
  return useQuery({
    queryKey: userKeys.notification(id),
    queryFn: async () => {
      const { data } = await getNotificationDetails(id)
      return data
    },
  })
}
